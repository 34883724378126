import React, { useEffect, useState } from "react";
import {
  Paper, Typography, Button, Box, Grid, Table, TableBody, TableCell, TableContainer, TableHead,
  TableRow,
} from "@mui/material";
import mudzie from "../images/Color logo - no background.png";
import twitter from "../images/twitter 1.png";
import facebook from "../images/facebook-app-symbol 1.png";
import linkdin from "../images/linkedin (2) 1.png";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import DescriptionIcon from "@mui/icons-material/Description";
// import PaymentIcon from "@mui/icons-material/Payment";
import DownloadIcon from "@mui/icons-material/CloudDownload";

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import TextField from '@mui/material/TextField';

import Logo from "../images/Color logo with background.png";
import { useUserData } from "../UserDataContext";
// import { renderToStaticMarkup } from "react-dom/server";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate } from "react-router-dom";
import Axios from "axios";
import { useForm } from "react-hook-form";

import ReactJsAlert from "reactjs-alert";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1740,
    },
  },
  palette: {
    primary: {
      main: "#1976D2", // Your primary color
    },
    secondary: {
      main: "#FF5722", // Your secondary color
    },
  },
  typography: {
    fontFamily: "Roboto, sans-serif", // Your preferred font
  },
});

// const cellStyles = {
//   display: "flex",
//   justifyContent: "space-between",
// };

const AccountPage = () => {
  // Fetch user information, plan details, expiry date, and invoice data from your backend/API
  const [value, setvalue] = useState(null);
  const { userData } = useUserData();
  const [anchorEl, setAnchorEl] = useState(null);
  const [team, setTeam] = useState(false)
  const [teamExpired, setTeamExpired] = useState(false)
  const [planActive, setPlanActive] = useState(false)
  const navigate = useNavigate();

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const user = {
    name: "John Doe",
    email: "john@example.com",
    plan: "Basic",
    expiryDate: "2023-12-31",
    invoice: {
      number: "INV-12345",
      downloadLink: "/path/to/invoice.pdf", // Replace with your actual download link
    },
  };

  // const handleDownload = () => {
  //   // Implement download logic here
  //   // For simplicity, we're opening a new window with invoice data for demonstration.
  //   const invoiceText = `Invoice Number: ${invoiceData.invoiceNumber}\nDate: ${invoiceData.date}\nTotal Amount: ${invoiceData.totalAmount}`;
  //   const newWindow = window.open();
  //   newWindow.document.write(`<pre>${invoiceText}</pre>`);
  //   newWindow.document.title = "Invoice";
  //   newWindow.print();
  //   newWindow.close();
  // };
  const [pro, setpro] = useState(null);
  const [users, setUser] = useState(null);
  const [history, setHistory] = useState(null);
  const [company, setcompany] = useState("");

  useEffect(() => {
    const user = localStorage.getItem("user");
    const userde = JSON.parse(user);
    setvalue(userde);
    if (userde?.companyName == null) {
      setcompany("");
    } else {
      setcompany(userde?.companyName);
    }
  }, []);

  useEffect(() => {
    const user = localStorage.getItem("user");
    const userde = JSON.parse(user);
    Axios.get(`${process.env.REACT_APP_BASE_URL}api/get-payment-details`, {
      headers: {
        Authorization: `Bearer ${userde.token}`,
      },
    })
      .then((res) => {
        console.log("<<<<<<<<<<<<<<<<<<<", res.data.data);
        setHistory(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    const user = localStorage.getItem("user");
    const userde = JSON.parse(user);
    const logId = localStorage.getItem("userId");
    // const today = new Date().toLocaleDateString();
    console.log(logId, "--142")
    Axios.get(
      `${process.env.REACT_APP_BASE_URL}api/admin/get-user-details/${logId}`,
      {
        headers: {
          Authorization: `Bearer ${userde.token}`,
        },
      }
    )
      .then((res) => {
        console.log("%%%%%%%%%%%%%%", res.data);
        console.log("plannnnnnnnnnnnnnnnn", res.data.user[0]);
        setUser(res.data.user[0]);
        if (res.data.user[res.data.user.length - 1].amount === 1000 || res.data.user[res.data.user.length - 1].amount === 10000) {
          setTeam(true)
          const givenDate = new Date(res.data.user[res.data.user.length - 1].expires_at);
          const currentDate = new Date();
          if (currentDate > givenDate)
            setTeamExpired(true)
          console.log("res.data.user[0].expires_at : ", res.data.user[res.data.user.length - 1].expires_at)
        } else {
          setTeam(false)
        }

        if (res.data.user[0].is_active_plan === 1) {
          setPlanActive(true)
        } else {
          setPlanActive(false)
        }

        if (
          res.data.user[0].mudziePlan === "Pro" &&
          res.data.user[0].period === "monthly" &&
          res.data.user[0].is_active_plan === 1 &&
          res.data.user[0].IndiOrTeam === 'Indi'
        ) {
          setpro("350");
        } else if (
          res.data.user[0].mudziePlan === "Pro" &&
          res.data.user[0].period === "monthly" &&
          res.data.user[0].is_active_plan === 1 &&
          res.data.user[0].IndiOrTeam === 'Indi'
        ) {
          setpro("1,400");
        }

        else if (
          res.data.user[0].mudziePlan === "Basic" &&
          res.data.user[0].period === "monthly" &&
          res.data.user[0].is_active_plan === 1 &&
          res.data.user[0].amount === 250
        ) {
          setpro("250");
        } else if (
          res.data.user[0].mudziePlan === "Basic" &&
          res.data.user[0].period === "monthly" &&
          res.data.user[0].is_active_plan === 0 &&
          res.data.user[0].amount === 1000
        ) {
          setpro("1,000");
          console.log("197==========")
        }
        else if (
          res.data.user[0].mudziePlan === "Ultra Pro" &&
          res.data.user[0].period === "monthly" &&
          res.data.user[0].is_active_plan === 1 &&
          res.data.user[0].IndiOrTeam === 'Indi'
        ) {
          setpro("450");
        } else if (
          res.data.user[0].mudziePlan === "Ultra Pro" &&
          res.data.user[0].period === "monthly" &&
          res.data.user[0].is_active_plan === 1 &&
          res.data.user[0].IndiOrTeam === 'Team'
        ) {
          setpro("1,800");
        }
        else if (
          res.data.user[0].mudziePlan === "Basic" &&
          res.data.user[0].period === "yearly" &&
          res.data.user[0].is_active_plan === 1 &&
          res.data.user[0].amount === 2500
        ) {
          setpro("2,500");
        } else if (
          res.data.user[0].mudziePlan === "Basic" &&
          res.data.user[0].period === "yearly" &&
          res.data.user[0].is_active_plan === 1 &&
          res.data.user[0].amount === 10000
        ) {
          setpro("10,000");
        }
        else if (
          res.data.user[0].mudziePlan === "Pro" &&
          res.data.user[0].period === "yearly" &&
          res.data.user[0].is_active_plan === 1 &&
          res.data.user[0].IndiOrTeam === 'Indi'
        ) {
          setpro("3,500");
        } else if (
          res.data.user[0].mudziePlan === "Pro" &&
          res.data.user[0].period === "yearly" &&
          res.data.user[0].is_active_plan === 1 &&
          res.data.user[0].IndiOrTeam === 'Team'
        ) {
          setpro("14,000");
        } else if (
          res.data.user[0].mudziePlan === "Ultra Pro" &&
          res.data.user[0].period === "yearly" &&
          res.data.user[0].is_active_plan === 1 &&
          res.data.user[0].IndiOrTeam === 'Team'
        ) {
          setpro("18,000");
        }
        else if (
          res.data.user[0].mudziePlan === "Ultra Pro" &&
          res.data.user[0].period === "yearly" &&
          res.data.user[0].is_active_plan === 1 &&
          res.data.user[0].IndiOrTeam === 'Individual'
        ) {
          setpro("4,500");
        }
        else {
          setpro("");
        }
        // if (
        //   res.data.user[0].mudziePlan == "Pro" &&
        //   res.data.user[0].period == "monthly"
        // ) {
        //   setpro("1500");
        // } else if (
        //   res.data.user[0].mudziePlan == "Basic" &&
        //   res.data.user[0].period == "monthly"
        // ) {
        //   setpro("1000");
        // } else if (
        //   res.data.user[0].mudziePlan == "Ultra Pro" &&
        //   res.data.user[0].period == "monthly"
        // ) {
        //   setpro("2500");
        // } else if (
        //   res.data.user[0].mudziePlan == "Basic" &&
        //   res.data.user[0].period == "yearly"
        // ) {
        //   setpro("10,000");
        // } else if (
        //   res.data.user[0].mudziePlan == "Pro" &&
        //   res.data.user[0].period == "yearly"
        // ) {
        //   setpro("15,000");
        // } else {
        //   setpro("25,000");
        // }
        // setplan(res.data.user[0]);
        // setSelectedPlan(res.data.user[0].mudziePlan);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // if (users?.mudziePlan == "Pro") {
  //   setpro("1500");
  // } else if (users?.mudziePlan == "Basic") {
  //   setpro("1000");
  // } else {
  //   setpro("2500");
  // }

  const handleDownload = () => {
    // Create an element that holds your invoice content with React components
    // const invoiceContent = (
    //   <div>
    //     <h1>Invoice</h1>
    //     <p>Invoice Number: sahil</p>
    //     <p>Date: 23/05/3456</p>
    //     <p>Total Amount: 1000</p>
    //     {/* Include more components and content here */}
    //   </div>
    // );

    // Convert the React element to HTML
    // const invoiceHtml = renderToStaticMarkup(invoiceContent);
    const today = new Date();
    const options = { year: "numeric", month: "long", day: "numeric" };
    const formattedDate = today.toLocaleDateString(undefined, options);

    // Create a new window and write the HTML content to it
    const newWindow = window.open();
    newWindow.document.write(
      `<!DOCTYPE html>
<html>
	<head>
		<meta charset="utf-8" />
		<title>Invoice</title>
    <link rel="icon" href="%PUBLIC_URL%/favicon.jpg" />
		<style>
			.invoice-box {
				max-width: 800px;
				margin: auto;
				padding: 30px;
				border: 1px solid #eee;
				box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
				font-size: 16px;
				line-height: 24px;
				font-family: 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
				color: #555;
			}
        @media print {
            @page {
              size: auto;
              margin: 0mm;
            }
            body {
              margin: 20mm; /* Adjust margin as needed */
            }
            .header,
            .footer {
              display: none;
            }
          }
			.invoice-box table {
				width: 100%;
				line-height: inherit;
				text-align: left;
			}

			.invoice-box table td {
				padding: 5px;
				vertical-align: top;
			}

			.invoice-box table tr td:nth-child(2) {
				text-align: right;
			}

			.invoice-box table tr.top table td {
				padding-bottom: 20px;
			}

			.invoice-box table tr.top table td.title {
				font-size: 45px;
				line-height: 45px;
				color: #333;
			}

			.invoice-box table tr.information table td {
				padding-bottom: 40px;
			}

			.invoice-box table tr.heading td {
				background: #eee;
				border-bottom: 1px solid #ddd;
				font-weight: bold;
			}

			.invoice-box table tr.details td {
				padding-bottom: 20px;
			}

			.invoice-box table tr.item td {
				border-bottom: 1px solid #eee;
			}

			.invoice-box table tr.item.last td {
				border-bottom: none;
			}

			.invoice-box table tr.total td:nth-child(2) {
				border-top: 2px solid #eee;
				font-weight: bold;
			}

			@media only screen and (max-width: 600px) {
				.invoice-box table tr.top table td {
					width: 100%;
					display: block;
					text-align: center;
				}

				.invoice-box table tr.information table td {
					width: 100%;
					display: block;
					text-align: center;
				}
			}

			/** RTL **/
			.invoice-box.rtl {
				direction: rtl;
				font-family: Tahoma, 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
			}

			.invoice-box.rtl table {
				text-align: right;
			}

			.invoice-box.rtl table tr td:nth-child(2) {
				text-align: left;
			}
		</style>
	</head>

	<body>
		<div class="invoice-box">
			<table cellpadding="0" cellspacing="0">
				<tr class="top">
					<td colspan="2">
						<table>
							<tr>
								<td class="title">
									<img
										src="${Logo}"
										style="width: 100%; max-width: 300px"
                    
									/>
								</td>

								<td>
									Invoice #: 123<br />
									Created: ${formattedDate}<br />
								</td>
							</tr>
						</table>
					</td>
				</tr>

				<tr class="information">
					<td colspan="2">
						<table>
							<tr>
								<td>
									KimmyMae, Inc.<br />
									12345 Sunny Road<br />
									Sunnyville, CA 12345
								</td>

								<td>
									${company}<br />
									${value?.name}<br />
									${value?.emailId}
								</td>
							</tr>
						</table>
					</td>
				</tr>

				<tr class="heading">
					<td>Payment Method</td>

					<td>Online #</td>
				</tr>

				<tr class="details">
					<td>Stripe</td>

					<td>${pro}</td>
				</tr>

				<tr class="heading">
					<td>Item</td>

					<td>Price</td>
				</tr>

				<tr class="item">
          <td>Mudzie ${users?.mudziePlan}</td>
          <td id="pricePlaceholder">${pro}</td>
        </tr>


				<tr class="total">
					<td></td>

					<td>Total:$${pro}.00</td>
				</tr>
			</table>
		</div>
	</body>

</html>`
    );

    // Print and close the new window
    newWindow.print();
    newWindow.close();
  };
  console.log("=================", userData);

  const handleOut = () => {
    localStorage.removeItem("userId");
    localStorage.removeItem("user");
    navigate("/");
  };

  const hableInvoice = (row) => {
    const today = new Date();
    const options = { year: "numeric", month: "long", day: "numeric" };
    const formattedDate = today.toLocaleDateString(undefined, options);

    // Create a new window and write the HTML content to it
    const newWindow = window.open();
    newWindow.document.write(
      `<!DOCTYPE html>
<html>
	<head>
		<meta charset="utf-8" />
		<title>Invoice</title>
    <link rel="icon" href="%PUBLIC_URL%/favicon.jpg" />
		<style>
			.invoice-box {
				max-width: 800px;
				margin: auto;
				padding: 30px;
				border: 1px solid #eee;
				box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
				font-size: 16px;
				line-height: 24px;
				font-family: 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
				color: #555;
			}
        @media print {
            @page {
              size: auto;
              margin: 0mm;
            }
            body {
              margin: 20mm; /* Adjust margin as needed */
            }
            .header,
            .footer {
              display: none;
            }
          }
			.invoice-box table {
				width: 100%;
				line-height: inherit;
				text-align: left;
			}

			.invoice-box table td {
				padding: 5px;
				vertical-align: top;
			}

			.invoice-box table tr td:nth-child(2) {
				text-align: right;
			}

			.invoice-box table tr.top table td {
				padding-bottom: 20px;
			}

			.invoice-box table tr.top table td.title {
				font-size: 45px;
				line-height: 45px;
				color: #333;
			}

			.invoice-box table tr.information table td {
				padding-bottom: 40px;
			}

			.invoice-box table tr.heading td {
				background: #eee;
				border-bottom: 1px solid #ddd;
				font-weight: bold;
			}

			.invoice-box table tr.details td {
				padding-bottom: 20px;
			}

			.invoice-box table tr.item td {
				border-bottom: 1px solid #eee;
			}

			.invoice-box table tr.item.last td {
				border-bottom: none;
			}

			.invoice-box table tr.total td:nth-child(2) {
				border-top: 2px solid #eee;
				font-weight: bold;
			}

			@media only screen and (max-width: 600px) {
				.invoice-box table tr.top table td {
					width: 100%;
					display: block;
					text-align: center;
				}

				.invoice-box table tr.information table td {
					width: 100%;
					display: block;
					text-align: center;
				}
			}

			/** RTL **/
			.invoice-box.rtl {
				direction: rtl;
				font-family: Tahoma, 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
			}

			.invoice-box.rtl table {
				text-align: right;
			}

			.invoice-box.rtl table tr td:nth-child(2) {
				text-align: left;
			}
		</style>
	</head>

	<body>
		<div class="invoice-box">
			<table cellpadding="0" cellspacing="0">
				<tr class="top">
					<td colspan="2">
						<table>
							<tr>
								<td class="title">
									<img
										src="${Logo}"
										style="width: 100%; max-width: 300px"
                    
									/>
								</td>

								<td>
									Invoice #: 123<br />
									Created: ${formattedDate}<br />
								</td>
							</tr>
						</table>
					</td>
				</tr>

				<tr class="information">
					<td colspan="2">
						<table>
							<tr>
								<td>
									KimmyMae, Inc.<br />
									12345 Sunny Road<br />
									Sunnyville, CA 12345
								</td>

								<td>
									${company}<br />
									${value?.name}<br />
									${value?.emailId}
								</td>
							</tr>
						</table>
					</td>
				</tr>

				<tr class="heading">
					<td>Payment Method</td>

					<td>Online #</td>
				</tr>

				<tr class="details">
					<td>Stripe</td>

					<td>${row?.amount}</td>
				</tr>

				<tr class="heading">
					<td>Item</td>

					<td>Price</td>
				</tr>

				<tr class="item">
          <td>Mudzie ${row?.plan}</td>
          <td id="pricePlaceholder">${row?.amount}</td>
        </tr>


				<tr class="total">
					<td></td>

					<td>Total:$${row?.amount}.00</td>
				</tr>
			</table>
		</div>
	</body>

</html>`
    );

    // Print and close the new window
    newWindow.print();
    newWindow.close();
  };

  // ///////// added by aniket
  const [toggleAddTeamBtn, setToggleTeamBtn] = useState(false)
  // const [teamMemberName, setTeamMemberName] = useState('')
  // const [teamMemberEmail, setTeamMemberEmail] = useState('')

  const handleAddTeamMember = () => {
    setToggleTeamBtn(true)
  }
  // const handleAddTeamMemberUpdate = () => {
  //   console.log(teamMemberName)
  //   console.log(teamMemberEmail)
  // }

  const hardcodedTeamData = [
    {
      id: 1,
      name: 'name1',
      email: 'email1'
    },
    {
      id: 2,
      name: 'name2',
      email: 'email2'
    }
  ]
  // ///////// added by aniket

  // Start - Code added by Prashant to complete add team member feature
  const localUser = localStorage.getItem("user");
  const userde = JSON.parse(localUser);
  const [alertStatus, setAlertStatus] = React.useState(false);
  const [type, setType] = React.useState("success");
  const [title, setTitle] = React.useState("");
  const [teamMembers, setTeamMembers] = React.useState();
  const {
    register,
    handleSubmit,
    watch,
    setError,
    formState: { errors },
  } = useForm();
  const onSubmit = ({
    name,
    contactNumber,
    emailId,
    password,
    companyName,
  }) => {
    const jsonobj = {
      name: name,
      contactNumber: contactNumber,
      emailId: emailId,
      password: password,
      companyName: companyName,
    };
    Axios.post(
      `${process.env.REACT_APP_BASE_URL}api/checkEmailIdExist`,
      jsonobj
    ).then((res) => {
      if (res.data.response === 1) {
        if (JSON.parse(localStorage.user).emailId === emailId && JSON.parse(localStorage.user).is_active_plan !== 0)
          setError("emailId", {
            type: "server",
            message: "Email id already exist and plan is activated already!",
          });
        else if (JSON.parse(localStorage.user).emailId === emailId && JSON.parse(localStorage.user).is_active_plan === 0) {
          const AdminJsonobj = {
            name: name,
            emailId: emailId,
            password: password,
            userId: JSON.parse(localStorage.user).userId
          };
          Axios.post(`${process.env.REACT_APP_BASE_URL}api/admin/addNewTeamAdmin`, AdminJsonobj,
            {
              headers: {
                Authorization: `Bearer ${userde?.token}`,
              },
            }
          ).then(
            (res) => {
              console.log("res.data.response : ", res.data.response);
              if (res.data.response === 1) {
                setAlertStatus(true);
                setType("success");
                setTitle("Account activated successfully!.");
              }
            }
          );
        }
        else
          setError("emailId", {
            type: "server",
            message: "Email id already exist.",
          });
      } else {
        const newJsonobj = {
          name: name,
          contactNumber: contactNumber,
          emailId: emailId,
          password: password,
          companyName: "",
          added_by: JSON.parse(localStorage.user).userId,
          mudziePlan: JSON.parse(localStorage.user).mudziePlan,
          period: JSON.parse(localStorage.user).period,
          expiryDate: JSON.parse(localStorage.user).expiryDate
        };
        Axios.post(`${process.env.REACT_APP_BASE_URL}api/admin/addNewTeamMember`, newJsonobj,
          {
            headers: {
              Authorization: `Bearer ${userde?.token}`,
            },
          }
        ).then(
          (res) => {
            if (res.data.response === 1) {
              setAlertStatus(true);
              setType("success");
              setTitle(
                "Account verificaton link has been sent to member's email id. Please verify account."
              );
            }
          }
        );
      }
    });
  };
  useEffect(() => {
    const user = localStorage.getItem("user");
    const userde = JSON.parse(user);
    Axios.post(`${process.env.REACT_APP_BASE_URL}api/admin/getAllTeamMemners`, { userId: userde.userId }, {
      headers: {
        Authorization: `Bearer ${userde.token}`,
      },
    })
      .then((res) => {
        console.log("teamMembers", res.data);
        setTeamMembers(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  // End - Code added by Prashant to complete add team member feature

  return (
    <ThemeProvider theme={theme}>
      <div>
        <Box
          sx={{
            flexDirection: "row",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            background: "linear-gradient(to right bottom, #1976D2, #175FA2)",
            height: "100px",
          }}
        >
          <Box sx={{ ml: { xl: 15, lg: 10, sm: 10, xs: 0 } }}>
            <img
              src={mudzie}
              alt="logo"
              width={200}
              height={60}
            // style={{ marginLeft: "80px", marginRight: "-30px" }}
            />
          </Box>
          <IconButton
            aria-label="profile"
            color="white"
            onClick={handleMenuOpen}
          >
            <AccountCircleIcon
              fontSize="large"
              sx={{ color: "white", mr: 5 }}
            />
          </IconButton>

          {/* <Typography
                            sx={{
								color: "white",
								marginLeft: "180px",
								fontFamily: "Ubuntu",
								fontSize: "10px",
								ml: { xl: 4, sm: 16, xs: 0 },
								mr: { xl: -16 },
							  }}
                        >
                            Hello,Prshant.hanamg...
                        </Typography> */}
        </Box>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={() => navigate("/calculator")}>
            Mudzie calculator
          </MenuItem>
          <MenuItem onClick={() => navigate("/payments")}>Pricing</MenuItem>
          <MenuItem onClick={handleOut}>Logout</MenuItem>
        </Menu>
        {/* <Box style={{ padding: "20px" }} height="70vh">
          <Typography variant="h4">Account Information</Typography>
          <Typography variant="body1">Name: {user.name}</Typography>
          <Typography variant="body1">Email: {user.email}</Typography>

          <Typography variant="h4">Plan Details</Typography>
          <Typography variant="body1">Plan: {user.plan}</Typography>
          <Typography variant="body1">
            Expiry Date: {user.expiryDate}
          </Typography>

          {user.invoice && (
            <div>
              <Typography variant="h4">Invoice</Typography>
              <Typography variant="body1">
                Invoice Number: {user.invoice.number}
              </Typography>
              <Button
                variant="contained"
                color="primary"
                href={user.invoice.downloadLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                Download Invoice
              </Button>
            </div>
          )}
        </Box> */}

        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="50vh" //// changed from 70vh
          marginTop="20px"
        >
          <Paper elevation={3} sx={{ padding: "20px", width: "80%" }}>
            <Typography
              variant="h4"
              sx={{
                marginTop: "10px",
                marginBottom: "20px",
                display: "flex",
                alignItems: "center",
              }}
            >
              My Account
            </Typography>
            <hr />
            <Grid container spacing={3} mt={2}>
              <Grid item xs={12} md={6}>
                <Typography
                  variant="h4"
                  sx={{
                    marginBottom: "20px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <AccountCircleIcon
                    fontSize="large"
                    style={{ marginRight: "10px" }}
                  />{" "}
                  Account Information
                </Typography>
                <Typography variant="body1" sx={{ ml: 6 }}>
                  <strong>Name:</strong> {users?.name}
                </Typography>
                <Typography variant="body1" sx={{ ml: 6 }}>
                  <strong>Email:</strong> {users?.emailId}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  variant="h4"
                  sx={{
                    marginBottom: "20px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <DescriptionIcon
                    fontSize="large"
                    style={{ marginRight: "10px" }}
                  />{" "}
                  Plan Details
                </Typography>
                {users?.mudziePlan === "Pro" ? (
                  <Typography variant="body1" sx={{ ml: 6 }}>
                    <strong>Plan:</strong> Mudzie {users?.mudziePlan}:For HUD,
                    RHS, and DUS lenders
                  </Typography>
                ) : (
                  <></>
                )}
                {users?.mudziePlan === "Basic" ? (
                  <Typography variant="body1" sx={{ ml: 6 }}>
                    <strong>Plan:</strong> Mudzie {users?.mudziePlan}:For RHS
                    and MAP lenders
                  </Typography>
                ) : (
                  <></>
                )}
                {users?.mudziePlan === "Ultra Pro" ? (
                  <Typography variant="body1" sx={{ ml: 6 }}>
                    <strong>Plan:</strong> Mudzie {users?.mudziePlan}:For
                    existing trading desks currently using Bloomberg
                  </Typography>
                ) : (
                  <></>
                )}

                {users?.expiryDate === null ? (
                  <></>
                ) : (
                  <Typography variant="body1" sx={{ ml: 6 }}>
                    <strong>Expiry Date:</strong> {users?.expiryDate}
                  </Typography>
                )}
                {/* <Typography variant="body1">
                  <strong>Expiry Date:</strong> {value?.expiryDate || ""}
                </Typography> */}
              </Grid>
            </Grid>

            {user.invoice && (
              <div>
                <Typography
                  variant="h4"
                  sx={{
                    marginTop: "30px",
                    marginBottom: "10px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <DescriptionIcon
                    fontSize="large"
                    style={{ marginRight: "10px" }}
                  />{" "}
                  Invoice
                </Typography>
                {/* <Typography variant="body1">
                  <strong>Invoice Number:</strong> {user.invoice.number}
                </Typography> */}
                <Button
                  variant="contained"
                  color="primary"
                  // href={user.invoice.downloadLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  disabled={users?.is_active_plan === 0}
                  sx={{ marginTop: "10px" }}
                  onClick={handleDownload}
                  startIcon={<DownloadIcon />}
                >
                  Download Invoice
                </Button>
              </div>
            )}
          </Paper>
        </Box>


        {/* Team table added by aniket */}

        {
          team === true && teamExpired === false ?
            <Grid container justifyContent="center" sx={{ mt: 3, mb: 8 }}>
              <Grid item xs={12} md={10}>
                <Paper elevation={3} sx={{ p: 2 }}>
                  <Typography variant="h5" gutterBottom>
                    Team
                  </Typography>
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }}>
                      <TableHead>
                        <TableRow>
                          <TableCell>Sr. No.</TableCell>
                          <TableCell>Name</TableCell>
                          <TableCell>Email</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {teamMembers?.map((row, index) => (
                          <TableRow key={row.id}>
                            <TableCell>{index+1}</TableCell>
                            <TableCell>{row?.name}</TableCell>
                            <TableCell>{row?.emailId}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                    {
                      toggleAddTeamBtn ?
                        <>
                          <Box component="form"
                            onSubmit={handleSubmit(onSubmit)}
                            noValidate>
                            <Box

                              sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', pt: 1, pb: 1 }}>
                              <div>
                                <TextField
                                  label="Name"
                                  variant="outlined"
                                  required
                                  name="name"
                                  sx={{ mx: 1, p: 0 }}
                                  // value={teamMemberName}
                                  // onChange={(e) => setTeamMemberName(e.target.value)}
                                  {...register("name", { required: "Please enter name." })}
                                  type="text"
                                  error={Boolean(errors.name)}
                                  helperText={errors.name?.message}
                                />
                                <TextField
                                  label="Email"
                                  variant="outlined"
                                  required
                                  sx={{ mx: 1 }}
                                  name="emailId"
                                  // value={teamMemberEmail}
                                  // onChange={(e) => setTeamMemberEmail(e.target.value)}
                                  {...register("emailId", {
                                    required: "Please enter email id.",
                                    pattern: {
                                      value:
                                        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                                      message: "Please enter valid email id.",
                                    },
                                  })}
                                  type="email"
                                  error={Boolean(errors.emailId)}
                                  helperText={errors.emailId?.message}
                                />
                                <TextField
                                  required
                                  sx={{ mx: 1 }}
                                  label="Contact Number"
                                  name="contactNumber"
                                  {...register("contactNumber", {
                                    required: "Please enter contact number.",
                                    pattern: {
                                      value: /^[0-9]/,
                                      message: "Please enter valid contact number.",
                                    },
                                  })}
                                  error={Boolean(errors.contactNumber)}
                                  helperText={errors.contactNumber?.message}
                                  inputProps={{ maxLength: 15 }}
                                />
                                <TextField
                                  required
                                  name="password"
                                  label="Password"
                                  type="password"
                                  {...register("password", {
                                    required: "Please enter password.",
                                  })}
                                  error={Boolean(errors.password)}
                                  helperText={errors.password?.message}
                                  inputProps={{ maxLength: 100 }}
                                />
                              </div>
                            </Box>
                            <Box
                              sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', pt: 1, pb: 1 }}>
                              <div>
                                <Button
                                  variant="contained"
                                  color="error"
                                  sx={{ mx: 1, p: 2 }}
                                  startIcon={<AddCircleOutlineIcon />}
                                  onClick={() => setToggleTeamBtn(false)}
                                >
                                  Cancel
                                </Button>
                                <Button
                                  type="submit"
                                  variant="contained"
                                  color="primary"
                                  sx={{ mx: 1, p: 2 }}
                                  startIcon={<AddCircleOutlineIcon />}
                                // onClick={() => handleAddTeamMemberUpdate()}
                                >
                                  Update
                                </Button>
                              </div>
                            </Box>
                          </Box>
                        </>
                        :
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%', pt: 1, pb: 1 }}>
                          <Button
                            variant="contained"
                            color="primary"
                            sx={{ mx: 1, p: 2 }}
                            startIcon={<AddCircleOutlineIcon />}
                            onClick={() => handleAddTeamMember()}
                          >
                            Add Team Member
                          </Button>
                        </Box>
                    }
                  </TableContainer>
                </Paper>
              </Grid>
            </Grid> : ''
        }


        {/* Team table added by aniket ended */}


        <Grid container justifyContent="center" sx={{ mt: 3 }}>
          {history?.length < 1 ? (
            <>{/* <div>No Record Found.</div> */}</>
          ) : (
            <Grid item xs={12} md={10}>
              <Paper elevation={3} sx={{ p: 2 }}>
                <Typography variant="h5" gutterBottom>
                  Payment History
                </Typography>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }}>
                    <TableHead>
                      <TableRow>
                        <TableCell>ID</TableCell>
                        <TableCell>Amount</TableCell>
                        <TableCell>Plan</TableCell>
                        <TableCell>Period</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {history?.map((row) => (
                        <TableRow key={row.id}>
                          <TableCell>{row?.id}</TableCell>
                          <TableCell>${row?.amount}</TableCell>
                          <TableCell>{row?.plan}</TableCell>
                          <TableCell>{row?.period}</TableCell>
                          <TableCell>{row?.status}</TableCell>
                          <TableCell>
                            <Button
                              variant="contained"
                              color="primary"
                              sx={{ mr: -14 }}
                              onClick={() => {
                                hableInvoice(row);
                              }}
                              startIcon={<DownloadIcon />}
                            >
                              Download Invoice
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </Grid>
          )}
        </Grid>
        {/* <Box sx={{ display: "flex", justifyContent: "center" }}>
          <TableContainer
            component={Paper}
            sx={{
              marginTop: "20px",
              width: "max-content",
              px: 6,
            }}
          >
            <Typography variant="h5" gutterBottom sx={{ mt: 3 }}>
              Payment History
            </Typography>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>

                  <TableCell>Amount</TableCell>
                  <TableCell>Plan</TableCell>
                  <TableCell>Period</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {history?.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell>{row?.id}</TableCell>

                    <TableCell>${row?.amount}</TableCell>
                    <TableCell>{row?.plan}</TableCell>
                    <TableCell>{row?.period}</TableCell>
                    <TableCell>{row?.status}</TableCell>
                    <TableCell>
                      <Button
                        variant="contained"
                        color="primary"
                        // href={user.invoice.downloadLink}
                        //target="_blank"
                        // rel="noopener noreferrer"
                        // disabled={users?.is_active_plan === 0}
                        sx={{ marginTop: "10px" }}
                        onClick={() => {
                          hableInvoice(row);
                        }}
                        startIcon={<DownloadIcon />}
                      >
                        Download Invoice
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box> */}
        <Box
          width="100%"
          height="80px"
          bottom="0"
          sx={{
            background: "linear-gradient(to right bottom, #1976D2, #175FA2)",
            mt: 5,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box height="100%" sx={{ display: "flex", alignItems: "center" }}>
            <Typography
              sx={{
                fontFamily: "Ubuntu",
                fontSize: "10px",
                color: "white",
                pl: 5,
              }}
            >
              Copyright © 2023 MUDZIE - All Rights Reserved | Powered by
              KimmyMae™
            </Typography>
          </Box>
          <Box>
            <Typography
              sx={{
                fontFamily: "Ubuntu",
                fontSize: "10px",
                color: "white",
                pr: 8,
                mt: 2,
              }}
            >
              Privacy policy | Terms and conditions
            </Typography>
            <hr style={{ width: "90%", marginLeft: "-5px" }} />
            <Box sx={{ display: "flex", gap: "10px", marginLeft: "100px" }}>
              <img src={twitter} alt="" width={20} height={20} />
              <img src={facebook} alt="" width={20} height={20} />
              <img src={linkdin} alt="" width={20} height={20} />
            </Box>
          </Box>
        </Box>
      </div>
      <ReactJsAlert
        status={alertStatus} // true or false
        type={type} // success, warning, error, info
        title={title}
        autoCloseIn="30000"
        Close={() => {
          setAlertStatus(false);
          window.location.reload()
        }}
      />
    </ThemeProvider>
  );
};

export default AccountPage;
