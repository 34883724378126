import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import Checkbox from '@mui/material/Checkbox';
import { NavLink } from 'react-router-dom';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
// import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import logo from './images/favicon.jpg';
import Axios from 'axios';
import ReactJsAlert from "reactjs-alert";
import { useNavigate } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" target="_blank" href="https://kimmymae.com/">
                KimmyMae™
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const theme = createTheme();

export default function SignIn() {
    const [alertStatus, setAlertStatus] = React.useState(false);
    const [type, setType] = React.useState("success");
    const [title, setTitle] = React.useState("");
    const [verificationCodeSent, setVerificationCodeSent] = React.useState(false);
    const [verificationCode, setVerificationCode] = React.useState();
    const [verificationCodeTemp, setVerificationCodeTemp] = React.useState("");
    const [passwordChangedFlag, setPasswordChangedFlag] = React.useState(false);
    const [emailId, setEmailId] = React.useState();
    const [loading, setLoading] = React.useState(false);
    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        // console.log(data.get('email'));
        setLoading(true)
        if (!verificationCodeSent) {
            setEmailId(data.get('email'))
            Axios.post(
                `${process.env.REACT_APP_BASE_URL}api/checkEmailIdExist`,
                { emailId: data.get('email') }
            ).then((res) => {
                console.log(res.data.response);
                if (res.data.response === 0) {
                    setAlertStatus(true);
                    setType("error");
                    setTitle("Email id does not exist. Please check once.");
                    setLoading(false)
                } else {
                    let randomCode = Math.floor(100000 + Math.random() * 900000)
                    setVerificationCode(randomCode);
                    let mailData = {
                        emailId: data.get('email'),
                        verificationCode: randomCode
                    }
                    console.log("mailData: ", mailData);
                    setVerificationCodeSent(true)
                    Axios.post(`${process.env.REACT_APP_BASE_URL}api/forgotPasswordVerificationCode`, mailData).then(
                        (res) => {
                            if (res.data.response === 1) {
                                setAlertStatus(true);
                                setType("success");
                                setTitle(
                                    "Account verificaton code has been sent to your email id. Please verify your account."
                                );
                                setLoading(false)
                                setVerificationCodeSent(true)
                            }
                        }
                    );
                }
            });
        }
        else {
            if (Number(data.get('verificationCode')) === verificationCode) {
                if (data.get('password') !== "") {
                    if (data.get('password') === data.get('confirmPassword')) {
                        let resetData = {
                            emailId: emailId,
                            password: data.get('password')
                        }
                        console.log("resetData: ", resetData);
                        Axios.post(`${process.env.REACT_APP_BASE_URL}api/resetPassword`, resetData).then(
                            (res) => {
                                if (res.data.response === 1) {
                                    setPasswordChangedFlag(true)
                                    setVerificationCodeSent(true)
                                    setAlertStatus(true);
                                    setType("success");
                                    setTitle("Your password has been changed successfuly!");
                                    setLoading(false)
                                }
                                else {
                                    setAlertStatus(true);
                                    setType("error");
                                    setTitle("Something went wrong! Your password has not changed successfuly!");
                                    setLoading(false)
                                }
                            }
                        );
                    }
                    else {
                        setAlertStatus(true);
                        setType("error");
                        setTitle("Password and Confirm Password does not match!");
                        setLoading(false)
                    }
                }
                else {
                    setAlertStatus(true);
                    setType("error");
                    setTitle("Password can not be blank!");
                    setLoading(false)
                }
            }
            else {
                setAlertStatus(true);
                setType("error");
                setTitle("Verification code does not match!");
                setLoading(false)
            }
        }
    };
    const navigate = useNavigate();
    const handleVerificationCodeChange = (e) => setVerificationCodeTemp(e.target.value);
    return (
        <ThemeProvider theme={theme}>
            <Container component="main">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 4,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar src={logo} alt="KM" />
                    <Typography
                        component="h1"
                        variant="h5"
                        color="primary"
                        sx={{ fontWeight: 'bold' }}>
                        MUDZIE
                    </Typography>
                    <Typography>
                        By KimmyMae™
                    </Typography>
                    <Typography component="h1" variant="h5">
                        Reset Password
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }} width={{ sm: 555 }}>
                        {
                            !verificationCodeSent ?
                                <>
                                    <TextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="email"
                                        label="Email Address"
                                        name="email"
                                        autoComplete="email"
                                    />
                                    <Button
                                        type='submit'
                                        fullWidth
                                        variant="contained"
                                        sx={{ mt: 2, mb: 2 }}
                                    >
                                        Send Verification Code
                                        {
                                            loading ?
                                                <ClipLoader
                                                    color="white"
                                                    loading={loading}
                                                    size={30}
                                                    aria-label="Loading Spinner"
                                                    data-testid="loader"
                                                />
                                                :
                                                ""
                                        }
                                    </Button>
                                </>
                                :
                                <>
                                    <TextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="verificationCode"
                                        label="Verification Code"
                                        name="verificationCode"
                                        value={verificationCodeTemp}
                                        onChange={handleVerificationCodeChange}
                                    />
                                    <TextField
                                        type='password'
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="password"
                                        label="New Password"
                                        name="password"
                                        autoComplete="password"
                                    />
                                    <TextField
                                        type='password'
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="confirmPassword"
                                        label="Confirm Password"
                                        name="confirmPassword"
                                        autoComplete="confirmPassword"
                                    />
                                    <Button
                                        type='submit'
                                        fullWidth
                                        variant="contained"
                                        sx={{ mt: 2, mb: 2 }}
                                    >
                                        Verify & Reset Password
                                        {
                                            loading ?
                                                <ClipLoader
                                                    color="white"
                                                    loading={loading}
                                                    size={30}
                                                    aria-label="Loading Spinner"
                                                    data-testid="loader"
                                                />
                                                :
                                                ""
                                        }
                                    </Button>
                                </>
                        }
                        <Grid container>
                            <Grid item>
                                <Link component={NavLink} to="/" variant="body2">
                                    {"Already have account? Click here to login."}
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
                <Copyright sx={{ mt: 4, mb: 4 }} />
            </Container>
            <ReactJsAlert
                status={alertStatus} // true or false
                type={type} // success, warning, error, info
                title={title}
                autoCloseIn="30000"
                Close={() => {
                    setAlertStatus(false);
                    if (passwordChangedFlag) { navigate("/", { replace: true }); }
                }}
            />
        </ThemeProvider>

    );
}