import React, { useState, useEffect } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import DashboardIcon from "@mui/icons-material/Dashboard";
import LocalParkingIcon from '@mui/icons-material/LocalParking';
import SettingsIcon from "@mui/icons-material/Settings";
import FolderCopyIcon from "@mui/icons-material/FolderCopy";
import MailIcon from "@mui/icons-material/Mail";
import ListIcon from "@mui/icons-material/List";
import { useNavigate } from "react-router-dom";
import { useAppStore } from "../appStore";
import { Typography } from "@mui/material";
// import dashboard from "../images/dashboard 1.png";
// import folder from "../images/folder (1) 1.png";
// import contact from "../images/envelope 1.png";
// import setting from "../images/setting 1.png";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import PhoneIcon from "@mui/icons-material/Phone";
import AddTaskIcon from '@mui/icons-material/AddTask';
import Axios from "axios";
const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function Sidenav(props) {
  console.log("props.menuHeighlightPath: ", props.menuHeighlightPath);
  const theme = useTheme();
  // const [open, setOpen] = React.useState(true);
  const navigate = useNavigate();
  const open = useAppStore((state) => state.dopen);
  const [selectedItem, setSelectedItem] = useState(props.menuHeighlightPath);


  const handleNavigate = (path) => {
    // setSelectedItem(path); // Set the selected item when navigating
    navigate(path);
  };

  const [loading, setLoading] = useState(true);
  const [rowsState, setRowsState] = useState(null);
  useEffect(() => {
    const user = localStorage.getItem("user");
    const userde = JSON.parse(user);
    const token = userde.token;
    const fetchData = async () => {
      try {
        const response = await Axios.get(
          `${process.env.REACT_APP_BASE_URL}api/admin/total-pending-call-requests`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.data.length < 1) {
          console.log("Records not found");
        } else {
          console.log("sidebar", response.data);
          let request = response.data.total;
          // const filteredData = response.data.filter(
          //   (item) =>
          //     item.is_call_requested === 1 &&
          //     item.is_approved_for_subscribe === 0
          // );
          // console.log("***********", filteredData.length);
          setRowsState(request);
          setLoading(false); // Update the state with the new rows
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData(); // Call the async function to fetch data
  }, []);

  console.log("#########", rowsState?.length);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Box height={30} />
      <Drawer variant="permanent" open={!open}>
        <DrawerHeader>
          <IconButton>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List sx={{ mt: 4 }}>
          {/* <ListItem disablePadding sx={{ display: 'block' }} onClick={()=>{navigate('/dashboard')}}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: !open ? 'initial' : 'center',
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: !open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                <DashboardIcon />
              </ListItemIcon>
              <ListItemText primary="Dashboard" sx={{ opacity: !open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem> */}
          {/* <ListItem disablePadding sx={{ display: 'block' }} onClick={()=>{navigate('/admin/about')}}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                <InboxIcon />
              </ListItemIcon>
              <ListItemText primary="About" sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem> */}
          <ListItem
            disablePadding
            sx={{
              display: "block",
              backgroundColor: selectedItem === "/dashboard/settings" ? "#1976D2" : "transparent",
              // "&:hover": {
              //   backgroundColor: "#1976D2",
              // },
            }}
          // onClick={() => {
          //   handleNavigate("/dashboard/settings");
          // }}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: !open ? "initial" : "center",
                px: 2.5,
              }}
              onClick={() => {
                handleNavigate("/dashboard/settings");
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: !open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <DashboardIcon
                  sx={{
                    color: selectedItem === "/dashboard/settings" ? "white" : "#1976D2"
                  }}
                />
                {/* <img
                  src={dashboard}
                  alt=""
                  style={{ filter: "hue-rotate(90deg)" }}
                /> */}
              </ListItemIcon>
              <ListItemText sx={{ opacity: !open ? 1 : 0 }}>
                <Typography
                  sx={{
                    fontFamily: "Ubuntu",
                    color:
                      selectedItem === "/dashboard/settings"
                        ? "white"
                        : "#1976D2",
                  }}
                >
                  Dashboard
                </Typography>
              </ListItemText>
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding
            sx={{
              display: "block",
              backgroundColor: selectedItem === "/dashboard/ratetopricepx" ? "#1976D2" : "transparent"
            }}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: !open ? "initial" : "center",
                px: 2.5,
              }}
              onClick={() => {
                handleNavigate("/dashboard/ratetopricepx");
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: !open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <LocalParkingIcon
                  sx={{
                    color: selectedItem === "/dashboard/ratetopricepx" ? "white" : "#1976D2"
                  }}
                />
              </ListItemIcon>
              <ListItemText sx={{ opacity: !open ? 1 : 0 }}>
                <Typography
                  sx={{
                    fontFamily: "Ubuntu",
                    color:
                      selectedItem === "/dashboard/ratetopricepx"
                        ? "white"
                        : "#1976D2",
                  }}
                >
                  Rate To Price PX
                </Typography>
              </ListItemText>
            </ListItemButton>
          </ListItem>
          <ListItem
            disablePadding
            sx={{
              display: "block",
              background:
                selectedItem === "/dashboard/logs" ? "#1976D2" : "transparent",
            }}
          // onClick={() => {
          //   handleNavigate("/dashboard/logs");
          // }}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: !open ? "initial" : "center",
                px: 2.5,
              }}
              onClick={() => {
                handleNavigate("/dashboard/logs");
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: !open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <FolderCopyIcon
                  sx={{
                    color:
                      selectedItem === "/dashboard/logs" ? "white" : "#1976D2",
                  }}
                />
                {/* <img src={folder} alt="" /> */}
              </ListItemIcon>
              <ListItemText sx={{ opacity: !open ? 1 : 0 }}>
                <Typography
                  sx={{
                    fontFamily: "Ubuntu",
                    color:
                      selectedItem === "/dashboard/logs" ? "white" : "#1976D2",
                  }}
                >
                  Logs
                </Typography>
              </ListItemText>
            </ListItemButton>
          </ListItem>
          <ListItem
            disablePadding
            sx={{
              display: "block",
              backgroundColor:
                selectedItem === "/dashboard/charttable"
                  ? "#1976D2"
                  : "transparent",
            }}
          // onClick={() => {
          //   navigate("/dashboard/charttable");
          // }}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: !open ? "initial" : "center",
                px: 2.5,
              }}
              onClick={() => {
                handleNavigate("/dashboard/charttable");
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: !open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <QueryStatsIcon
                  sx={{
                    color:
                      selectedItem === "/dashboard/charttable"
                        ? "white"
                        : "#1976D2",
                  }}
                />
              </ListItemIcon>
              <ListItemText sx={{ opacity: !open ? 1 : 0 }}>
                <Typography
                  sx={{
                    fontFamily: "Ubuntu",
                    color:
                      selectedItem === "/dashboard/charttable"
                        ? "white"
                        : "#1976D2",
                  }}
                >
                  Chart Table
                </Typography>
              </ListItemText>
            </ListItemButton>
          </ListItem>
          <ListItem
            disablePadding
            sx={{
              display: "block",
              backgroundColor:
                selectedItem === "/dashboard/adduser"
                  ? "#1976D2"
                  : "transparent",
            }}
          // onClick={() => {
          //   navigate("/dashboard/charttable");
          // }}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: !open ? "initial" : "center",
                px: 2.5,
              }}
              onClick={() => {
                handleNavigate("/dashboard/adduser");
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: !open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <PersonAddIcon
                  sx={{
                    color:
                      selectedItem === "/dashboard/adduser"
                        ? "white"
                        : "#1976D2",
                  }}
                />
              </ListItemIcon>
              <ListItemText sx={{ opacity: !open ? 1 : 0 }}>
                <Typography
                  sx={{
                    fontFamily: "Ubuntu",
                    color:
                      selectedItem === "/dashboard/adduser"
                        ? "white"
                        : "#1976D2",
                  }}
                >
                  Add User
                </Typography>
              </ListItemText>
            </ListItemButton>
          </ListItem>
          <ListItem
            disablePadding
            sx={{
              display: "block",
              backgroundColor:
                selectedItem === "/dashboard/showuser"
                  ? "#1976D2"
                  : "transparent",
            }}
          // onClick={() => {
          //   navigate("/dashboard/charttable");
          // }}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: !open ? "initial" : "center",
                px: 2.5,
              }}
              onClick={() => {
                handleNavigate("/dashboard/showuser");
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: !open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <PersonSearchIcon
                  sx={{
                    color:
                      selectedItem === "/dashboard/showuser"
                        ? "white"
                        : "#1976D2",
                  }}
                />
              </ListItemIcon>
              <ListItemText sx={{ opacity: !open ? 1 : 0 }}>
                <Typography
                  sx={{
                    fontFamily: "Ubuntu",
                    color:
                      selectedItem === "/dashboard/showuser"
                        ? "white"
                        : "#1976D2",
                  }}
                >
                  Show Users
                </Typography>
              </ListItemText>
            </ListItemButton>
          </ListItem>
          <ListItem
            disablePadding
            sx={{
              display: "block",
              backgroundColor:
                selectedItem === "/dashboard/callrequest"
                  ? "#1976D2"
                  : "transparent",
            }}
          // onClick={() => {
          //   navigate("/dashboard/charttable");
          // }}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: !open ? "initial" : "center",
                px: 2.5,
              }}
              onClick={() => {
                handleNavigate("/dashboard/callrequest");
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: !open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <PhoneIcon
                  sx={{
                    color:
                      selectedItem === "/dashboard/callrequest"
                        ? "white"
                        : "#1976D2",
                  }}
                />
                {!loading ? (
                  <span
                    style={{
                      background: "red",
                      borderRadius: "50%",
                      width: "15px",
                      height: "15px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      color: "white",
                      fontSize: "10px",
                      marginLeft: "-10px",
                      marginTop: "-6px",
                    }}
                  >
                    {rowsState}
                  </span>
                ) : (
                  <></>
                )}
              </ListItemIcon>
              <ListItemText sx={{ opacity: !open ? 1 : 0 }}>
                <Typography
                  sx={{
                    fontFamily: "Ubuntu",
                    color:
                      selectedItem === "/dashboard/callrequest"
                        ? "white"
                        : "#1976D2",
                  }}
                >
                  Call Requests
                </Typography>
              </ListItemText>
            </ListItemButton>
          </ListItem>
          <ListItem
            disablePadding
            sx={{
              display: "block",
              backgroundColor: selectedItem === "/dashboard/duspxspread" ? "#1976D2" : "transparent",
            }}
          >
            <ListItemButton
              sx={{ minHeight: 48, justifyContent: !open ? "initial" : "center", px: 2.5 }}
              onClick={() => { handleNavigate("/dashboard/duspxspread"); }}
            >
              <ListItemIcon sx={{ minWidth: 0, mr: !open ? 3 : "auto", justifyContent: "center" }} >
                <AddTaskIcon
                  sx={{ color: selectedItem === "/dashboard/duspxspread" ? "white" : "#1976D2" }}
                />
              </ListItemIcon>
              <ListItemText sx={{ opacity: !open ? 1 : 0 }}>
                <Typography
                  sx={{
                    fontFamily: "Ubuntu",
                    color: selectedItem === "/dashboard/duspxspread" ? "white" : "#1976D2",
                  }}
                >
                  DUS PX Spread
                </Typography>
              </ListItemText>
            </ListItemButton>
          </ListItem>
          {/* <ListItem
            disablePadding
            sx={{
              display: "block",
              backgroundColor:
                selectedItem === "/dashboard/contact"
                  ? "#1976D2"
                  : "transparent",
            }}
            onClick={() => {
              navigate("/dashboard/contact");
            }}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: !open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: !open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <MailIcon
                  sx={{
                    color:
                      selectedItem === "/dashboard/contact"
                        ? "white"
                        : "#1976D2",
                  }}
                />
              </ListItemIcon>
              <ListItemText sx={{ opacity: !open ? 1 : 0 }}>
                <Typography sx={{ fontFamily: "Ubuntu", color: "#1976D2" }}>
                  Contact
                </Typography>
              </ListItemText>
            </ListItemButton>
          </ListItem> */}
          {/* <ListItem
            disablePadding
            sx={{
              display: "block",
              backgroundColor:
                selectedItem === "/dashboard/setting"
                  ? "#1976D2"
                  : "transparent",
            }}
            onClick={() => {
              navigate("/dashboard/setting");
            }}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: !open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: !open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <SettingsIcon
                  sx={{
                    color:
                      selectedItem === "/dashboard/setting"
                        ? "white"
                        : "#1976D2",
                  }}
                />
              </ListItemIcon>
              <ListItemText sx={{ opacity: !open ? 1 : 0 }}>
                <Typography sx={{ fontFamily: "Ubuntu", color: "#1976D2" }}>
                  Setting
                </Typography>
              </ListItemText>
            </ListItemButton>
          </ListItem> */}
          {/* <ListItem
            disablePadding
            sx={{ display: "block" }}
            onClick={() => {
              navigate("/dashboard/logs");
            }}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: !open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: !open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <ListIcon />
              </ListItemIcon>
              <ListItemText primary="Logs" sx={{ opacity: !open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem> */}
        </List>
      </Drawer>
    </Box>
  );
}
