import Axios from "axios";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Logo from "../images/Color logo with background.png";
import mudzie from "../images/Color logo - no background.png";
import { Box, Container } from "@mui/material";
import twitter from "../images/twitter 1.png";
import facebook from "../images/facebook-app-symbol 1.png";
import linkdin from "../images/linkedin (2) 1.png";

const Cancel = () => {
  const navigate = useNavigate();
  return (
    <div>
      <Box
        sx={{
          flexDirection: "row",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          background: "linear-gradient(to right bottom, #1976D2, #175FA2)",
          height: "100px",
        }}
      >
        <Box sx={{ ml: { xl: 15, lg: 10, sm: 10, xs: 0 } }}>
          <img
            src={mudzie}
            alt="logo"
            width={200}
            height={60}
            // style={{ marginLeft: "80px", marginRight: "-30px" }}
          />
        </Box>

        {/* <Typography
                            sx={{
								color: "white",
								marginLeft: "180px",
								fontFamily: "Ubuntu",
								fontSize: "10px",
								ml: { xl: 4, sm: 16, xs: 0 },
								mr: { xl: -16 },
							  }}
                        >
                            Hello,Prshant.hanamg...
                        </Typography> */}
      </Box>

      {/* body */}
      <Card elevation={3} style={{ width: "400px", margin: "50px auto" }}>
        <CardContent>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            //minHeight="100vh"
            //bgcolor="#EBF0F5"
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              borderRadius="50%"
              width="200px"
              height="200px"
              bgcolor="#F8FAF5"
              boxShadow="0 2px 3px #C8D0D8"
              sx={{ marginBottom: "20px" }}
            >
              <Typography
                variant="h1"
                sx={{
                  color: "#FF5252",
                  fontSize: "100px",
                  lineHeight: "200px",
                  // marginLeft: "-15px",
                }}
              >
                ❌
              </Typography>
            </Box>
            <Typography
              variant="h1"
              sx={{
                color: "#FF5252",
                fontWeight: 900,
                fontSize: "40px",
                marginBottom: "10px",
                fontFamily: "Nunito Sans, Helvetica Neue, sans-serif",
              }}
            >
              Failed
            </Typography>
            <Typography
              variant="body1"
              sx={{
                color: "#404F5E",
                fontSize: "20px",
                textAlign: "center",
                mt: 2,
                fontFamily: "Nunito Sans, Helvetica Neue, sans-serif",
              }}
            >
              Your purchase request has been canceled.
            </Typography>
            <Button
              variant="contained"
              sx={{ mt: 4 }}
              onClick={() => navigate("/accountpage")}
              fullWidth
            >
              Go to My Account
            </Button>
          </Box>
        </CardContent>
      </Card>
      {/* footer */}
      <Box
        width="100%"
        height="80px"
        bottom="0"
        sx={{
          background: "linear-gradient(to right bottom, #1976D2, #175FA2)",
          mt: 9,
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box height="100%" sx={{ display: "flex", alignItems: "center" }}>
          <Typography
            sx={{
              fontFamily: "Ubuntu",
              fontSize: "10px",
              color: "white",
              pl: 5,
            }}
          >
            Copyright © 2023 MUDZIE - All Rights Reserved | Powered by KimmyMae™
          </Typography>
        </Box>
        <Box>
          <Typography
            sx={{
              fontFamily: "Ubuntu",
              fontSize: "10px",
              color: "white",
              pr: 8,
              mt: 2,
            }}
          >
            Privacy policy | Terms and conditions
          </Typography>
          <hr style={{ width: "90%", marginLeft: "-5px" }} />
          <Box sx={{ display: "flex", gap: "10px", marginLeft: "100px" }}>
            <img src={twitter} alt="" width={20} height={20} />
            <img src={facebook} alt="" width={20} height={20} />
            <img src={linkdin} alt="" width={20} height={20} />
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default Cancel;
