import React, { useState, useEffect } from "react";
import Axios from "axios";
import {
  Box,
  Card,
  CardHeader,
  CardContent,
  Grid,
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  TablePagination,
  Button,
  Typography,
} from "@mui/material";
import Sidenav from "../components/Sidenav";
import Navbar from "../components/Navbar";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import { InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ReactJsAlert from "reactjs-alert";
// import LoadingButton from "@mui/lab/LoadingButton";
// import SaveIcon from "@mui/icons-material/Save";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

const CallRequest = () => {
  const [loading, setLoading] = useState(true);
  const [rowsState, setRowsState] = useState();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [alertStatus, setAlertStatus] = React.useState(false);
  const [type, setType] = React.useState("success");
  const [title, setTitle] = React.useState("");
  const [paid, setPaid] = useState();
  const [callRequestSwitchDefaultValue, setCallRequestSwitchDefaultValue] = useState(false);

  const columns = [
    { id: "logId", label: "Sr.No." },
    { id: "name", label: "Name" },
    { id: "contactNumber", label: "Contact Number" },
    { id: "emailId", label: "Email" },
    // { id: "mudziePlan", label: "Plan" },
    // { id: "expiryDate", label: "Expiry Date" },
    // { id: "companyName", label: "Company" },
    // { id: "poNumber", label: "PO Number" },
  ];

  const column = [
    { id: "logId", label: "Sr.No." },
    { id: "name", label: "Name" },
    { id: "contactNumber", label: "Contact Number" },
    { id: "emailId", label: "Email" },
    // { id: "mudziePlan", label: "Plan Purchase" },
    // { id: "mudziePlan", label: "Plan" },
    // { id: "expiryDate", label: "Expiry Date" },
    // { id: "companyName", label: "Company" },
    // { id: "poNumber", label: "PO Number" },
  ];

  function createData(
    logId,
    name,
    contactNumber,
    emailId,
    requested_plan,
    is_call_requested,
    user_id,
    is_approved_for_subscribe,
    is_reject_for_subsicribe
  ) {
    return {
      logId,
      name,
      contactNumber,
      emailId,
      requested_plan,
      is_call_requested,
      user_id,
      is_approved_for_subscribe,
      is_reject_for_subsicribe,
    };
  }

  function createDat(logId, name, contactNumber, emailId, mudziePlan) {
    return {
      logId,
      name,
      contactNumber,
      emailId,
      mudziePlan,
    };
  }

  const [PaymentAllowed, setPaymentAllowed] = useState(false);
  const [Reject, setReject] = useState(false);

  useEffect(() => {
    const user = localStorage.getItem("user");
    const userde = JSON.parse(user);
    const token = userde.token;
    const fetchData = async () => {
      try {
        const response = await Axios.get(
          `${process.env.REACT_APP_BASE_URL}api/admin/get-recent-call-requests`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.data.length < 1) {
          console.log("Records not found");
        } else {
          console.log("222222222222222222", response.data);
          const newRows = response.data.data.map((row, index) =>
            createData(
              index + 1,
              row.user.name,
              row.user.contactNumber,
              row.user.emailId,
              row.requested_plan,
              row.is_call_requested,
              row.user_id,
              row.is_approved_for_subscribe,
              row.is_reject_for_subsicribe,
              row.is_subscribed
            )
          );
          setRowsState(newRows);
          setLoading(false); // Update the state with the new rows
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData(); // Call the async function to fetch data
  }, [PaymentAllowed, Reject]);

  useEffect(() => {
    console.log("DDDDDDDDDDDDDDDDDD",localStorage.getItem("call_request_switch"));
    if(localStorage.getItem("call_request_switch")==="ON")
      setCallRequestSwitchDefaultValue(true)
    const user = localStorage.getItem("user");
    const userde = JSON.parse(user);
    const token = userde.token;
    const fetchData = async () => {
      try {
        const response = await Axios.get(
          `${process.env.REACT_APP_BASE_URL}api/admin/get-paid-call-requests`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.data.length < 1) {
          console.log("Records not found");
        } else {
          console.log("2345678", response.data.data);
          const newRows = response.data.data.map((row, index) =>
            createDat(
              index + 1,
              row.user?.name,
              row.user?.contactNumber,
              row.user?.emailId,
              row.user?.mudziePlan
              // row.requested_plan,
              // row.is_call_requested,
              // row.user_id,
              // row.is_approved_for_subscribe,
              // row.is_reject_for_subsicribe,
            )
          );
          setPaid(newRows);
          // setLoading(false); // Update the state with the new rows
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData(); // Call the async function to fetch data
  }, []);

  // console.log("111111111111111111111", rowsState);
  console.log("111111111111111111111", paid);

  const [filterValue, setFilterValue] = useState("");
  const [filtervalu, setFiltervalu] = useState("");

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleAllowClick = async (id) => {
    const user = localStorage.getItem("user");
    const userde = JSON.parse(user);
    const token = userde.token;
    console.log("-----------------@@@@@@@@@@@@", id);

    await Axios.get(
      `${process.env.REACT_APP_BASE_URL}api/admin/approve-for-subscribe/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((res) => {
        console.log(res.data);
        setAlertStatus(true);
        setPaymentAllowed(true);
        setType("success");
        setTitle("User notified via email to subscribe to Mudzie Paid Plans.");
      })
      .catch((error) => {
        console.log(error);
        setAlertStatus(true);
        setType("error");
        setTitle("User notified via email");
      });
  };

  const handleRejectClick = async (id) => {
    const user = localStorage.getItem("user");
    const userde = JSON.parse(user);
    const token = userde.token;
    console.log("-----------------@@@@@@@@@@@@", id);

    await Axios.get(
      `${process.env.REACT_APP_BASE_URL}api/admin/reject-for-subscribe/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((res) => {
        console.log(res.data);
        setAlertStatus(true);
        setReject(true);
        setType("error");
        setTitle("User Not Allow For Subscribe");
      })
      .catch((error) => {
        console.log(error);
        setAlertStatus(true);
        setType("error");
        setTitle("Error During Status update");
      });
  };

  const handleCallRequestSwitchChange = (event) => {
    setCallRequestSwitchDefaultValue(event.target.checked)
    const user = localStorage.getItem("user");
    const userde = JSON.parse(user);
    const token = userde.token;
    let call_request_switch_value;
    if(event.target.checked)
      call_request_switch_value = 'ON'
    else
      call_request_switch_value = 'OFF'
    Axios.post(
      `${process.env.REACT_APP_BASE_URL}api/admin/change-call-request-switch`,
      {'call_request_switch':call_request_switch_value},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
  };

  return (
    <div>
      <Navbar />
      <Box height={70} />
      <Box sx={{ display: "flex" }}>
        <Sidenav menuHeighlightPath="/dashboard/callrequest" />
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Card>
                <CardHeader
                  title="Call Requests"
                  action={
                    <>
                      {/* <FormGroup> */}
                        <FormControlLabel 
                          control={<Switch checked={callRequestSwitchDefaultValue} onChange={handleCallRequestSwitchChange} />} 
                          label="Call Request Switch" 
                        />
                      {/* </FormGroup> */}
                      {" "}
                      <TextField
                        value={filterValue}
                        onChange={(e) => setFilterValue(e.target.value)}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon />
                            </InputAdornment>
                          ),
                        }}
                        variant="standard"
                      />
                    </>
                  }
                />
                <CardContent>
                  <Paper sx={{ width: "100%", overflow: "hidden" }}>
                    <TableContainer sx={{ maxHeight: 700, maxWidth: "100%" }}>
                      <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                          <TableRow>
                            {columns.map((column, index) => (
                              <TableCell
                                key={index}
                                // align={column.align}
                                align="center"
                                style={{
                                  minWidth: column.minWidth,
                                }}
                              >
                                {column.label}
                              </TableCell>
                            ))}
                            <TableCell sx={{ pl: "30px" }} align="center">
                              Requested Plan
                            </TableCell>
                            <TableCell sx={{ pl: "30px" }} align="center">
                              Status
                            </TableCell>
                            {/* <TableCell>Delete</TableCell> */}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {loading ? (
                            <TableRow>
                              <TableCell colSpan={9}>Loading...</TableCell>
                            </TableRow>
                          ) : rowsState?.length ? (
                            rowsState
                              .filter((row) => {
                                return filterValue.toLowerCase() === ""
                                  ? row
                                  : row.name.toString().includes(filterValue) ||
                                  row.emailId
                                    .toString()
                                    .toLowerCase()
                                    .includes(filterValue);
                              })
                              .slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                              )
                              .map((row, index) => {
                                return (
                                  <TableRow
                                    hover
                                    role="checkbox"
                                    tabIndex={-1}
                                    key={index}
                                  >
                                    {columns.map((column, dindex) => {
                                      const value = row[column.id];
                                      return (
                                        <TableCell
                                          // align={column.align}
                                          align="center"
                                          key={dindex}
                                        >
                                          {column.format &&
                                            typeof value === "number"
                                            ? column.format(value)
                                            : value}
                                        </TableCell>
                                      );
                                    })}
                                    <TableCell align="center">
                                      <Typography>
                                        Mudzie {row.requested_plan}
                                      </Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                      {row.is_reject_for_subsicribe === 1 ? (
                                        <></>
                                      ) : row.is_approved_for_subscribe ===
                                        1 ? (
                                        <span
                                          style={{
                                            color: "green",
                                            fontSize: "16px",
                                            fontWeight: "bold",
                                            fontFamily: "Ubuntu",
                                          }}
                                        >
                                          Waiting For Payment.....
                                        </span>
                                      ) : (
                                        <Button
                                          variant="contained"
                                          color="primary"
                                          onClick={() =>
                                            handleAllowClick(row.user_id)
                                          }
                                        >
                                          Allow Payment
                                        </Button>
                                      )}

                                      {row.is_approved_for_subscribe === 1 ? (
                                        <></>
                                      ) : row.is_reject_for_subsicribe === 1 ? (
                                        <span
                                          style={{
                                            color: "red",
                                            fontSize: "16px",
                                            fontWeight: "bold",
                                            fontFamily: "Ubuntu",
                                          }}
                                        >
                                          Payment Rejected
                                        </span>
                                      ) : (
                                        <Button
                                          variant="contained"
                                          color="error"
                                          onClick={() =>
                                            handleRejectClick(row.user_id)
                                          }
                                          sx={{ ml: 3 }}
                                        >
                                          Reject
                                        </Button>
                                      )}
                                    </TableCell>
                                  </TableRow>
                                );
                              })
                          ) : (
                            <TableRow>
                              <TableCell colSpan={9}>
                                No records found
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    {loading ? (
                      <></>
                    ) : (
                      <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={rowsState.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    )}
                  </Paper>
                </CardContent>
              </Card>
              {/* paid user */}
              <Card>
                <CardHeader
                  title="Call Requested Paid User"
                  action={
                    <>
                      {" "}
                      <TextField
                        value={filtervalu}
                        onChange={(e) => setFiltervalu(e.target.value)}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon />
                            </InputAdornment>
                          ),
                        }}
                        variant="standard"
                      />
                    </>
                  }
                />
                <CardContent>
                  <Paper sx={{ width: "100%", overflow: "hidden" }}>
                    <TableContainer sx={{ maxHeight: 700, maxWidth: "100%" }}>
                      <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                          <TableRow>
                            {column.map((column, index) => (
                              <TableCell
                                key={index}
                                // align={column.align}
                                align="center"
                                style={{
                                  minWidth: column.minWidth,
                                }}
                              >
                                {column.label}
                              </TableCell>
                            ))}
                            <TableCell sx={{ pl: "30px" }} align="center">
                              Purchased Plan
                            </TableCell>
                            <TableCell sx={{ pl: "30px" }} align="center">
                              Status
                            </TableCell>
                            {/* <TableCell>Delete</TableCell> */}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {loading ? (
                            <TableRow>
                              <TableCell colSpan={9}>Loading...</TableCell>
                            </TableRow>
                          ) : paid?.length ? (
                            paid
                              .filter((row) => {
                                return filtervalu.toLowerCase() === ""
                                  ? row
                                  : row.name.toString().includes(filtervalu) ||
                                  row.emailId
                                    .toString()
                                    .toLowerCase()
                                    .includes(filtervalu);
                              })
                              .slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                              )
                              .map((row, index) => {
                                return (
                                  <TableRow
                                    hover
                                    role="checkbox"
                                    tabIndex={-1}
                                    key={index}
                                  >
                                    {column.map((column, dindex) => {
                                      const value = row[column.id];
                                      return (
                                        <TableCell
                                          // align={column.align}
                                          align="center"
                                          key={dindex}
                                        >
                                          {column.format &&
                                            typeof value === "number"
                                            ? column.format(value)
                                            : value}
                                        </TableCell>
                                      );
                                    })}
                                    <TableCell align="center">
                                      <Typography>
                                        Mudzie {row?.mudziePlan}
                                      </Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                      <span
                                        style={{
                                          color: "green",
                                          fontSize: "16px",
                                          fontWeight: "bold",
                                          fontFamily: "Ubuntu",
                                        }}
                                      >
                                        Paid
                                      </span>
                                    </TableCell>
                                  </TableRow>
                                );
                              })
                          ) : (
                            <TableRow>
                              <TableCell colSpan={9}>
                                No records found
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    {loading ? (
                      <></>
                    ) : (
                      <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={paid?.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    )}
                  </Paper>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <ReactJsAlert
        status={alertStatus} // true or false
        type={type} // success, warning, error, info
        title={title}
        autoCloseIn="30000"
        Close={() => {
          setAlertStatus(false);
          //   navigate("/", { replace: true });
        }}
      />
    </div>
  );
};

export default CallRequest;
