import React, { createContext, useContext, useState } from "react";

// Step 1: Create a Context
const UserDataContext = createContext();

// Step 2: Create a Provider Component
export function UserDataProvider({ children }) {
  const [userData, setUserData] = useState(null);

  return (
    <UserDataContext.Provider value={{ userData, setUserData }}>
      {children}
    </UserDataContext.Provider>
  );
}

// Step 3: Use useContext in Consumer Components
export function useUserData() {
  return useContext(UserDataContext);
}
