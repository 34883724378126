import React, { useState, useEffect } from "react";
import { Chart } from "react-google-charts";
import Axios from 'axios';
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import blue from "../images/Ellipse 1.png";
import red from "../images/Ellipse 1 (1).png";

export let data;
export const options = {
  // chart: {
  //   title: "Ginnie Mae Permanent Loans @$102 vs. 10 year yield"
  // },
  hAxis: {
    textStyle: { color: 'orange' }
  }
};

function LineChart(props) {
  const [loanType, setLoanType] = useState(props.data)
  const [MIPSectionVisible, setMIPSectionVisible] = useState(props.MIPSectionVisible)
  const [loading, setLoading] = useState(true);
  const [tenYearRateAvg, setTenYearRateAvg] = useState();
  const [permAtOneZeroTowAvg, setPermAtOneZeroTowAvg] = useState();
  useEffect(() => {
    setLoanType(props.data)
    setMIPSectionVisible(props.MIPSectionVisible)
    setLoading(true)
    if (props.MIPSectionVisible)
      data = [["", "10 Year Rate", "Perm @ 102"]];
    else
      data = [["", "10 Year Rate"]];
    Axios.post("https://mudzie.com/admin/public/api/getGennieYeildTable")
      .then((res) => {
        let tenYearRateSum = 0;
        let permAtOneZeroTowSum = 0;
        for (let i = 0; i < res.data.length; i++) {
          var date = new Date(res.data[i].yieldDate);
          var options = {
            month: "short", day: "numeric"
          };
          if (props.data === "Permanent") {

            if (props.MIPSectionVisible)
              data.push([date.toLocaleDateString("en-us", options), res.data[i].tenYearRate, res.data[i].permAtOneZeroTow]);
            else
              data.push([date.toLocaleDateString("en-us", options), res.data[i].tenYearRate]);

            tenYearRateSum = tenYearRateSum + res.data[i].tenYearRate;
            permAtOneZeroTowSum = permAtOneZeroTowSum + res.data[i].permAtOneZeroTow;
          }
          else {
            if (res.data[i].yieldDate.split("-")[1] === '08') {
              if (props.MIPSectionVisible)
                data.push([date.toLocaleDateString("en-us", options), res.data[i].tenYearRate, Number((res.data[i].permAtOneZeroTow + 0.35).toFixed(2))]);
              else
                data.push([date.toLocaleDateString("en-us", options), res.data[i].tenYearRate]);

              tenYearRateSum = tenYearRateSum + res.data[i].tenYearRate;
              permAtOneZeroTowSum = permAtOneZeroTowSum + Number((res.data[i].permAtOneZeroTow + 0.35).toFixed(2));
            }
            else {
              if (props.MIPSectionVisible)
                data.push([date.toLocaleDateString("en-us", options), res.data[i].tenYearRate, Number((res.data[i].permAtOneZeroTow + 0.60).toFixed(2))]);
              else
                data.push([date.toLocaleDateString("en-us", options), res.data[i].tenYearRate]);

              tenYearRateSum = tenYearRateSum + res.data[i].tenYearRate;
              permAtOneZeroTowSum = permAtOneZeroTowSum + Number((res.data[i].permAtOneZeroTow + 0.60).toFixed(2));
            }
          }
        }
        setTenYearRateAvg((tenYearRateSum / (data.length - 1)).toFixed(2));
        setPermAtOneZeroTowAvg((permAtOneZeroTowSum / (data.length - 1)).toFixed(2));
        if (data.length > 1)
          setLoading(false);
      })
  }, [props]);
  return (
    loading ? <></> :
      <>
        <Typography
          sx={{
            mt: 3, fontSize: { sm: 28, xs: 26 }, fontWeight: "bold", textAlign: "center",
            fontFamily: "Ubuntu"
          }}
        >
          {props.MIPSectionVisible ?
            `${loanType} loan GNMA rates for the Past 3 Months`
            :
            `10-year treasury yield`
          }

        </Typography>
        <Box
          sx={{ width: "100%", height: "100px", boxShadow: 12, mb: 5, mt: 3 }}
          borderRadius="20px"
          display="flex"
          flexDirection="row"
        >
          <Grid item sx={{ ml: 5, mt: 2 }}>
            <img src={blue} alt="" style={{ display: "inline" }} />
            <Typography sx={{ fontFamily: "Ubuntu", color: "#6D6D6D", mt: -3, ml: 3 }} >
              10 year Rate
            </Typography>
            <Typography sx={{ fontFamily: "Ubuntu", fontWeight: "bold", fontSize: "24px", mt: 1 }} >
              AVG : {tenYearRateAvg}%
            </Typography>
          </Grid>
          {
            MIPSectionVisible ?
              <Grid item sx={{ ml: 5, mt: 2 }}>
                <img src={red} alt="" />
                <Typography sx={{ fontFamily: "Ubuntu", color: "#6D6D6D", mt: -3, ml: 3 }} >
                  Perm @ 102
                </Typography>
                <Typography sx={{ fontFamily: "Ubuntu", fontWeight: "bold", fontSize: "24px", mt: 1 }} >
                  AVG : {permAtOneZeroTowAvg}%
                </Typography>
              </Grid>
              :
              <></>
          }
        </Box>
        <Grid
          container
          flexDirection={"column"}
          borderRadius="20px"
          sx={{ boxShadow: 12, width: { sm: "100%" }, height: "420px" }}
        >
          <Grid item sx={{ m: 1, width: "100%" }}>
            <Chart
              chartType="Line"
              width="98.5%"
              height="400px"
              data={data}
              options={options}
            />
          </Grid>
        </Grid>
      </>
  );
}
export default React.memo(LineChart);