import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
// import FormControlLabel from '@mui/material/FormControlLabel';
// import Checkbox from '@mui/material/Checkbox';
import { NavLink } from "react-router-dom";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
// import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import logo from "./images/favicon.jpg";
import Axios from "axios";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import ReactJsAlert from "reactjs-alert";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" target="_blank" href="https://kimmymae.com/">
        KimmyMae™
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const theme = createTheme();

export default function SignUp() {
  const [alertStatus, setAlertStatus] = React.useState(false);
  const [type, setType] = React.useState("success");
  const [title, setTitle] = React.useState("");
  const {
    register,
    handleSubmit,
    watch,
    setError,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const onSubmit = ({
    name,
    contactNumber,
    emailId,
    password,
    companyName,
  }) => {
    const jsonobj = {
      name: name,
      contactNumber: contactNumber,
      emailId: emailId,
      password: password,
      companyName: companyName,
    };

    //console.log("000000000000000000", data);

    Axios.post(
      `${process.env.REACT_APP_BASE_URL}api/checkEmailIdExist`,
      jsonobj
    ).then((res) => {
      console.log(res.data.response);
      if (res.data.response === 1) {
        setError("emailId", {
          type: "server",
          message: "Email id already exist.",
        });
      } else {
        Axios.post(`${process.env.REACT_APP_BASE_URL}api/add`, jsonobj).then(
          (res) => {
            if (res.data.response === 1) {
              setAlertStatus(true);
              setType("success");
              setTitle(
                "Account verificaton link has been sent to your email id. Please verify your account."
              );
            }
          }
        );
      }
    });
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar src={logo} alt="KM" />
          <Typography component="h1" variant="h5">
            Sign up
          </Typography>
          <Typography
            component="h1"
            variant="h5"
            color="primary"
            sx={{ fontWeight: "bold" }}
          >
            MUDZIE
          </Typography>
          <Typography>By KimmyMae™</Typography>
          <Typography sx={{ mt: 2 }}>
            Login for live market information and interest rates. Currently
            serving select partners in the HUD, RHS, DUS, and SBA markets.
          </Typography>

          <Box
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            noValidate
            sx={{ mt: 1 }}
            width={{ sm: 555 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="name"
              label="Name"
              name="name"
              {...register("name", { required: "Please enter name." })}
              error={Boolean(errors.name)}
              helperText={errors.name?.message}
              inputProps={{ maxLength: 250 }}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="contactNumber"
              label="Contact Number"
              name="contactNumber"
              {...register("contactNumber", {
                required: "Please enter contact number.",
                pattern: {
                  value: /^[0-9]/,
                  message: "Please enter valid contact number.",
                },
              })}
              error={Boolean(errors.contactNumber)}
              helperText={errors.contactNumber?.message}
              inputProps={{ maxLength: 15 }}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="emailId"
              label="Email Address"
              name="emailId"
              {...register("emailId", {
                required: "Please enter email id.",
                pattern: {
                  value:
                    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                  message: "Please enter valid email id.",
                },
              })}
              error={Boolean(errors.emailId)}
              helperText={errors.emailId?.message}
              inputProps={{ maxLength: 250 }}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              {...register("password", {
                required: "Please enter password.",
              })}
              error={Boolean(errors.password)}
              helperText={errors.password?.message}
              inputProps={{ maxLength: 100 }}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="confirmPassword"
              label="Confirm Password"
              type="password"
              id="confirmPassword"
              {...register(
                "confirmPassword",
                { required: "Please enter confirm password." },
                {
                  validate: (val) => {
                    if (watch("password") !== val) {
                      return "Your passwords do no match";
                    }
                  },
                }
              )}
              error={Boolean(errors.confirmPassword)}
              helperText={errors.confirmPassword?.message}
              inputProps={{ maxLength: 100 }}
            />
            <TextField
              margin="normal"
              fullWidth
              id="companyName"
              label="Company Name"
              name="companyName"
              {...register("companyName")}
              error={Boolean(errors.companyName)}
              helperText={errors.companyName?.message}
              inputProps={{ maxLength: 250 }}
            />
            {/* <FormControlLabel
                            control={<Checkbox value="remember" color="primary" />}
                            label="Remember me"
                        /> */}
            <Button
              fullWidth
              type="submit"
              variant="contained"
              sx={{ mt: 2, mb: 2 }}
              // href="/calculator"
              // component={NavLink} to={'/calculator'}
            >
              Sign Up
            </Button>
            <Grid container>
              <Grid item>
                <Link component={NavLink} to="/" variant="body2">
                  {"Already have account? Click here to login."}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Typography variant="body2" align="center" sx={{ mt: 2 }}>
          By clicking "Sign Up", you attest you have read and agree to be bound
          by KimmyMae™’s Terms and Conditions, Privacy Policy and disclaimers
          specific to the use of this tool. NOTE: Access to this tool is by
          express authorization only. The use of this tool by any persons other
          than the named subscriber is: i. strictly prohibited; ii. may cause
          the named subscriber to be banned from further access indefinitely,
          and; iii. is grounds for legal action against any perpetrators.
        </Typography>
        <Copyright sx={{ mt: 4, mb: 4 }} />
      </Container>
      <ReactJsAlert
        status={alertStatus} // true or false
        type={type} // success, warning, error, info
        title={title}
        autoCloseIn="30000"
        Close={() => {
          setAlertStatus(false);
          navigate("/", { replace: true });
        }}
      />
    </ThemeProvider>
  );
}
