import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Tooltip1 from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Modal from "@mui/material/Modal";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import backimage from "./images/mudzie_banner.png";
import application from "./images/application.png";
import mudzie from "./images/Color logo - no background.png";
import loan from "./images/loan.png";
import lock from "./images/lock 1.png";
import caution from "./images/caution 1.png";
import twitter from "./images/twitter 1.png";
import facebook from "./images/facebook-app-symbol 1.png";
import linkdin from "./images/linkedin (2) 1.png";
import Axios from "axios";
import ClipLoader from "react-spinners/ClipLoader";
import LineChart from "./charts/LineChart";
import RefreshIcon from "@mui/icons-material/Refresh";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import ReactJsAlert from "reactjs-alert";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";


// //////////added by aniket

import Fab from '@mui/material/Fab';
import Download from '@mui/icons-material/Download';
import html2canvas from 'html2canvas'
import logo from './images/Color logo with background.png'
// import sample from './images/sample.jpg'
////////// npm install html2canvas

// //////// added by aniket end

const theme = createTheme({ breakpoints: { values: { xs: 0, sm: 600, md: 960, lg: 1280, xl: 1920 } } });

export default function Calculator() {
  const [alertStatus, setAlertStatus] = React.useState(false);
  const [type, setType] = React.useState("success");
  const [title, setTitle] = React.useState("");
  let [loading, setLoading] = useState(false);
  let [pageLoading, setPageLoading] = useState(true);
  let [tenYearTreasuryloading, setTenYearTreasuryloading] = useState(true);
  let [tenYearTreasury, setTenYearTreasury] = useState();
  let [tenYearSOFRSwap, setTenYearSOFRSwap] = useState();
  let [currDate, setCurrDate] = useState(new Date().toDateString());
  let [currTime, setCurrTime] = useState(
    new Date().toLocaleTimeString() +
    " " +
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );

  //*** Dynamic Code
  const [remainingBalance, setRemainingBalance] = useState(0);
  const [remainingBalanceTemp, setRemainingBalanceTemp] = useState(0);
  const [term, setTerm] = useState(0);
  const [amort, setAmort] = useState(0);
  const [ioTerm, setIoTerm] = useState(0);
  const [passThru, setPassThru] = useState();
  const [passThruFromPrice, setPassThruFromPrice] = useState();
  const [sAndG, setSAndG] = useState(0);
  const [override, setOverride] = useState("");
  const [pxSpread, setPxSpread] = useState(0);
  const [pxSpreadConstruction, setPxSpreadConstruction] = useState(0);
  const [jTen, setJTen] = useState("");
  const [programId, setProgramId] = useState();

  useEffect(() => {
    setPageLoading(true);
    const getSettings = () => {
      // Axios.get("https://mudzie.com/admin/public/api/getSettings").then(
      Axios.get(`${process.env.REACT_APP_BASE_URL}api/getSettings`).then(
        (res) => {
          setRemainingBalance(res.data.loanAmount);
          setRemainingBalanceTemp(res.data.loanAmount);
          setTerm(res.data.term);
          setAmort(res.data.amort);
          setIoTerm(res.data.ioTerm);
          // setPassThru(res.data.passThru);
          setSAndG(res.data.sAndG);
          setOverride(res.data.override);
          setPxSpread(res.data.pxSpread);
          setPxSpreadConstruction(res.data.pxSpreadConstruction);
          setJTen(res.data.jTen);
          localStorage.setItem("call_request_switch", res.data.call_request_switch)
          console.log("call_request_switch", res.data.call_request_switch);
        }
      );
    };
    getSettings();
    Axios.get(
      "https://h8ypxuftnl.execute-api.us-west-2.amazonaws.com/dev/refinitiv"
    ).then((res) => {
      setTreasuryDelay(false)
      setTenYearTreasury(Number(res.data.body.split(",")[4]).toFixed(2));
      setTenYearSOFRSwap(Number(res.data.body.split(",")[12]).toFixed(2));
      setTenYearTreasuryloading(false);
      setPageLoading(false);
    })
    .catch((error) => {
      setTreasuryDelay(true)
      console.error("Error fetching data:", error);
      setTenYearTreasuryloading(false); // Update the loading state to false even on error
      setPageLoading(false);
      // Optionally, you can set default values or show error messages
      setTenYearTreasury("N/A");
      setTenYearSOFRSwap("N/A");
    })
  }, []);

  const refreshTenYrTreasuryRate = () => {
    setTenYearTreasuryloading(true);
    setTenYearTreasury("");
    Axios.get(
      "https://h8ypxuftnl.execute-api.us-west-2.amazonaws.com/dev/refinitiv"
    ).then((res) => {
      setTenYearTreasury(Number(res.data.body.split(",")[4]).toFixed(2));
      setCurrDate(new Date().toDateString());
      setCurrTime(
        new Date().toLocaleTimeString() +
        " " +
        Intl.DateTimeFormat().resolvedOptions().timeZone
      );
      setTenYearTreasuryloading(false);
    })
    .catch((error) => {
      setTreasuryDelay(true)
      console.error("Error fetching data:", error);
      setTenYearTreasuryloading(false); // Update the loading state to false even on error
      setPageLoading(false);
      // Optionally, you can set default values or show error messages
      setTenYearTreasury("N/A");
      setTenYearSOFRSwap("N/A");
    });
  };

  //Accrued Days field
  let today = new Date();
  var d = new Date(today.getFullYear(), parseInt(today.getMonth()) + 3, 0);

  const ddate = new Date(d);
  ddate.setMonth(ddate.getMonth() - 1);
  ddate.setDate(ddate.getDate() + 26);
  let dateForAccruedDays = new Date(d);
  dateForAccruedDays.setMonth(dateForAccruedDays.getMonth() - 1);

  const [priceState, setPriceState] = useState();
  const [mIPpriceState, setMIPPriceState] = useState();
  const navigate = useNavigate();
  const [showPrice, setShowPrice] = useState(false);
  const [showErrorRate, setShowErrorRate] = useState(false);
  const [showErrorFrontEndFees, setShowErrorFrontEndFees] = useState(false);
  const [showErrorIOTermSAndG, setShowErrorIOTermSAndG] = useState(false);
  const [showErrorMIPpriceState, setShowErrorMIPpriceState] = useState(false);
  const [reducedMIPLoan, setReducedMIPLoan] = useState("N");
  const [frontEndFees, setFrontEndFees] = useState("");
  const [DPPPXSpread, setDPPPXSpread] = useState("");
  const [IOTermSAndG, setIOTermSAndG] = useState("");
  const [JTenForSOFR, setJTenForSOFR] = useState("");

  const [treasuryDelay, setTreasuryDelay] = useState(false)

  const calculateIndicator = () => {
    if (plan !== 0) {
      console.log("priceState :" + priceState);
      console.log("mIPpriceState :" + mIPpriceState);
      console.log("passThru :" + passThru);
      console.log("passThruFromPrice :" + passThruFromPrice);
      console.log("DPPPXSpread :" + DPPPXSpread);
      console.log("IOTermSAndG :" + IOTermSAndG);
      let logTime = new Date().toLocaleDateString() + " " + new Date().toLocaleTimeString();
      let uId = localStorage.getItem("userId");
      console.log("showErrorRate : ", showErrorRate);
      if (showErrorFrontEndFees !== true && showErrorRate !== true) {
        setLoading(true);
        if ((passThru === undefined || passThru === "") && (priceState === undefined || priceState === "")) {
          setLoading(false);
          setAlertStatus(true);
          setType("error");
          setTitle("Please enter Pass-Through Rate (%) or Price ($).");
        }
        else if (passThru !== undefined && passThru !== "" && priceState !== undefined && priceState !== "") {
          setLoading(false);
          setAlertStatus(true);
          setType("error");
          setTitle("You can either enter Pass-Through Rate (%) or Price ($)");
        }
        else if ((passThru === undefined || passThru === "") && (priceState !== undefined || priceState !== "")) {
          let callAPI = false;
          let strArry = priceState.split(" ");
          if (Number.isInteger(Number(strArry[0]))) {
            if (strArry.length > 1) {
              let newStrArry = strArry[1].split("/");
              if (Number.isInteger(Number(newStrArry[0])) && Number(newStrArry[0]) < 32 && Number(newStrArry[1]) === 32)
                callAPI = true;
            }
            else
              callAPI = true;
          }
          if (callAPI === true) {
            const data = {
              price: priceState,
              programId: programId,
              userId: uId,
              loanType: loanType,
              type: "Rate From Price",
              frontEndFees: frontEndFees,
              logTime: logTime,
              DPPPXSpread: DPPPXSpread,
              IOTermSAndG: IOTermSAndG,
              JTenForSOFR: JTenForSOFR
            };
            Axios.post(`${process.env.REACT_APP_BASE_URL}api/getRateFromPrice`, data)
              .then((res) => {
                setPassThru(res.data.toFixed(2));
                setLoading(false);
              })
              .catch((error) => {
                setTreasuryDelay(true)
                console.error("Error fetching data:", error);
                setTenYearTreasuryloading(false); // Update the loading state to false even on error
                setPageLoading(false);
              });
          }
          else {
            setLoading(false);
            setAlertStatus(true);
            setType("error");
            setTitle("Please enter valid price. e.g. 102 7/32");
          }
        }
        else if ((priceState === undefined || priceState === "") && (passThru !== undefined || passThru !== "")) {
          const data = {
            passThroughRate: passThru,
            programId: programId,
            userId: uId,
            loanType: loanType,
            type: "Price From Rate",
            logTime: logTime,
            DPPPXSpread: DPPPXSpread,
            IOTermSAndG: IOTermSAndG,
            JTenForSOFR: JTenForSOFR
          };
          Axios.post(`${process.env.REACT_APP_BASE_URL}api/getPriceFromRate`, data)
            .then((res) => {
              setPriceState(res.data);
              setShowPrice(true);
              setLoading(false);
            })
            .catch((error) => {
              setTreasuryDelay(true)
              console.error("Error fetching data:", error);
              setTenYearTreasuryloading(false); // Update the loading state to false even on error
              setPageLoading(false);
            });
        }
      }
    }
    else
      setIsMyAccountDialogOpen(true)
  };
  const [colorFlag, setColorFlag] = useState(true);
  const [loanType, setLoanType] = useState("Permanent");
  function handleButtonColor(e) {
    let type = e.target.id;
    setColorFlag(!colorFlag);
    if (type === "permanent") {
      setLoanType("Permanent");
      Axios.get("https://mudzie.com/admin/public/api/getSettings").then(
        (res) => {
          setPxSpread(res.data.pxSpread);
        }
      );
    }
    if (type === "construction") {
      setLoanType("Construction");
      setPxSpread(pxSpreadConstruction);
    }
  }

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    overflow: "auto",
    height: "80%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const [subscribe, setsubscribe] = useState(true);
  const [plan, setPlan] = useState(null);
  const [expiryDate, setExpirydate] = useState(null);

  useEffect(() => {
    const user = localStorage.getItem("user");
    const userde = JSON.parse(user);
    const logId = localStorage.getItem("userId");
    const esp = new Date();
    const year = esp.getFullYear();
    const month = String(esp.getMonth() + 1).padStart(2, "0"); // Add 1 to the month since it's 0-based
    const day = String(esp.getDate()).padStart(2, "0");

    const formattedDate = `${year}-${month}-${day}`;
    // const today = new Date().toLocaleDateString();
    Axios.get(
      `${process.env.REACT_APP_BASE_URL}api/admin/get-user-details/${logId}`,
      {
        headers: {
          Authorization: `Bearer ${userde?.token}`,
        },
      }
    )
      .then((res) => {
        console.log("~~~~~~~~~~~~~~~~~~~~~~~", res.data);
        setPlan(res.data.user[0].is_active_plan);
        setExpirydate(res.data.user[0].expiryDate);
        if (res.data.user[0].expiryDate < formattedDate) {
          Axios.get(`${process.env.REACT_APP_BASE_URL}api/end-subscription`, {
            headers: {
              Authorization: `Bearer ${userde?.token}`,
            },
          })
            .then((res) => {
              console.log(res.data);
            })
            .catch((err) => {
              console.log("rrrrrrrrr", err);
            });
        }
      })
      .catch((err) => {
        console.log(err);
      });
    if (userde?.is_active_plan === 1) {
      setsubscribe(false);
    } else {
      setsubscribe(true);
    }
  }, []);

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isMyAccountDialogOpen, setIsMyAccountDialogOpen] = useState(false);

  const handleCalculate = () => {
    setIsDeleteDialogOpen(true);
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleOut = () => {
    localStorage.removeItem("userId");
    localStorage.removeItem("user");
    navigate("/");
  };

  const esp = new Date();
  const year = esp.getFullYear();
  const month = String(esp.getMonth() + 1).padStart(2, "0"); // Add 1 to the month since it's 0-based
  const day = String(esp.getDate()).padStart(2, "0");

  const formattedDate = `${year}-${month}-${day}`;

  // window.scrollTo({ top: 0, left: 0, behavior: 'instant' });

  const calculateMaxPremium = () => {
    console.log(frontEndFees);
    if (frontEndFees !== "" || frontEndFees > 1) {
      setShowErrorFrontEndFees(false);
      let mudzieMaxPrice = 5 - frontEndFees + 100;
      var mudzieMaxPriceWholeNumber = Math.floor(mudzieMaxPrice);
      var mudzieMaxPriceFraction = Math.round(
        (mudzieMaxPrice - mudzieMaxPriceWholeNumber) * 32
      );
      if (mudzieMaxPriceFraction === 32) {
        mudzieMaxPriceFraction = 0;
        mudzieMaxPriceWholeNumber += 1;
      }
      mudzieMaxPrice =
        mudzieMaxPriceWholeNumber.toString() +
        " " +
        mudzieMaxPriceFraction.toString() +
        "/32";
      setPriceState(mudzieMaxPrice);
      // setMIPPriceState(mudzieMaxPrice);
      setShowErrorMIPpriceState(false);
      // setShowPrice(true)
    } else setShowErrorFrontEndFees(true);
  };

  const changeMIPOption = (val) => {
    setPassThru("");
    setPriceState("");
    setFrontEndFees("");
    if (val === "N") {
      setPassThruFromPrice("");
    } else setMIPPriceState("");
    setReducedMIPLoan(val);
  };

  const resetCalculator = () => {
    setProgramId("");
    setPassThru("");
    setPriceState("");
    setFrontEndFees("");
    setPassThruFromPrice("");
    setReducedMIPLoan("N");
  };

  const [HUDRHSBackground, setHUDRHSBackground] = useState("linear-gradient(to right bottom, #1976D2, #175FA2)")
  const [DUSBackground, setDUSBackground] = useState("purple")
  const [loanTypeOpacity, setLoanTypeOpacity] = useState(1)
  const [loanTypePointerEvents, setLoanTypePointerEvents] = useState("auto")
  const [MIPSectionVisible, setMIPSectionVisible] = useState(true)
  const [showSAndG, setShowSAndG] = useState("N")
  const [DPPOption, setDPPOption] = useState("N")

  const dusClick = () => {
    setHUDRHSBackground("purple")
    setDUSBackground("linear-gradient(to right bottom, #1976D2, #175FA2)")
    setLoanTypeOpacity(0.5)
    setLoanTypePointerEvents("none")
    setMIPSectionVisible(false)
    setLoanType("Permanent")
    setJTenForSOFR("D")
  }

  const HUSRHSClick = () => {
    setHUDRHSBackground("linear-gradient(to right bottom, #1976D2, #175FA2)")
    setDUSBackground("purple")
    setLoanTypeOpacity(1)
    setLoanTypePointerEvents("auto")
    setMIPSectionVisible(true)
    setDPPPXSpread("")
    setJTenForSOFR("")
  }


  /////// added by aniket

  const [widgetConstructorRate, setWidgetConstructorRate] = useState('')
  const [widgetPermenantRate, setWidgetPermenantRate] = useState('')
  const [widgetLogTime, setWidgetLogTime] = useState('')
  const [widgetLoader, setWidgetLoader] = useState(false)

  const calculateIndicatorForWidget = async () => {

    setWidgetLoader(true)

    let logTime = new Date().toLocaleDateString() + " " + new Date().toLocaleTimeString();
    setWidgetLogTime(logTime)

    const data = {
      price: "102 0/32",
      programId: "",
      userId: "1",
      loanType: "Permanent",
      type: "Rate From Price",
      frontEndFees: "",
      logTime: logTime,
      DPPPXSpread: "",
      IOTermSAndG: "",
      JTenForSOFR: ""
    };
    await Axios.post(`${process.env.REACT_APP_BASE_URL}api/getRateFromPrice`, data)
      .then((res) => {
        setWidgetPermenantRate(res.data.toFixed(2));
        // setPassThru(res.data.toFixed(2));
        setLoading(false);
      });

    const data2 = {
      price: "102 0/32",
      programId: "",
      userId: "1",
      loanType: "Construction",
      type: "Rate From Price",
      frontEndFees: "",
      logTime: logTime,
      DPPPXSpread: "",
      IOTermSAndG: "",
      JTenForSOFR: ""
    };
    await Axios.post(`${process.env.REACT_APP_BASE_URL}api/getRateFromPrice`, data2)
      .then((res) => {
        setWidgetConstructorRate(res.data.toFixed(2));
        // setPassThru(res.data.toFixed(2));
        setLoading(false);
      });

    setTimeout(() => {
      handleImageDownload();
    }, 2000);

  };

  const handleImageDownload = async () => {

    const element = document.getElementById('print');
    if (!element) {
      console.error('Element with id "print" not found');
      return;
    }

    // Temporarily show the content to capture the image
    if (element.style.display === 'none') {
      element.style.display = 'block'
      element.style.visibility = 'visible';
      element.style.position = 'absolute';
      element.style.left = '-9999px';
    }


    const canvas = await html2canvas(element);
    const dataUrl = canvas.toDataURL('image/jpeg');

    // Revert the styles to hide the content again
    element.style.display = 'none';
    element.style.position = '';
    element.style.left = '';

    const link = document.createElement('a');
    link.href = dataUrl;
    link.download = 'downloaded-image.jpg';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    setWidgetLoader(false)
  };

  /////// added by aniket ended


  return (
    <>
      {/* {pageLoading ? (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <ClipLoader
            color="#013a80"
            loading={pageLoading}
            size={50}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </Box>
      ) : ( */}
        <ThemeProvider theme={theme}>
          <Container maxWidth="xl" component="main">
            {/* <CssBaseline /> */}
            <Box sx={{ marginTop: 0, display: "flex", flexDirection: "column", width: "100%" }} >
              <Box
                sx={{
                  flexDirection: "row", display: "flex", justifyContent: "space-between",
                  alignItems: "center", width: "100%",
                  background: "linear-gradient(to right bottom, #1976D2, #175FA2)", height: "100px",
                }}
              >
                <Box sx={{ ml: { xl: 15, lg: 10, sm: 10, xs: 0 } }}>
                  <img
                    src={mudzie}
                    alt="logo"
                    width={200}
                    height={60}
                  // style={{ marginLeft: "80px", marginRight: "-30px" }}
                  />
                </Box>
                <IconButton
                  aria-label="profile"
                  color="white"
                  onClick={handleMenuOpen}
                >
                  <AccountCircleIcon fontSize="large" sx={{ color: "white", mr: 5 }} />
                </IconButton>
              </Box>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
              >
                <MenuItem onClick={() => navigate("/accountpage")}>
                  My Account
                </MenuItem>
                <MenuItem onClick={() => navigate("/payments")}>
                  Pricing
                </MenuItem>
                <MenuItem onClick={handleOut}>Logout</MenuItem>
              </Menu>
              <Dialog
                open={treasuryDelay}
                onClose={() => setTreasuryDelay(false)}
              >
                <DialogTitle textAlign="center">Treasury Delay</DialogTitle>
                {/* <DialogContent>
                  You have used up your free credits.
                </DialogContent> */}
                <DialogContent>
                  Getting delayed in loading treasury data!
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => setTreasuryDelay(false)}
                    color="error"
                    variant="contained"
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={() => window.location.reload()}
                    variant="contained"
                    color="primary"
                  >
                    Try again
                  </Button>
                </DialogActions>
              </Dialog>
              <Dialog
                open={isDeleteDialogOpen}
                onClose={() => setIsDeleteDialogOpen(false)}
              >
                <DialogTitle textAlign="center">Please Subscribe</DialogTitle>
                {/* <DialogContent>
                  You have used up your free credits.
                </DialogContent> */}
                <DialogContent>
                  Please subscribe to continue using.
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => setIsDeleteDialogOpen(false)}
                    color="error"
                    variant="contained"
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={() => navigate("/payments")}
                    variant="contained"
                    color="primary"
                  >
                    Take to Pricing page
                  </Button>
                </DialogActions>
              </Dialog>
              <Dialog
                open={isMyAccountDialogOpen}
                onClose={() => setIsMyAccountDialogOpen(false)}
              >
                <DialogTitle textAlign="center">Add Yourself</DialogTitle>
                <DialogContent>
                  Please add yourself as a team member in My Account section.
                </DialogContent>
                {/* <DialogContent sx={{ mt: -4 }}>
                  Please subscribe to continue using.
                </DialogContent> */}
                <DialogActions>
                  <Button
                    onClick={() => setIsMyAccountDialogOpen(false)}
                    color="error"
                    variant="contained"
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={() => navigate("/accountpage")}
                    variant="contained"
                    color="primary"
                  >
                    My Account
                  </Button>
                </DialogActions>
              </Dialog>
              <Box
                sx={{
                  mt: 3,
                  width: { sm: "100%", xl: "100%", lg: "100%", xs: "100%" },
                  gap: "20px",
                  display: "flex",
                  flexDirection: { sm: "row", xs: "column" },
                }}
              >
                <Box
                  // width="50%"
                  height="220px"
                  bgcolor="white"
                  borderRadius="20px"
                  sx={{
                    boxShadow: 12,
                    width: { xl: "50%", lg: "50%", sm: "50%", xs: "100%" },
                  }}
                >
                  <Grid container>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      sx={{ mt: 4, ml: { xl: 16, lg: 8, sm: 8, xs: 5 } }}
                    //   justifyContent={{ sm: "flex-end" }}
                    >
                      <img src={application} alt="" width={36} height={36} />
                      <Typography
                        sx={{
                          ml: { xs: 6 },
                          fontSize: "28px",
                          fontFamily: "Ubuntu",
                          mt: -5.5,
                        }}
                      >
                        Program
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      sm={1}
                      sx={{ mt: 1, mr: 1 }}
                      container
                      justifyContent="flex-end"
                    >
                    </Grid>
                    <Grid
                      container
                      spacing={2}
                      sx={{ ml: { xl: 12, lg: 4, sm: 4, xs: 2 } }}
                    >
                      <Grid item xs={3.5} sm={4} xl={3.5} lg={3.5}>
                        <Button
                          type="submit"
                          variant="contained"
                          style={{ background: HUDRHSBackground }}
                          sx={{
                            mt: 2,
                            color: "white",
                            width: {
                              sm: "10px",
                              xs: "10px",
                              lg: "140px",
                              xl: "140px",
                            },
                            height: "50px",
                            borderTopLeftRadius: "14px",
                            borderTopRightRadius: "14px",
                            fontSize: { sm: "10px", xs: "10px", lg: "0.875rem" },
                            px: { sm: 2, xs: 4, xl: 4, lg: 4 },
                          }}
                          onClick={HUSRHSClick}
                        >
                          HUD / RHS
                        </Button>
                      </Grid>
                      <Grid item xs={3.5} sm={4} xl={3.5} lg={3.5}>
                        <Tooltip1 title="Coming Soon!" placement="top">
                          <span>
                            <Button
                              type="submit"
                              variant="contained"
                              // style={{ background: DUSBackground }}
                              sx={{
                                mt: 2,
                                backgroundColor: "lightgray",
                                color: "black",
                                width: {
                                  sm: "20%",
                                  xs: "10%",
                                  lg: "140px",
                                  xl: "140px",
                                },
                                height: "50px",
                                borderTopLeftRadius: "14px",
                                borderTopRightRadius: "14px",
                                px: { sm: 2, xs: 2, xl: 4, lg: 4 },
                              }}
                              onClick={dusClick}
                              disabled
                            >
                              DUS
                            </Button>
                          </span>
                        </Tooltip1>
                      </Grid>
                      <Grid item xs={3.5} sm={4} xl={3.5} lg={3.5}>
                        <Tooltip1 title="Coming Soon!" placement="top">
                          <span>
                            <Button
                              type="submit"
                              variant="contained"
                              sx={{
                                mt: 2,
                                backgroundColor: "lightgray",
                                color: "black",
                                width: {
                                  sm: "20%",
                                  xs: "10%",
                                  lg: "140px",
                                  xl: "140px",
                                },
                                height: "50px",
                                borderTopLeftRadius: "14px",
                                borderTopRightRadius: "14px",
                                px: { sm: 2, xs: 2, xl: 4, lg: 4 },
                              }}
                              disabled
                            >
                              SBA
                            </Button>
                          </span>
                        </Tooltip1>
                      </Grid>
                      <Grid item xs={10} sm={9.8} mt={-3.2} xl={9.3}>
                        <hr color="#175FA2" />
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
                <Box
                  // width="50%"
                  height="220px"
                  bgcolor="white"
                  borderRadius="20px"
                  style={{ opacity: loanTypeOpacity, pointerEvents: loanTypePointerEvents }}
                  sx={{ boxShadow: 12, width: { xl: "50%", lg: "50%", sm: "50%", xs: "100%" } }}
                >
                  <Grid container sx={{ mb: 3 }}>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      sx={{ mt: 4, ml: { xl: 16, lg: 8, sm: 8, xs: 5 } }}
                      container
                    //   justifyContent={{ sm: "flex-end" }}
                    >
                      <img src={loan} alt="" width={36} height={36} />
                      <Typography
                        sx={{
                          ml: { xs: 2 },
                          fontSize: "28px",
                          fontFamily: "Ubuntu",
                        }}
                      >
                        Loan Type
                      </Typography>
                    </Grid>
                    <Grid
                      container
                      spacing={2}
                      sx={{ ml: { xl: 12, lg: 4, sm: 4, xs: 2 } }}
                    >
                      <Grid item xs={3} sm={2.5} xl={3} lg={3}>
                        <Button
                          type="submit"
                          variant="contained"
                          sx={{
                            mt: 3,
                            color: "#fff",
                            px: { sm: 6, xs: 6, xl: 7, lg: 7 },
                            background: colorFlag
                              ? "linear-gradient(to right bottom, #1976D2, #175FA2)"
                              : "lightgray",
                            width: {
                              sm: "30%",
                              xs: "30%",
                              lg: "200px",
                              xl: "200px",
                            },
                            height: "50px",
                            borderTopLeftRadius: "14px",
                            borderTopRightRadius: "14px",
                          }}
                          id="permanent"
                          onClick={handleButtonColor}
                        >
                          Permanent
                        </Button>
                      </Grid>
                      <Grid
                        item
                        xs={3}
                        sm={3}
                        sx={{ mt: 3, mr: 1 }}
                        container
                        justifyContent={{ xs: "center", sm: "center" }}
                      >
                        <Typography
                          sx={{ fontWeight: "bold", mt: 1.5, mr: -6 }}
                        >
                          OR
                        </Typography>
                      </Grid>
                      <Grid item xs={1} sm={2} xl={1} lg={1}>
                        <Button
                          type="submit"
                          variant="contained"
                          sx={{
                            mt: 3,
                            color: "black",
                            px: { lg: 6.8, xl: 6.8, sm: 8, xs: 8 },
                            backgroundColor: colorFlag
                              ? "transparent"
                              : "#1976d2",
                            width: {
                              sm: "30%",
                              xs: "10%",
                              lg: "200px",
                              xl: "200px",
                            },
                            height: "50px",
                            borderTopLeftRadius: "14px",
                            borderTopRightRadius: "14px",
                          }}
                          id="construction"
                          onClick={handleButtonColor}
                        >
                          Construction
                        </Button>
                      </Grid>
                      <Grid item xs={11.3} sm={10.3} mt={-3.2} xl={9.4}>
                        <hr color="#175FA2" style={{ width: "95%" }} />
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
              <Box
                sx={{
                  mt: 3,
                  width: { sm: "100%" },
                }}
              >
                <Typography
                  sx={{
                    fontSize: { sm: 28, xs: 26 },
                    fontWeight: "bold",
                    textAlign: "center",
                    fontFamily: "Ubuntu",
                    mt: 4,
                    mb: 4,
                  }}
                >
                  MUDZIE Indication Tool
                </Typography>
                {
                  MIPSectionVisible ?
                    <Grid
                      container
                      // columnSpacing={2}
                      sx={{
                        mt: 2,
                        background: "linear-gradient(to right, #1976D2, #5D39C1)",
                      }}
                      borderRadius="24px"
                    >
                      <Grid item sx={{ p: { sm: 4, xs: 2, lg: 6, xl: 6 } }}>
                        <Typography
                          sx={{
                            fontSize: "20px",
                            color: "white",
                            fontFamily: "Ubuntu",
                            textAlign: "center",
                          }}
                        >
                          Is this a reduced MIP loan?
                        </Typography>
                      </Grid>
                      <Grid item sx={{ py: { lg: 5, xl: 5, sm: 2, xs: 2 }, px: { sm: 2, xs: 2 } }}>
                        <FormControl>
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            // defaultValue="N"
                            onChange={
                              (e, val) =>
                                changeMIPOption(val) /*setReducedMIPLoan(val)*/
                            }
                          >
                            <FormControlLabel
                              value="Y"
                              control={
                                <Radio
                                  sx={{
                                    color: "white",
                                    "&, &.Mui-checked": { color: "white" },
                                  }}
                                  checked={reducedMIPLoan === "Y" ? true : false}
                                />
                              }
                              label={
                                <Typography sx={{ color: "white" }}>Yes</Typography>
                              }
                            />
                            <FormControlLabel
                              value="N"
                              control={
                                <Radio
                                  sx={{
                                    color: "white",
                                    "&, &.Mui-checked": { color: "white" },
                                  }}
                                  checked={reducedMIPLoan === "N" ? true : false}
                                />
                              }
                              label={
                                <Typography sx={{ color: "white" }}>No</Typography>
                              }
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                      {reducedMIPLoan === "Y" ? (
                        <>
                          <Grid item py={1} px={3}>
                            <Typography
                              sx={{
                                fontSize: "10px",
                                color: "white",
                                fontFamily: "Ubuntu",
                              }}
                            >
                              Front-End Fees (%)
                            </Typography>
                            <TextField
                              variant="outlined"
                              type="number"
                              onKeyDown={(e) => {
                                if (e.key === "e" || e.key === "E" || e.key === "-" || e.key === "+") {
                                  e.preventDefault()
                                }
                              }}
                              sx={{
                                "& .MuiInput-underline": {
                                  borderBottomColor: "#FFFFFF",
                                  borderWidth: "2px",
                                  borderBottomStyle: "solid", // Replace with your desired color
                                },
                                "& .MuiInputBase-input": {
                                  color: "white",
                                },
                                "& .MuiOutlinedInput-root": {
                                  // - The Input-root, inside the TextField-root
                                  "& fieldset": {
                                    // - The <fieldset> inside the Input-root
                                    borderColor: "white", // - Set the Input border
                                  },
                                  "&:hover fieldset": {
                                    borderColor: "white", // - Set the Input border when parent has :hover
                                  },
                                  "&.Mui-focused fieldset": {
                                    // - Set the Input border when parent is focused
                                    borderColor: "white",
                                  },
                                },
                              }}
                              margin="normal"
                              required
                              fullWidth
                              id="frontEndFees"
                              name="frontEndFees"
                              onChange={(e) => {
                                if (e.target.value === '' || (Number(e.target.value) <= 5 && Number(e.target.value) > 0)) {
                                  setFrontEndFees(e.target.value);
                                  setShowErrorFrontEndFees(false);
                                } else {
                                  setShowErrorFrontEndFees(true);
                                }
                              }}
                              error={showErrorFrontEndFees ? true : false}
                              helperText={
                                showErrorFrontEndFees
                                  ? "Value must be less than or equal to 5."
                                  : ""
                              }
                            />
                          </Grid>
                          <Grid item py={6} px={3}>
                            <Button
                              onClick={calculateMaxPremium}
                              // variant="contained"
                              style={{ textTransform: "none" }}
                              sx={{
                                px: 5,
                                fontSize: { sm: 15 },
                                border: "1px solid white",
                                borderRadius: "24px",
                                backgroundColor: "rgba(255, 255, 255, 0.15)",
                                color: "white",
                                fontFamily: "Ubuntu",
                              }}
                            >
                              Submit
                            </Button>
                          </Grid>
                        </>
                      ) : (
                        <></>
                      )}
                    </Grid>
                    :
                    <Grid container
                      sx={{
                        mt: 2,
                        background: "linear-gradient(to right, #1976D2, #5D39C1)",
                        borderRadius: 7
                      }}
                    >
                      <Grid item xs={8}>
                        <Grid container>
                          <Grid item sx={{ minWidth: 200, py: { lg: 5, xl: 5, sm: 2, xs: 2 }, px: { sm: 2, xs: 2 } }} >
                            <FormControl fullWidth>
                              <InputLabel id="demo-simple-select-label"
                                sx={{ color: "white", "&.Mui-focused": { color: "white" } }}
                              >
                                Structure
                              </InputLabel>
                              <Select
                                sx={{
                                  color: "white",
                                  '& .MuiSvgIcon-root': { color: 'white' },
                                  "&.MuiOutlinedInput-root": {
                                    "& fieldset": { borderColor: "white" },
                                    "&:hover fieldset": { borderColor: "white" },
                                    "&.Mui-focused fieldset": { borderColor: "white" }
                                  }
                                }}
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={DPPPXSpread}
                                label="PX Spread"
                                onChange={(e) => setDPPPXSpread(e.target.value)}
                              >
                                <MenuItem value={120}>5/4.5</MenuItem>
                                <MenuItem value={125}>5/3</MenuItem>
                                <MenuItem value={130}>7/6.5</MenuItem>
                                <MenuItem value={135}>7/5</MenuItem>
                                <MenuItem value={140}>10/9.5</MenuItem>
                                <MenuItem value={145}>10/8</MenuItem>
                                <MenuItem value={150}>10/7</MenuItem>
                                <MenuItem value={155}>12/11.5</MenuItem>
                                <MenuItem value={160}>12/10</MenuItem>
                                <MenuItem value={165}>15/14.5</MenuItem>
                                <MenuItem value={170}>15/10</MenuItem>
                                <MenuItem value={175}>20/15</MenuItem>
                                <MenuItem value={180}>30/15</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>

                          <Grid item sx={{ py: { sm: 4, xs: 3, lg: 6, xl: 6 }, pl: { sm: 4, xs: 2, lg: 6, xl: 6 }, pr: { xs: 2, sm: 1, lg: 1, xl: 1 } }}>
                            <Typography
                              sx={{
                                fontSize: "20px",
                                color: "white",
                                fontFamily: "Ubuntu",
                                textAlign: "center",
                              }}
                            >
                              IO Term
                            </Typography>
                          </Grid>
                          <Grid item sx={{ py: { lg: 5, xl: 5, sm: 2, xs: 2 }, px: { sm: 2, xs: 2 } }}>
                            <FormControl>
                              <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                onChange={(e, val) => setShowSAndG(val)}
                              >
                                <FormControlLabel
                                  value="Y"
                                  control={
                                    <Radio
                                      sx={{ color: "white", "&, &.Mui-checked": { color: "white" } }}
                                      checked={showSAndG === "Y" ? true : false}
                                    />
                                  }
                                  label={
                                    <Typography sx={{ color: "white" }}>Yes</Typography>
                                  }
                                />
                                <FormControlLabel
                                  value="N"
                                  control={
                                    <Radio
                                      sx={{ color: "white", "&, &.Mui-checked": { color: "white" } }}
                                      checked={showSAndG === "N" ? true : false}
                                    />
                                  }
                                  label={
                                    <Typography sx={{ color: "white" }}>No</Typography>
                                  }
                                />
                              </RadioGroup>
                            </FormControl>
                          </Grid>
                          {showSAndG === "Y" ? (
                            <>
                              <Grid item py={1} px={3}>
                                <Typography sx={{ fontSize: "10px", color: "white", fontFamily: "Ubuntu" }} >
                                  IO Term
                                </Typography>
                                <TextField
                                  variant="outlined"
                                  type="number"
                                  onKeyDown={(e) => {
                                    if (e.key === "e" || e.key === "E" || e.key === "-" || e.key === "+") {
                                      e.preventDefault()
                                    }
                                  }}
                                  sx={{
                                    "& .MuiInput-underline": {
                                      borderBottomColor: "#FFFFFF",
                                      borderWidth: "2px",
                                      borderBottomStyle: "solid", // Replace with your desired color
                                    },
                                    "& .MuiInputBase-input": {
                                      color: "white",
                                    },
                                    "& .MuiOutlinedInput-root": {
                                      // - The Input-root, inside the TextField-root
                                      "& fieldset": {
                                        // - The <fieldset> inside the Input-root
                                        borderColor: "white", // - Set the Input border
                                      },
                                      "&:hover fieldset": {
                                        borderColor: "white", // - Set the Input border when parent has :hover
                                      },
                                      "&.Mui-focused fieldset": {
                                        // - Set the Input border when parent is focused
                                        borderColor: "white",
                                      },
                                    },
                                  }}
                                  margin="normal"
                                  required
                                  fullWidth
                                  id="IOTermSAndG"
                                  name="IOTermSAndG"
                                  onChange={(e) => {
                                    if (e.target.value === '' || (Number(e.target.value) <= 5 && Number(e.target.value) > 0)) {
                                      setIOTermSAndG(e.target.value);
                                      setShowErrorIOTermSAndG(false);
                                    }
                                    else
                                      setShowErrorIOTermSAndG(true);
                                  }}
                                  error={showErrorIOTermSAndG ? true : false}
                                  helperText={
                                    showErrorIOTermSAndG
                                      ? "Value must be less than or equal to 5."
                                      : ""
                                  }
                                />
                              </Grid>
                            </>
                          ) : (
                            <></>
                          )}
                        </Grid>
                      </Grid>
                      <Grid item xs={4}>
                        <Grid container justifyContent='flex-end'>
                          <Grid item sx={{ py: { sm: 4, xs: 3, lg: 6, xl: 6 }, pl: { sm: 2, xs: 7, lg: 3, xl: 3 }, pr: { sm: 1, lg: 1, xl: 1 } }}>
                            <Typography
                              sx={{
                                fontSize: "20px",
                                color: "white",
                                fontFamily: "Ubuntu",
                                textAlign: "center",
                              }}
                            >
                              DPP
                            </Typography>
                          </Grid>
                          <Grid item sx={{ py: { lg: 5, xl: 5, sm: 2, xs: 2 }, px: { sm: 2, xs: 8 } }}>
                            <FormControl>
                              <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                onChange={(e, val) => { setDPPOption(val); if (val === "N") setDPPPXSpread("") }}
                              >
                                <FormControlLabel
                                  value="Y"
                                  control={
                                    <Radio
                                      sx={{
                                        color: "white",
                                        "&, &.Mui-checked": { color: "white" },
                                      }}
                                      checked={DPPOption === "Y" ? true : false}
                                    />
                                  }
                                  label={
                                    <Typography sx={{ color: "white" }}>Yes</Typography>
                                  }
                                />
                                <FormControlLabel
                                  value="N"
                                  control={
                                    <Radio
                                      sx={{
                                        color: "white",
                                        "&, &.Mui-checked": { color: "white" },
                                      }}
                                      checked={DPPOption === "N" ? true : false}
                                    />
                                  }
                                  label={
                                    <Typography sx={{ color: "white" }}>No</Typography>
                                  }
                                />
                              </RadioGroup>
                            </FormControl>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                }

                {/* <Grid
                  container
                  // columnSpacing={2}
                  sx={{
                    mt: 2,
                    background: "linear-gradient(to right, #1976D2, #5D39C1)",
                    display: "flex",
                    justifyContent: "center",
                    gap: {
                      xl: "160px",
                      lg: "160px",
                      sm: "70px",
                      xs: "20px",
                    },
                  }}
                  borderRadius="24px"
                >
                  <Grid item mt={5}>
                    <Typography
                      sx={{
                        fontSize: "10px",
                        color: "white",
                        fontFamily: "Ubuntu",
                      }}
                    >
                      Project Name/Program Id (if available)
                    </Typography>
                    <TextField
                      variant="outlined"
                      sx={{
                        "& .MuiInput-underline": {
                          borderBottomColor: "#FFFFFF",
                          borderWidth: "2px",
                          borderBottomStyle: "solid", // Replace with your desired color
                        },
                        "& .MuiInputBase-input": {
                          fontSize: "1.5rem",
                          color: "white",
                        },
                        "& .MuiOutlinedInput-root": {
                          // - The Input-root, inside the TextField-root
                          "& fieldset": {
                            // - The <fieldset> inside the Input-root
                            borderColor: "white", // - Set the Input border
                          },
                          "&:hover fieldset": {
                            borderColor: "white", // - Set the Input border when parent has :hover
                          },
                          "&.Mui-focused fieldset": {
                            // - Set the Input border when parent is focused
                            borderColor: "white",
                          },
                        },
                      }}
                      // InputProps={{
                      //     disableUnderline: true,
                      // }}
                      margin="normal"
                      required
                      fullWidth
                      id="programId"
                      name="programId"
                      autoComplete="programId"
                      value={programId}
                      onChange={(e) => {
                        setProgramId(e.target.value);
                      }}
                    // disabled
                    />
                  </Grid>
                  <Grid item sx={{ mt: { xl: 5, lg: 5, sm: -3, xs: 5 } }}>
                    
                    <Typography
                      sx={{
                        fontSize: "10px",
                        color: "white",
                        fontFamily: "Ubuntu",
                      }}
                    >
                      Pass-Through Rate (%)*
                    </Typography>
                    <TextField
                      variant="outlined"
                      type="number"
                      onKeyDown={(e) => {
                        if (e.key === "e" || e.key === "E" || e.key === "-" || e.key === "+") {
                          e.preventDefault()
                        }
                      }}
                      // focused
                      // autoFocus
                      sx={{
                        "& .MuiInput-underline": {
                          borderBottomColor: "#FFFFFF",
                          borderWidth: "2px",
                          borderBottomStyle: "solid", // Replace with your desired color
                        },
                        "& .MuiInputBase-input": {
                          color: "white",
                          fontSize: "1.5rem",
                        },
                        "& .MuiOutlinedInput-root": {
                          // - The Input-root, inside the TextField-root
                          "& fieldset": {
                            // - The <fieldset> inside the Input-root
                            borderColor: "white", // - Set the Input border
                          },
                          "&:hover fieldset": {
                            borderColor: "white", // - Set the Input border when parent has :hover
                          },
                          "&.Mui-focused fieldset": {
                            // - Set the Input border when parent is focused
                            borderColor: "white",
                          },
                        },
                      }}
                      margin="normal"
                      required
                      fullWidth
                      bgcolor="transparent"
                      id="rate"
                      name="rate"
                      value={passThru}
                      onChange={(e) => {
                        setPassThru(e.target.value)
                        if (e.target.value === '' || (Number(e.target.value) < 100 && Number(e.target.value) > 0)) {
                          setPassThru(e.target.value)
                          setShowErrorRate(false);
                        } else {
                          setShowErrorRate(true);
                        }
                      }}
                      error={showErrorRate ? true : false}
                      helperText={showErrorRate ? "Please enter valid Pass-Through Rate." : ""}
                    />
                  </Grid>
                  <Grid item sx={{ mt: { xl: 5, lg: 5, sm: -3, xs: 5 } }}>
                    <Typography
                      sx={{
                        fontSize: "10px",
                        color: "white",
                        fontFamily: "Ubuntu",
                      }}
                    >
                      Price ($)*
                    </Typography>
                    <TextField
                      // variant="standard"
                      variant="outlined"
                      sx={{
                        "& .MuiInput-underline": {
                          borderBottomColor: "#FFFFFF",
                          borderWidth: "2px",
                          borderBottomStyle: "solid", // Replace with your desired color
                        },
                        // "& .MuiInputBase-input.Mui-disabled": {
                        //   WebkitTextFillColor: "white",
                        // },
                        "& .MuiInputBase-input": {
                          color: "white",
                          fontSize: "1.5rem",
                        },
                        "& .MuiOutlinedInput-root": {
                          // - The Input-root, inside the TextField-root
                          "& fieldset": {
                            // - The <fieldset> inside the Input-root
                            borderColor: "white", // - Set the Input border
                          },
                          "&:hover fieldset": {
                            borderColor: "white", // - Set the Input border when parent has :hover
                          },
                          "&.Mui-focused fieldset": {
                            // - Set the Input border when parent is focused
                            borderColor: "white",
                          },
                        },
                      }}
                      margin="normal"
                      required
                      fullWidth
                      id="price"
                      name="price"
                      value={priceState}
                      onChange={(e) => {
                        //^\d+(?: \d+/\d+)?$
                        setPriceState(e.target.value)
                      }}
                    />

                  </Grid>
                  {expiryDate < formattedDate || expiryDate == null ? (
                    <Button
                      onClick={handleCalculate}
                      // variant="contained"
                      style={{ textTransform: "none" }}
                      sx={{
                        m: 2,
                        ml: { sm: 0, xs: 4 },
                        pr: { sm: 5, xl: 5 },
                        pl: { sm: 5, xl: 5 },
                        pt: 1,
                        pb: 1,
                        mt: { xl: -20, lg: -15, sm: -5 },
                        mb: 5,
                        fontSize: { sm: 20 },
                        border: "1px solid white",
                        borderRadius: "24px",
                        backgroundColor: "rgba(255, 255, 255, 0.15)",
                        color: "white",
                        fontFamily: "Ubuntu",
                      }}
                    >
                      Calculate Your KimmyMae Mudzie Indicator
                    </Button>
                  ) : (
                    <Box>
                      <Button
                        onClick={calculateIndicator}
                        // variant="contained"
                        style={{ textTransform: "none" }}
                        sx={{
                          m: 2,
                          ml: { sm: 0, xs: 2 },
                          pr: { sm: 5, xl: 5 },
                          pl: { sm: 5, xl: 5 },
                          pt: 2,
                          pb: 2,
                          mt: { xl: -20, lg: -15, sm: -5 },
                          mb: 5,
                          fontSize: { sm: 20, xs: 10 },
                          border: "1px solid white",
                          borderRadius: "24px",
                          backgroundColor: "rgba(255, 255, 255, 0.15)",
                          color: "white",
                          fontFamily: "Ubuntu",
                        }}
                      >
                        Calculate Your KimmyMae Mudzie Indicator
                        <ClipLoader
                          color="#ffffff"
                          loading={loading}
                          size={40}
                          aria-label="Loading Spinner"
                          data-testid="loader"
                        />
                      </Button>
                      <Button
                        onClick={resetCalculator}
                        // variant="contained"
                        style={{ textTransform: "none" }}
                        sx={{
                          m: 2,
                          ml: { sm: 0, xs: 0 },
                          pr: { sm: 5, xl: 5 },
                          pl: { sm: 5, xl: 5 },
                          pt: 2,
                          pb: 2,
                          mt: { xl: -20, lg: -15, sm: -5 },
                          mb: 5,
                          fontSize: { sm: 20, xs: 10 },
                          border: "1px solid white",
                          borderRadius: "24px",
                          backgroundColor: "rgba(255, 255, 255, 0.15)",
                          color: "white",
                          fontFamily: "Ubuntu",
                        }}
                      >
                        Clear
                      </Button>
                    </Box>
                  )}
                </Grid> */}

                <Grid container
                  sx={{
                    mt: 5,
                    pt: 3,
                    background: "linear-gradient(to right, #1976D2, #5D39C1)",
                    borderRadius: 7,
                    justifyContent: 'center'
                  }}
                >
                  <Grid item xs={12} sm={12} lg={4} xl={4} sx={{ textAlign: 'center' }}>
                    <Typography
                      sx={{
                        textAlign: 'start',
                        fontSize: "10px",
                        color: "white",
                        fontFamily: "Ubuntu",
                        marginLeft: 6.5
                      }}
                    >
                      Project Name/Program Id (if available)
                    </Typography>
                    <TextField
                      variant="outlined"
                      sx={{
                        width: '75%',
                        "& .MuiInput-underline": {
                          borderBottomColor: "#FFFFFF",
                          borderWidth: "2px",
                          borderBottomStyle: "solid", // Replace with your desired color
                        },
                        "& .MuiInputBase-input": {
                          fontSize: "1.5rem",
                          color: "white",
                        },
                        "& .MuiOutlinedInput-root": {
                          // - The Input-root, inside the TextField-root
                          "& fieldset": {
                            // - The <fieldset> inside the Input-root
                            borderColor: "white", // - Set the Input border
                          },
                          "&:hover fieldset": {
                            borderColor: "white", // - Set the Input border when parent has :hover
                          },
                          "&.Mui-focused fieldset": {
                            // - Set the Input border when parent is focused
                            borderColor: "white",
                          },
                        },
                      }}
                      margin="normal"
                      required
                      fullWidth
                      id="programId"
                      name="programId"
                      autoComplete="programId"
                      value={programId}
                      onChange={(e) => {
                        setProgramId(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} lg={4} xl={4} sx={{ textAlign: 'center' }}>
                    <Typography
                      sx={{
                        textAlign: 'start',
                        fontSize: "10px",
                        color: "white",
                        fontFamily: "Ubuntu",
                        marginLeft: 6.5
                      }}
                    >
                      Pass-Through Rate (%)*
                    </Typography>
                    <TextField
                      variant="outlined"
                      type="number"
                      onKeyDown={(e) => {
                        if (e.key === "e" || e.key === "E" || e.key === "-" || e.key === "+") {
                          e.preventDefault()
                        }
                      }}
                      // focused
                      // autoFocus
                      sx={{
                        width: '75%',
                        "& .MuiInput-underline": {
                          borderBottomColor: "#FFFFFF",
                          borderWidth: "2px",
                          borderBottomStyle: "solid", // Replace with your desired color
                        },
                        "& .MuiInputBase-input": {
                          color: "white",
                          fontSize: "1.5rem",
                        },
                        "& .MuiOutlinedInput-root": {
                          // - The Input-root, inside the TextField-root
                          "& fieldset": {
                            // - The <fieldset> inside the Input-root
                            borderColor: "white", // - Set the Input border
                          },
                          "&:hover fieldset": {
                            borderColor: "white", // - Set the Input border when parent has :hover
                          },
                          "&.Mui-focused fieldset": {
                            // - Set the Input border when parent is focused
                            borderColor: "white",
                          },
                        },
                      }}
                      margin="normal"
                      required
                      fullWidth
                      bgcolor="transparent"
                      id="rate"
                      name="rate"
                      value={passThru}
                      onChange={(e) => {
                        setPassThru(e.target.value)
                        if (e.target.value === '' || (Number(e.target.value) < 100 && Number(e.target.value) > 0)) {
                          setPassThru(e.target.value)
                          setShowErrorRate(false);
                        } else {
                          setShowErrorRate(true);
                        }
                      }}
                      error={showErrorRate ? true : false}
                      helperText={showErrorRate ? "Please enter valid Pass-Through Rate." : ""}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} lg={4} xl={4} sx={{ textAlign: 'center' }}>
                    <Typography
                      sx={{
                        textAlign: 'start',
                        fontSize: "10px",
                        color: "white",
                        fontFamily: "Ubuntu",
                        marginLeft: 6.5
                      }}
                    >
                      Price ($)*
                    </Typography>
                    <TextField
                      // variant="standard"
                      variant="outlined"
                      sx={{
                        width: '75%',
                        "& .MuiInput-underline": {
                          borderBottomColor: "#FFFFFF",
                          borderWidth: "2px",
                          borderBottomStyle: "solid", // Replace with your desired color
                        },
                        // "& .MuiInputBase-input.Mui-disabled": {
                        //   WebkitTextFillColor: "white",
                        // },
                        "& .MuiInputBase-input": {
                          color: "white",
                          fontSize: "1.5rem",
                        },
                        "& .MuiOutlinedInput-root": {
                          // - The Input-root, inside the TextField-root
                          "& fieldset": {
                            // - The <fieldset> inside the Input-root
                            borderColor: "white", // - Set the Input border
                          },
                          "&:hover fieldset": {
                            borderColor: "white", // - Set the Input border when parent has :hover
                          },
                          "&.Mui-focused fieldset": {
                            // - Set the Input border when parent is focused
                            borderColor: "white",
                          },
                        },
                      }}
                      margin="normal"
                      required
                      fullWidth
                      id="price"
                      name="price"
                      value={priceState}
                      onChange={(e) => {
                        //^\d+(?: \d+/\d+)?$
                        setPriceState(e.target.value)
                      }}
                    />
                  </Grid>
                  {/* <Grid item xs={4} sx={{ textAlign: 'center' }}>
                    <Typography
                      sx={{
                        textAlign: 'start',
                        fontSize: "10px",
                        color: "white",
                        fontFamily: "Ubuntu",
                        marginLeft: 6.5
                      }}
                    >
                      S & G (%)
                    </Typography>
                    <TextField
                      variant="outlined"
                      sx={{
                        width: '75%',
                        "& .MuiInput-underline": {
                          borderBottomColor: "#FFFFFF",
                          borderWidth: "2px",
                          borderBottomStyle: "solid", // Replace with your desired color
                        },
                        "& .MuiInputBase-input": {
                          fontSize: "1.5rem",
                          color: "white",
                        },
                        "& .MuiOutlinedInput-root": {
                          // - The Input-root, inside the TextField-root
                          "& fieldset": {
                            // - The <fieldset> inside the Input-root
                            borderColor: "white", // - Set the Input border
                          },
                          "&:hover fieldset": {
                            borderColor: "white", // - Set the Input border when parent has :hover
                          },
                          "&.Mui-focused fieldset": {
                            // - Set the Input border when parent is focused
                            borderColor: "white",
                          },
                        },
                      }}
                      margin="normal"
                      required
                      fullWidth
                      id="programId"
                      name="programId"
                      autoComplete="programId"
                      value={programId}
                      onChange={(e) => {
                        setProgramId(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>

                  </Grid>
                  <Grid item xs={4} sx={{ textAlign: 'center' }}>
                    <Typography
                      sx={{
                        textAlign: 'start',
                        fontSize: "10px",
                        color: "white",
                        fontFamily: "Ubuntu",
                        marginLeft: 6.5
                      }}
                    >
                      Note Rate
                    </Typography>
                    <TextField
                      variant="outlined"
                      sx={{
                        width: '75%',
                        "& .MuiInput-underline": {
                          borderBottomColor: "#FFFFFF",
                          borderWidth: "2px",
                          borderBottomStyle: "solid", // Replace with your desired color
                        },
                        "& .MuiInputBase-input": {
                          fontSize: "1.5rem",
                          color: "white",
                        },
                        "& .MuiOutlinedInput-root": {
                          // - The Input-root, inside the TextField-root
                          "& fieldset": {
                            // - The <fieldset> inside the Input-root
                            borderColor: "white", // - Set the Input border
                          },
                          "&:hover fieldset": {
                            borderColor: "white", // - Set the Input border when parent has :hover
                          },
                          "&.Mui-focused fieldset": {
                            // - Set the Input border when parent is focused
                            borderColor: "white",
                          },
                        },
                      }}
                      margin="normal"
                      required
                      fullWidth
                      id="programId"
                      name="programId"
                      autoComplete="programId"
                      value={programId}
                      onChange={(e) => {
                        setProgramId(e.target.value);
                      }}
                    />
                  </Grid> */}
                  <Grid item xs={12} sx={{ textAlign: 'center' }}>
                    {expiryDate < formattedDate || expiryDate == null ? (
                      <Button
                        onClick={handleCalculate}
                        // variant="contained"
                        style={{ textTransform: "none" }}
                        sx={{
                          m: 2,
                          pr: { sm: 5, xl: 5 },
                          pl: { sm: 5, xl: 5 },
                          pt: 2,
                          pb: 2,
                          mb: 5,
                          fontSize: { sm: 20, xs: 10 },
                          border: "1px solid white",
                          borderRadius: "24px",
                          backgroundColor: "rgba(255, 255, 255, 0.15)",
                          color: "white",
                          fontFamily: "Ubuntu",
                        }}
                      >
                        Calculate Your KimmyMae Mudzie Indicator
                      </Button>
                    ) : (
                      <Box>
                        <Button
                          onClick={calculateIndicator}
                          // variant="contained"
                          // style={{ textTransform: "none" }}
                          disabled= {pageLoading? true : false}
                          sx={{
                            m: 2,
                            pr: { sm: 5, xl: 5 },
                            pl: { sm: 5, xl: 5 },
                            pt: 2,
                            pb: 2,
                            my: { xs: 2, sm: 5, md: 5, lg: 5, xl: 5 },
                            fontSize: { sm: 20, xs: 10 },
                            border: "1px solid white",
                            borderRadius: "24px",
                            backgroundColor: "rgba(255, 255, 255, 0.15)",
                            color: "white",
                            fontFamily: "Ubuntu",
                          }}
                        >
                          Calculate Your KimmyMae Mudzie Indicator
                          <ClipLoader
                            color="#ffffff"
                            loading={loading || pageLoading}
                            size={40}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                          />
                        </Button>
                        <Button
                          onClick={resetCalculator}
                          // variant="contained"
                          // style={{ textTransform: "none" }}
                          sx={{
                            m: 2,
                            py: 2,
                            my: { xs: 2, sm: 5, md: 5, lg: 5, xl: 5 },
                            px: { xs: 15, sm: 5, lg: 5, xl: 5 },
                            fontSize: { sm: 20, xs: 10 },
                            border: "1px solid white",
                            borderRadius: "24px",
                            backgroundColor: "rgba(255, 255, 255, 0.15)",
                            color: "white",
                            fontFamily: "Ubuntu",
                          }}
                        >
                          Clear
                        </Button>
                      </Box>
                    )}
                  </Grid>
                </Grid>

                {showPrice && loading === false ? (
                  <Box
                    borderRadius="15px"
                    sx={{
                      width: "100%",
                      height: "100px",
                      background: "#E9E9E9",
                      mt: 8,
                      ml: -1,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      ml={4}
                      width="95%"
                      sx={{ fontFamily: "Ubuntu" }}
                    >
                      Your indicated PRICE is
                      <span
                        style={{
                          fontSize: "24px",
                          color: "blue",
                          fontFamily: "Ubuntu",
                          textDecoration: "underline",
                        }}
                      >
                        {priceState}
                      </span>
                      for a HUD/RHS {loanType} loan at a Rate of {passThru} as
                      of {currTime} on {currDate}, at which point, the 10-year
                      U.S. Treasury was {tenYearTreasury}%.
                    </Typography>
                  </Box>
                ) : (
                  <></>
                )}
                {loanType === "Permanent" ? (
                  <Typography
                    sx={{
                      fontSize: 14,
                      fontWeight: "bold",
                      mt: 3,
                      color: "red",
                      fontFamily: "Ubuntu",
                      width: "90%",
                      // textAlign: "center",
                    }}
                  >
                    *NOTE: These results assume a delivery within 60 days of
                    rate lock, 0/10-1% call protection, standard late fees, and
                    a 35 year (HUD) or 40 year (RHS) loan.
                  </Typography>
                ) : (
                  <Typography
                    sx={{
                      fontSize: 14,
                      fontWeight: "bold",
                      mt: 3,
                      color: "red",
                      fontFamily: "Ubuntu",
                      width: "90%",
                      // textAlign: "center",
                    }}
                  >
                    *NOTE: These results assume an initial GNMA delivery within
                    60 days of rate lock, a 10% or greater first draw, 0/10-1%
                    call protection, standard late fees, and a 40 year term with
                    a 16 month or shorter construction period.
                  </Typography>
                )}

                <Box
                  width="100%"
                  gap="20px"
                  display="flex"
                  mt={5}
                  justifyContent="center"
                >
                  <Box
                    width="45%"
                    sx={{
                      boxShadow: 12,
                      borderRadius: "10px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      src={lock}
                      alt=""
                      width={30}
                      height={30}
                      style={{ marginTop: "20px", marginRight: "15px" }}
                    />
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        mt: 3,
                        mb: 2,
                        fontFamily: "Ubuntu",
                        fontSize: {
                          xl: "20px",
                          lg: "20px",
                          sm: "20px",
                          xs: "10px",
                        },
                      }}
                    >
                      To lock an interest rate, please contact Kim at
                      kim@branig.com
                    </Typography>
                  </Box>
                  <Box
                    width="45%"
                    sx={{
                      boxShadow: 12,
                      borderRadius: "10px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={caution}
                      alt=""
                      width={30}
                      height={30}
                      style={{ marginTop: "8px", marginRight: "15px" }}
                    />
                    <Typography
                      sx={{
                        cursor: 'pointer',
                        fontWeight: "bold",
                        mt: 3,
                        mb: 2,
                        textAlign: "center",
                        fontFamily: "Ubuntu",
                        fontSize: {
                          xl: "20px",
                          lg: "20px",
                          sm: "20px",
                          xs: "10px",
                        },
                      }}
                      onClick={handleOpen}
                    >
                      DISCLAIMER
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  mt: 3,
                  width: { sm: "100%" },
                  height: "300px",
                  //   background: "linear-gradient(to right bottom, #1976D2, #A1BBD4)",
                  background: `url(${backimage})`,
                  backgroundPosition: "center center",
                  backgroundSize: "cover",
                  alignItems: "center",
                  display: "flex",
                }}
              >
                <Grid
                  container
                  sx={{
                    justifyContent: "center",
                    gap: { xs: 3, sm: 0, xl: 0, lg: 0 },
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    sm={8}
                    xl={4}
                    lg={4}
                    sx={{
                      mt: 3.5,
                      textAlign: { sm: "center" },
                      mb: { xs: 2 },
                      mr: { sm: 10, xl: 10, lg: 10 },
                      ml: { xs: 5, sm: 0, xl: 0, lg: 0 },
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: "300",
                        fontSize: 25,
                        color: "#fff",
                        fontFamily: "Ubuntu",
                      }}
                    >
                      Rates are Current as of :
                    </Typography>
                    {/* <Box
                      sx={{
                        ml: {
                          xl: "100px",
                          lg: "100px",
                          sm: "100px",
                          xs: "20px",
                        },
                      }}
                    > */}
                    <hr style={{ width: "80%" }} />
                    {/* </Box> */}
                    <Typography
                      sx={{
                        fontSize: 15,
                        fontFamily: "Ubuntu",
                        color: "#fff",
                        // marginLeft: {
                        //   xl: "-80px",
                        //   lg: "-80px",
                        //   sm: "-80px",
                        //   xs: "20px",
                        // },
                      }}
                    >
                      {currDate}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: 15,
                        fontFamily: "Ubuntu",
                        color: "#fff",
                        // marginLeft: {
                        //   xl: "-100px",
                        //   lg: "-100px",
                        //   sm: "-100px",
                        //   xs: "20px",
                        // },
                      }}
                    >
                      {currTime}
                    </Typography>
                  </Grid>
                  <Grid item xs={4} sm={2} xl={2} lg={2}
                    sx={{
                      mb: { xs: 2 }, mr: { sm: 15 }, borderRadius: "10px", background: "#eeefe8", opacity: "0.8",
                      ml: { sm: 15, xl: 0, lg: 0, xs: 5 },
                    }}
                  // textAlign="center"
                  >
                    <Tooltip1 title="Click here to get latest 10-Yr Treasury rate">
                      <IconButton onClick={() => { refreshTenYrTreasuryRate(); }} >
                        <RefreshIcon />
                        <Typography>Refresh</Typography>
                      </IconButton>
                    </Tooltip1>
                    <hr style={{ width: "25%", marginTop: "-2px", marginLeft: "10px" }} />
                    <Typography
                      sx={{ fontSize: 16, mb: 1, fontFamily: "Ubuntu", ml: { xl: "20px", lg: "20px", sm: "20px", xs: 0 } }}
                    >
                      10-Yr Treasury
                    </Typography>
                    <Box
                      display="inline"
                      sx={{
                        color: "black", p: 1, px: { sm: 1, xl: 3.5, lg: 3.5 }, fontSize: 24, fontFamily: "Ubuntu",
                        fontWeight: "bold",
                      }}
                    >
                      <ClipLoader
                        color="black"
                        loading={tenYearTreasuryloading}
                        size={25}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                      />
                      {tenYearTreasury}%
                    </Box>
                  </Grid>
                  {/* <Grid item xs={4} sm={2} xl={2} lg={2}
                    sx={{
                      mb: { xs: 2 }, mr: { sm: 15 }, borderRadius: "10px", background: "#eeefe8", opacity: "0.8",
                      ml: { sm: 15, xl: 0, lg: 0, xs: 5 },
                    }}
                  // textAlign="center"
                  >
                    <Tooltip1 title="Click here to get latest 10-Yr Treasury rate">
                      <IconButton onClick={() => { refreshTenYrTreasuryRate(); }} >
                        <RefreshIcon />
                        <Typography>Refresh</Typography>
                      </IconButton>
                    </Tooltip1>
                    <hr style={{ width: "25%", marginTop: "-2px", marginLeft: "10px" }} />
                    <Typography
                      sx={{ fontSize: 16, mb: 1, fontFamily: "Ubuntu", ml: { xl: "20px", lg: "20px", sm: "20px", xs: 0 } }}
                    >
                      10-Yr SOFR Swap
                    </Typography>
                    <Box
                      display="inline"
                      sx={{
                        color: "black", p: 1, px: { sm: 1, xl: 3.5, lg: 3.5 }, fontSize: 24, fontFamily: "Ubuntu",
                        fontWeight: "bold",
                      }}
                    >
                      <ClipLoader
                        color="black"
                        loading={tenYearTreasuryloading}
                        size={25}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                      />
                      {tenYearSOFRSwap}%
                    </Box>
                  </Grid> */}

                </Grid>
              </Box>

              <LineChart data={loanType} MIPSectionVisible={MIPSectionVisible} />
              <Box
                width="100%"
                height="80px"
                sx={{
                  background:
                    "linear-gradient(to right bottom, #1976D2, #175FA2)",
                  mt: 5,
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Box
                  height="100%"
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Ubuntu",
                      fontSize: "10px",
                      color: "white",
                      pl: 5,
                    }}
                  >
                    Copyright © 2024 MUDZIE - All Rights Reserved | Powered by
                    KimmyMae™
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    sx={{
                      fontFamily: "Ubuntu",
                      fontSize: "10px",
                      color: "white",
                      pr: 8,
                      mt: 2,
                    }}
                  >
                    Privacy policy | Terms and conditions
                  </Typography>
                  <hr style={{ width: "90%", marginLeft: "-5px" }} />
                  <Box
                    sx={{ display: "flex", gap: "10px", marginLeft: "100px" }}
                  >
                    <img src={twitter} alt="" width={20} height={20} />
                    <img src={facebook} alt="" width={20} height={20} />
                    <img src={linkdin} alt="" width={20} height={20} />
                  </Box>
                </Box>
              </Box>
            </Box>

            <div>
              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                  >
                    DISCLAIMER
                  </Typography>
                  <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    The information and interest rate indications provided on
                    this website are intended solely for informational purposes
                    and should not be construed as financial advice or a
                    recommendation to engage in any specific financial
                    activities. The interest rate indications presented on this
                    website are based on various factors, including market
                    conditions, historical trends, and statistical data.
                    However, these indications are subject to change and may not
                    accurately reflect current market rates or the rates offered
                    by financial institutions.
                    <br />
                    <br />
                    Users of this website should exercise caution and
                    independently verify any interest rates before making
                    financial decisions. The rates provided are not guaranteed,
                    and actual rates offered by financial institutions may vary
                    based on several factors, such as an individual&#39;s
                    creditworthiness, loan-to-value ratio, loan term, and other
                    relevant factors determined by the financial institution.
                    <br />
                    <br />
                    Furthermore, this website does not endorse or promote any
                    particular financial institution, product, or service. Users
                    are encouraged to conduct their own due diligence, seek
                    advice from qualified financial professionals, and carefully
                    evaluate their financial situation and objectives before
                    making any financial decisions.
                    <br />
                    <br />
                    While every effort is made to provide accurate and
                    up-to-date information, we make no representations or
                    warranties of any kind, express or implied, about the
                    completeness, accuracy, reliability, suitability, or
                    availability of the information presented on this website.
                    We shall not be held liable for any loss or damage,
                    including but not limited to financial loss, arising
                    directly or indirectly from the use of or reliance on the
                    information provided on this website.
                    <br />
                    <br />
                    By accessing and using this website, you acknowledge and
                    agree to the terms of this disclaimer. You understand that
                    the information presented here is subject to change without
                    notice and that you are solely responsible for your
                    financial decisions.
                    <br />
                    <br />
                    It is recommended that users consult with a qualified
                    financial advisor or professional before making any
                    financial commitments or entering into any financial
                    transactions.
                    <br />
                    <br />
                    Please note that this disclaimer applies to the entire
                    website, including all pages, subdomains, and any linked or
                    referenced content.
                    <br />
                    <br />
                    Last updated: June 28, 2023
                  </Typography>
                </Box>
              </Modal>
            </div>
          </Container>
          <ReactJsAlert
            status={alertStatus} // true or false
            type={type} // success, warning, error, info
            title={title}
            autoCloseIn="30000"
            Close={() => {
              setAlertStatus(false);
            }}
          />
        </ThemeProvider>
      {/* )} */}

      {/* //////// added by aniket */}

      {
        widgetLoader ?

          <Fab style={{ backgroundColor: "#1868B5" }} aria-label="add"
            sx={{ position: 'fixed', bottom: '20px', right: '20px' }}>
            <ClipLoader
              color="white"
              loading={widgetLoader}
              size={50}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </Fab> :
          <Fab style={{ backgroundColor: "#1868B5" }} aria-label="add"
            sx={{ position: 'fixed', bottom: '20px', right: '20px' }}>
            <Download style={{ color: 'white' }} onClick={calculateIndicatorForWidget} />
          </Fab>
      }


      {/* <div style={{visibility:'hidden'}}> */}
      <div id="print" style={{ visibility: 'hidden', display: 'none', position: 'absolute', left: '-9999px', background: 'linear-gradient(to right, #386ECC, #1F3D79)', width: "720px", height: "880px" }}>

        <div style={{ color: 'white', display: 'flex', justifyContent: 'center', marginTop: '50px' }}> <img src={logo} style={{ height: '150px', width: '540px' }} alt="Logo" /></div>
        <h1 style={{ display: 'flex', justifyContent: 'center', color: 'white' }}>For Lenders</h1>
        <div style={{ display: 'flex', justifyContent: 'around' }}>
          <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', marginLeft: '90px', marginRight: '30px', marginTop: '30px', width: '35%' }}>
            <h2 style={{ color: 'black', padding: '10px', borderRadius: '10px', backgroundColor: '#EE7D30', width: '100%', display: 'flex', justifyContent: 'center' }}>Construction</h2>
            <h1 style={{ color: 'white', padding: '10px', borderRadius: '10px', marginTop: '30px', border: '4px solid #013A7F', background: 'linear-gradient(to right, #1F3D79, #386ECC)', width: '100%', display: 'flex', justifyContent: 'center' }}>{widgetConstructorRate}%</h1>
          </div>
          <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', marginLeft: '30px', marginRight: '90px', marginTop: '30px', width: '35%' }}>
            <h2 style={{ color: 'black', padding: '10px', borderRadius: '10px', backgroundColor: '#EE7D30', width: '100%', display: 'flex', justifyContent: 'center' }}>Permanent</h2>
            <h1 style={{ color: 'white', padding: '10px', borderRadius: '10px', marginTop: '30px', border: '4px solid #013A7F', background: 'linear-gradient(to right, #1F3D79, #386ECC)', width: '100%', display: 'flex', justifyContent: 'center' }}>{widgetPermenantRate}%</h1>
          </div>
        </div>
        <h1 style={{ display: 'flex', fontSize: '3em', justifyContent: 'center', color: 'black' }}>10 Year Treasury</h1>
        <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
          <h1 style={{ margin: '0px', color: 'white', padding: '10px', border: '4px solid black', backgroundColor: '#EE7D30', display: 'inline-block', textAlign: 'center' }}>{tenYearTreasury}%</h1>

        </div>
        <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
          <h2 style={{ margin: '10px', color: 'white', padding: '3px', textAlign: 'center' }}>Data current as of {widgetLogTime}</h2>

        </div>


        {/* <img src={sample} style={{ height: '1080px', width: '720px', position: 'absolute', bottom: '-900px' }} /> */}

      </div>

      {/* </div> */}


      {/* //////// added y aniket ended */}
    </>
  );
}
