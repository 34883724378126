import React, { useState, useEffect } from 'react';
import Sidenav from '../components/Sidenav';
import Navbar from '../components/Navbar';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import "../dash.css";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
// import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Button, Divider } from '@mui/material';
import Axios from 'axios';
import ClipLoader from "react-spinners/ClipLoader";
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

function cumprinc(rate, nper, pv, start_period, end_period, type) {
  // console.log("rate, nper, pv, start_period, end_period, type : ",rate, nper, pv, start_period, end_period, type);
  let principal = 0;
  let remaining_pv = pv;

  for (let period = start_period; period <= end_period; period++) {
    let interest = remaining_pv * rate;
    let principal_payment = (pv / nper) - interest;

    if (type === 1) {
      principal += principal_payment;
    } else {
      principal += principal_payment;
      remaining_pv -= (pv / nper);
    }
  }
  return principal;
}

function pmt(rate, periods, presentValue, futureValue, type) {
  if (!rate || !periods || !presentValue) {
    return null;
  }

  // Compute the periodic payment amount
  var payment = -rate * (presentValue * Math.pow(1 + rate, periods) + futureValue) /
    ((1 + rate * type) * (Math.pow(1 + rate, periods) - 1));

  return payment;
}

function match(value, range, match_type) {
  // Default match type is 1 (exact match)
  match_type = match_type || 1;

  // Determine the index of the value in the range
  var index = range.indexOf(value);

  // If an exact match is required and the value is not found, return #N/A error
  if (match_type === 0 && index === -1) {
    return "#N/A";
  }

  // If an approximate match is required, adjust the index based on the match type
  if (match_type !== 0 && index === -1) {
    if (match_type === 1) {
      index = range.findIndex(function (val) {
        return val > value;
      });
    } else if (match_type === -1) {
      index = range.reverse().findIndex(function (val) {
        return val < value;
      });
      if (index !== -1) {
        index = range.length - index - 1;
      }
    }
  }

  // If the value is not found and an approximate match is required, return #N/A error
  if (index === -1) {
    return "#N/A";
  }

  // Return the position of the value in the range
  return index + 1;
}

function forecast(x, known_x, known_y) {
  if (known_x.length !== known_y.length) {
    throw new Error('The known_x and known_y arrays must have the same length.');
  }

  var n = known_x.length;

  var x_sum = 0;
  var y_sum = 0;
  var xy_sum = 0;
  var x_squared_sum = 0;

  for (var i = 0; i < n; i++) {
    x_sum += known_x[i];

    y_sum += known_y[i];
    xy_sum += known_x[i] * known_y[i];
    x_squared_sum += known_x[i] * known_x[i];
  }

  var x_mean = x_sum / n;
  var y_mean = y_sum / n;

  var slope = (n * xy_sum - x_sum * y_sum) / (n * x_squared_sum - x_sum * x_sum);
  var intercept = y_mean - slope * x_mean;

  return intercept + slope * x;
}


export default function Settings() {

  let [alertMessage, setAlertMessage] = useState(false);

  const [remainingBalance, setRemainingBalance] = useState(0);
  const [remainingBalanceTemp, setRemainingBalanceTemp] = useState(0)
  const [term, setTerm] = useState(0);
  const [termTemp, setTermTemp] = useState(0);
  const [amort, setAmort] = useState(0);
  const [amortTemp, setAmortTemp] = useState(0);
  const [ioTerm, setIoTerm] = useState(0);
  const [ioTermTemp, setIoTermTemp] = useState(0);
  const [passThru, setPassThru] = useState(0);
  const [passThruTemp, setPassThruTemp] = useState(0);
  const [sAndG, setSAndG] = useState(0);
  const [sAndGTemp, setSAndGTemp] = useState(0);
  const [override, setOverride] = useState("N");
  const [overrideTemp, setOverrideTemp] = useState("N");
  const [pxSpread, setPxSpread] = useState(0);
  // const [oneZeroTwoPXPL, setOneZeroTwoPXPL] = useState(0);
  // const [oneZeroFourPXPL, setOneZeroFourPXPL] = useState(0);
  // const [oneZeroSixPXPL, setOneZeroSixPXPL] = useState(0);
  // const [oneZeroEightPXPL, setOneZeroEightPXPL] = useState(0);
  // const [oneZeroTenPXPL, setOneZeroTenPXPL] = useState(0);
  const [pxSpreadTemp, setPxSpreadTemp] = useState(0);
  const [pxSpreadConstruction, setPxSpreadConstruction] = useState(0);
  const [pxSpreadConstructionTemp, setPxSpreadConstructionTemp] = useState(0);
  const [jTen, setJTen] = useState("D");
  const [jTenTemp, setJTenTemp] = useState("D");
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const getSettings = () => {
      // Axios.get("https://mudzie.com/admin/public/api/getSettings")
      Axios.get(`${process.env.REACT_APP_BASE_URL}api/getSettings`)
        .then((res) => {
          console.log(res.data);
          setRemainingBalance(res.data.loanAmount);
          setTerm(res.data.term);
          setAmort(res.data.amort);
          setIoTerm(res.data.ioTerm);
          setPassThru(res.data.passThru);
          setSAndG(res.data.sAndG);
          setOverride(res.data.override);
          setPxSpread(res.data.pxSpread);
          setPxSpreadConstruction(res.data.pxSpreadConstruction);
          setJTen(res.data.jTen);
          setIsLoading(false)
          localStorage.setItem("call_request_switch", res.data.call_request_switch)
          // setOneZeroTwoPXPL(res.data.oneZeroTwoPXPL)
          // setOneZeroFourPXPL(res.data.oneZeroFourPXPL)
          // setOneZeroSixPXPL(res.data.oneZeroSixPXPL)
          // setOneZeroEightPXPL(res.data.oneZeroEightPXPL)
          // setOneZeroTenPXPL(res.data.oneZeroTenPXPL)
        })
    }
    getSettings()
  }, [])


  let accrual = "30/360";
  const [delayedDaysState, setDelayedDays] = useState(0);
  let delayedDays = 0;
  let amortFactorRegularTermLoans = parseFloat((1.000000).toFixed(6));
  //Accrued Days field
  let today = new Date();
  var d = new Date(today.getFullYear(), parseInt(today.getMonth()) + 3, 0);
  let date = d.getMonth() + 1 + "/" + d.getDate() + "/" + d.getFullYear();

  let ddate = new Date(d);
  ddate.setMonth(ddate.getMonth() - 1);
  ddate = new Date(ddate.getFullYear(), ddate.getMonth() + 1, 0)
  ddate.setDate(ddate.getDate() + 25);
  let dateForAccruedDays = new Date(date);
  dateForAccruedDays.setMonth(dateForAccruedDays.getMonth() - 1);
  let newDateForAccruedDays = new Date(dateForAccruedDays.getFullYear(), dateForAccruedDays.getMonth(), 0)
  // dateForAccruedDays.setDate(dateForAccruedDays.getDate());
  // console.log("newDateForAccruedDays : ",newDateForAccruedDays);
  const date1 = new Date(date);
  const date2 = new Date(newDateForAccruedDays);
  const diffTime = Math.abs(date1 - date2);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  // console.log("diffDays : ",diffDays-1);
  // const [accruedDays, setAccruedDays] = useState(diffDays);
  let accruedDays = diffDays - 1;
  const [couponState, setCouponState] = useState(0);
  let coupon = 0;
  const [walState, setWalState] = useState(0);
  let wal = 0;
  let originalWal = 0;
  let amortFactorIOTermLoans = 0;
  let sumOfTotalCashflowMEY = 0;
  let sumOfTotalPrincipal = 0;
  const [sumOfTotalPrincipalState, setSumOfTotalPrincipalState] = useState(0);
  let sumOfGrossInterest = 0;
  const [sumOfGrossInterestState, setSumOfGrossInterestState] = useState(0);
  let sumOfServicing = 0;
  const [sumOfServicingState, setSumOfServicingState] = useState(0);
  let sumOfNetInterest = 0;
  const [sumOfNetInterestState, setSumOfNetInterestState] = useState(0);
  let sumOfSchedule = 0;
  const [sumOfScheduleState, setSumOfScheduleState] = useState(0);
  let sumOfPrepayments = 0;
  const [sumOfPrepaymentsState, setSumOfPrepaymentsState] = useState(0);
  let sumOfTotalCashFlow = 0;
  const [sumOfTotalCashFlowState, setSumOfTotalCashFlowState] = useState(0);
  let sumOfPeriodInTheFuture = 0;
  let totalCashflowArray = [1000000];
  let remainingBalanceArray = [];
  let sumOfNPV = 0;
  let sumOfProductForMacDur = 0;
  const [macDurState, setMacDurState] = useState(0);
  let macDur = 0;
  let currentAmortFactorRegularTermLoans = parseFloat(0.000000);
  var previousAmortFactorRegularTermLoans = parseFloat(1.000000);
  let currentRemainingBalance = 0;
  let originalCurrentRemainingBalance = 0;
  let currentGrossInterest = 0;
  let currentGrossInterestWithSixDecimal = 0;
  let servicing = 0;
  let originalServicing = 0;
  let sumOfPeriodInTheFutureAndTotalPrincipal = 0;
  let beyOneBp = 0;
  const [beyOneBpState, setBeyOneBpState] = useState(0);
  let pAndI = 0;
  const [pAndIState, setPAndIState] = useState(0);
  let modDur = 0;
  const [modDurState, setModDurState] = useState(0);
  let halfLife = 0;
  const [halfLifeState, setHalfLifeState] = useState(0);
  let intSOFR = 0;
  const [intSOFRState, setIntSOFRState] = useState(0);
  let myYield = 0;
  const [myYieldState, setMyYieldState] = useState(0);
  let MEY = 0;
  let originalMEY = 0;
  const [MEYState, setMEYState] = useState(0);
  let price = 0;
  const [priceState, setPriceState] = useState(0);
  let accInt = 0;
  const [accIntState, setAccIntState] = useState(0);
  let MEYTwo = 0;
  const [MEYTwoState, setMEYTwoState] = useState(0);
  let columns = [];
  const [columnsState, setColumnState] = useState([]);
  let rows = [];
  const [rowsState, setRowsState] = useState([]);
  let intTSY = 0;
  const [intTSYState, setIntTSYState] = useState(0);

  const [loanAmountDisableFlag, setLoanAmountDisableFlag] = useState(true)
  const [termDisableFlag, setTermDisableFlag] = useState(true)
  const [amortDisableFlag, setAmortDisableFlag] = useState(true)
  const [ioTermDisableFlag, setIoTermDisableFlag] = useState(true)
  const [passThruDisableFlag, setPassThruDisableFlag] = useState(true)
  const [sAndGDisableFlag, setSAndGDisableFlag] = useState(true)
  const [overrideDisableFlag, setOverrideDisableFlag] = useState(true)
  const [pxSpreadDisableFlag, setPxSpreadDisableFlag] = useState(true)
  const [jTenDisableFlag, setJTenDisableFlag] = useState(true)

  const [calculateIndicatorFlag, setCalculateIndicatorFlag] = useState(false)
  
  const [callRequestSwitchDefaultValue, setCallRequestSwitchDefaultValue] = useState(false);

  const handleCallRequestSwitchChange = (event) => {
    setCallRequestSwitchDefaultValue(event.target.checked)
  }

  function handleChangeTerm(value) {
    let term = Number(value);
    // setTerm(term)
    setTermTemp(term)
  }

  function handleChangeRemainingBalance(value) {
    let remainingBalance = Number(value);
    setRemainingBalanceTemp(remainingBalance)
    // setRemainingBalance(remainingBalance);
  }

  function handleChangeAmort(value) {
    let amort = Number(value);
    // setAmort(amort);
    setAmortTemp(amort);
  }

  function handleChangeIoTerm(value) {
    let ioTerm = Number(value);
    // setIoTerm(ioTerm);
    setIoTermTemp(ioTerm);
  }

  function handleChangePassThru(value) {
    let passThru = Number(value);
    // setPassThru(passThru);
    setPassThruTemp(passThru);
  }

  function handleChangeSAndG(value) {
    let sAndG = Number(value);
    // setSAndG(sAndG);
    setSAndGTemp(sAndG);
  }

  function handleChangeOverride(value) {
    // setOverride(value);
    setOverrideTemp(value);
  }

  function handleChangePxSpread(value) {
    let pxSpread = Number(value);
    // setPxSpread(pxSpread);
    setPxSpreadTemp(pxSpread);
  }

  function handleChangePxSpreadConstruction(value) {
    let pxSpreadConstruction = Number(value);
    // setPxSpread(pxSpread);
    setPxSpreadConstructionTemp(pxSpreadConstruction);
  }

  function handleChangeJTen(value) {
    // setJTen(value);
    setJTenTemp(value);
  }

  async function calculateAmortTable() {
    // let delayedDays = 0;
    if (override === "Y")
      delayedDays = 54;
    else if (accrual === "30/360")
      delayedDays = 44;
    else
      delayedDays = 54;
    setDelayedDays(delayedDays);
    coupon = passThru + sAndG;
    // console.log("Coupan : ",coupon);
    setCouponState(coupon);
    columns = [
      { id: 'period', label: 'Period' },
      { id: 'paymentDate', label: 'Payment Date' },
      { id: 'accruedDays', label: 'Accrued Days' },
      { id: 'amortFactorRegularTermLoans', label: 'Amort Factor – Regular Term Loans' },
      { id: 'amortFactorIOTermLoans', label: 'Amort Factor – IO_Term Loans' },
      { id: 'periodInTheFuture', label: 'Period in The Future' },
      { id: 'grossInterest', label: 'Gross Interest' },
      { id: 'servicing', label: 'Servicing' },
      { id: 'netInterest', label: 'Net Interest' },
      { id: 'scheduled', label: 'Scheduled' },
      { id: 'prepayments', label: 'Prepayments' },
      { id: 'remainingBalance', label: 'Remaining Balance' },
      { id: 'totalPrincipal', label: 'Total Principal' },
      { id: 'totalCashflow', label: 'Total Cashflow' },
      { id: 'totalCashflowMEY', label: 'Total Cashflow MEY' },
      { id: 'totalCashflowMEY2', label: 'Total Cashflow MEY2' },
    ];
    setColumnState(columns)

    function createData(period, paymentDate, accruedDays, amortFactorRegularTermLoans, amortFactorIOTermLoans, periodInTheFuture, grossInterest, servicing, netInterest, scheduled, prepayments, remainingBalance, totalPrincipal, totalCashflow, totalCashflowMEY, totalCashflowMEY2) {
      return { period, paymentDate, accruedDays, amortFactorRegularTermLoans, amortFactorIOTermLoans, periodInTheFuture, grossInterest, servicing, netInterest, scheduled, prepayments, remainingBalance, totalPrincipal, totalCashflow, totalCashflowMEY, totalCashflowMEY2 };
    }

    rows = [
      createData('', ddate.toLocaleDateString(), '', amortFactorRegularTermLoans.toFixed(6), amortFactorRegularTermLoans.toFixed(6), '', '', '', '', '', '', remainingBalance, '', '', '', '')
    ];
    setRowsState(rows);
    // let monthlyInterestrate = ((passThru / 12) / 100).toFixed(6);
    // let amortFactorRegularTermLoans = ((monthlyInterestrate * (1 + monthlyInterestrate) ^ term) / ((1 + monthlyInterestrate) ^ term - 1)).toFixed(6);



    //***PandI
    pAndI = -1 * pmt((coupon / 12) / 100, amort, remainingBalance, 0, 0);
    setPAndIState(pAndI)

    //***Calculation for WAL, MEY, Yield
    for (let i = 1; i <= parseInt(term); i++) {
      //***Period in The Future
      let periodInTheFuture = (i + (delayedDays - accruedDays - 30) / 30);

      ///***Gross Interest
      if (i === 1)
        currentGrossInterest = ((coupon / 100) / 360 * 30 * remainingBalance);
      else
        currentGrossInterest = ((coupon / 100) / 360 * 30 * originalCurrentRemainingBalance);

      ///***Scheduled
      let scheduled = 0;
      if (i > term) {
        scheduled = 0;
      }
      else if (i <= ioTerm) {
        scheduled = 0;
      }
      else {
        scheduled = pAndI - currentGrossInterest;
      }
      if (scheduled < 999.999)
        scheduled = scheduled.toFixed(7);
      else
        scheduled = scheduled.toFixed(6);
      //***Prepayments
      let prepayments = 0;
      if (i === term)
        prepayments = parseFloat(originalCurrentRemainingBalance - scheduled);

      //***Remaining Balance
      if (i === 1)
        originalCurrentRemainingBalance = parseFloat(remainingBalance - scheduled - prepayments);
      else
        originalCurrentRemainingBalance = parseFloat(originalCurrentRemainingBalance - scheduled - prepayments);
      //***Total Principal
      let totalPrincipal = 0;
      totalPrincipal = parseFloat(scheduled) + parseFloat(prepayments);

      //***WAL Calculation
      // console.log("periodInTheFuture * totalPrincipal : ", periodInTheFuture, totalPrincipal);
      sumOfPeriodInTheFutureAndTotalPrincipal = sumOfPeriodInTheFutureAndTotalPrincipal + periodInTheFuture * totalPrincipal;
      if (i === parseFloat(term)) {
        wal = (sumOfPeriodInTheFutureAndTotalPrincipal / remainingBalance / 12).toFixed(2);
        originalWal = (sumOfPeriodInTheFutureAndTotalPrincipal / remainingBalance / 12).toFixed(9);
        setWalState(wal)
      }
    }

    //***Int SOFR
    // const swapTermForMatchResult = [4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 20, 30];
    const swapTermForMatchResult = [4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 20, 30];
    let matchResult = match(originalWal, swapTermForMatchResult, -1) - 1;
    // const swapTerm = [4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 20, 30];
    const swapTerm = [4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 20, 30];
    // const swapRates = [0.160, 0.8659, 2.772, 2.7808, 1.155, 3.950, 3.930, 3.467, 1.733, 1.785, 3.000, 3.000, 3.000, 4.000];
    const swapRates = [3.774, 3.639, 3.560, 3.507, 3.475, 3.457, 3.445, 0.000, 3.438, 0.000, 0.000, 3.433, 3.387, 3.185];
    intSOFR = (forecast(originalWal, [swapTerm[matchResult], swapTerm[matchResult + 1]], [swapRates[matchResult], swapRates[matchResult + 1]]) / 100 * 100);
    let myIntSORF = 0;
    myIntSORF = intSOFR.toFixed(9);
    intSOFR = intSOFR.toFixed(2);
    setIntSOFRState(intSOFR)

    //***Int TSY
    // const TSYTermForMatchResult = [2, 3, 5, 7, 10, 30];
    const TSYTermForMatchResult = [2, 3, 5, 7, 10, 30];
    let TSYmatchResult = match(wal, TSYTermForMatchResult, -1) - 1;
    // const TSYTerm = [2, 3, 5, 7, 10, 30];
    const TSYTerm = [2, 3, 5, 7, 10, 30];
    // const TSYRates = [4.230, 3.900, 3.610, 3.550, 3.500, 1.370];
    // const TSYRates = [4.845, 4.496, 4.143, 4.013, 3.873, 3.929];
    let TSYRates
    setIsLoading(true)
    await Axios.get("https://h8ypxuftnl.execute-api.us-west-2.amazonaws.com/dev/refinitiv")
      .then((res) => {
        console.log(res.data.body);
        TSYRates = res.data.body.replace(/\s/g, '').replace("[", "").replace("]", "").split(',').map(Number)
        setIsLoading(false)
      })
      .catch(function (error) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser 
          // and an instance of http.ClientRequest in node.js
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
        }


      });
    intTSY = (forecast(wal, [TSYTerm[TSYmatchResult], TSYTerm[TSYmatchResult + 1]], [TSYRates[TSYmatchResult], TSYRates[TSYmatchResult + 1]]) / 100 * 100);
    let myIntTSY = 0;
    myIntTSY = intTSY;
    intTSY = intTSY.toFixed(2);
    setIntTSYState(intTSY);
    // console.log("intTSYState :",intTSY);

    //***JTen
    // let jTen = "S";
    // const [jTen, setJTen] = useState("S")

    //***Yield
    if (jTen === "S")
      myYield = (((myIntSORF * 100) + pxSpread) / 10000) * 100;
    else
      myYield = (((myIntTSY * 100) + pxSpread) / 10000) * 100;
    setMyYieldState(myYield)

    //***BEY+bp
    beyOneBp = (((myYield / 100) + 0.0001) * 100).toFixed(3);
    setBeyOneBpState(beyOneBp)

    //***MEY2
    //MEYTwo = (12 * ((1 + (beyOneBp / 100) / 2) ** (1 / 6) - 1) * 100).toFixed(3);
    MEYTwo = ((12 * (((1 + (4.693 / 100) / 2) ** (1 / 6)) - 1)) * 100).toFixed(3);
    setMEYTwoState(MEYTwo)

    //***MEY
    MEY = (12 * ((1 + (myYield / 100) / 2) ** (1 / 6) - 1) * 100).toFixed(2);
    originalMEY = 12 * ((1 + (myYield / 100) / 2) ** (1 / 6) - 1) * 100;
    setMEYState(MEY)
    for (let i = 1; i <= parseInt(term); i++) {
      // console.log("i : ");
      let dt = new Date(ddate);
      dt.setMonth(dt.getMonth() + i)

      //***Amort Factor Regular Term Loan
      if (i <= amort) {
        let cumprincd = cumprinc((coupon / 12) / 100, amort - i + 1, previousAmortFactorRegularTermLoans, 1, 1, 0);
        currentAmortFactorRegularTermLoans = parseFloat(previousAmortFactorRegularTermLoans + cumprincd);
      }
      previousAmortFactorRegularTermLoans = currentAmortFactorRegularTermLoans;
      // setAmortFactorRegularTermLoans(...currentAmortFactorRegularTermLoans);

      //***Amort Factor IO Term Loan
      if (i <= ioTerm)
        amortFactorIOTermLoans = 1;
      else
        amortFactorIOTermLoans = currentAmortFactorRegularTermLoans.toFixed(6);

      //***Period in The Future
      let periodInTheFuture = (i + (delayedDays - accruedDays - 30) / 30).toFixed(2);
      let originalPeriodInTheFuture = i + (delayedDays - accruedDays - 30) / 30;
      sumOfPeriodInTheFuture = parseFloat(sumOfPeriodInTheFuture) + parseFloat(periodInTheFuture);

      ///***Gross Interest
      if (i === 1) {
        currentGrossInterest = ((coupon / 360 * 30 * remainingBalance) / 100).toFixed(4);
        currentGrossInterestWithSixDecimal = ((coupon / 360 * 30 * remainingBalance) / 100);
      }
      else {
        currentGrossInterest = ((coupon / 360 * 30 * originalCurrentRemainingBalance) / 100).toFixed(4);
        currentGrossInterestWithSixDecimal = ((coupon / 360 * 30 * originalCurrentRemainingBalance) / 100);
      }
      sumOfGrossInterest = (parseFloat(sumOfGrossInterest) + parseFloat(currentGrossInterest)).toFixed(2);
      setSumOfGrossInterestState(sumOfGrossInterest);

      ///***Servicing
      if (i === 1) {
        servicing = ((sAndG / 360 * 30 * remainingBalance) / 100).toFixed(4);
        originalServicing = (sAndG / 360 * 30 * remainingBalance) / 100;
      }
      else {
        servicing = ((sAndG / 360 * 30 * currentRemainingBalance) / 100).toFixed(4);
        originalServicing = (sAndG / 360 * 30 * originalCurrentRemainingBalance) / 100
      }
      sumOfServicing = (parseFloat(sumOfServicing) + parseFloat(servicing)).toFixed(2)
      setSumOfServicingState(sumOfServicing)

      ///***Net Interest
      let netInterest = 0;
      let originalNetInterest = 0;
      netInterest = parseFloat(currentGrossInterest - servicing).toFixed(2);
      originalNetInterest = parseFloat(currentGrossInterestWithSixDecimal - originalServicing);

      sumOfNetInterest = (parseFloat(sumOfNetInterest) + parseFloat(originalNetInterest)).toFixed(2)
      setSumOfNetInterestState(originalNetInterest);

      ///***Scheduled
      let scheduled = 0;
      if (i > term)
        scheduled = 0;
      else if (i <= ioTerm)
        scheduled = 0;
      else
        scheduled = pAndI - currentGrossInterestWithSixDecimal;
      // if(i===1) {
      // console.log("pAndI : ",pAndI);
      // console.log("currentGrossInterestWithSixDecimal : ",currentGrossInterestWithSixDecimal);
      if (scheduled < 999.999)
        scheduled = scheduled.toFixed(7);
      else
        scheduled = scheduled.toFixed(6);
      // }
      sumOfSchedule = parseFloat(sumOfSchedule) + parseFloat(scheduled);
      setSumOfScheduleState(sumOfSchedule);

      //***Prepayments
      let prepayments = 0;
      let originalPrepayment = 0;
      if (i === term) {
        originalPrepayment = parseFloat(originalCurrentRemainingBalance.toFixed(4) - scheduled);
        // console.log("originalCurrentRemainingBalance : ",originalCurrentRemainingBalance.toFixed(4));
        // console.log("scheduled : ",scheduled);
        // console.log("originalPrepayment : ",originalPrepayment);
        prepayments = parseFloat(currentRemainingBalance - scheduled).toFixed(0);
      }
      sumOfPrepayments = parseFloat(sumOfPrepayments) + parseFloat(prepayments);
      setSumOfPrepaymentsState(sumOfPrepayments);

      //***Remaining Balance
      if (i === 1) {
        originalCurrentRemainingBalance = parseFloat(remainingBalance - scheduled - originalPrepayment);
        currentRemainingBalance = parseFloat(remainingBalance - scheduled - prepayments).toFixed(0);
      }
      else {
        originalCurrentRemainingBalance = parseFloat(originalCurrentRemainingBalance - scheduled - originalPrepayment);
        currentRemainingBalance = parseFloat(currentRemainingBalance - scheduled - prepayments).toFixed(0);
      }
      // console.log("originalCurrentRemainingBalance : ",originalCurrentRemainingBalance.toFixed(4));

      remainingBalanceArray.push(originalCurrentRemainingBalance.toFixed(0));

      //***Total Principal
      // console.log("originalPrepayment : ",originalPrepayment);
      let totalPrincipal = 0;
      totalPrincipal = parseFloat(scheduled) + parseFloat(originalPrepayment);
      sumOfTotalPrincipal = sumOfTotalPrincipal + totalPrincipal;
      setSumOfTotalPrincipalState(sumOfTotalPrincipal)
      // if(i===term)
      //   console.log("scheduled : ",scheduled, "prepayments : ",prepayments, "totalPrincipal : ",totalPrincipal.toFixed(0));

      //***Total Cashflow
      let totalCashflow = 0;
      totalCashflow = parseFloat(originalNetInterest) + parseFloat(totalPrincipal);
      sumOfTotalCashFlow = parseFloat(sumOfTotalCashFlow) + parseFloat(totalCashflow);
      setSumOfTotalCashFlowState(sumOfTotalCashFlow);
      totalCashflowArray.push(parseFloat(totalCashflow.toFixed(0)));

      //***WAL Calculation
      // sumOfPeriodInTheFutureAndTotalPrincipal = sumOfPeriodInTheFutureAndTotalPrincipal + periodInTheFuture * totalPrincipal
      // if (i === term)
      //   wal = (sumOfPeriodInTheFutureAndTotalPrincipal / remainingBalance / 12).toFixed(2);
      // console.log("WAL : ",wal);

      //***Total Cashflow MEY
      let totalCashflowMEY = 0;
      let totalCashflowMEYOriginal = 0;
      totalCashflowMEY = (totalCashflow / (((1 + (MEY / 100) / 12) ** periodInTheFuture) * 100) * 100).toFixed(0);
      // totalCashflowMEYOriginal = (totalCashflow.toFixed(6) / (((1 + (MEY / 100) / 12) ** originalPeriodInTheFuture) * 100) * 100);
      totalCashflowMEYOriginal = totalCashflow / ((1 + (originalMEY / 100) / 12) ** (originalPeriodInTheFuture));
      // if(i===1) {
      //   console.log("totalCashflowMEYOriginal : ",totalCashflowMEYOriginal.toFixed(6));
      // console.log("totalCashflow : ",totalCashflow.toFixed(6));
      // console.log("originalMEY : ",(originalMEY/100).toFixed(9));
      // console.log("originalPeriodInTheFuture : ",originalPeriodInTheFuture);
      // }
      sumOfTotalCashflowMEY = parseFloat(sumOfTotalCashflowMEY) + parseFloat(totalCashflowMEYOriginal);
      // console.log("sumOfTotalCashflowMEY : ",sumOfTotalCashflowMEY);
      //***Total Cashflow MEY2
      let totalCashflowMEYTwo = 0;
      totalCashflowMEYTwo = (totalCashflow / (((1 + (MEYTwo / 100) / 12) ** periodInTheFuture) * 100) * 100).toFixed(0);


      //***Mac Dur Calculation
      ////=Z43/(1+(MEY/12))^M43
      sumOfNPV += totalCashflow / (1 + (MEY / 100) / 12) ** i;
      sumOfProductForMacDur += periodInTheFuture * (1 / (1 + (MEY / 100) / 12) ** periodInTheFuture / 12) * totalCashflow;

      rows.push(createData(i, dt.toLocaleDateString(), 30, currentAmortFactorRegularTermLoans.toFixed(6), amortFactorIOTermLoans, periodInTheFuture, parseFloat(currentGrossInterest).toFixed(0), parseFloat(servicing).toFixed(0), netInterest, parseFloat(scheduled).toFixed(0), originalPrepayment.toFixed(0), originalCurrentRemainingBalance.toFixed(0), parseFloat(totalPrincipal).toFixed(0), totalCashflow.toFixed(0), totalCashflowMEYOriginal.toFixed(0), totalCashflowMEYTwo))
    }
    //***Acc Int
    accInt = (((accruedDays / 360) * (passThru / 100) * 1000000)).toFixed(2);
    setAccIntState(accInt)

    //***cellAA
    let cellAA = 0;
    cellAA = (sumOfTotalCashflowMEY - accInt).toFixed(2);
    //***Price
    price = cellAA / sumOfTotalPrincipal * 100;
    var wholeNumber = Math.floor(price);
    var fraction = Math.round((price - wholeNumber) * 32);
    price = wholeNumber.toString() + " " + fraction.toString() + "/32";
    setPriceState(price)

    //***Mac. Dur
    macDur = sumOfProductForMacDur / sumOfNPV;
    setMacDurState(macDur);

    //***Mod Dur
    modDur = macDur / (1 + (myYield / 100) / 2);
    setModDurState(modDur)

    //***Half Life
    halfLife = match(remainingBalance / 12, remainingBalanceArray, -1) / 12;
    // console.log(halfLife);
    setHalfLifeState(halfLife)
  }

  // useEffect(() => {
  //   calculateAmortTable();
  // }, []);

  useEffect(() => {
    calculateAmortTable();
  }, [term, remainingBalance, amort, ioTerm, passThru, sAndG, override, pxSpread, jTen]);

  function updateEditableValues() {
    let settingsValues = {
      loanAmount: remainingBalance,
      term: term,
      amort: amort,
      ioTerm: ioTerm,
      passThru: passThru,
      sAndG: sAndG,
      override: override,
      pxSpread: pxSpread,
      pxSpreadConstruction: pxSpreadConstruction,
      jTen: jTen,
      // oneZeroTwoPXPL: Number(oneZeroTwoPXPL),
      // oneZeroFourPXPL: Number(oneZeroFourPXPL),
      // oneZeroSixPXPL: Number(oneZeroSixPXPL),
      // oneZeroEightPXPL: Number(oneZeroEightPXPL),
      // oneZeroTenPXPL: Number(oneZeroTenPXPL)
    }
    console.log("settingsValues: ", settingsValues);
    Axios.post(`${process.env.REACT_APP_BASE_URL}api/updateSettings`, settingsValues)
      .then((res) => {
        setLoanAmountDisableFlag(true)
        setTermDisableFlag(true)
        setAmortDisableFlag(true)
        setIoTermDisableFlag(true)
        setPassThruDisableFlag(true)
        setSAndGDisableFlag(true)
        setOverrideDisableFlag(true)
        setPxSpreadDisableFlag(true)
        setJTenDisableFlag(true)
        // setCalculateIndicatorFlag(true)
        setAlertMessage(true)
      })
  }

  const calculateIndicator = () => {
    if (remainingBalanceTemp > 0)
      setRemainingBalance(remainingBalanceTemp);
    if (termTemp > 0)
      setTerm(termTemp);
    if (amortTemp > 0)
      setAmort(amortTemp);
    if (ioTermTemp > 0)
      setIoTerm(ioTermTemp);
    if (passThruTemp > 0)
      setPassThru(passThruTemp);
    if (sAndGTemp > 0)
      setSAndG(sAndGTemp);
    if (overrideTemp != "")
      setOverride(overrideTemp);
    if (pxSpreadTemp > 0)
      setPxSpread(pxSpreadTemp);
    if (pxSpreadConstructionTemp > 0)
      setPxSpreadConstruction(pxSpreadConstructionTemp);
    if (jTenTemp != "")
      setJTen(jTenTemp);
    setCalculateIndicatorFlag(false)
  }

  return (
    <>
      {isLoading ?
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <ClipLoader
            color="#013a80"
            loading={isLoading}
            size={50}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </Box>
        :
        <div>
          <Navbar />
          <Box height={10} />
          <Box sx={{ display: 'flex' }}>
            <Sidenav menuHeighlightPath="/dashboard/settings" />
            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Card sx={{ boxShadow: 12, borderRadius: '20px', mt: 10, width: "90%" }}>
                    {alertMessage ?
                      <Alert severity="success" onClose={() => { setAlertMessage(false) }}>
                        <AlertTitle>Success</AlertTitle>
                        All values saved successfully!
                      </Alert>
                      :
                      null
                    }
                    <CardHeader
                      // title="Settings"
                      // title="PX Spread"
                      title="Commission"
                      titleTypographyProps={{ fontFamily: 'Ubuntu', fontWeight: 'bold', mt: 3, pl: 4 }}
                      action={
                        <>
                          {
                            loanAmountDisableFlag ?
                              <Button variant="contained" onClick={() => {
                                setLoanAmountDisableFlag(false)
                                setTermDisableFlag(false)
                                setAmortDisableFlag(false)
                                setIoTermDisableFlag(false)
                                setPassThruDisableFlag(false)
                                setSAndGDisableFlag(false)
                                setOverrideDisableFlag(false)
                                setPxSpreadDisableFlag(false)
                                setJTenDisableFlag(false)
                                setCalculateIndicatorFlag(true)
                              }}>
                                Edit
                              </Button>
                              :
                              calculateIndicatorFlag ?
                                <Button variant="contained" onClick={() => calculateIndicator()}>
                                  Calculate
                                </Button>
                                :
                                <Button variant="contained" onClick={() => updateEditableValues()}>
                                  Save
                                </Button>
                          }
                        </>
                      }
                    />
                    <hr width='95%' />
                    <CardContent>
                      <Stack spacing={8} direction={"column"}>
                        <Box sx={{ display: 'flex', gap: '100px', paddingLeft: '30px' }}>
                          <Grid item xs={1.2}>
                            {/* <TextField
                              InputLabelProps={{ style: { color: '#8D8D8D', fontFamily: 'Ubuntu', fontSize: '15px' } }}
                              margin="normal"
                              label="Px Spread Permanent"
                              type="number"
                              required
                              defaultValue={pxSpread > 0 ? pxSpread.toFixed(1) : 0}
                              disabled={pxSpreadDisableFlag}
                              // onChange={(e) => (setPxSpread(e.target.value))}
                              onChange={(e) => { handleChangePxSpread(e.target.value) }}
                              size="small"
                              variant="standard"
                              sx={{
                                '& .MuiInput-underline': {
                                  borderBottomColor: '#8D8D8D',
                                  borderWidth: '2px',
                                  borderBottomStyle: 'solid' // Replace with your desired color
                                },
                                '& .MuiInput-underline:after': {
                                  borderBottomColor: '#8D8D8D',
                                  borderWidth: '2px',
                                  borderBottomStyle: 'solid' // Replace with your desired color
                                },
                              }}
                            /> */}
                            <TextField
                              InputLabelProps={{ style: { color: '#8D8D8D', fontFamily: 'Ubuntu', fontSize: '15px' } }}
                              margin="normal"
                              label="Commission"
                              type="number"
                              required
                              defaultValue={0}
                              disabled={pxSpreadDisableFlag}
                              // onChange={(e) => (setPxSpread(e.target.value))}
                              // onChange={(e) => { handleChangePxSpread(e.target.value) }}
                              size="small"
                              variant="standard"
                              sx={{
                                '& .MuiInput-underline': {
                                  borderBottomColor: '#8D8D8D',
                                  borderWidth: '2px',
                                  borderBottomStyle: 'solid' // Replace with your desired color
                                },
                                '& .MuiInput-underline:after': {
                                  borderBottomColor: '#8D8D8D',
                                  borderWidth: '2px',
                                  borderBottomStyle: 'solid' // Replace with your desired color
                                },
                              }}
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <FormControlLabel
                              control={<Switch disabled checked={callRequestSwitchDefaultValue} onChange={handleCallRequestSwitchChange} />}
                              label="Add Commission Switch"
                            />
                          </Grid>
                          {/* <Grid item xs={1.2}>
                            <TextField
                              InputLabelProps={{ style: { color: '#8D8D8D', fontFamily: 'Ubuntu', fontSize: '15px' } }}
                              margin="normal"
                              label="$102-00 PX P/L"
                              type="number"
                              required
                              // defaultValue={pxSpread > 0 ? pxSpread.toFixed(1) : 0}
                              defaultValue={oneZeroTwoPXPL}
                              disabled={pxSpreadDisableFlag}
                              onChange={(e) => { setOneZeroTwoPXPL(e.target.value) }}
                              size="small"
                              variant="standard"
                              sx={{
                                '& .MuiInput-underline': {
                                  borderBottomColor: '#8D8D8D',
                                  borderWidth: '2px',
                                  borderBottomStyle: 'solid' // Replace with your desired color
                                },
                                '& .MuiInput-underline:after': {
                                  borderBottomColor: '#8D8D8D',
                                  borderWidth: '2px',
                                  borderBottomStyle: 'solid' // Replace with your desired color
                                },
                              }}
                            />
                          </Grid>
                          <Grid item xs={1.2}>
                            <TextField
                              InputLabelProps={{ style: { color: '#8D8D8D', fontFamily: 'Ubuntu', fontSize: '15px' } }}
                              margin="normal"
                              label="$104-00 PX P/L"
                              type="number"
                              required
                              // defaultValue={pxSpread > 0 ? pxSpread.toFixed(1) : 0}
                              defaultValue={oneZeroFourPXPL}
                              disabled={pxSpreadDisableFlag}
                              onChange={(e) => { setOneZeroFourPXPL(e.target.value) }}
                              size="small"
                              variant="standard"
                              sx={{
                                '& .MuiInput-underline': {
                                  borderBottomColor: '#8D8D8D',
                                  borderWidth: '2px',
                                  borderBottomStyle: 'solid' // Replace with your desired color
                                },
                                '& .MuiInput-underline:after': {
                                  borderBottomColor: '#8D8D8D',
                                  borderWidth: '2px',
                                  borderBottomStyle: 'solid' // Replace with your desired color
                                },
                              }}
                            />
                          </Grid>
                          <Grid item xs={1.2}>
                            <TextField
                              InputLabelProps={{ style: { color: '#8D8D8D', fontFamily: 'Ubuntu', fontSize: '15px' } }}
                              margin="normal"
                              label="$106-00 PX P/L"
                              type="number"
                              required
                              // defaultValue={pxSpread > 0 ? pxSpread.toFixed(1) : 0}
                              defaultValue={oneZeroSixPXPL}
                              disabled={pxSpreadDisableFlag}
                              onChange={(e) => { setOneZeroSixPXPL(e.target.value) }}
                              size="small"
                              variant="standard"
                              sx={{
                                '& .MuiInput-underline': {
                                  borderBottomColor: '#8D8D8D',
                                  borderWidth: '2px',
                                  borderBottomStyle: 'solid' // Replace with your desired color
                                },
                                '& .MuiInput-underline:after': {
                                  borderBottomColor: '#8D8D8D',
                                  borderWidth: '2px',
                                  borderBottomStyle: 'solid' // Replace with your desired color
                                },
                              }}
                            />
                          </Grid>
                          <Grid item xs={1.2}>
                            <TextField
                              InputLabelProps={{ style: { color: '#8D8D8D', fontFamily: 'Ubuntu', fontSize: '15px' } }}
                              margin="normal"
                              label="$108-00 PX P/L"
                              type="number"
                              required
                              // defaultValue={pxSpread > 0 ? pxSpread.toFixed(1) : 0}
                              defaultValue={oneZeroEightPXPL}
                              disabled={pxSpreadDisableFlag}
                              onChange={(e) => { setOneZeroEightPXPL(e.target.value) }}
                              size="small"
                              variant="standard"
                              sx={{
                                '& .MuiInput-underline': {
                                  borderBottomColor: '#8D8D8D',
                                  borderWidth: '2px',
                                  borderBottomStyle: 'solid' // Replace with your desired color
                                },
                                '& .MuiInput-underline:after': {
                                  borderBottomColor: '#8D8D8D',
                                  borderWidth: '2px',
                                  borderBottomStyle: 'solid' // Replace with your desired color
                                },
                              }}
                            />
                          </Grid>
                          <Grid item xs={1.2}>
                            <TextField
                              InputLabelProps={{ style: { color: '#8D8D8D', fontFamily: 'Ubuntu', fontSize: '15px' } }}
                              margin="normal"
                              label="$110-00 PX P/L"
                              type="number"
                              required
                              // defaultValue={pxSpread > 0 ? pxSpread.toFixed(1) : 0}
                              defaultValue={oneZeroTenPXPL}
                              disabled={pxSpreadDisableFlag}
                              onChange={(e) => { setOneZeroTenPXPL(e.target.value) }}
                              size="small"
                              variant="standard"
                              sx={{
                                '& .MuiInput-underline': {
                                  borderBottomColor: '#8D8D8D',
                                  borderWidth: '2px',
                                  borderBottomStyle: 'solid' // Replace with your desired color
                                },
                                '& .MuiInput-underline:after': {
                                  borderBottomColor: '#8D8D8D',
                                  borderWidth: '2px',
                                  borderBottomStyle: 'solid' // Replace with your desired color
                                },
                              }}
                            />
                          </Grid> */}
                        </Box>
                        {/* <Box sx={{ display: 'flex', gap: '100px', paddingLeft: '30px' }}>
                          <Grid item xs={1.2}>
                            <TextField
                              InputLabelProps={{ style: { color: '#8D8D8D', fontFamily: 'Ubuntu', fontSize: '15px' } }}
                              margin="normal"
                              label="Px Spread Construction"
                              type="number"
                              required
                              defaultValue={pxSpreadConstruction > 0 ? pxSpreadConstruction.toFixed(1) : 0}
                              disabled={pxSpreadDisableFlag}
                              // onChange={(e) => (setPxSpread(e.target.value))}
                              onChange={(e) => { handleChangePxSpreadConstruction(e.target.value) }}
                              size="small"
                              variant="standard"
                              sx={{
                                '& .MuiInput-underline': {
                                  borderBottomColor: '#8D8D8D',
                                  borderWidth: '2px',
                                  borderBottomStyle: 'solid' // Replace with your desired color
                                },
                                '& .MuiInput-underline:after': {
                                  borderBottomColor: '#8D8D8D',
                                  borderWidth: '2px',
                                  borderBottomStyle: 'solid' // Replace with your desired color
                                },
                              }}
                            />
                          </Grid>
                        </Box> */}
                      </Stack>
                    </CardContent>
                  </Card>
                  <Card sx={{ boxShadow: 12, borderRadius: '20px', mt: 5, width: "90%" }}>
                    <CardHeader
                      // title="Settings"
                      title="Customize values as per your need"
                      titleTypographyProps={{ fontFamily: 'Ubuntu', fontWeight: 'bold', mt: 3, pl: 4 }}
                    // action={
                    //   <>
                    //     {
                    //       loanAmountDisableFlag ?
                    //         <Button variant="contained" onClick={() => {
                    //           setLoanAmountDisableFlag(false)
                    //           setTermDisableFlag(false)
                    //           setAmortDisableFlag(false)
                    //           setIoTermDisableFlag(false)
                    //           setPassThruDisableFlag(false)
                    //           setSAndGDisableFlag(false)
                    //           setOverrideDisableFlag(false)
                    //           setPxSpreadDisableFlag(false)
                    //           setJTenDisableFlag(false)
                    //           setCalculateIndicatorFlag(true)
                    //         }}>
                    //           Edit
                    //         </Button>
                    //         :
                    //         calculateIndicatorFlag ?
                    //           <Button variant="contained" onClick={() => calculateIndicator(false)}>
                    //             Calculate
                    //           </Button>
                    //           :
                    //           <Button variant="contained" onClick={() => updateEditableValues(false)}>
                    //             Save
                    //           </Button>
                    //     }

                    //   </>
                    // }
                    />
                    <hr width='95%' />
                    <CardContent>
                      <Stack spacing={8} direction={"column"}>
                        <Box sx={{ display: 'flex', gap: { xl: '100px', lg: '100px', sm: '10px', xs: 0 }, paddingLeft: '30px' }}>
                          <Grid item xs={1.2}>
                            <TextField
                              InputLabelProps={{ style: { color: '#8D8D8D', fontFamily: 'Ubuntu', fontSize: '15px' } }}
                              margin="normal"
                              label="Loan Amount ($)"
                              type="number"
                              required
                              defaultValue={remainingBalance}
                              disabled={loanAmountDisableFlag}
                              // onChange={(e) => (setRemainingBalance(e.target.value))}
                              onChange={(e) => { handleChangeRemainingBalance(e.target.value) }}
                              size="normal"
                              variant="standard"
                              sx={{
                                '& .MuiInput-underline:before': {
                                  borderBottomColor: '#8D8D8D',
                                  borderWidth: '2px',
                                  borderBottomStyle: 'solid' // Replace with your desired color
                                },
                                '& .MuiInput-underline:after': {
                                  borderBottomColor: '#8D8D8D',
                                  borderWidth: '2px',
                                  borderBottomStyle: 'solid' // Replace with your desired color
                                },
                              }}
                            />
                            <TextField
                              InputLabelProps={{ style: { color: '#8D8D8D', fontFamily: 'Ubuntu', fontSize: '15px' } }}
                              margin="normal"
                              required
                              inputProps={{ maxLength: 3 }}
                              label="Term (months)"
                              type="number"
                              defaultValue={term}
                              disabled={termDisableFlag}
                              // onChange={(e) => { (setTerm(e.target.value)); calculateAmortTable() }}
                              // onChange={e => { setTerm(e.target.value); calculateAmortTable() }}
                              // onChange={(e) => (setTerm(e.target.value))}
                              onChange={(e) => { handleChangeTerm(e.target.value) }}
                              size="medium"
                              variant="standard"
                              sx={{
                                '& .MuiInput-underline:before': {
                                  borderBottomColor: '#8D8D8D',
                                  borderWidth: '2px',
                                  borderBottomStyle: 'solid' // Replace with your desired color
                                },
                                '& .MuiInput-underline:after': {
                                  borderBottomColor: '#8D8D8D',
                                  borderWidth: '2px',
                                  borderBottomStyle: 'solid' // Replace with your desired color
                                },
                              }}
                            />
                            <TextField
                              InputLabelProps={{ style: { color: '#8D8D8D', fontFamily: 'Ubuntu', fontSize: '15px' } }}
                              margin="normal"
                              required
                              label="Amort"
                              type="number"
                              defaultValue={amort}
                              disabled={amortDisableFlag}
                              // onChange={(e) => (setAmort(e.target.value))}
                              onChange={(e) => { handleChangeAmort(e.target.value) }}
                              size="small"
                              variant="standard"
                              sx={{
                                '& .MuiInput-underline:before': {
                                  borderBottomColor: '#8D8D8D',
                                  borderWidth: '2px',
                                  borderBottomStyle: 'solid' // Replace with your desired color
                                },
                                '& .MuiInput-underline:after': {
                                  borderBottomColor: '#8D8D8D',
                                  borderWidth: '2px',
                                  borderBottomStyle: 'solid' // Replace with your desired color
                                },
                              }}
                            />
                          </Grid>
                          <Grid item xs={1.2}>
                            <TextField
                              InputLabelProps={{ style: { color: '#8D8D8D', fontFamily: 'Ubuntu', fontSize: '15px' } }}
                              margin="normal"
                              required
                              label="IO Term (months)"
                              type="number"
                              defaultValue={ioTerm}
                              disabled={ioTermDisableFlag}
                              // onChange={(e) => (setIoTerm(e.target.value))}
                              onChange={(e) => { handleChangeIoTerm(e.target.value) }}
                              size="small"
                              variant="standard"
                              sx={{
                                '& .MuiInput-underline:before': {
                                  borderBottomColor: '#8D8D8D',
                                  borderWidth: '2px',
                                  borderBottomStyle: 'solid' // Replace with your desired color
                                },
                                '& .MuiInput-underline:after': {
                                  borderBottomColor: '#8D8D8D',
                                  borderWidth: '2px',
                                  borderBottomStyle: 'solid' // Replace with your desired color
                                },
                              }}
                            />
                            <TextField
                              InputLabelProps={{ style: { color: '#8D8D8D', fontFamily: 'Ubuntu', fontSize: '15px' } }}
                              margin="normal"
                              required
                              inputProps={{ maxLength: 4 }}
                              label="Pass-thru (%)"
                              type="number"
                              defaultValue={passThru}
                              disabled={passThruDisableFlag}
                              // onChange={(e) => (setPassThru(e.target.value))}
                              onChange={(e) => { handleChangePassThru(e.target.value) }}
                              size="small"
                              variant="standard"
                              sx={{
                                '& .MuiInput-underline:before': {
                                  borderBottomColor: '#8D8D8D',
                                  borderWidth: '2px',
                                  borderBottomStyle: 'solid' // Replace with your desired color
                                },
                                '& .MuiInput-underline:after': {
                                  borderBottomColor: '#8D8D8D',
                                  borderWidth: '2px',
                                  borderBottomStyle: 'solid' // Replace with your desired color
                                },
                              }}
                            />
                            <TextField
                              InputLabelProps={{ style: { color: '#8D8D8D', fontFamily: 'Ubuntu', fontSize: '15px' } }}
                              margin="normal"
                              required
                              label="S&G (%)"
                              type="number"
                              defaultValue={sAndG}
                              disabled={sAndGDisableFlag}
                              // onChange={(e) => (setSAndG(e.target.value))}
                              onChange={(e) => { handleChangeSAndG(e.target.value) }}
                              size="small"
                              variant="standard"
                              sx={{
                                '& .MuiInput-underline:before': {
                                  borderBottomColor: '#8D8D8D',
                                  borderWidth: '2px',
                                  borderBottomStyle: 'solid' // Replace with your desired color
                                },
                                '& .MuiInput-underline:after': {
                                  borderBottomColor: '#8D8D8D',
                                  borderWidth: '2px',
                                  borderBottomStyle: 'solid' // Replace with your desired color
                                },
                              }}
                            />
                          </Grid>
                          <Grid item xs={1.2}>
                            <TextField
                              InputLabelProps={{ style: { color: '#8D8D8D', fontFamily: 'Ubuntu', fontSize: '15px' } }}
                              margin="normal"
                              label="Override"
                              required
                              defaultValue={override}
                              disabled={overrideDisableFlag}
                              // onChange={(e) => (setOverride(e.target.value))}
                              onChange={(e) => { handleChangeOverride(e.target.value) }}
                              size="small"
                              variant="standard"
                              sx={{
                                '& .MuiInput-underline:before': {
                                  borderBottomColor: '#8D8D8D',
                                  borderWidth: '2px',
                                  borderBottomStyle: 'solid' // Replace with your desired color
                                },
                                '& .MuiInput-underline:after': {
                                  borderBottomColor: '#8D8D8D',
                                  borderWidth: '2px',
                                  borderBottomStyle: 'solid' // Replace with your desired color
                                },
                              }}
                            />
                            <TextField
                              InputLabelProps={{ style: { color: '#8D8D8D', fontFamily: 'Ubuntu', fontSize: '15px' } }}
                              margin="normal"
                              label="J 10"
                              required
                              defaultValue={jTen}
                              disabled={jTenDisableFlag}
                              // onChange={(e) => (setJTen(e.target.value))}
                              onChange={(e) => { handleChangeJTen(e.target.value) }}
                              size="small"
                              variant="standard"
                              sx={{
                                '& .MuiInput-underline:before': {
                                  borderBottomColor: '#8D8D8D',
                                  borderWidth: '2px',
                                  borderBottomStyle: 'solid' // Replace with your desired color
                                },
                                '& .MuiInput-underline:after': {
                                  borderBottomColor: '#8D8D8D',
                                  borderWidth: '2px',
                                  borderBottomStyle: 'solid' // Replace with your desired color
                                },
                              }}
                            />
                          </Grid>
                          <div style={{ width: '1px', height: '200px', backgroundColor: '#C6C6C6', }}></div>
                          <Grid item xs={1.2}>
                            <TextField
                              InputLabelProps={{ style: { color: '#8D8D8D', fontFamily: 'Ubuntu', fontSize: '15px' } }}
                              margin="normal"
                              // sx={{ input: { background: "lightgray" } }}
                              disabled
                              label="Settlement Date"
                              defaultValue={date}
                              size="small"
                              variant='standard'
                              sx={{
                                '& .MuiInput-underline:before': {
                                  borderBottomColor: '#8D8D8D',
                                  borderWidth: '2px',
                                  borderBottomStyle: 'solid' // Replace with your desired color
                                },
                                '& .MuiInput-underline:after': {
                                  borderBottomColor: '#8D8D8D',
                                  borderWidth: '2px',
                                  borderBottomStyle: 'solid' // Replace with your desired color
                                },
                              }}
                            />
                            <TextField
                              InputLabelProps={{ style: { color: '#8D8D8D', fontFamily: 'Ubuntu', fontSize: '15px' } }}
                              margin="normal"
                              // sx={{ input: { background: "lightgray" } }}
                              disabled
                              label="Coupon (%)"
                              value={couponState}
                              size="small"
                              variant='standard'
                              sx={{
                                '& .MuiInput-underline:before': {
                                  borderBottomColor: '#8D8D8D',
                                  borderWidth: '2px',
                                  borderBottomStyle: 'solid' // Replace with your desired color
                                },
                                '& .MuiInput-underline:after': {
                                  borderBottomColor: '#8D8D8D',
                                  borderWidth: '2px',
                                  borderBottomStyle: 'solid' // Replace with your desired color
                                },
                              }}
                            />
                            <TextField
                              InputLabelProps={{ style: { color: '#8D8D8D', fontFamily: 'Ubuntu', fontSize: '15px' } }}
                              margin="normal"
                              // sx={{ input: { background: "lightgray" } }}
                              disabled
                              label="Accrual"
                              defaultValue={accrual}
                              size="small"
                              variant='standard'
                              sx={{
                                '& .MuiInput-underline:before': {
                                  borderBottomColor: '#8D8D8D',
                                  borderWidth: '2px',
                                  borderBottomStyle: 'solid' // Replace with your desired color
                                },
                                '& .MuiInput-underline:after': {
                                  borderBottomColor: '#8D8D8D',
                                  borderWidth: '2px',
                                  borderBottomStyle: 'solid' // Replace with your desired color
                                },
                              }}
                            />
                          </Grid>
                          <Grid item xs={1.2}>
                            <TextField
                              InputLabelProps={{ style: { color: '#8D8D8D', fontFamily: 'Ubuntu', fontSize: '15px' } }}
                              margin="normal"
                              // sx={{ input: { background: "lightgray" } }}
                              disabled
                              label="Accured Days"
                              defaultValue={30}
                              size="small"
                              variant='standard'
                              sx={{
                                '& .MuiInput-underline:before': {
                                  borderBottomColor: '#8D8D8D',
                                  borderWidth: '2px',
                                  borderBottomStyle: 'solid' // Replace with your desired color
                                },
                                '& .MuiInput-underline:after': {
                                  borderBottomColor: '#8D8D8D',
                                  borderWidth: '2px',
                                  borderBottomStyle: 'solid' // Replace with your desired color
                                },
                              }}
                            />
                            <TextField
                              InputLabelProps={{ style: { color: '#8D8D8D', fontFamily: 'Ubuntu', fontSize: '20px' } }}
                              margin="normal"
                              // sx={{ input: { background: "lightgray" } }}
                              disabled
                              label="Delay Days"
                              value={delayedDaysState}
                              // onChange={delayedDays}
                              size="small"
                              variant='standard'
                              sx={{
                                '& .MuiInput-underline:before': {
                                  borderBottomColor: '#8D8D8D',
                                  borderWidth: '2px',
                                  borderBottomStyle: 'solid' // Replace with your desired color
                                },
                                '& .MuiInput-underline:after': {
                                  borderBottomColor: '#8D8D8D',
                                  borderWidth: '2px',
                                  borderBottomStyle: 'solid' // Replace with your desired color
                                },
                              }}
                            />
                          </Grid>
                        </Box>
                        {/* <Box sx={{display:'flex',gap:'100px',boxShadow:12,borderRadius:'20px',width:'80%',marginTop:'80px',paddingLeft:'30px'}}>
                        <Grid item xs={1.2}>
                          <TextField
                             InputLabelProps={{ style: { color: '#8D8D8D',fontFamily:'Ubuntu',fontSize:'15px' } }}
                            margin="normal"
                            // sx={{ input: { background: "yellow" } }}
                            disabled
                            label="WAL"
                            value={walState}
                            size="small"
                             variant='standard'
                            sx={{
                                '& .MuiInput-underline': {
                                       borderBottomColor: '#8D8D8D',
                                       borderWidth:'2px',
                                       borderBottomStyle:'solid' // Replace with your desired color
                                       },
                              }}  
                          />
                          <br />
                          <br />
                          <TextField
                            InputLabelProps={{ style: { color: '#8D8D8D',fontFamily:'Ubuntu',fontSize:'15px' } }}
                            margin="normal"
                            // sx={{ input: { background: "yellow" } }}
                            disabled
                            label="Mac. Dur"
                            value={macDurState.toFixed(2)}
                            size="small"
                             variant='standard'
                            sx={{
                                '& .MuiInput-underline': {
                                       borderBottomColor: '#8D8D8D',
                                       borderWidth:'2px',
                                       borderBottomStyle:'solid' // Replace with your desired color
                                       },
                              }} 
                          />
                          <br />
                          <br />
                          <TextField
                             InputLabelProps={{ style: { color: '#8D8D8D',fontFamily:'Ubuntu',fontSize:'15px' } }}
                            margin="normal"
                            // sx={{ input: { background: "yellow" } }}
                            disabled
                            label="Mod Dur"
                            value={modDurState.toFixed(2)}
                            size="small"
                             variant='standard'
                            sx={{
                                '& .MuiInput-underline': {
                                       borderBottomColor: '#8D8D8D',
                                       borderWidth:'2px',
                                       borderBottomStyle:'solid' // Replace with your desired color
                                       },
                              }} 
                          />
                        </Grid>
                        <Grid item xs={1.2}>
                          <TextField
                             InputLabelProps={{ style: { color: '#8D8D8D',fontFamily:'Ubuntu',fontSize:'15px' } }}
                            margin="normal"
                            // sx={{ input: { background: "yellow" } }}
                            disabled
                            label="Half Life"
                            value={halfLifeState.toFixed(2)}
                            size="small"
                             variant='standard'
                            sx={{
                                '& .MuiInput-underline': {
                                       borderBottomColor: '#8D8D8D',
                                       borderWidth:'2px',
                                       borderBottomStyle:'solid' // Replace with your desired color
                                       },
                              }} 
                          />
                          <br />
                          <br />
                          <TextField
                             InputLabelProps={{ style: { color: '#8D8D8D',fontFamily:'Ubuntu',fontSize:'15px' } }}
                            margin="normal"
                            // sx={{ input: { background: "yellow" } }}
                            disabled
                            label="Int Tsy (%)"
                            value={intTSYState}
                            size="small"
                             variant='standard'
                            sx={{
                                '& .MuiInput-underline': {
                                       borderBottomColor: '#8D8D8D',
                                       borderWidth:'2px',
                                       borderBottomStyle:'solid' // Replace with your desired color
                                       },
                              }} 
                          />
                          <br />
                          <br />
                          <TextField
                             InputLabelProps={{ style: { color: '#8D8D8D',fontFamily:'Ubuntu',fontSize:'15px' } }}
                            margin="normal"
                            // sx={{ input: { background: "yellow" } }}
                            disabled
                            label="Int SOFR (%)"
                            value={intSOFRState + "%"}
                            size="small"
                             variant='standard'
                            sx={{
                                '& .MuiInput-underline': {
                                       borderBottomColor: '#8D8D8D',
                                       borderWidth:'2px',
                                       borderBottomStyle:'solid' // Replace with your desired color
                                       },
                              }} 
                          />
                        </Grid>
                        <div style={{width:'1px' ,height:'200px',backgroundColor:'#C6C6C6',marginTop:'10px'}}></div>
                        <Grid item xs={1.2}>
                          <TextField
                             InputLabelProps={{ style: { color: '#8D8D8D',fontFamily:'Ubuntu',fontSize:'15px' } }}
                            margin="normal"
                            // sx={{ input: { background: "lightblue" } }}
                            disabled
                            label="Yield (%)"
                            value={myYieldState.toFixed(2) + "%"}
                            size="normal"
                             variant='standard'
                            sx={{
                                '& .MuiInput-underline': {
                                       borderBottomColor: '#8D8D8D',
                                       borderWidth:'2px',
                                       borderBottomStyle:'solid' // Replace with your desired color
                                       },
                              }} 
                          />
                          <br />
                          <br />
                          <TextField
                             InputLabelProps={{ style: { color: '#8D8D8D',fontFamily:'Ubuntu',fontSize:'15px' } }}
                            margin="normal"
                            // sx={{ input: { background: "lightblue" } }}
                            disabled
                            label="MEY (%)"
                            value={MEYState + "%"}
                            size="small"
                             variant='standard'
                            sx={{
                                '& .MuiInput-underline': {
                                       borderBottomColor: '#8D8D8D',
                                       borderWidth:'2px',
                                       borderBottomStyle:'solid' // Replace with your desired color
                                       },
                              }} 
                          />
                          <br />
                          <br />
                          <TextField
                             InputLabelProps={{ style: { color: '#8D8D8D',fontFamily:'Ubuntu',fontSize:'15px' } }}
                            margin="normal"
                            // sx={{ input: { background: "lightblue" } }}
                            disabled
                            label="Price"
                            value={priceState}
                            size="small"
                             variant='standard'
                            sx={{
                                '& .MuiInput-underline': {
                                       borderBottomColor: '#8D8D8D',
                                       borderWidth:'2px',
                                       borderBottomStyle:'solid' // Replace with your desired color
                                       },
                              }} 
                          />
                        </Grid>
                        <Grid item xs={1.2}>
                          <TextField
                             InputLabelProps={{ style: { color: '#8D8D8D',fontFamily:'Ubuntu',fontSize:'15px' } }}
                            margin="normal"
                            // sx={{ input: { background: "lightblue" } }}
                            disabled
                            label="PandI"
                            value={pAndIState.toFixed(2)}
                            size="small"
                             variant='standard'
                            sx={{
                                '& .MuiInput-underline': {
                                       borderBottomColor: '#8D8D8D',
                                       borderWidth:'2px',
                                       borderBottomStyle:'solid' // Replace with your desired color
                                       },
                              }} 
                          />
                          <br />
                          <br />
                          <TextField
                            InputLabelProps={{ style: { color: '#8D8D8D',fontFamily:'Ubuntu',fontSize:'15px' } }}
                            margin="normal"
                            // sx={{ input: { background: "lightblue" } }}
                            disabled
                            label="Acc Int"
                            value={accIntState}
                            size="small"
                             variant='standard'
                            sx={{
                                '& .MuiInput-underline': {
                                       borderBottomColor: '#8D8D8D',
                                       borderWidth:'2px',
                                       borderBottomStyle:'solid' // Replace with your desired color
                                       },
                              }} 
                          />
                        </Grid>
                        </Box> */}
                      </Stack>
                    </CardContent>
                  </Card>
                  <Box sx={{ display: 'flex', gap: '100px', boxShadow: 12, borderRadius: '20px', width: '90%', marginTop: '80px', paddingLeft: '30px', background: 'white' }}>
                    <Grid item xs={1.2}>
                      <TextField
                        InputLabelProps={{ style: { color: '#8D8D8D', fontFamily: 'Ubuntu', fontSize: '15px' } }}
                        margin="normal"
                        // sx={{ input: { background: "yellow" } }}
                        disabled
                        label="WAL"
                        value={walState}
                        size="small"
                        variant='standard'
                        sx={{
                          '& .MuiInput-underline:before': {
                            borderBottomColor: '#8D8D8D',
                            borderWidth: '2px',
                            borderBottomStyle: 'solid' // Replace with your desired color
                          },
                          '& .MuiInput-underline:after': {
                            borderBottomColor: '#8D8D8D',
                            borderWidth: '2px',
                            borderBottomStyle: 'solid' // Replace with your desired color
                          },
                        }}
                      />
                      <br />
                      <br />
                      <TextField
                        InputLabelProps={{ style: { color: '#8D8D8D', fontFamily: 'Ubuntu', fontSize: '15px' } }}
                        margin="normal"
                        // sx={{ input: { background: "yellow" } }}
                        disabled
                        label="Mac. Dur"
                        value={macDurState.toFixed(2)}
                        size="small"
                        variant='standard'
                        sx={{
                          '& .MuiInput-underline:before': {
                            borderBottomColor: '#8D8D8D',
                            borderWidth: '2px',
                            borderBottomStyle: 'solid' // Replace with your desired color
                          },
                          '& .MuiInput-underline:after': {
                            borderBottomColor: '#8D8D8D',
                            borderWidth: '2px',
                            borderBottomStyle: 'solid' // Replace with your desired color
                          },
                        }}
                      />
                      <br />
                      <br />
                      <TextField
                        InputLabelProps={{ style: { color: '#8D8D8D', fontFamily: 'Ubuntu', fontSize: '15px' } }}
                        margin="normal"
                        // sx={{ input: { background: "yellow" } }}
                        disabled
                        label="Mod Dur"
                        value={modDurState.toFixed(2)}
                        size="small"
                        variant='standard'
                        sx={{
                          '& .MuiInput-underline:before': {
                            borderBottomColor: '#8D8D8D',
                            borderWidth: '2px',
                            borderBottomStyle: 'solid' // Replace with your desired color
                          },
                          '& .MuiInput-underline:after': {
                            borderBottomColor: '#8D8D8D',
                            borderWidth: '2px',
                            borderBottomStyle: 'solid' // Replace with your desired color
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={1.2}>
                      <TextField
                        InputLabelProps={{ style: { color: '#8D8D8D', fontFamily: 'Ubuntu', fontSize: '15px' } }}
                        margin="normal"
                        // sx={{ input: { background: "yellow" } }}
                        disabled
                        label="Half Life"
                        value={halfLifeState.toFixed(2)}
                        size="small"
                        variant='standard'
                        sx={{
                          '& .MuiInput-underline:before': {
                            borderBottomColor: '#8D8D8D',
                            borderWidth: '2px',
                            borderBottomStyle: 'solid' // Replace with your desired color
                          },
                          '& .MuiInput-underline:after': {
                            borderBottomColor: '#8D8D8D',
                            borderWidth: '2px',
                            borderBottomStyle: 'solid' // Replace with your desired color
                          },
                        }}
                      />
                      <br />
                      <br />
                      <TextField
                        InputLabelProps={{ style: { color: '#8D8D8D', fontFamily: 'Ubuntu', fontSize: '15px' } }}
                        margin="normal"
                        // sx={{ input: { background: "yellow" } }}
                        disabled
                        label="Int Tsy (%)"
                        value={intTSYState}
                        size="small"
                        variant='standard'
                        sx={{
                          '& .MuiInput-underline:before': {
                            borderBottomColor: '#8D8D8D',
                            borderWidth: '2px',
                            borderBottomStyle: 'solid' // Replace with your desired color
                          },
                          '& .MuiInput-underline:after': {
                            borderBottomColor: '#8D8D8D',
                            borderWidth: '2px',
                            borderBottomStyle: 'solid' // Replace with your desired color
                          },
                        }}
                      />
                      <br />
                      <br />
                      <TextField
                        InputLabelProps={{ style: { color: '#8D8D8D', fontFamily: 'Ubuntu', fontSize: '15px' } }}
                        margin="normal"
                        // sx={{ input: { background: "yellow" } }}
                        disabled
                        label="Int SOFR (%)"
                        value={intSOFRState + "%"}
                        size="small"
                        variant='standard'
                        sx={{
                          '& .MuiInput-underline:before': {
                            borderBottomColor: '#8D8D8D',
                            borderWidth: '2px',
                            borderBottomStyle: 'solid' // Replace with your desired color
                          },
                          '& .MuiInput-underline:after': {
                            borderBottomColor: '#8D8D8D',
                            borderWidth: '2px',
                            borderBottomStyle: 'solid' // Replace with your desired color
                          },
                        }}
                      />
                    </Grid>
                    <div style={{ width: '1px', height: '260px', backgroundColor: '#C6C6C6', marginTop: '10px' }}></div>
                    <Grid item xs={1.2}>
                      <TextField
                        InputLabelProps={{ style: { color: '#8D8D8D', fontFamily: 'Ubuntu', fontSize: '15px' } }}
                        margin="normal"
                        // sx={{ input: { background: "lightblue" } }}
                        disabled
                        label="Yield (%)"
                        value={myYieldState.toFixed(2) + "%"}
                        size="normal"
                        variant='standard'
                        sx={{
                          '& .MuiInput-underline:before': {
                            borderBottomColor: '#8D8D8D',
                            borderWidth: '2px',
                            borderBottomStyle: 'solid' // Replace with your desired color
                          },
                          '& .MuiInput-underline:after': {
                            borderBottomColor: '#8D8D8D',
                            borderWidth: '2px',
                            borderBottomStyle: 'solid' // Replace with your desired color
                          },
                        }}
                      />
                      <br />
                      <br />
                      <TextField
                        InputLabelProps={{ style: { color: '#8D8D8D', fontFamily: 'Ubuntu', fontSize: '15px' } }}
                        margin="normal"
                        // sx={{ input: { background: "lightblue" } }}
                        disabled
                        label="MEY (%)"
                        value={MEYState + "%"}
                        size="small"
                        variant='standard'
                        sx={{
                          '& .MuiInput-underline:before': {
                            borderBottomColor: '#8D8D8D',
                            borderWidth: '2px',
                            borderBottomStyle: 'solid' // Replace with your desired color
                          },
                          '& .MuiInput-underline:after': {
                            borderBottomColor: '#8D8D8D',
                            borderWidth: '2px',
                            borderBottomStyle: 'solid' // Replace with your desired color
                          },
                        }}
                      />
                      <br />
                      <br />
                      <TextField
                        InputLabelProps={{ style: { color: '#8D8D8D', fontFamily: 'Ubuntu', fontSize: '15px' } }}
                        margin="normal"
                        // sx={{ input: { background: "lightblue" } }}
                        disabled
                        label="Price"
                        value={priceState}
                        size="small"
                        variant='standard'
                        sx={{
                          '& .MuiInput-underline:before': {
                            borderBottomColor: '#8D8D8D',
                            borderWidth: '2px',
                            borderBottomStyle: 'solid' // Replace with your desired color
                          },
                          '& .MuiInput-underline:after': {
                            borderBottomColor: '#8D8D8D',
                            borderWidth: '2px',
                            borderBottomStyle: 'solid' // Replace with your desired color
                          },
                        }}
                      />
                      <br />
                      <br />
                    </Grid>
                    <Grid item xs={1.2}>
                      <TextField
                        InputLabelProps={{ style: { color: '#8D8D8D', fontFamily: 'Ubuntu', fontSize: '15px' } }}
                        margin="normal"
                        // sx={{ input: { background: "lightblue" } }}
                        disabled
                        label="PandI"
                        value={pAndIState.toFixed(2)}
                        size="small"
                        variant='standard'
                        sx={{
                          '& .MuiInput-underline:before': {
                            borderBottomColor: '#8D8D8D',
                            borderWidth: '2px',
                            borderBottomStyle: 'solid' // Replace with your desired color
                          },
                          '& .MuiInput-underline:after': {
                            borderBottomColor: '#8D8D8D',
                            borderWidth: '2px',
                            borderBottomStyle: 'solid' // Replace with your desired color
                          },
                        }}
                      />
                      <br />
                      <br />
                      <TextField
                        InputLabelProps={{ style: { color: '#8D8D8D', fontFamily: 'Ubuntu', fontSize: '15px' } }}
                        margin="normal"
                        // sx={{ input: { background: "lightblue" } }}
                        disabled
                        label="Acc Int"
                        value={accIntState}
                        size="small"
                        variant='standard'
                        sx={{
                          '& .MuiInput-underline:before': {
                            borderBottomColor: '#8D8D8D',
                            borderWidth: '2px',
                            borderBottomStyle: 'solid' // Replace with your desired color
                          },
                          '& .MuiInput-underline:after': {
                            borderBottomColor: '#8D8D8D',
                            borderWidth: '2px',
                            borderBottomStyle: 'solid' // Replace with your desired color
                          },
                        }}
                      />
                    </Grid>
                  </Box>
                  <Card sx={{ boxShadow: 12, borderRadius: '20px', mt: 5, mb: 5, pl: 5, width: "90%" }}>
                    <CardHeader
                      title="Amort Table Header"
                      titleTypographyProps={{ fontFamily: 'Ubuntu', fontWeight: '500', mt: 3 }}
                    />
                    <hr style={{ width: '92%', marginLeft: '10px' }} />
                    <CardContent>
                      <Stack spacing={10} direction={"row"}>
                        <Grid item xs={1.2}>
                          <TextField
                            InputLabelProps={{ style: { color: '#8D8D8D', fontFamily: 'Ubuntu', fontSize: '15px' } }}
                            margin="normal"
                            label="BEY + 1bp (%)"
                            required
                            value={beyOneBpState}
                            size="small"
                            disabled
                            variant="standard"
                            sx={{
                              '& .MuiInput-underline:before': {
                                borderBottomColor: '#8D8D8D',
                                borderWidth: '2px',
                                borderBottomStyle: 'solid' // Replace with your desired color
                              },
                              '& .MuiInput-underline:after': {
                                borderBottomColor: '#8D8D8D',
                                borderWidth: '2px',
                                borderBottomStyle: 'solid' // Replace with your desired color
                              },
                            }}
                          />
                          <TextField
                            InputLabelProps={{ style: { color: '#8D8D8D', fontFamily: 'Ubuntu', fontSize: '15px' } }}
                            margin="normal"
                            required
                            label="MEY2 (%)"
                            value={MEYTwoState}
                            size="small"
                            disabled
                            variant="standard"
                            sx={{
                              '& .MuiInput-underline:before': {
                                borderBottomColor: '#8D8D8D',
                                borderWidth: '2px',
                                borderBottomStyle: 'solid' // Replace with your desired color
                              },
                              '& .MuiInput-underline:after': {
                                borderBottomColor: '#8D8D8D',
                                borderWidth: '2px',
                                borderBottomStyle: 'solid' // Replace with your desired color
                              },
                            }}
                          />
                        </Grid>
                        <Grid item xs={1.2}>
                          <TextField
                            InputLabelProps={{ style: { color: '#8D8D8D', fontFamily: 'Ubuntu', fontSize: '15px' } }}
                            margin="normal"
                            label="Total Principal Sum ($)"
                            required
                            value={sumOfTotalPrincipalState.toFixed(0)}
                            size="small"
                            disabled
                            variant="standard"
                            sx={{
                              '& .MuiInput-underline:before': {
                                borderBottomColor: '#8D8D8D',
                                borderWidth: '2px',
                                borderBottomStyle: 'solid' // Replace with your desired color
                              },
                              '& .MuiInput-underline:after': {
                                borderBottomColor: '#8D8D8D',
                                borderWidth: '2px',
                                borderBottomStyle: 'solid' // Replace with your desired color
                              },
                            }}
                          />
                          <TextField
                            InputLabelProps={{ style: { color: '#8D8D8D', fontFamily: 'Ubuntu', fontSize: '15px' } }}
                            margin="normal"
                            label="Total Cash Flow Sum"
                            required
                            value={sumOfTotalCashFlowState.toFixed(2)}
                            size="small"
                            disabled
                            variant="standard"
                            sx={{
                              '& .MuiInput-underline:before': {
                                borderBottomColor: '#8D8D8D',
                                borderWidth: '2px',
                                borderBottomStyle: 'solid' // Replace with your desired color
                              },
                              '& .MuiInput-underline:after': {
                                borderBottomColor: '#8D8D8D',
                                borderWidth: '2px',
                                borderBottomStyle: 'solid' // Replace with your desired color
                              },
                            }}
                          />
                        </Grid>
                        <Grid item xs={1.2}>
                          <TextField
                            InputLabelProps={{ style: { color: '#8D8D8D', fontFamily: 'Ubuntu', fontSize: '15px' } }}
                            margin="normal"
                            label="Gross Interest Sum"
                            required
                            value={sumOfGrossInterestState}
                            size="small"
                            disabled
                            variant="standard"
                            sx={{
                              '& .MuiInput-underline:before': {
                                borderBottomColor: '#8D8D8D',
                                borderWidth: '2px',
                                borderBottomStyle: 'solid' // Replace with your desired color
                              },
                              '& .MuiInput-underline:after': {
                                borderBottomColor: '#8D8D8D',
                                borderWidth: '2px',
                                borderBottomStyle: 'solid' // Replace with your desired color
                              },
                            }}
                          />
                          <TextField
                            InputLabelProps={{ style: { color: '#8D8D8D', fontFamily: 'Ubuntu', fontSize: '15px' } }}
                            margin="normal"
                            label="Servicing Sum"
                            required
                            value={sumOfServicingState}
                            size="small"
                            disabled
                            variant="standard"
                            sx={{
                              '& .MuiInput-underline:before': {
                                borderBottomColor: '#8D8D8D',
                                borderWidth: '2px',
                                borderBottomStyle: 'solid' // Replace with your desired color
                              },
                              '& .MuiInput-underline:after': {
                                borderBottomColor: '#8D8D8D',
                                borderWidth: '2px',
                                borderBottomStyle: 'solid' // Replace with your desired color
                              },
                            }}
                          />
                        </Grid>
                        <Grid item xs={1.2}>
                          <TextField
                            InputLabelProps={{ style: { color: '#8D8D8D', fontFamily: 'Ubuntu', fontSize: '15px' } }}
                            margin="normal"
                            label="Net Interest Sum"
                            required
                            value={sumOfNetInterestState}
                            size="small"
                            disabled
                            variant="standard"
                            sx={{
                              '& .MuiInput-underline:before': {
                                borderBottomColor: '#8D8D8D',
                                borderWidth: '2px',
                                borderBottomStyle: 'solid' // Replace with your desired color
                              },
                              '& .MuiInput-underline:after': {
                                borderBottomColor: '#8D8D8D',
                                borderWidth: '2px',
                                borderBottomStyle: 'solid' // Replace with your desired color
                              },
                            }}
                          />

                          <TextField
                            InputLabelProps={{ style: { color: '#8D8D8D', fontFamily: 'Ubuntu', fontSize: '15px' } }}
                            margin="normal"
                            label="Schedule Sum"
                            required
                            value={sumOfScheduleState}
                            size="small"
                            disabled
                            variant="standard"
                            sx={{
                              '& .MuiInput-underline:before': {
                                borderBottomColor: '#8D8D8D',
                                borderWidth: '2px',
                                borderBottomStyle: 'solid' // Replace with your desired color
                              },
                              '& .MuiInput-underline:after': {
                                borderBottomColor: '#8D8D8D',
                                borderWidth: '2px',
                                borderBottomStyle: 'solid' // Replace with your desired color
                              },
                            }}
                          />
                        </Grid>
                        <Grid item xs={1.2}>
                          <TextField
                            InputLabelProps={{ style: { color: '#8D8D8D', fontFamily: 'Ubuntu', fontSize: '15px' } }}
                            margin="normal"
                            label="Prepayments Sum"
                            required
                            value={sumOfPrepaymentsState}
                            size="small"
                            disabled
                            variant="standard"
                            sx={{
                              '& .MuiInput-underline:before': {
                                borderBottomColor: '#8D8D8D',
                                borderWidth: '2px',
                                borderBottomStyle: 'solid' // Replace with your desired color
                              },
                              '& .MuiInput-underline:after': {
                                borderBottomColor: '#8D8D8D',
                                borderWidth: '2px',
                                borderBottomStyle: 'solid' // Replace with your desired color
                              },
                            }}
                          />
                          {/* <TextField
                            margin="normal"
                            label="Total Cash Flow Sum"
                            required
                            value={sumOfTotalCashFlowState.toFixed(2)}
                            size="small"
                            disabled
                          /> */}
                        </Grid>
                      </Stack>
                    </CardContent>
                  </Card>
                  <Card sx={{ boxShadow: 12, borderRadius: '20px', mt: 5, mb: 5, pl: 5, width: "90%" }}>
                    <CardHeader
                      title="Amort Table"
                      titleTypographyProps={{ fontFamily: 'Ubuntu', fontWeight: '500', mt: 3 }}
                    />
                    <hr style={{ width: '95%', marginLeft: '10px' }} />
                    <CardContent>
                      <Paper sx={{ width: '100%', overflow: 'hidden', borderRadius: '20px', border: '1px solid #B8B8B8' }}>
                        <TableContainer sx={{ maxHeight: 700, maxWidth: 1300, }}>
                          <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                              <TableRow sx={{
                                "& .MuiTableCell-head": {
                                  color: "black",
                                  backgroundColor: "#EBF5FF"
                                },
                              }}>
                                {columnsState.map((column, index) => (
                                  <TableCell
                                    key={index}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth }}
                                  >
                                    {column.label}
                                  </TableCell>
                                ))}
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {
                                rowsState.length ?
                                  rowsState.map((row, index) => {
                                    return (
                                      <TableRow hover role="checkbox" tabIndex={-1} key={index} sx={{
                                        "& .MuiTableCell-body": {
                                          color: "black",
                                          backgroundColor: "#EEEEEE"
                                        },
                                      }}>
                                        {columnsState.map((column, dindex) => {
                                          const value = row[column.id];
                                          return (
                                            <TableCell align={column.align} key={dindex}>
                                              {column.format && typeof value === 'number'
                                                ? column.format(value)
                                                : value}
                                            </TableCell>
                                          );
                                        })}
                                      </TableRow>
                                    );
                                  }) :
                                  <></>

                              }
                            </TableBody>
                          </Table>
                        </TableContainer>
                        {/* <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={rows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      /> */}
                      </Paper>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </div>
      }


    </>
  )
}
