import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Navbar from "../components/Navbar";
import Sidenav from "../components/Sidenav";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import {
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Axios from "axios";
import ReactJsAlert from "reactjs-alert";

const Adduser = () => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [mudziePlan, setMudziePlan] = useState("");
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showerror, setshowerror] = useState(false);
  const [is_active_plan, setactive] = useState(1);
  const [is_call_requested, setcall] = useState(0);
  const [is_approved_for_subscribe, setsubscribe] = useState(1);
  const [alertStatus, setAlertStatus] = React.useState(false);
  const [type, setType] = React.useState("success");
  const [title, setTitle] = React.useState("");

  const handleChange = (event) => {
    setMudziePlan(event.target.value);
  };

  const onSubmit = async (data, event) => {
    console.log(data);
    const newData = {
      ...data, // Spread the existing data fields
      is_active_plan: is_active_plan,
      is_call_requested: is_call_requested,
      is_approved_for_subscribe: is_approved_for_subscribe, // Add the new field
    };
    console.log("4444444444", newData);
    const user = localStorage.getItem("user");
    const userde = JSON.parse(user);
    const token = userde.token;
    await Axios.post(
      `${process.env.REACT_APP_BASE_URL}api/admin/add`,
      newData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((res) => {
        if (res.data.response === 1) {
          console.log("user create successfully");
          setAlertStatus(true);
          setType("success");
          setTitle("User Add Successfully");
          event.target.reset();
          setMudziePlan("");
        }
      })
      .catch((error) => {
        console.error(error);
        // setAlertMessage(true);
        setAlertStatus(true);
        setType("error");
        setTitle("User Not Add Successfully");
        event.target.reset();
        setMudziePlan("");
      });
    console.log(data);
  };

  useEffect(() => {
    if (showConfirmation) {
      const timer = setTimeout(() => {
        setShowConfirmation(false);
      }, 1000); // 2 seconds (2000 milliseconds)

      return () => {
        clearTimeout(timer); // Clear the timeout if the component unmounts or showConfirmation becomes false
      };
    }
    if (showerror) {
      const timer = setTimeout(() => {
        setshowerror(false);
      }, 1000); // 2 seconds (2000 milliseconds)

      return () => {
        clearTimeout(timer); // Clear the timeout if the component unmounts or showConfirmation becomes false
      };
    }
  }, [showConfirmation, showerror]);

  const currentDate = new Date();
  const formattedCurrentDate = currentDate.toISOString().split("T")[0];

  return (
    <div>
      <Navbar />
      <Box height={70} />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center", // Center horizontally
          justifyContent: "center", // Center vertically
          minHeight: "100vh",
          width: "100%", // Set a minimum height to center vertically
        }}
      >
        <Sidenav menuHeighlightPath = "/dashboard/adduser" />
        <Box sx={{ display: "flex", alignItems: "center", gap: "20px" }}>
          <PersonAddIcon />
          <Typography
            sx={{ fontSize: "20px", fontFamily: "Ubuntu", fontWeight: "bold" }}
          >
            Add User
          </Typography>
        </Box>
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          noValidate
          maxWidth={555}
          //   width={{ sm: 555, xs: 250 }}
        >
          <Grid
            container
            spacing={2}
            sx={{
              mt: { xs: 1, sm: 0 },
              pl: { xs: 10, sm: 0 },
              pr: { xs: 5, sm: 0 },
            }}
          >
            <Grid item xs={12} sm={6}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="name"
                label="Name"
                name="name"
                {...register("name", { required: "Please enter name." })}
                error={Boolean(errors.name)}
                helperText={errors.name?.message}
                //inputProps={{ maxLength: 250 }}
                inputProps={{ style: { textTransform: "capitalize" } }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="normal"
                required
                fullWidth
                type="number"
                id="contactNumber"
                label="Contact Number"
                name="contactNumber"
                {...register("contactNumber", {
                  required: "Please enter contact number.",
                  pattern: {
                    value: /^[0-9]/,
                    message: "Please enter a valid contact number.",
                  },
                })}
                error={Boolean(errors.contactNumber)}
                helperText={errors.contactNumber?.message}
                inputProps={{ maxLength: 15 }}
              />
            </Grid>
            {/* Repeat the same pattern for other TextField components */}
            <Grid item xs={12} sm={6}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="emailId"
                label="Email Address"
                name="emailId"
                {...register("emailId", {
                  required: "Please enter email id.",
                  pattern: {
                    value:
                      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                    message: "Please enter a valid email id.",
                  },
                })}
                error={Boolean(errors.emailId)}
                helperText={errors.emailId?.message}
                inputProps={{ maxLength: 250 }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                {...register("password", {
                  required: "Please enter password.",
                })}
                error={Boolean(errors.password)}
                helperText={errors.password?.message}
                inputProps={{ maxLength: 100 }}
              />
            </Grid>
            {/* Add more Grid items for other TextField components */}
            <Grid item xs={12} sm={6}>
              <FormControl
                fullWidth
                margin="normal"
                required
                error={Boolean(errors.mudziePlan)}
              >
                <InputLabel htmlFor="mudziePlan">Mudzie Plan</InputLabel>
                <Controller
                  name="mudziePlan"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <Select id="mudziePlan" label="Mudzie Plan" {...field}>
                      <MenuItem value="Basic">Basic</MenuItem>
                      <MenuItem value="Pro" disabled>
                        Pro
                      </MenuItem>
                      <MenuItem value="UltraPro" disabled>
                        Ultra Pro
                      </MenuItem>
                    </Select>
                  )}
                  rules={{ required: "Please select a plan." }}
                />
                {errors.mudziePlan && (
                  <span
                    role="alert"
                    style={{
                      color: "red",
                      fontSize: "10px",
                      marginLeft: "15px",
                      marginTop: "8px",
                    }}
                  >
                    {errors.mudziePlan.message}
                  </span>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="normal"
                required
                fullWidth
                name="expiryDate"
                label="Expiry Date"
                type="date"
                id="expiryDate"
                InputLabelProps={{
                  shrink: true, // Set shrink to true to prevent label from shrinking
                }}
                inputProps={{
                  min: formattedCurrentDate, // Set the minimum date to today
                }}
                {...register("expiryDate", {
                  required: "Please enter expiry date",
                })}
                error={Boolean(errors.expiryDate)}
                helperText={errors.expiryDate?.message}
                //inputProps={{ maxLength: 100 }}
              />
            </Grid>
            {/* Add more Grid items for other form fields */}
            {/* Add more Grid items for other form fields */}
            <Grid item xs={12} sm={6}>
              <TextField
                margin="normal"
                fullWidth
                id="poNumber"
                label="PO Number"
                name="poNumber"
                // {...register("poNumber", {
                //   required: "Please enter PO number.",
                // })}
                // error={Boolean(errors.poNumber)}
                // helperText={errors.poNumber?.message}
                inputProps={{ maxLength: 250 }}
              />
            </Grid>
            {/* Add more Grid items for other form fields */}
            <Grid item xs={12} sm={6}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="companyName"
                label="Company Name"
                name="companyName"
                {...register("companyName", {
                  required: "Please enter Company Name",
                })}
                error={Boolean(errors.companyName)}
                helperText={errors.companyName?.message}
                inputProps={{ maxLength: 250 }}
              />
            </Grid>
            {/* Add more Grid items for other form fields */}
          </Grid>
          <Box
            sx={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <Button
              type="submit"
              variant="contained"
              sx={{
                mt: 2,
                mb: 2,
                width: "100%",
              }}
            >
              ADD
            </Button>
          </Box>
        </Box>
      </Box>
      <ReactJsAlert
        status={alertStatus} // true or false
        type={type} // success, warning, error, info
        title={title}
        autoCloseIn="30000"
        Close={() => {
          setAlertStatus(false);
          //   navigate("/", { replace: true });
        }}
      />
    </div>
  );
};

export default Adduser;
