import React, { useEffect, useState } from 'react'
import Navbar from '../components/Navbar'
import { Alert, AlertTitle, Box, Button, Card, CardContent, CardHeader, Grid, Stack, TextField } from '@mui/material'
import Sidenav from '../components/Sidenav'
import axios from 'axios'
import ClipLoader from "react-spinners/ClipLoader";

export const RateToPricePX = () => {
    let [alertMessage, setAlertMessage] = useState(false);
    let [alertMessageText, setAlertMessageText] = useState();
    let [alertMessageTitle, setAlertMessageTitle] = useState();
    let [alertMessageType, setAlertMessageType] = useState();
    let [alertMessagePriceToRate, setAlertMessagePriceToRate] = useState(false);
    let [alertMessageTextPriceToRate, setAlertMessageTextPriceToRate] = useState();
    let [alertMessageTitlePriceToRate, setAlertMessageTitlePriceToRate] = useState();
    let [alertMessageTypePriceToRate, setAlertMessageTypePriceToRate] = useState();
    let [rateOne, setRateOne] = useState(0);
    let [rateTwo, setRateTwo] = useState(0);
    let [rateThree, setRateThree] = useState(0);
    let [rateFour, setRateFour] = useState(0);
    let [rateFive, setRateFive] = useState(0);
    let [rateSix, setRateSix] = useState(0);
    let [pXOne, setPXOne] = useState(0);
    let [pXTwo, setPXTwo] = useState(0);
    let [pXThree, setPXThree] = useState(0);
    let [pXFour, setPXFour] = useState(0);
    let [pXFive, setPXFive] = useState(0);
    let [pXSix, setPXSix] = useState(0);
    let [cLPXOne, setCLPXOne] = useState(0);
    let [cLPXTwo, setCLPXTwo] = useState(0);
    let [cLPXThree, setCLPXThree] = useState(0);
    let [cLPXFour, setCLPXFour] = useState(0);
    let [cLPXFive, setCLPXFive] = useState(0);
    let [cLPXSix, setCLPXSix] = useState(0);
    const [oneZeroTwoPXPL, setOneZeroTwoPXPL] = useState(0);
    const [oneZeroFourPXPL, setOneZeroFourPXPL] = useState(0);
    const [oneZeroSixPXPL, setOneZeroSixPXPL] = useState(0);
    const [oneZeroEightPXPL, setOneZeroEightPXPL] = useState(0);
    const [oneZeroTenPXPL, setOneZeroTenPXPL] = useState(0);
    const [priceToRatePriceOneCLPX, setPriceToRatePriceOneCLPX] = useState(0);
    const [priceToRatePriceTwoCLPX, setPriceToRatePriceTwoCLPX] = useState(0);
    const [priceToRatePriceThreeCLPX, setPriceToRatePriceThreeCLPX] = useState(0);
    const [priceToRatePriceFourCLPX, setPriceToRatePriceFourCLPX] = useState(0);
    const [priceToRatePriceFiveCLPX, setPriceToRatePriceFiveCLPX] = useState(0);
    const [priceToRatePriceOne, setPriceToRatePriceOne] = useState(0);
    const [priceToRatePriceTwo, setPriceToRatePriceTwo] = useState(0);
    const [priceToRatePriceThree, setPriceToRatePriceThree] = useState(0);
    const [priceToRatePriceFour, setPriceToRatePriceFour] = useState(0);
    const [priceToRatePriceFive, setPriceToRatePriceFive] = useState(0);
    const [records, setRecords] = useState()
    const [loadingRateToPrice, setLoadingRateToPrice] = useState(true)
    const [loadingPriceToRate, setLoadingPriceToRate] = useState(true)
    const updateValues = async () => {
        let pXAndRates = [];
        if (rateTwo > rateOne) {
            let rateDiffOne = (rateTwo - rateOne).toFixed(2)
            let pXDiffOne = pXTwo - pXOne
            let cLPXDiffOne = cLPXTwo - cLPXOne
            let firstCalculation = pXDiffOne / rateDiffOne / 100
            let firstCalculationCL = cLPXDiffOne / rateDiffOne / 100
            for (let i = 1; i <= rateDiffOne * 100; i++) {
                if (i === 1)
                    pXAndRates.push({ rate: Number(Number(rateOne).toFixed(2)), px: Number(Number(pXOne).toFixed(2)), clpx: Number(Number(cLPXOne).toFixed(2)), reset_point_flag: "Y" });
                rateOne = Number(rateOne) + 0.01;
                pXOne = Number(pXOne) + firstCalculation;
                cLPXOne = Number(cLPXOne) + firstCalculationCL;
                if (i === rateDiffOne * 100)
                    pXAndRates.push({ rate: Number(rateOne.toFixed(2)), px: Number(pXOne.toFixed(2)), clpx: Number(Number(cLPXOne).toFixed(2)), reset_point_flag: "Y" });
                else
                    pXAndRates.push({ rate: Number(rateOne.toFixed(2)), px: Number(pXOne.toFixed(2)), clpx: Number(Number(cLPXOne).toFixed(2)), reset_point_flag: "N" });
            }
        }

        if (rateThree > rateTwo) {
            let rateDiffTwo = (rateThree - rateTwo).toFixed(2)
            let pXDiffTwo = pXThree - pXTwo
            let cLPXDiffTwo = cLPXThree - cLPXTwo
            let secondCalculation = pXDiffTwo / rateDiffTwo / 100
            let secondCalculationCL = cLPXDiffTwo / rateDiffTwo / 100
            for (let i = 1; i <= rateDiffTwo * 100; i++) {
                rateTwo = Number(rateTwo) + 0.01;
                pXTwo = Number(pXTwo) + secondCalculation;
                cLPXTwo = Number(cLPXTwo) + secondCalculationCL;
                if (i === rateDiffTwo * 100)
                    pXAndRates.push({ rate: Number(rateTwo.toFixed(2)), px: Number(pXTwo.toFixed(2)), clpx: Number(cLPXTwo.toFixed(2)), reset_point_flag: "Y" });
                else
                    pXAndRates.push({ rate: Number(rateTwo.toFixed(2)), px: Number(pXTwo.toFixed(2)), clpx: Number(cLPXTwo.toFixed(2)), reset_point_flag: "N" });
            }
        }

        if (rateFour > rateThree) {
            let rateDiffThree = (rateFour - rateThree).toFixed(2)
            let pXDiffThree = pXFour - pXThree
            let cLPXDiffThree = cLPXFour - cLPXThree
            let ThirdCalculation = pXDiffThree / rateDiffThree / 100
            let ThirdCalculationCL = cLPXDiffThree / rateDiffThree / 100
            for (let i = 1; i <= rateDiffThree * 100; i++) {
                rateThree = Number(rateThree) + 0.01;
                pXThree = Number(pXThree) + ThirdCalculation;
                cLPXThree = Number(cLPXThree) + ThirdCalculationCL;
                if (i === rateDiffThree * 100)
                    pXAndRates.push({ rate: Number(rateThree.toFixed(2)), px: Number(pXThree.toFixed(2)), clpx: Number(cLPXThree.toFixed(2)), reset_point_flag: "Y" });
                else
                    pXAndRates.push({ rate: Number(rateThree.toFixed(2)), px: Number(pXThree.toFixed(2)), clpx: Number(cLPXThree.toFixed(2)), reset_point_flag: "N" });
            }
        }

        if (rateFive > rateFour) {
            let rateDiffFour = (rateFive - rateFour).toFixed(2)
            let pXDiffFour = pXFive - pXFour
            let cLPXDiffFour = cLPXFive - cLPXFour
            let FourthCalculation = pXDiffFour / rateDiffFour / 100
            let FourthCalculationCL = cLPXDiffFour / rateDiffFour / 100
            for (let i = 1; i <= rateDiffFour * 100; i++) {
                rateFour = Number(rateFour) + 0.01;
                pXFour = Number(pXFour) + FourthCalculation;
                cLPXFour = Number(cLPXFour) + FourthCalculationCL;
                console.log(i + "===" + rateDiffFour * 100);
                if (i === rateDiffFour * 100)
                    pXAndRates.push({ rate: Number(rateFour.toFixed(2)), px: Number(pXFour.toFixed(2)), clpx: Number(cLPXFour.toFixed(2)), reset_point_flag: "Y" });
                else
                    pXAndRates.push({ rate: Number(rateFour.toFixed(2)), px: Number(pXFour.toFixed(2)), clpx: Number(cLPXFour.toFixed(2)), reset_point_flag: "N" });
            }
        }

        if (rateSix > rateFive) {
            let rateDiffFive = (rateSix - rateFive).toFixed(2)
            let pXDiffFive = pXSix - pXFive
            let cLPXDiffFive = cLPXSix - cLPXFive
            let FifthCalculation = pXDiffFive / rateDiffFive / 100
            let FifthCalculationCL = cLPXDiffFive / rateDiffFive / 100
            for (let i = 1; i <= rateDiffFive * 100; i++) {
                rateFive = Number(rateFive) + 0.01;
                pXFive = Number(pXFive) + FifthCalculation;
                cLPXFive = Number(cLPXFive) + FifthCalculationCL;
                if (i === rateDiffFive * 100)
                    pXAndRates.push({ rate: Number(rateFive.toFixed(2)), px: Number(pXFive.toFixed(2)), clpx: Number(cLPXFive.toFixed(2)), reset_point_flag: "Y" });
                else
                    pXAndRates.push({ rate: Number(rateFive.toFixed(2)), px: Number(pXFive.toFixed(2)), clpx: Number(cLPXFive.toFixed(2)), reset_point_flag: "N" });
            }
        }
        console.log(pXAndRates);
        setRecords(pXAndRates)
        try {
            await axios.post(`${process.env.REACT_APP_BASE_URL}api/rate-to-price-px`, {
                records: pXAndRates
            });
            setAlertMessage(true);
            setAlertMessageText('Records added successfully');
            setAlertMessageTitle('Success');
            setAlertMessageType('success');
        } catch (error) {
            console.error('There was an error adding the records!', error);
            setAlertMessage(true);
            setAlertMessageText('Failed to add records');
            setAlertMessageTitle('Failed');
            setAlertMessageType('error');
        }
    }
    useEffect(() => {
        const fetchRecords = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BASE_URL}api/rate-to-price-px/reset-point`);
                if (response.data.data[0]) {
                    setRateOne(response.data.data[0].rate);
                    setPXOne(response.data.data[0].px);
                    setCLPXOne(response.data.data[0].clpx);
                }
                if (response.data.data[1]) {
                    setRateTwo(response.data.data[1].rate);
                    setPXTwo(response.data.data[1].px);
                    setCLPXTwo(response.data.data[1].clpx);
                }
                if (response.data.data[2]) {
                    setRateThree(response.data.data[2].rate);
                    setPXThree(response.data.data[2].px);
                    setCLPXThree(response.data.data[2].clpx);
                }
                if (response.data.data[3]) {
                    setRateFour(response.data.data[3].rate);
                    setPXFour(response.data.data[3].px);
                    setCLPXFour(response.data.data[3].clpx);
                }
                if (response.data.data[4]) {
                    setRateFive(response.data.data[4].rate);
                    setPXFive(response.data.data[4].px);
                    setCLPXFive(response.data.data[4].clpx);
                }
                if (response.data.data[5]) {
                    setRateSix(response.data.data[5].rate);
                    setPXSix(response.data.data[5].px);
                    setCLPXSix(response.data.data[5].clpx);
                }
                setLoadingRateToPrice(false)
            } catch (err) {
                console.log(err);;
            }
        };

        fetchRecords();

        const getSettings = async () => {
            try {
                const priceToRateResponse = await axios.get(`${process.env.REACT_APP_BASE_URL}api/price-to-rate-px/reset-point`);

                if (priceToRateResponse.data.data[0]) {
                    setOneZeroTwoPXPL(priceToRateResponse.data.data[0].px)
                    setPriceToRatePriceOne(priceToRateResponse.data.data[0].price.substring(0, 3))
                    setPriceToRatePriceOneCLPX(priceToRateResponse.data.data[0].clpx)
                }
                if (priceToRateResponse.data.data[1]) {
                    setOneZeroFourPXPL(priceToRateResponse.data.data[1].px)
                    setPriceToRatePriceTwo(priceToRateResponse.data.data[1].price.substring(0, 3))
                    setPriceToRatePriceTwoCLPX(priceToRateResponse.data.data[1].clpx)
                }
                if (priceToRateResponse.data.data[2]) {
                    setOneZeroSixPXPL(priceToRateResponse.data.data[2].px)
                    setPriceToRatePriceThree(priceToRateResponse.data.data[2].price.substring(0, 3))
                    setPriceToRatePriceThreeCLPX(priceToRateResponse.data.data[2].clpx)
                }
                if (priceToRateResponse.data.data[3]) {
                    setOneZeroEightPXPL(priceToRateResponse.data.data[3].px)
                    setPriceToRatePriceFour(priceToRateResponse.data.data[3].price.substring(0, 3))
                    setPriceToRatePriceFourCLPX(priceToRateResponse.data.data[3].clpx)
                }
                if (priceToRateResponse.data.data[4]) {
                    setOneZeroTenPXPL(priceToRateResponse.data.data[4].px)
                    setPriceToRatePriceFive(priceToRateResponse.data.data[4].price.substring(0, 3))
                    setPriceToRatePriceFiveCLPX(priceToRateResponse.data.data[4].clpx)
                }
                setLoadingPriceToRate(false)
            } catch (err) {
                console.log(err);;
            }
            // await axios.get(`${process.env.REACT_APP_BASE_URL}api/getSettings`)
            //     .then((res) => {
            //         console.log(res.data);
            //         setOneZeroTwoPXPL(res.data.oneZeroTwoPXPL)
            //         setOneZeroFourPXPL(res.data.oneZeroFourPXPL)
            //         setOneZeroSixPXPL(res.data.oneZeroSixPXPL)
            //         setOneZeroEightPXPL(res.data.oneZeroEightPXPL)
            //         setOneZeroTenPXPL(res.data.oneZeroTenPXPL)
            //     })
        }
        getSettings()
    }, []); // Empty dependency array ensures this runs once
    const updatePriceToRatePX = async () => {
        try {
            // let settingsValues = {
            //     oneZeroTwoPXPL: Number(oneZeroTwoPXPL),
            //     oneZeroFourPXPL: Number(oneZeroFourPXPL),
            //     oneZeroSixPXPL: Number(oneZeroSixPXPL),
            //     oneZeroEightPXPL: Number(oneZeroEightPXPL),
            //     oneZeroTenPXPL: Number(oneZeroTenPXPL)
            // }
            let priceToRatePXArray = []
            if (Number(oneZeroTwoPXPL) < Number(oneZeroFourPXPL)) {
                let firstDifference = Number(oneZeroFourPXPL) - Number(oneZeroTwoPXPL);
                let firstDifferenceCL = Number(priceToRatePriceTwoCLPX) - Number(priceToRatePriceOneCLPX);
                let firstAdditionValue = firstDifference / 64
                let firstAdditionValueCL = firstDifferenceCL / 64
                let tempOneZeroTwoPXPL = Number(oneZeroTwoPXPL)
                let tempPriceToRatePriceOneCLPXPL = Number(priceToRatePriceOneCLPX)
                for (let i = 0; i <= 64; i++) {
                    if (i <= 32) {
                        if (i === 0)
                            priceToRatePXArray.push({ price: priceToRatePriceOne + " " + i + "/32", px: tempOneZeroTwoPXPL, clpx: tempPriceToRatePriceOneCLPXPL, reset_point_flag: "Y" });
                        else
                            priceToRatePXArray.push({ price: priceToRatePriceOne + " " + i + "/32", px: tempOneZeroTwoPXPL, clpx: tempPriceToRatePriceOneCLPXPL, reset_point_flag: "N" });
                        // console.log("102 "+i+"/32",tempOneZeroTwoPXPL)
                    }
                    if (i >= 32) {
                        priceToRatePXArray.push({ price: Number(priceToRatePriceOne) + 1 + " " + Number(i - 32) + "/32", px: tempOneZeroTwoPXPL, clpx: tempPriceToRatePriceOneCLPXPL, reset_point_flag: "N" });
                        // console.log("103 "+Number(i-32)+"/32",tempOneZeroTwoPXPL)
                        if (i === 64)
                            priceToRatePXArray.push({ price: priceToRatePriceTwo + " 0/32", px: tempOneZeroTwoPXPL, clpx: tempPriceToRatePriceOneCLPXPL, reset_point_flag: "Y" });
                        // console.log("104 0/32",tempOneZeroTwoPXPL)
                    }
                    tempOneZeroTwoPXPL = tempOneZeroTwoPXPL + firstAdditionValue
                    tempPriceToRatePriceOneCLPXPL = tempPriceToRatePriceOneCLPXPL + firstAdditionValueCL
                }
            }

            if (Number(oneZeroFourPXPL) < Number(oneZeroSixPXPL)) {
                let SecondDifference = Number(oneZeroSixPXPL) - Number(oneZeroFourPXPL);
                let SecondDifferenceCL = Number(priceToRatePriceThreeCLPX) - Number(priceToRatePriceTwoCLPX);
                let SecondAdditionValue = SecondDifference / 64
                let SecondAdditionValueCL = SecondDifferenceCL / 64
                let tempOneZeroFourPXPL = Number(oneZeroFourPXPL)
                let tempPriceToRatePriceTwoCLPXPL = Number(priceToRatePriceTwoCLPX)
                for (let i = 1; i <= 64; i++) {
                    tempOneZeroFourPXPL = tempOneZeroFourPXPL + SecondAdditionValue
                    tempPriceToRatePriceTwoCLPXPL = tempPriceToRatePriceTwoCLPXPL + SecondAdditionValueCL
                    if (i <= 32)
                        priceToRatePXArray.push({ price: priceToRatePriceTwo + " " + i + "/32", px: tempOneZeroFourPXPL, clpx: tempPriceToRatePriceTwoCLPXPL, reset_point_flag: "N" });
                    // console.log("104 "+i+"/32",tempOneZeroFourPXPL)
                    if (i >= 32) {
                        priceToRatePXArray.push({ price: Number(priceToRatePriceTwo) + 1 + " " + Number(i - 32) + "/32", px: tempOneZeroFourPXPL, clpx: tempPriceToRatePriceTwoCLPXPL, reset_point_flag: "N" });
                        // console.log("105 "+Number(i-32)+"/32",tempOneZeroFourPXPL)
                        if (i === 64)
                            priceToRatePXArray.push({ price: priceToRatePriceThree + " 0/32", px: tempOneZeroFourPXPL, clpx: tempPriceToRatePriceTwoCLPXPL, reset_point_flag: "Y" });
                        // console.log("106 0/32",tempOneZeroFourPXPL)
                    }
                }
            }

            if (Number(oneZeroSixPXPL) < Number(oneZeroEightPXPL)) {
                let ThirdDifference = Number(oneZeroEightPXPL) - Number(oneZeroSixPXPL);
                let ThirdDifferenceCL = Number(priceToRatePriceFourCLPX) - Number(priceToRatePriceThreeCLPX);
                let ThirdAdditionValue = ThirdDifference / 64
                let ThirdAdditionValueCL = ThirdDifferenceCL / 64
                let tempOneZeroSixPXPL = Number(oneZeroSixPXPL)
                let tempPriceToRatePriceThreeCLPXPL = Number(priceToRatePriceThreeCLPX)
                for (let i = 1; i <= 64; i++) {
                    tempOneZeroSixPXPL = tempOneZeroSixPXPL + ThirdAdditionValue
                    tempPriceToRatePriceThreeCLPXPL = tempPriceToRatePriceThreeCLPXPL + ThirdAdditionValueCL
                    if (i <= 32)
                        priceToRatePXArray.push({ price: priceToRatePriceThree + " " + i + "/32", px: tempOneZeroSixPXPL, clpx: tempPriceToRatePriceThreeCLPXPL, reset_point_flag: "N" });
                    // console.log("106 "+i+"/32",tempOneZeroSixPXPL)
                    if (i >= 32) {
                        priceToRatePXArray.push({ price: Number(priceToRatePriceThree) + 1 + " " + Number(i - 32) + "/32", px: tempOneZeroSixPXPL, clpx: tempPriceToRatePriceThreeCLPXPL, reset_point_flag: "N" });
                        // console.log("107 "+Number(i-32)+"/32",tempOneZeroSixPXPL)
                        if (i === 64)
                            priceToRatePXArray.push({ price: priceToRatePriceFour + " 0/32", px: tempOneZeroSixPXPL, clpx: tempPriceToRatePriceThreeCLPXPL, reset_point_flag: "Y" });
                        // console.log("108 0/32",tempOneZeroSixPXPL)
                    }
                }
            }

            if (Number(oneZeroEightPXPL) < Number(oneZeroTenPXPL)) {
                let FourthDifference = Number(oneZeroTenPXPL) - Number(oneZeroEightPXPL);
                let FourthDifferenceCL = Number(priceToRatePriceFiveCLPX) - Number(priceToRatePriceFourCLPX);
                let FourthAdditionValue = FourthDifference / 64
                let FourthAdditionValueCL = FourthDifferenceCL / 64
                let tempOneZeroEightPXPL = Number(oneZeroEightPXPL)
                let tempPriceToRatePriceFourCLPXPL = Number(priceToRatePriceFourCLPX)
                for (let i = 1; i <= 64; i++) {
                    tempOneZeroEightPXPL = tempOneZeroEightPXPL + FourthAdditionValue
                    tempPriceToRatePriceFourCLPXPL = tempPriceToRatePriceFourCLPXPL + FourthAdditionValueCL
                    if (i <= 32)
                        priceToRatePXArray.push({ price: priceToRatePriceFour + " " + i + "/32", px: tempOneZeroEightPXPL, clpx: tempPriceToRatePriceFourCLPXPL, reset_point_flag: "N" });
                    // console.log("108 "+i+"/32",tempOneZeroEightPXPL)
                    if (i >= 32) {
                        priceToRatePXArray.push({ price: Number(priceToRatePriceFour) + 1 + " " + Number(i - 32) + "/32", px: tempOneZeroEightPXPL, clpx: tempPriceToRatePriceFourCLPXPL, reset_point_flag: "N" });
                        // console.log("109 "+Number(i-32)+"/32",tempOneZeroEightPXPL)
                        if (i === 64)
                            priceToRatePXArray.push({ price: priceToRatePriceFive + " 0/32", px: tempOneZeroEightPXPL, clpx: tempPriceToRatePriceFourCLPXPL, reset_point_flag: "Y" });
                        // console.log("110 0/32",tempOneZeroEightPXPL)
                    }
                }
            }
            console.log(priceToRatePXArray);
            await axios.post(`${process.env.REACT_APP_BASE_URL}api/price-to-rate-px`, {
                records: priceToRatePXArray
            });
            setAlertMessagePriceToRate(true);
            setAlertMessageTextPriceToRate('Records added successfullyddd');
            setAlertMessageTitlePriceToRate('Success');
            setAlertMessageTypePriceToRate('success');
        } catch (error) {
            console.error('There was an error adding the records!', error);
            setAlertMessagePriceToRate(true);
            setAlertMessageTextPriceToRate('Failed to add records');
            setAlertMessageTitlePriceToRate('Failed');
            setAlertMessageTypePriceToRate('error');
        }
    }
    return (
        <div>
            <Navbar />
            <Box height={10} />
            <Box sx={{ display: 'flex' }}>
                <Sidenav menuHeighlightPath="/dashboard/ratetopricepx" />
                <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Card sx={{ boxShadow: 12, borderRadius: '20px', mt: 10 }}>
                                {alertMessage ?
                                    <Alert severity={alertMessageType} onClose={() => { setAlertMessage(false) }}>
                                        <AlertTitle>{alertMessageTitle}</AlertTitle>
                                        {alertMessageText}
                                    </Alert>
                                    :
                                    null
                                }
                                <CardHeader
                                    // title="Settings"
                                    title="Rate To Price PX Spread "
                                    titleTypographyProps={{ fontFamily: 'Ubuntu', fontWeight: 'bold', mt: 3, pl: 4 }}
                                    action={
                                        <>

                                            <Button variant="contained" onClick={() => updateValues()}>
                                                Save
                                            </Button>
                                        </>

                                    }
                                />
                                <hr width='95%' />
                                <CardContent>
                                    {
                                        loadingRateToPrice ?
                                            <Box sx={{ textAlign: 'center' }}>
                                                <ClipLoader
                                                    color="#013a80"
                                                    loading={loadingRateToPrice}
                                                    size={50}
                                                    aria-label="Loading Spinner"
                                                    data-testid="loader"
                                                />
                                            </Box>
                                            :
                                            <>
                                                <Stack spacing={8} direction={"column"}>
                                                    <Box sx={{ display: 'flex', gap: '100px', paddingLeft: '30px' }}>
                                                        <Grid item xs={1.2}>
                                                            <TextField
                                                                InputLabelProps={{ style: { color: '#8D8D8D', fontFamily: 'Ubuntu', fontSize: '15px' } }}
                                                                margin="normal"
                                                                label="Rate 1"
                                                                type="number"
                                                                required
                                                                value={rateOne}
                                                                onChange={(e) => { setRateOne(e.target.value) }}
                                                                size="small"
                                                                variant="standard"
                                                                sx={{
                                                                    '& .MuiInput-underline': {
                                                                        borderBottomColor: '#8D8D8D',
                                                                        borderWidth: '2px',
                                                                        borderBottomStyle: 'solid' // Replace with your desired color
                                                                    },
                                                                    '& .MuiInput-underline:after': {
                                                                        borderBottomColor: '#8D8D8D',
                                                                        borderWidth: '2px',
                                                                        borderBottomStyle: 'solid' // Replace with your desired color
                                                                    },
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={1.2}>
                                                            <TextField
                                                                InputLabelProps={{ style: { color: '#8D8D8D', fontFamily: 'Ubuntu', fontSize: '15px' } }}
                                                                margin="normal"
                                                                label="Rate 2"
                                                                type="number"
                                                                required
                                                                value={rateTwo}
                                                                onChange={(e) => { setRateTwo(e.target.value) }}
                                                                size="small"
                                                                variant="standard"
                                                                sx={{
                                                                    '& .MuiInput-underline': {
                                                                        borderBottomColor: '#8D8D8D',
                                                                        borderWidth: '2px',
                                                                        borderBottomStyle: 'solid' // Replace with your desired color
                                                                    },
                                                                    '& .MuiInput-underline:after': {
                                                                        borderBottomColor: '#8D8D8D',
                                                                        borderWidth: '2px',
                                                                        borderBottomStyle: 'solid' // Replace with your desired color
                                                                    },
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={1.2}>
                                                            <TextField
                                                                InputLabelProps={{ style: { color: '#8D8D8D', fontFamily: 'Ubuntu', fontSize: '15px' } }}
                                                                margin="normal"
                                                                label="Rate 3"
                                                                type="number"
                                                                required
                                                                value={rateThree}
                                                                onChange={(e) => { setRateThree(e.target.value) }}
                                                                size="small"
                                                                variant="standard"
                                                                sx={{
                                                                    '& .MuiInput-underline': {
                                                                        borderBottomColor: '#8D8D8D',
                                                                        borderWidth: '2px',
                                                                        borderBottomStyle: 'solid' // Replace with your desired color
                                                                    },
                                                                    '& .MuiInput-underline:after': {
                                                                        borderBottomColor: '#8D8D8D',
                                                                        borderWidth: '2px',
                                                                        borderBottomStyle: 'solid' // Replace with your desired color
                                                                    },
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={1.2}>
                                                            <TextField
                                                                InputLabelProps={{ style: { color: '#8D8D8D', fontFamily: 'Ubuntu', fontSize: '15px' } }}
                                                                margin="normal"
                                                                label="Rate 4"
                                                                type="number"
                                                                required
                                                                value={rateFour}
                                                                onChange={(e) => { setRateFour(e.target.value) }}
                                                                size="small"
                                                                variant="standard"
                                                                sx={{
                                                                    '& .MuiInput-underline': {
                                                                        borderBottomColor: '#8D8D8D',
                                                                        borderWidth: '2px',
                                                                        borderBottomStyle: 'solid' // Replace with your desired color
                                                                    },
                                                                    '& .MuiInput-underline:after': {
                                                                        borderBottomColor: '#8D8D8D',
                                                                        borderWidth: '2px',
                                                                        borderBottomStyle: 'solid' // Replace with your desired color
                                                                    },
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={1.2}>
                                                            <TextField
                                                                InputLabelProps={{ style: { color: '#8D8D8D', fontFamily: 'Ubuntu', fontSize: '15px' } }}
                                                                margin="normal"
                                                                label="Rate 5"
                                                                type="number"
                                                                required
                                                                value={rateFive}
                                                                onChange={(e) => { setRateFive(e.target.value) }}
                                                                size="small"
                                                                variant="standard"
                                                                sx={{
                                                                    '& .MuiInput-underline': {
                                                                        borderBottomColor: '#8D8D8D',
                                                                        borderWidth: '2px',
                                                                        borderBottomStyle: 'solid' // Replace with your desired color
                                                                    },
                                                                    '& .MuiInput-underline:after': {
                                                                        borderBottomColor: '#8D8D8D',
                                                                        borderWidth: '2px',
                                                                        borderBottomStyle: 'solid' // Replace with your desired color
                                                                    },
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={1.2}>
                                                            <TextField
                                                                InputLabelProps={{ style: { color: '#8D8D8D', fontFamily: 'Ubuntu', fontSize: '15px' } }}
                                                                margin="normal"
                                                                label="Rate 6"
                                                                type="number"
                                                                required
                                                                value={rateSix}
                                                                onChange={(e) => { setRateSix(e.target.value) }}
                                                                size="small"
                                                                variant="standard"
                                                                sx={{
                                                                    '& .MuiInput-underline': {
                                                                        borderBottomColor: '#8D8D8D',
                                                                        borderWidth: '2px',
                                                                        borderBottomStyle: 'solid' // Replace with your desired color
                                                                    },
                                                                    '& .MuiInput-underline:after': {
                                                                        borderBottomColor: '#8D8D8D',
                                                                        borderWidth: '2px',
                                                                        borderBottomStyle: 'solid' // Replace with your desired color
                                                                    },
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Box>
                                                    {/* PX Permenant */}
                                                    <Box sx={{ display: 'flex', gap: '100px', paddingLeft: '30px' }}>
                                                        <Grid item xs={1.2}>
                                                            <TextField
                                                                InputLabelProps={{ style: { color: '#8D8D8D', fontFamily: 'Ubuntu', fontSize: '15px' } }}
                                                                margin="normal"
                                                                label="Permanent PX 1"
                                                                type="number"
                                                                required
                                                                value={pXOne}
                                                                onChange={(e) => { setPXOne(e.target.value) }}
                                                                size="small"
                                                                variant="standard"
                                                                sx={{
                                                                    '& .MuiInput-underline': {
                                                                        borderBottomColor: '#8D8D8D',
                                                                        borderWidth: '2px',
                                                                        borderBottomStyle: 'solid' // Replace with your desired color
                                                                    },
                                                                    '& .MuiInput-underline:after': {
                                                                        borderBottomColor: '#8D8D8D',
                                                                        borderWidth: '2px',
                                                                        borderBottomStyle: 'solid' // Replace with your desired color
                                                                    },
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={1.2}>
                                                            <TextField
                                                                InputLabelProps={{ style: { color: '#8D8D8D', fontFamily: 'Ubuntu', fontSize: '15px' } }}
                                                                margin="normal"
                                                                label="Permanent PX 2"
                                                                type="number"
                                                                required
                                                                value={pXTwo}
                                                                onChange={(e) => { setPXTwo(e.target.value) }}
                                                                size="small"
                                                                variant="standard"
                                                                sx={{
                                                                    '& .MuiInput-underline': {
                                                                        borderBottomColor: '#8D8D8D',
                                                                        borderWidth: '2px',
                                                                        borderBottomStyle: 'solid' // Replace with your desired color
                                                                    },
                                                                    '& .MuiInput-underline:after': {
                                                                        borderBottomColor: '#8D8D8D',
                                                                        borderWidth: '2px',
                                                                        borderBottomStyle: 'solid' // Replace with your desired color
                                                                    },
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={1.2}>
                                                            <TextField
                                                                InputLabelProps={{ style: { color: '#8D8D8D', fontFamily: 'Ubuntu', fontSize: '15px' } }}
                                                                margin="normal"
                                                                label="Permanent PX 3"
                                                                type="number"
                                                                required
                                                                value={pXThree}
                                                                onChange={(e) => { setPXThree(e.target.value) }}
                                                                size="small"
                                                                variant="standard"
                                                                sx={{
                                                                    '& .MuiInput-underline': {
                                                                        borderBottomColor: '#8D8D8D',
                                                                        borderWidth: '2px',
                                                                        borderBottomStyle: 'solid' // Replace with your desired color
                                                                    },
                                                                    '& .MuiInput-underline:after': {
                                                                        borderBottomColor: '#8D8D8D',
                                                                        borderWidth: '2px',
                                                                        borderBottomStyle: 'solid' // Replace with your desired color
                                                                    },
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={1.2}>
                                                            <TextField
                                                                InputLabelProps={{ style: { color: '#8D8D8D', fontFamily: 'Ubuntu', fontSize: '15px' } }}
                                                                margin="normal"
                                                                label="Permanent PX 4"
                                                                type="number"
                                                                required
                                                                value={pXFour}
                                                                onChange={(e) => { setPXFour(e.target.value) }}
                                                                size="small"
                                                                variant="standard"
                                                                sx={{
                                                                    '& .MuiInput-underline': {
                                                                        borderBottomColor: '#8D8D8D',
                                                                        borderWidth: '2px',
                                                                        borderBottomStyle: 'solid' // Replace with your desired color
                                                                    },
                                                                    '& .MuiInput-underline:after': {
                                                                        borderBottomColor: '#8D8D8D',
                                                                        borderWidth: '2px',
                                                                        borderBottomStyle: 'solid' // Replace with your desired color
                                                                    },
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={1.2}>
                                                            <TextField
                                                                InputLabelProps={{ style: { color: '#8D8D8D', fontFamily: 'Ubuntu', fontSize: '15px' } }}
                                                                margin="normal"
                                                                label="Permanent PX 5"
                                                                type="number"
                                                                required
                                                                value={pXFive}
                                                                onChange={(e) => { setPXFive(e.target.value) }}
                                                                size="small"
                                                                variant="standard"
                                                                sx={{
                                                                    '& .MuiInput-underline': {
                                                                        borderBottomColor: '#8D8D8D',
                                                                        borderWidth: '2px',
                                                                        borderBottomStyle: 'solid' // Replace with your desired color
                                                                    },
                                                                    '& .MuiInput-underline:after': {
                                                                        borderBottomColor: '#8D8D8D',
                                                                        borderWidth: '2px',
                                                                        borderBottomStyle: 'solid' // Replace with your desired color
                                                                    },
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={1.2}>
                                                            <TextField
                                                                InputLabelProps={{ style: { color: '#8D8D8D', fontFamily: 'Ubuntu', fontSize: '15px' } }}
                                                                margin="normal"
                                                                label="Permanent PX 6"
                                                                type="number"
                                                                required
                                                                value={pXSix}
                                                                onChange={(e) => { setPXSix(e.target.value) }}
                                                                size="small"
                                                                variant="standard"
                                                                sx={{
                                                                    '& .MuiInput-underline': {
                                                                        borderBottomColor: '#8D8D8D',
                                                                        borderWidth: '2px',
                                                                        borderBottomStyle: 'solid' // Replace with your desired color
                                                                    },
                                                                    '& .MuiInput-underline:after': {
                                                                        borderBottomColor: '#8D8D8D',
                                                                        borderWidth: '2px',
                                                                        borderBottomStyle: 'solid' // Replace with your desired color
                                                                    },
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Box>
                                                    {/* PX Construction */}
                                                    <Box sx={{ display: 'flex', gap: '100px', paddingLeft: '30px' }}>
                                                        <Grid item xs={1.2}>
                                                            <TextField
                                                                InputLabelProps={{ style: { color: '#8D8D8D', fontFamily: 'Ubuntu', fontSize: '15px' } }}
                                                                margin="normal"
                                                                label="Construction PX 1"
                                                                type="number"
                                                                required
                                                                value={cLPXOne}
                                                                onChange={(e) => { setCLPXOne(e.target.value) }}
                                                                size="small"
                                                                variant="standard"
                                                                sx={{
                                                                    '& .MuiInput-underline': {
                                                                        borderBottomColor: '#8D8D8D',
                                                                        borderWidth: '2px',
                                                                        borderBottomStyle: 'solid' // Replace with your desired color
                                                                    },
                                                                    '& .MuiInput-underline:after': {
                                                                        borderBottomColor: '#8D8D8D',
                                                                        borderWidth: '2px',
                                                                        borderBottomStyle: 'solid' // Replace with your desired color
                                                                    },
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={1.2}>
                                                            <TextField
                                                                InputLabelProps={{ style: { color: '#8D8D8D', fontFamily: 'Ubuntu', fontSize: '15px' } }}
                                                                margin="normal"
                                                                label="Construction PX 2"
                                                                type="number"
                                                                required
                                                                value={cLPXTwo}
                                                                onChange={(e) => { setCLPXTwo(e.target.value) }}
                                                                size="small"
                                                                variant="standard"
                                                                sx={{
                                                                    '& .MuiInput-underline': {
                                                                        borderBottomColor: '#8D8D8D',
                                                                        borderWidth: '2px',
                                                                        borderBottomStyle: 'solid' // Replace with your desired color
                                                                    },
                                                                    '& .MuiInput-underline:after': {
                                                                        borderBottomColor: '#8D8D8D',
                                                                        borderWidth: '2px',
                                                                        borderBottomStyle: 'solid' // Replace with your desired color
                                                                    },
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={1.2}>
                                                            <TextField
                                                                InputLabelProps={{ style: { color: '#8D8D8D', fontFamily: 'Ubuntu', fontSize: '15px' } }}
                                                                margin="normal"
                                                                label="Construction PX 3"
                                                                type="number"
                                                                required
                                                                value={cLPXThree}
                                                                onChange={(e) => { setCLPXThree(e.target.value) }}
                                                                size="small"
                                                                variant="standard"
                                                                sx={{
                                                                    '& .MuiInput-underline': {
                                                                        borderBottomColor: '#8D8D8D',
                                                                        borderWidth: '2px',
                                                                        borderBottomStyle: 'solid' // Replace with your desired color
                                                                    },
                                                                    '& .MuiInput-underline:after': {
                                                                        borderBottomColor: '#8D8D8D',
                                                                        borderWidth: '2px',
                                                                        borderBottomStyle: 'solid' // Replace with your desired color
                                                                    },
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={1.2}>
                                                            <TextField
                                                                InputLabelProps={{ style: { color: '#8D8D8D', fontFamily: 'Ubuntu', fontSize: '15px' } }}
                                                                margin="normal"
                                                                label="Construction PX 4"
                                                                type="number"
                                                                required
                                                                value={cLPXFour}
                                                                onChange={(e) => { setCLPXFour(e.target.value) }}
                                                                size="small"
                                                                variant="standard"
                                                                sx={{
                                                                    '& .MuiInput-underline': {
                                                                        borderBottomColor: '#8D8D8D',
                                                                        borderWidth: '2px',
                                                                        borderBottomStyle: 'solid' // Replace with your desired color
                                                                    },
                                                                    '& .MuiInput-underline:after': {
                                                                        borderBottomColor: '#8D8D8D',
                                                                        borderWidth: '2px',
                                                                        borderBottomStyle: 'solid' // Replace with your desired color
                                                                    },
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={1.2}>
                                                            <TextField
                                                                InputLabelProps={{ style: { color: '#8D8D8D', fontFamily: 'Ubuntu', fontSize: '15px' } }}
                                                                margin="normal"
                                                                label="Construction PX 5"
                                                                type="number"
                                                                required
                                                                value={cLPXFive}
                                                                onChange={(e) => { setCLPXFive(e.target.value) }}
                                                                size="small"
                                                                variant="standard"
                                                                sx={{
                                                                    '& .MuiInput-underline': {
                                                                        borderBottomColor: '#8D8D8D',
                                                                        borderWidth: '2px',
                                                                        borderBottomStyle: 'solid' // Replace with your desired color
                                                                    },
                                                                    '& .MuiInput-underline:after': {
                                                                        borderBottomColor: '#8D8D8D',
                                                                        borderWidth: '2px',
                                                                        borderBottomStyle: 'solid' // Replace with your desired color
                                                                    },
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={1.2}>
                                                            <TextField
                                                                InputLabelProps={{ style: { color: '#8D8D8D', fontFamily: 'Ubuntu', fontSize: '15px' } }}
                                                                margin="normal"
                                                                label="Construction PX 6"
                                                                type="number"
                                                                required
                                                                value={cLPXSix}
                                                                onChange={(e) => { setCLPXSix(e.target.value) }}
                                                                size="small"
                                                                variant="standard"
                                                                sx={{
                                                                    '& .MuiInput-underline': {
                                                                        borderBottomColor: '#8D8D8D',
                                                                        borderWidth: '2px',
                                                                        borderBottomStyle: 'solid' // Replace with your desired color
                                                                    },
                                                                    '& .MuiInput-underline:after': {
                                                                        borderBottomColor: '#8D8D8D',
                                                                        borderWidth: '2px',
                                                                        borderBottomStyle: 'solid' // Replace with your desired color
                                                                    },
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Box>
                                                </Stack>
                                            </>
                                    }
                                </CardContent>
                            </Card>
                            <Card sx={{ boxShadow: 12, borderRadius: '20px', mt: 5 }}>
                                {alertMessagePriceToRate ?
                                    <Alert severity={alertMessageTypePriceToRate} onClose={() => { setAlertMessagePriceToRate(false) }}>
                                        <AlertTitle>{alertMessageTitlePriceToRate}</AlertTitle>
                                        {alertMessageTextPriceToRate}
                                    </Alert>
                                    :
                                    null
                                }
                                <CardHeader
                                    // title="Settings"
                                    title="Price To Rate PX Spread"
                                    titleTypographyProps={{ fontFamily: 'Ubuntu', fontWeight: 'bold', mt: 3, pl: 4 }}
                                    action={
                                        <Button variant="contained" onClick={() => updatePriceToRatePX()}>
                                            Save
                                        </Button>
                                    }
                                />
                                <hr width='95%' />
                                <CardContent>
                                    {
                                        loadingPriceToRate ?
                                            <Box sx={{ textAlign: 'center' }}>
                                                <ClipLoader
                                                    color="#013a80"
                                                    loading={loadingRateToPrice}
                                                    size={50}
                                                    aria-label="Loading Spinner"
                                                    data-testid="loader"
                                                />
                                            </Box>
                                            :
                                            <>

                                                <Stack spacing={8} direction={"column"}>
                                                    <Box sx={{ display: 'flex', gap: '100px', paddingLeft: '30px' }}>
                                                        <Grid item xs={1.2}>
                                                            <TextField
                                                                InputLabelProps={{ style: { color: '#8D8D8D', fontFamily: 'Ubuntu', fontSize: '15px' } }}
                                                                margin="normal"
                                                                label="Price 1"
                                                                type="number"
                                                                required
                                                                value={priceToRatePriceOne}
                                                                onChange={(e) => { setPriceToRatePriceOne(e.target.value) }}
                                                                size="small"
                                                                variant="standard"
                                                                sx={{
                                                                    '& .MuiInput-underline': {
                                                                        borderBottomColor: '#8D8D8D',
                                                                        borderWidth: '2px',
                                                                        borderBottomStyle: 'solid' // Replace with your desired color
                                                                    },
                                                                    '& .MuiInput-underline:after': {
                                                                        borderBottomColor: '#8D8D8D',
                                                                        borderWidth: '2px',
                                                                        borderBottomStyle: 'solid' // Replace with your desired color
                                                                    },
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={1.2}>
                                                            <TextField
                                                                InputLabelProps={{ style: { color: '#8D8D8D', fontFamily: 'Ubuntu', fontSize: '15px' } }}
                                                                margin="normal"
                                                                label="Price 2"
                                                                type="number"
                                                                required
                                                                value={priceToRatePriceTwo}
                                                                onChange={(e) => { setPriceToRatePriceTwo(e.target.value) }}
                                                                size="small"
                                                                variant="standard"
                                                                sx={{
                                                                    '& .MuiInput-underline': {
                                                                        borderBottomColor: '#8D8D8D',
                                                                        borderWidth: '2px',
                                                                        borderBottomStyle: 'solid' // Replace with your desired color
                                                                    },
                                                                    '& .MuiInput-underline:after': {
                                                                        borderBottomColor: '#8D8D8D',
                                                                        borderWidth: '2px',
                                                                        borderBottomStyle: 'solid' // Replace with your desired color
                                                                    },
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={1.2}>
                                                            <TextField
                                                                InputLabelProps={{ style: { color: '#8D8D8D', fontFamily: 'Ubuntu', fontSize: '15px' } }}
                                                                margin="normal"
                                                                label="Price 3"
                                                                type="number"
                                                                required
                                                                value={priceToRatePriceThree}
                                                                onChange={(e) => { setPriceToRatePriceThree(e.target.value) }}
                                                                size="small"
                                                                variant="standard"
                                                                sx={{
                                                                    '& .MuiInput-underline': {
                                                                        borderBottomColor: '#8D8D8D',
                                                                        borderWidth: '2px',
                                                                        borderBottomStyle: 'solid' // Replace with your desired color
                                                                    },
                                                                    '& .MuiInput-underline:after': {
                                                                        borderBottomColor: '#8D8D8D',
                                                                        borderWidth: '2px',
                                                                        borderBottomStyle: 'solid' // Replace with your desired color
                                                                    },
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={1.2}>
                                                            <TextField
                                                                InputLabelProps={{ style: { color: '#8D8D8D', fontFamily: 'Ubuntu', fontSize: '15px' } }}
                                                                margin="normal"
                                                                label="Price 4"
                                                                type="number"
                                                                required
                                                                value={priceToRatePriceFour}
                                                                onChange={(e) => { setPriceToRatePriceFour(e.target.value) }}
                                                                size="small"
                                                                variant="standard"
                                                                sx={{
                                                                    '& .MuiInput-underline': {
                                                                        borderBottomColor: '#8D8D8D',
                                                                        borderWidth: '2px',
                                                                        borderBottomStyle: 'solid' // Replace with your desired color
                                                                    },
                                                                    '& .MuiInput-underline:after': {
                                                                        borderBottomColor: '#8D8D8D',
                                                                        borderWidth: '2px',
                                                                        borderBottomStyle: 'solid' // Replace with your desired color
                                                                    },
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={1.2}>
                                                            <TextField
                                                                InputLabelProps={{ style: { color: '#8D8D8D', fontFamily: 'Ubuntu', fontSize: '15px' } }}
                                                                margin="normal"
                                                                label="Price 5"
                                                                type="number"
                                                                required
                                                                value={priceToRatePriceFive}
                                                                onChange={(e) => { setPriceToRatePriceFive(e.target.value) }}
                                                                size="small"
                                                                variant="standard"
                                                                sx={{
                                                                    '& .MuiInput-underline': {
                                                                        borderBottomColor: '#8D8D8D',
                                                                        borderWidth: '2px',
                                                                        borderBottomStyle: 'solid' // Replace with your desired color
                                                                    },
                                                                    '& .MuiInput-underline:after': {
                                                                        borderBottomColor: '#8D8D8D',
                                                                        borderWidth: '2px',
                                                                        borderBottomStyle: 'solid' // Replace with your desired color
                                                                    },
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Box>
                                                    <Box sx={{ display: 'flex', gap: '100px', paddingLeft: '30px' }}>
                                                        <Grid item xs={1.2}>
                                                            <TextField
                                                                InputLabelProps={{ style: { color: '#8D8D8D', fontFamily: 'Ubuntu', fontSize: '15px' } }}
                                                                margin="normal"
                                                                label="Price 1 PX P/L"
                                                                type="number"
                                                                required
                                                                value={oneZeroTwoPXPL}
                                                                onChange={(e) => { setOneZeroTwoPXPL(e.target.value) }}
                                                                size="small"
                                                                variant="standard"
                                                                sx={{
                                                                    '& .MuiInput-underline': {
                                                                        borderBottomColor: '#8D8D8D',
                                                                        borderWidth: '2px',
                                                                        borderBottomStyle: 'solid' // Replace with your desired color
                                                                    },
                                                                    '& .MuiInput-underline:after': {
                                                                        borderBottomColor: '#8D8D8D',
                                                                        borderWidth: '2px',
                                                                        borderBottomStyle: 'solid' // Replace with your desired color
                                                                    },
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={1.2}>
                                                            <TextField
                                                                InputLabelProps={{ style: { color: '#8D8D8D', fontFamily: 'Ubuntu', fontSize: '15px' } }}
                                                                margin="normal"
                                                                label="Price 2 PX P/L"
                                                                type="number"
                                                                required
                                                                value={oneZeroFourPXPL}
                                                                onChange={(e) => { setOneZeroFourPXPL(e.target.value) }}
                                                                size="small"
                                                                variant="standard"
                                                                sx={{
                                                                    '& .MuiInput-underline': {
                                                                        borderBottomColor: '#8D8D8D',
                                                                        borderWidth: '2px',
                                                                        borderBottomStyle: 'solid' // Replace with your desired color
                                                                    },
                                                                    '& .MuiInput-underline:after': {
                                                                        borderBottomColor: '#8D8D8D',
                                                                        borderWidth: '2px',
                                                                        borderBottomStyle: 'solid' // Replace with your desired color
                                                                    },
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={1.2}>
                                                            <TextField
                                                                InputLabelProps={{ style: { color: '#8D8D8D', fontFamily: 'Ubuntu', fontSize: '15px' } }}
                                                                margin="normal"
                                                                label="Price 3 PX P/L"
                                                                type="number"
                                                                required
                                                                value={oneZeroSixPXPL}
                                                                onChange={(e) => { setOneZeroSixPXPL(e.target.value) }}
                                                                size="small"
                                                                variant="standard"
                                                                sx={{
                                                                    '& .MuiInput-underline': {
                                                                        borderBottomColor: '#8D8D8D',
                                                                        borderWidth: '2px',
                                                                        borderBottomStyle: 'solid' // Replace with your desired color
                                                                    },
                                                                    '& .MuiInput-underline:after': {
                                                                        borderBottomColor: '#8D8D8D',
                                                                        borderWidth: '2px',
                                                                        borderBottomStyle: 'solid' // Replace with your desired color
                                                                    },
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={1.2}>
                                                            <TextField
                                                                InputLabelProps={{ style: { color: '#8D8D8D', fontFamily: 'Ubuntu', fontSize: '15px' } }}
                                                                margin="normal"
                                                                label="Price 4 PX P/L"
                                                                type="number"
                                                                required
                                                                value={oneZeroEightPXPL}
                                                                onChange={(e) => { setOneZeroEightPXPL(e.target.value) }}
                                                                size="small"
                                                                variant="standard"
                                                                sx={{
                                                                    '& .MuiInput-underline': {
                                                                        borderBottomColor: '#8D8D8D',
                                                                        borderWidth: '2px',
                                                                        borderBottomStyle: 'solid' // Replace with your desired color
                                                                    },
                                                                    '& .MuiInput-underline:after': {
                                                                        borderBottomColor: '#8D8D8D',
                                                                        borderWidth: '2px',
                                                                        borderBottomStyle: 'solid' // Replace with your desired color
                                                                    },
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={1.2}>
                                                            <TextField
                                                                InputLabelProps={{ style: { color: '#8D8D8D', fontFamily: 'Ubuntu', fontSize: '15px' } }}
                                                                margin="normal"
                                                                label="Price 5 PX P/L"
                                                                type="number"
                                                                required
                                                                value={oneZeroTenPXPL}
                                                                onChange={(e) => { setOneZeroTenPXPL(e.target.value) }}
                                                                size="small"
                                                                variant="standard"
                                                                sx={{
                                                                    '& .MuiInput-underline': {
                                                                        borderBottomColor: '#8D8D8D',
                                                                        borderWidth: '2px',
                                                                        borderBottomStyle: 'solid' // Replace with your desired color
                                                                    },
                                                                    '& .MuiInput-underline:after': {
                                                                        borderBottomColor: '#8D8D8D',
                                                                        borderWidth: '2px',
                                                                        borderBottomStyle: 'solid' // Replace with your desired color
                                                                    },
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Box>
                                                    <Box sx={{ display: 'flex', gap: '100px', paddingLeft: '30px' }}>
                                                        <Grid item xs={1.2}>
                                                            <TextField
                                                                InputLabelProps={{ style: { color: '#8D8D8D', fontFamily: 'Ubuntu', fontSize: '15px' } }}
                                                                margin="normal"
                                                                label="Price 1 PX C/L"
                                                                type="number"
                                                                required
                                                                value={priceToRatePriceOneCLPX}
                                                                onChange={(e) => { setPriceToRatePriceOneCLPX(e.target.value) }}
                                                                size="small"
                                                                variant="standard"
                                                                sx={{
                                                                    '& .MuiInput-underline': {
                                                                        borderBottomColor: '#8D8D8D',
                                                                        borderWidth: '2px',
                                                                        borderBottomStyle: 'solid' // Replace with your desired color
                                                                    },
                                                                    '& .MuiInput-underline:after': {
                                                                        borderBottomColor: '#8D8D8D',
                                                                        borderWidth: '2px',
                                                                        borderBottomStyle: 'solid' // Replace with your desired color
                                                                    },
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={1.2}>
                                                            <TextField
                                                                InputLabelProps={{ style: { color: '#8D8D8D', fontFamily: 'Ubuntu', fontSize: '15px' } }}
                                                                margin="normal"
                                                                label="Price 2 PX C/L "
                                                                type="number"
                                                                required
                                                                value={priceToRatePriceTwoCLPX}
                                                                onChange={(e) => { setPriceToRatePriceTwoCLPX(e.target.value) }}
                                                                size="small"
                                                                variant="standard"
                                                                sx={{
                                                                    '& .MuiInput-underline': {
                                                                        borderBottomColor: '#8D8D8D',
                                                                        borderWidth: '2px',
                                                                        borderBottomStyle: 'solid' // Replace with your desired color
                                                                    },
                                                                    '& .MuiInput-underline:after': {
                                                                        borderBottomColor: '#8D8D8D',
                                                                        borderWidth: '2px',
                                                                        borderBottomStyle: 'solid' // Replace with your desired color
                                                                    },
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={1.2}>
                                                            <TextField
                                                                InputLabelProps={{ style: { color: '#8D8D8D', fontFamily: 'Ubuntu', fontSize: '15px' } }}
                                                                margin="normal"
                                                                label="Price 3 PX C/L"
                                                                type="number"
                                                                required
                                                                value={priceToRatePriceThreeCLPX}
                                                                onChange={(e) => { setPriceToRatePriceThreeCLPX(e.target.value) }}
                                                                size="small"
                                                                variant="standard"
                                                                sx={{
                                                                    '& .MuiInput-underline': {
                                                                        borderBottomColor: '#8D8D8D',
                                                                        borderWidth: '2px',
                                                                        borderBottomStyle: 'solid' // Replace with your desired color
                                                                    },
                                                                    '& .MuiInput-underline:after': {
                                                                        borderBottomColor: '#8D8D8D',
                                                                        borderWidth: '2px',
                                                                        borderBottomStyle: 'solid' // Replace with your desired color
                                                                    },
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={1.2}>
                                                            <TextField
                                                                InputLabelProps={{ style: { color: '#8D8D8D', fontFamily: 'Ubuntu', fontSize: '15px' } }}
                                                                margin="normal"
                                                                label="Price 4 PX C/L"
                                                                type="number"
                                                                required
                                                                value={priceToRatePriceFourCLPX}
                                                                onChange={(e) => { setPriceToRatePriceFourCLPX(e.target.value) }}
                                                                size="small"
                                                                variant="standard"
                                                                sx={{
                                                                    '& .MuiInput-underline': {
                                                                        borderBottomColor: '#8D8D8D',
                                                                        borderWidth: '2px',
                                                                        borderBottomStyle: 'solid' // Replace with your desired color
                                                                    },
                                                                    '& .MuiInput-underline:after': {
                                                                        borderBottomColor: '#8D8D8D',
                                                                        borderWidth: '2px',
                                                                        borderBottomStyle: 'solid' // Replace with your desired color
                                                                    },
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={1.2}>
                                                            <TextField
                                                                InputLabelProps={{ style: { color: '#8D8D8D', fontFamily: 'Ubuntu', fontSize: '15px' } }}
                                                                margin="normal"
                                                                label="Price 5 PX C/L"
                                                                type="number"
                                                                required
                                                                value={priceToRatePriceFiveCLPX}
                                                                onChange={(e) => { setPriceToRatePriceFiveCLPX(e.target.value) }}
                                                                size="small"
                                                                variant="standard"
                                                                sx={{
                                                                    '& .MuiInput-underline': {
                                                                        borderBottomColor: '#8D8D8D',
                                                                        borderWidth: '2px',
                                                                        borderBottomStyle: 'solid' // Replace with your desired color
                                                                    },
                                                                    '& .MuiInput-underline:after': {
                                                                        borderBottomColor: '#8D8D8D',
                                                                        borderWidth: '2px',
                                                                        borderBottomStyle: 'solid' // Replace with your desired color
                                                                    },
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Box>
                                                </Stack>
                                            </>
                                    }
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </div>
    )
}
