import React, { useState, useEffect } from "react";
import {
  Container,
  Grid,
  Paper,
  Button,
  Typography,
  Box,
  List,
  ListItem,
  TextField,
  Dialog,
  Alert,
  AlertTitle,
} from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import logo from "../images/kimmymae-removebg-preview 1.png";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import mudzie from "../images/Color logo - no background.png";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import twitter from "../images/twitter 1.png";
import facebook from "../images/facebook-app-symbol 1.png";
import linkdin from "../images/linkedin (2) 1.png";
import emailjs from "@emailjs/browser";
import { useNavigate } from "react-router-dom";
import Logo from "../images/Color logo with background.png";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1740,
    },
  },
});

const Investors = () => {
  const [showForm, setShowForm] = useState(false);
  const [showDeleteConfirm, setshowDeleteConfirm] = useState(false);
  const [showerror, setshowerror] = useState(false);

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    company: "",
    phoneNumber: "",
  });
  const navigate = useNavigate();

  useEffect(() => {
    if (showDeleteConfirm) {
      const timer = setTimeout(() => {
        setshowDeleteConfirm(false);
      }, 1000); // 2 seconds (2000 milliseconds)

      return () => {
        clearTimeout(timer); // Clear the timeout if the component unmounts or showConfirmation becomes false
      };
    }
    if (showerror) {
      const timer = setTimeout(() => {
        setshowerror(false);
      }, 1000); // 2 seconds (2000 milliseconds)

      return () => {
        clearTimeout(timer); // Clear the timeout if the component unmounts or showConfirmation becomes false
      };
    }
  }, [showDeleteConfirm, showerror]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handlePhoneChange = (value) => {
    setFormData({
      ...formData,
      phoneNumber: value,
    });
  };

  const handleButtonClick = () => {
    setShowForm(true);
  };
  // const handleChange = (phoneNumber) => {
  //   setValue(phoneNumber);
  // };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("++++++++++++++++++++", formData);

    // Replace with your Email.js USER_ID, SERVICE_ID, and TEMPLATE_ID
    const USER_ID = "VoamUqyg0SRxTowzX";
    const SERVICE_ID = "service_z3b0zme";
    const TEMPLATE_ID = "template_79860re";
    const publicKey = "VoamUqyg0SRxTowzX";
    const AUTO_TEMPLATE_ID = "template_71c9m2w";

    // Send email using Email.js
    emailjs
      .send(
        SERVICE_ID,
        TEMPLATE_ID,
        {
          to_name: "Mudzie Team",
          from_name: `${formData.firstName} ${formData.lastName}`,
          message: `Company: ${formData.company}\nEmail: ${formData.email}\nPhone Number: ${formData.phoneNumber}`,
          reply_to: formData.email,
        },
        publicKey
      )
      .then((response) => {
        console.log("Email sent:", response);
        setshowDeleteConfirm(true);
        setFormData({
          firstName: "",
          lastName: "",
          email: "",
          company: "",
          phoneNumber: "",
        });
      })
      .catch((error) => {
        console.error("Email error:", error);
        setshowerror(true);
        setFormData({
          firstName: "",
          lastName: "",
          email: "",
          company: "",
          phoneNumber: "",
        });
        // Add your error handling logic here
      });
  };

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          flexDirection: "row",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          background: "linear-gradient(to right bottom, #1976D2, #175FA2)",
          height: "100px",
        }}
      >
        <Box sx={{ ml: { xl: 15, lg: 10, sm: 10, xs: 0 } }}>
          <img
            src={mudzie}
            alt="logo"
            width={200}
            height={60}
            // style={{ marginLeft: "80px", marginRight: "-30px" }}
          />
        </Box>
        {/* <Typography
          component="h3"
          variant="h5"
          color="white"
          fontFamily="Ubuntu"
          sx={{
            fontWeight: "500",
            mr: { xl: "60%", xs: "5%", sm: 4, lg: 79 },
            fontFamily: "Ubuntu",
            ml: { sm: 2, xs: 0, xl: -16 },
          }}
        >
          MUDZIE
        </Typography> */}
        {/* <Typography
                            sx={{
								color: "white",
								marginLeft: "180px",
								fontFamily: "Ubuntu",
								fontSize: "10px",
								ml: { xl: 4, sm: 16, xs: 0 },
								mr: { xl: -16 },
							  }}
                        >
                            Hello,Prshant.hanamg...
                        </Typography> */}
        {/* <Button
            variant="contained"
            sx={{
              pr: { sm: 5 },
              pl: { sm: 5 },
              fontSize: { sm: 14 },
              backgroundColor: "white",
              color: "black",
              mr: { xl: 10, lg: 10, sm: 10, xs: 2 },
            }}
            onClick={() => navigate("/payments", { replace: true })}
          >
            Subscribe
          </Button> */}
        {/* <Button
          variant="contained"
          sx={{
            pr: { sm: 5 },
            pl: { sm: 5 },
            fontSize: { sm: 14 },
            backgroundColor: "white",
            color: "black",
            mr: { xl: 10, lg: 10, sm: 10, xs: 2 },
          }}
          onClick={() => navigate("/", { replace: true })}
        >
          Logout
        </Button> */}
      </Box>
      <Container>
        <Grid container spacing={4} alignItems="center">
          {showForm ? (
            // Form Section
            <Grid item xs={12} sx={{ mt: 10 }}>
              <Box sx={{ width: "76%", textAlign: "center" }}>
                <Typography variant="h6" gutterBottom fontFamily="Ubuntu">
                  Founding License Application Form
                </Typography>
              </Box>
              <form
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
                onSubmit={handleSubmit}
              >
                <TextField
                  label="First Name"
                  fullWidth
                  required
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  sx={{ width: "50%" }}
                  margin="normal"
                  variant="standard"
                  placeholder="Sahil"
                />

                <TextField
                  label="Last Name"
                  fullWidth
                  required
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                  margin="normal"
                  sx={{ width: "50%" }}
                  variant="standard"
                  placeholder="Sahil"
                />

                <PhoneInput
                  inputStyle={{
                    width: "100%",
                    fontSize: "16px",
                    border: "none",
                  }}
                  containerStyle={{
                    width: "50%",
                    borderBottom: "1px solid #b3b3b3",
                    marginTop: "20px",
                    marginBottom: "20px",
                    padding: "10px",
                  }}
                  country={"us"} // Set the default country
                  value={formData.phoneNumber}
                  onChange={handlePhoneChange}
                  placeholder="Enter Phone Number"
                />

                <TextField
                  label="Email"
                  fullWidth
                  required
                  name="email"
                  type="email"
                  value={formData.email}
                  onChange={handleChange}
                  sx={{ width: "50%" }}
                  margin="normal"
                  variant="standard"
                  placeholder="name@example.com"
                />

                <TextField
                  label="Company"
                  fullWidth
                  required
                  name="company"
                  value={formData.company}
                  onChange={handleChange}
                  sx={{ width: "50%" }}
                  margin="normal"
                  variant="standard"
                  placeholder="Acme Corporation"
                />

                <Box sx={{ width: "50%", textAlign: "flex-start" }}>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    style={{
                      marginTop: "16px",
                      alignSelf: "flex-start",
                      fontFamily: "Ubuntu",
                    }}
                  >
                    Submit
                  </Button>
                </Box>
              </form>
            </Grid>
          ) : (
            // Content Section
            <>
              <Grid item xs={12} sm={6}>
                {/* <Paper elevation={3} style={{ padding: "20px" }}> */}
                {/* Logo goes here */}
                <img
                  src={Logo}
                  alt="Logo"
                  style={{
                    maxWidth: "100%",
                    backgroundColor: "black",
                    padding: "0",
                  }}
                  // height="200px"
                />
                {/* </Paper> */}
              </Grid>

              <Grid item xs={12} sm={6} sx={{ marginTop: "20px" }}>
                {/* <Paper elevation={3} style={{ padding: "20px" }}> */}
                <Typography variant="h6" gutterBottom>
                  Founding License Benefits:
                </Typography>
                <List>
                  {/* List Item 1 */}
                  <ListItem>
                    <Typography
                      variant="body1"
                      color="textSecondary"
                      gutterBottom
                      sx={{ display: "flex", py: 2 }}
                    >
                      <CheckCircleOutlineIcon
                        color="primary"
                        sx={{ marginRight: 1 }}
                      />
                      Lenders must commit to loading all of their HUD and DUS
                      loans (when available) into KimmyMae.
                    </Typography>
                  </ListItem>

                  {/* List Item 2 */}
                  <ListItem>
                    <Typography
                      variant="body1"
                      color="textSecondary"
                      gutterBottom
                      sx={{ display: "flex", py: 2 }}
                    >
                      <CheckCircleOutlineIcon
                        color="primary"
                        sx={{ marginRight: 1 }}
                      />
                      Unlimited Mudzie benefits, depending on which of your
                      teammates should receive information (all Mudzie versions
                      are available).
                    </Typography>
                  </ListItem>

                  {/* List Item 3 */}
                  <ListItem>
                    <Typography
                      variant="body1"
                      color="textSecondary"
                      gutterBottom
                      sx={{ display: "flex", py: 2 }}
                    >
                      <CheckCircleOutlineIcon
                        color="primary"
                        sx={{ marginRight: 1 }}
                      />
                      Mudzie calculations are linked to each loan in the
                      KimmyMae platform. If you want instant indications for a
                      specific loan at $102.5, for example, Mudzie will be tied
                      into your loan dashboard and will make those calculations
                      for each loan in submitted pre-auction status.
                    </Typography>
                  </ListItem>

                  {/* List Item 4 */}
                  <ListItem>
                    <Typography
                      variant="body1"
                      color="textSecondary"
                      gutterBottom
                      sx={{ display: "flex", py: 2 }}
                    >
                      <CheckCircleOutlineIcon
                        color="primary"
                        sx={{ marginRight: 1 }}
                      />
                      Request specific rate/price indications for each loan as
                      if the loan was going to auction. (An “indication
                      auction”).
                    </Typography>
                  </ListItem>

                  {/* List Item 5 */}
                  <ListItem>
                    <Typography
                      variant="body1"
                      color="textSecondary"
                      gutterBottom
                      sx={{ display: "flex", py: 2 }}
                    >
                      <CheckCircleOutlineIcon
                        color="primary"
                        sx={{ marginRight: 1 }}
                      />
                      No KimmyMae trading fees ($2500/loan value) for 12 months
                    </Typography>
                  </ListItem>

                  {/* List Item 6 */}
                  <ListItem>
                    <Typography
                      variant="body1"
                      color="textSecondary"
                      gutterBottom
                      sx={{ display: "flex", py: 2 }}
                    >
                      <CheckCircleOutlineIcon
                        color="primary"
                        sx={{ marginRight: 1 }}
                      />
                      Founding partner messaging on KimmyMae website and in
                      KimmyMae and Mudzie marketing copy.
                    </Typography>
                  </ListItem>

                  {/* List Item 7 */}
                  <ListItem>
                    <Typography
                      variant="body1"
                      color="textSecondary"
                      gutterBottom
                      sx={{ display: "flex", py: 2 }}
                    >
                      <CheckCircleOutlineIcon
                        color="primary"
                        sx={{ marginRight: 1 }}
                      />
                      Peferred rate lock action from the investors.
                    </Typography>
                  </ListItem>
                </List>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleButtonClick}
                >
                  Sign Up for a Founding License
                </Button>
                {/* </Paper> */}
              </Grid>

              {/* <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleButtonClick}
              >
                Sign Up for a Founding License
              </Button>
            </Grid> */}
            </>
          )}
        </Grid>
      </Container>
      <Box
        width="100%"
        height="80px"
        sx={{
          background: "linear-gradient(to right bottom, #1976D2, #175FA2)",
          mt: 5,
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box height="100%" sx={{ display: "flex", alignItems: "center" }}>
          <Typography
            sx={{
              fontFamily: "Ubuntu",
              fontSize: "10px",
              color: "white",
              pl: 5,
            }}
          >
            Copyright © 2023 MUDZIE - All Rights Reserved | Powered by KimmyMae™
          </Typography>
        </Box>
        <Box>
          <Typography
            sx={{
              fontFamily: "Ubuntu",
              fontSize: "10px",
              color: "white",
              pr: 8,
              mt: 2,
            }}
          >
            Privacy policy | Terms and conditions
          </Typography>
          <hr style={{ width: "90%", marginLeft: "-5px" }} />
          <Box sx={{ display: "flex", gap: "10px", marginLeft: "100px" }}>
            <img src={twitter} alt="" width={20} height={20} />
            <img src={facebook} alt="" width={20} height={20} />
            <img src={linkdin} alt="" width={20} height={20} />
          </Box>
        </Box>
      </Box>
      <Dialog
        open={showDeleteConfirm}
        onClose={() => setshowDeleteConfirm(false)}
      >
        <div>
          {showDeleteConfirm && (
            <Alert
              severity="success"
              onClose={() => setshowDeleteConfirm(false)}
            >
              <AlertTitle>success</AlertTitle>
              Email Send Successfully
            </Alert>
          )}
        </div>
      </Dialog>

      <Dialog open={showerror} onClose={() => setshowerror(false)}>
        <div>
          {showDeleteConfirm && (
            <Alert severity="error" onClose={() => setshowerror(false)}>
              <AlertTitle>Error</AlertTitle>
              Email not send Successfully
            </Alert>
          )}
        </div>
      </Dialog>
    </ThemeProvider>
  );
};

export default Investors;
