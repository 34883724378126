import React, { useState, useEffect } from "react";
import Axios from "axios";
import Navbar from "../components/Navbar";
import Sidenav from "../components/Sidenav";
//import Stack from "@mui/material/Stack";
import SearchIcon from "@mui/icons-material/Search";
import "../dash.css";
// import TablePagination from '@mui/material/TablePagination';
import { InputAdornment, Typography } from "@mui/material";
import {
  Box,
  Card,
  CardHeader,
  CardContent,
  Grid,
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  TablePagination,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Navigate, useNavigate } from "react-router-dom";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import ReactJsAlert from "reactjs-alert";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1740,
    },
  },
});

const Showuser = () => {
  let rows = [];
  const [loading, setLoading] = useState(true);
  const [rowsState, setRowsState] = useState();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [editRowIndex, setEditRowIndex] = useState(null);
  const [isEditFormOpen, setIsEditFormOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [deleteRowIndex, setDeleteRowIndex] = useState(null);

  const columns = [
    { id: "logId", label: "Log Id" },
    { id: "name", label: "Name" },
    { id: "contactNumber", label: "Contact Number" },
    { id: "emailId", label: "Email" },
    // { id: "mudziePlan", label: "Plan" },
    // { id: "expiryDate", label: "Expiry Date" },
    // { id: "companyName", label: "Company" },
    // { id: "poNumber", label: "PO Number" },
  ];

  function createData(
    logId,
    id,
    name,
    contactNumber,
    emailId,
    password,
    mudziePlan,
    expiryDate,
    companyName,
    poNumber,
    is_call_requested,
    isVerified,
    is_active_plan,
    added_by
  ) {
    return {
      logId,
      id,
      name,
      contactNumber,
      emailId,
      password,
      mudziePlan,
      expiryDate,
      companyName,
      poNumber,
      is_call_requested,
      isVerified,
      is_active_plan,
      added_by,
    };
  }
  const [filterValue, setFilterValue] = useState("");
  // const [token, settoken] = useState(null);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const navigate = useNavigate();

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [alertStatus, setAlertStatus] = React.useState(false);
  const [type, setType] = React.useState("success");
  const [title, setTitle] = React.useState("");

  // useEffect(() => {
  //   const user = localStorage.getItem("user");
  //   const userde = JSON.parse(user);
  //   settoken(userde.token);
  // }, []);

  const [update, setUpdate] = useState(false);
  const [delet, setDelete] = useState(false);

  useEffect(() => {
    const user = localStorage.getItem("user");
    const userde = JSON.parse(user);
    const token = userde.token;
    const fetchData = async () => {
      try {
        const response = await Axios.get(
          `${process.env.REACT_APP_BASE_URL}api/admin/get-all-users`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.data.length < 1) {
          console.log("Records not found");
        } else {
          console.log(">>>>>>>>>>>>>>>>>>>>>>", response.data);
          const newRows = response.data.map((row, index) =>
            createData(
              index + 1,
              row.id,
              row.name,
              row.contactNumber,
              row.emailId,
              row.password,
              row.mudziePlan,
              row.expiryDate,
              row.companyName,
              row.poNumber,
              row.is_call_requested,
              row.isVerified,
              row.is_active_plan,
              row.added_by
            )
          );

          setRowsState(newRows);
          setLoading(false); // Update the state with the new rows
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData(); // Call the async function to fetch data
  }, [update, delet]);

  console.log("-------------------------++++++++++++++++=", rowsState);

  const handleClick = (index) => {
    console.log("))))))))))))", index);
    // <Navigate to="/viewdetails" state={selectedRow} />;

    const queryString = new URLSearchParams(index).toString();
    console.log("##############", queryString);
    navigate(`/viewdetails`, { state: { logId: index } });
  };

  const [editFormData, setEditFormData] = useState({
    user_id: "",
    name: "",
    contactNumber: 0,
    emailId: "",
    password: "",
    mudziePlan: "",
    password: "",
    expiryDate: "",
    companyName: "",
    poNumber: "",
    isVerified: "",
    is_active_plan: "",
  });

  const handleEditClick = async (index) => {
    setIsEditFormOpen(true);
    console.log("-----", index);
    setEditRowIndex(index);
    const selectedRow = rowsState[index - 1];
    setEditFormData({
      user_id: selectedRow.id,
      name: selectedRow.name,
      contactNumber: selectedRow.contactNumber,
      emailId: selectedRow.emailId,
      password: selectedRow.password,
      mudziePlan: selectedRow.mudziePlan,
      expiryDate: selectedRow.expiryDate,
      companyName: selectedRow.companyName,
      poNumber: selectedRow.poNumber,
      isVerified: selectedRow.isVerified,
      is_active_plan: selectedRow.is_active_plan,
      // ginnieYieldTableId: selectedRow.ginnieYieldTableId,
      // permAtPar: selectedRow.permAtPar,
      // description: selectedRow.description,
      // descriptionNew: selectedRow.descriptionNew,
    });
  };

  const currentDate = new Date();
  const formattedCurrentDate = currentDate.toISOString().split("T")[0];

  const handleEditFieldChange = (value, fieldName) => {
    setEditFormData((prevData) => ({
      ...prevData,
      [fieldName]: value,
    }));
  };

  const handleEditFormSubmit = (e) => {
    const user = localStorage.getItem("user");
    const userde = JSON.parse(user);
    const token = userde.token;
    e.preventDefault();
    const inputDate = new Date(editFormData.expiryDate);
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    let temp_is_active_plan;
    if(inputDate > today)
      temp_is_active_plan = 1;
    else
      temp_is_active_plan = editFormData.is_active_plan;
    const editedData = {
      user_id: editFormData.user_id,
      name: editFormData.name,
      contactNumber: editFormData.contactNumber,
      emailId: editFormData.emailId,
      password: editFormData.password,
      mudziePlan: editFormData.mudziePlan,
      expiryDate: editFormData.expiryDate,
      poNumber: editFormData.poNumber,
      isVerified: editFormData.isVerified,
      is_active_plan: temp_is_active_plan,
      companyName: editFormData.companyName,
      //  descriptionNew: editFormData.descriptionNew,
    };

    console.log("333333333333", editedData);
    Axios.post(
      `${process.env.REACT_APP_BASE_URL}api/admin/update-user`,
      editedData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((res) => {
        console.log("!!!!!!!!!!!", res.data);
        setIsEditFormOpen(false);
        setUpdate(true);
        setAlertStatus(true);
        setType("success");
        setTitle("User data has been updated");
      })
      .catch((error) => {
        console.log(error);
        setIsEditFormOpen(false);
        setAlertStatus(true);
        setType("Error");
        setTitle("User data not updated");
      });
  };

  const handleDeleteClick = (id) => {
    setIsDeleteDialogOpen(true);
    console.log("===============", id);
    // Axios.post("https://mudzie.com/admin/public/api/deleteGraphData", {
    //   ginnieYieldTableId: ginnieYieldTableId,
    // })
    //   .then((response) => {
    //     console.log("Item deleted successfully:", response.data);
    //     // Update the state to reflect the deletion
    //   })
    //   .catch((error) => {
    //     console.error("Error deleting item:", error);
    //   })
    setDeleteRowIndex(id);
    //  setEditRowIndex(null);
  };

  const handleDeleteConfirmation = () => {
    const user = localStorage.getItem("user");
    const userde = JSON.parse(user);
    console.log("+++++++++++++++++++++", deleteRowIndex);
    Axios.post(
      `${process.env.REACT_APP_BASE_URL}api/admin/delete-user`,
      {
        user_id: deleteRowIndex,
      },
      {
        headers: {
          Authorization: `Bearer ${userde.token}`,
        },
      }
    )
      .then((response) => {
        console.log("Item deleted successfully:", response.data);
        setDelete(true);
        setAlertStatus(true);
        setType("success");
        setTitle("User deleted successfully");
        //setshowDeleteConfirm(true);
      })
      .catch((error) => {
        console.error("Error deleting item:", error);
        setAlertStatus(true);
        setType("error");
        setTitle("User not deleted successfully");
      });

    setIsDeleteDialogOpen(false);
  };

  return (
    <div>
      <Navbar />
      <Box height={70} />
      <Box sx={{ display: "flex" }}>
        <Sidenav menuHeighlightPath = "/dashboard/showuser" />
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Card>
                <CardHeader
                  title="Show Users"
                  action={
                    <>
                      {" "}
                      <TextField
                        value={filterValue}
                        onChange={(e) => setFilterValue(e.target.value)}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon />
                            </InputAdornment>
                          ),
                        }}
                        variant="standard"
                      />
                    </>
                  }
                />
                <CardContent>
                  <Paper sx={{ width: "100%", overflow: "hidden" }}>
                    <TableContainer sx={{ maxHeight: 700, maxWidth: "100%" }}>
                      <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                          <TableRow>
                            {columns.map((column, index) => (
                              <TableCell
                                key={index}
                                // align={column.align}
                                align="center"
                                style={{
                                  minWidth: column.minWidth,
                                }}
                              >
                                {column.label}
                              </TableCell>
                            ))}
                            <TableCell align="center">Added By</TableCell>
                            <TableCell sx={{ pl: "30px" }} align="center">
                              View Details
                            </TableCell>
                            <TableCell sx={{ pl: 4 }}>Edit</TableCell>
                            <TableCell sx={{ pl: 3 }}>Delete</TableCell>
                            {/* <TableCell>Delete</TableCell> */}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {loading ? (
                            <TableRow>
                              <TableCell colSpan={9}>Loading...</TableCell>
                            </TableRow>
                          ) : rowsState.length ? (
                            rowsState
                              .filter((row) => {
                                return filterValue.toLowerCase() === ""
                                  ? row
                                  : row.name.toString().includes(filterValue) ||
                                      row.emailId
                                        .toString()
                                        .toLowerCase()
                                        .includes(filterValue);
                              })
                              .slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                              )
                              .map((row, index) => {
                                return (
                                  <TableRow
                                    hover
                                    role="checkbox"
                                    tabIndex={-1}
                                    key={index}
                                  >
                                    {columns.map((column, dindex) => {
                                      const value = row[column.id];
                                      return (
                                        <TableCell
                                          // align={column.align}
                                          align="center"
                                          key={dindex}
                                        >
                                          {column.format &&
                                          typeof value === "number"
                                            ? column.format(value)
                                            : value}
                                        </TableCell>
                                      );
                                    })}
                                    <TableCell align="center">
                                      {row.added_by == "admin" ? (
                                        <Typography>Admin</Typography>
                                      ) : (
                                        <></>
                                      )}
                                    </TableCell>
                                    <TableCell align="center">
                                      <Button
                                        onClick={() => handleClick(row.id)}
                                      >
                                        View Details
                                      </Button>
                                    </TableCell>
                                    <TableCell>
                                      <Button
                                        onClick={() =>
                                          handleEditClick(row.logId)
                                        }
                                      >
                                        <EditIcon />
                                      </Button>
                                    </TableCell>
                                    <TableCell>
                                      <Button
                                        onClick={() =>
                                          handleDeleteClick(row.id)
                                        }
                                      >
                                        <DeleteIcon />
                                      </Button>
                                    </TableCell>
                                  </TableRow>
                                );
                              })
                          ) : (
                            <TableRow>
                              <TableCell colSpan={9}>
                                No records found
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    {loading ? (
                      <></>
                    ) : (
                      <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={rowsState.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    )}
                  </Paper>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Dialog
        open={isEditFormOpen}
        onClose={() => setIsEditFormOpen(false)}
        fullWidth
      >
        <form onSubmit={handleEditFormSubmit}>
          <DialogTitle>Edit Entry</DialogTitle>
          <DialogContent>
            {editRowIndex !== null && (
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Name"
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    value={editFormData.name}
                    onChange={(e) =>
                      handleEditFieldChange(e.target.value, "name")
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="ContactNumber"
                    variant="outlined"
                    required
                    fullWidth
                    sx={{ mt: { xl: 2, lg: 2, md: 2, xs: 0, sm: 2 } }}
                    value={editFormData.contactNumber}
                    onChange={(e) =>
                      handleEditFieldChange(e.target.value, "contactNumber")
                    }
                    error={isNaN(editFormData.contactNumber)}
                    helperText={
                      isNaN(editFormData.contactNumber)
                        ? "Please enter number"
                        : ""
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Email ID"
                    variant="outlined"
                    required
                    fullWidth
                    value={editFormData.emailId}
                    onChange={(e) =>
                      handleEditFieldChange(e.target.value, "emailId")
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth variant="outlined" required>
                    <InputLabel htmlFor="mudziePlan">MudZie Plan</InputLabel>
                    <Select
                      label="MudZie Plan"
                      id="mudziePlan"
                      value={editFormData.mudziePlan}
                      onChange={(e) =>
                        handleEditFieldChange(e.target.value, "mudziePlan")
                      }
                    >
                      <MenuItem value="Basic">Basic</MenuItem>
                      <MenuItem value="Pro" disabled>
                        Pro
                      </MenuItem>
                      <MenuItem value="Ultra Pro" disabled>
                        Ultra Pro
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Expiry Date"
                    type="date"
                    variant="outlined"
                    required
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      min: formattedCurrentDate, // Set the minimum date to today
                    }}
                    value={editFormData.expiryDate}
                    onChange={(e) =>
                      handleEditFieldChange(e.target.value, "expiryDate")
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Company Name"
                    variant="outlined"
                    required
                    fullWidth
                    value={editFormData.companyName}
                    onChange={(e) =>
                      handleEditFieldChange(e.target.value, "companyName")
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="PO Number"
                    variant="outlined"
                    fullWidth
                    value={editFormData.poNumber}
                    onChange={(e) =>
                      handleEditFieldChange(e.target.value, "poNumber")
                    }
                  />
                </Grid>
              </Grid>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setIsEditFormOpen(false)}>Cancel</Button>

            <Button type="submit" color="primary" variant="contained">
              Update
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      {/* delete dialog */}

      <Dialog
        open={isDeleteDialogOpen}
        onClose={() => setIsDeleteDialogOpen(false)}
      >
        <DialogTitle>Delete user</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this user? This will delete all data
          related to this user!
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setIsDeleteDialogOpen(false)}
            color="error"
            variant="contained"
          >
            Cancel
          </Button>
          <Button
            onClick={handleDeleteConfirmation}
            color="primary"
            variant="contained"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <ReactJsAlert
        status={alertStatus} // true or false
        type={type} // success, warning, error, info
        title={title}
        autoCloseIn="30000"
        Close={() => {
          setAlertStatus(false);
          //   navigate("/", { replace: true });
        }}
      />
    </div>
  );
};

export default Showuser;
