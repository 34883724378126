import React from 'react'
import Sidenav from '../components/Sidenav'
import Navbar from '../components/Navbar'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import "../dash.css";
import StorefrontIcon from '@mui/icons-material/Storefront';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import AccordionDash from '../components/AccordionDash';
import BarChart from '../charts/BarChart';
import CountUp from 'react-countup';

export default function Dashboard() {
  return (
    <>
      <div className='bgcolor'>
        <Navbar />
        <Box height={70} />
        <Box sx={{ display: 'flex' }}>
          <Sidenav menuHeighlightPath = "/dashboard/settings" />
          <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={8}>
                <Stack spacing={2} direction={"row"}>
                  <Card sx={{ minWidth: 49 + "%", height: 160 }} className="gradient">
                    <CardContent>
                      <div className='iconcolorwhite'>
                        <CreditCardIcon />
                      </div>
                      <Typography gutterBottom variant="h5" component="div" sx={{ color: '#ffffff' }}>
                        $<CountUp delay={0.4} end={500.00} duration={0.6} />
                      </Typography>
                      <Typography gutterBottom variant="body2" component="div" sx={{ color: '#ffffff' }}>
                        Total Earnings
                      </Typography>
                    </CardContent>
                  </Card>
                  <Card sx={{ minWidth: 49 + "%", height: 160 }} className="gradientlight">
                    <CardContent>
                      <div className='iconcolorwhite'>
                        <ShoppingBagIcon />
                      </div>
                      <Typography gutterBottom variant="h5" component="div" sx={{ color: '#ffffff' }}>
                        $<CountUp delay={0.4} end={900.00} duration={0.6} />
                      </Typography>
                      <Typography gutterBottom variant="body2" component="div" sx={{ color: '#ffffff' }}>
                        Total Orders
                      </Typography>
                    </CardContent>
                  </Card>
                </Stack>
              </Grid>
              <Grid item xs={4}>
                <Stack spacing={2}>
                  <Card className="gradientlight">
                    <Stack spacing={2} direction='row'>
                      <div className='iconstyle iconcolorwhite'>
                        <StorefrontIcon />
                      </div>
                      <div className='paddingall iconcolorwhite'>
                        <span className='pricetitle'>$<CountUp delay={0.4} end={203} duration={0.6} />K</span>
                        <br />
                        <span className='pricesubtitle'>Total Income</span>
                      </div>
                    </Stack>
                  </Card>
                  <Card sx={{ maxWidth: 345 }}>
                    <Stack spacing={2} direction='row'>
                      <div className='iconstyle iconcolorblack'>
                        <StorefrontIcon />
                      </div>
                      <div className='paddingall'>
                        <span className='pricetitle'>$<CountUp delay={0.4} end={203} duration={0.6} />K</span>
                        <br />
                        <span className='pricesubtitle'>Total Income</span>
                      </div>
                    </Stack>
                  </Card>
                </Stack>
              </Grid>
            </Grid>
            <Box height={20} />
            <Grid container spacing={2}>
              <Grid item xs={8}>
                <Card sx={{ height: 60 + "vh" }}>
                  <CardContent>
                    <BarChart />
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={4}>
                <Card sx={{ height: 60 + "vh" }}>
                  <CardContent>
                    <div className='paddingall'>
                      <span className='pricetitle'>Popular Products</span>
                    </div>
                    <AccordionDash />
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </div>
    </>
  )
}
