import React, { useState, useEffect } from "react";
import Axios from "axios";
import Navbar from "../components/Navbar";
import Sidenav from "../components/Sidenav";
//import Stack from "@mui/material/Stack";
import SearchIcon from "@mui/icons-material/Search";
import "../dash.css";
// import TablePagination from '@mui/material/TablePagination';
import { InputAdornment } from "@mui/material";
import {
  Box,
  Card,
  CardHeader,
  CardContent,
  Grid,
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  TablePagination,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1740,
    },
  },
});

const Charttable = () => {
  const columns = [
    { id: "logId", label: "Log Id" },
    { id: "yieldDate", label: "Yield Date" },
    { id: "tenYearRate", label: "10-Year Rate" },
    { id: "swap", label: "Swap" },
    { id: "SOFRTen", label: "SOFR-10" },
    { id: "permAtOneZeroFour", label: "Perm At-104" },
    { id: "FHARate", label: "FHARate" },
    // { id: "ginnieYieldTableId", label: "ginnieYield Table" },
    { id: "permAtOneZeroTow", label: "Perm At-102" },
    { id: "permAtPar", label: "Perm AtPar" },
    { id: "description", label: "Description-1" },
    { id: "descriptionNew", label: "Description-2" },
  ];
  function createData(
    logId,
    yieldDate,
    tenYearRate,
    swap,
    SOFRTen,
    permAtOneZeroFour,
    FHARate,
    permAtOneZeroTow,
    permAtPar,
    description,
    descriptionNew,
    ginnieYieldTableId
  ) {
    return {
      logId,
      yieldDate,
      tenYearRate,
      swap,
      SOFRTen,
      permAtOneZeroFour,
      FHARate,
      permAtOneZeroTow,
      permAtPar,
      description,
      descriptionNew,
      ginnieYieldTableId,
    };
  }

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [formData, setFormData] = useState({
    yieldDate: "",
    tenYearRate: "",
    swap: "",
    SOFRTen: "",
    permAtOneZeroFour: "",
    FHARate: "",
    permAtOneZeroTow: "",
    permAtPar: "",
    description: "",
    descriptionNew: "",
  });
  const [showError, setShowError] = useState(false);
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

  const handleTextFieldClick = () => {
    setIsDatePickerOpen(true);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  let rows = [];
  const [loading, setLoading] = useState(true);
  const [rowsState, setRowsState] = useState();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showDeleteConfirm, setshowDeleteConfirm] = useState(false);
  const [showUpdate, setshowUpdate] = useState(false);
  const [showerror, setshowerror] = useState(false);
  const [showupdateerror, setshowupdateerror] = useState(false);

  useEffect(() => {
    if (showConfirmation) {
      const timer = setTimeout(() => {
        setShowConfirmation(false);
      }, 1000); // 2 seconds (2000 milliseconds)

      return () => {
        clearTimeout(timer); // Clear the timeout if the component unmounts or showConfirmation becomes false
      };
    }
    if (showDeleteConfirm) {
      const timer = setTimeout(() => {
        setshowDeleteConfirm(false);
      }, 1000); // 2 seconds (2000 milliseconds)

      return () => {
        clearTimeout(timer); // Clear the timeout if the component unmounts or showConfirmation becomes false
      };
    }
    if (showUpdate) {
      const timer = setTimeout(() => {
        setshowUpdate(false);
      }, 1000); // 2 seconds (2000 milliseconds)

      return () => {
        clearTimeout(timer); // Clear the timeout if the component unmounts or showConfirmation becomes false
      };
    }
    if (showerror) {
      const timer = setTimeout(() => {
        setshowerror(false);
      }, 1000); // 2 seconds (2000 milliseconds)

      return () => {
        clearTimeout(timer); // Clear the timeout if the component unmounts or showConfirmation becomes false
      };
    }
    if (showupdateerror) {
      const timer = setTimeout(() => {
        setshowupdateerror(false);
      }, 1000); // 2 seconds (2000 milliseconds)

      return () => {
        clearTimeout(timer); // Clear the timeout if the component unmounts or showConfirmation becomes false
      };
    }
  }, [
    showConfirmation,
    showDeleteConfirm,
    showUpdate,
    showerror,
    showupdateerror,
  ]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await Axios.post(
          "https://mudzie.com/admin/public/api/getCompleteGennieYeildTable"
        );

        if (response.data.length < 1) {
          console.log("Records not found");
        } else {
          console.log(response.data);
          const newRows = response.data.map((row, index) =>
            createData(
              index + 1,
              row.yieldDate,
              row.tenYearRate,
              row.swap,
              row.SOFRTen ?? 0,
              row.permAtOneZeroFour,
              row.FHARate,
              row.permAtOneZeroTow,
              row.permAtPar ?? "",
              row.description ?? "",
              row.descriptionNew ?? "",
              row.ginnieYieldTableId
            )
          );

          setRowsState(newRows);
          setLoading(false); // Update the state with the new rows
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData(); // Call the async function to fetch data
  }, [showConfirmation, showDeleteConfirm, showUpdate]);

  const [filterValue, setFilterValue] = useState("");
  console.log("=========", filterValue);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [isEditFormOpen, setIsEditFormOpen] = useState(false);
  const [editRowIndex, setEditRowIndex] = useState(null);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [deleteRowIndex, setDeleteRowIndex] = useState(null);
  const [editFormData, setEditFormData] = useState({
    FHARate: 0,
    SOFRTen: 0,
    permAtOneZeroFour: 0,
    permAtOneZeroTow: 0,
    swap: 0,
    tenYearRate: 0,
    yieldDate: "",
    description: "",
    descriptionNew: "",
  });
  const [fieldErrors, setFieldErrors] = useState({});

  const handleFormSubmit = (e) => {
    e.preventDefault();

    const formattedData = {
      yieldDate: formData.yieldDate,
      tenYearRate: parseFloat(formData.tenYearRate),
      swap: parseFloat(formData.swap),
      SOFRTen: parseFloat(formData.SOFRTen),
      permAtOneZeroFour: parseFloat(formData.permAtOneZeroFour),
      FHARate: parseFloat(formData.FHARate),
      permAtOneZeroTow: parseFloat(formData.permAtOneZeroTow),
      permAtPar: formData.permAtPar,
      description: formData.description,
      descriptionNew: formData.descriptionNew,
    };

    Axios.post(
      "https://mudzie.com/admin/public/api/addGraphData",
      formattedData
    )
      .then((response) => {
        console.log(response.data);
        setShowConfirmation(true);
        setFormData({
          // Reset form data to initial values
          FHARate: "",
          yieldDate: "",
          tenYearRate: "",
          swap: "",
          SOFRTen: "",
          permAtOneZeroFour: "",
          permAtOneZeroTow: "",
          permAtPar: "",
          description: "",
          descriptionNew: "",
        });
      })
      .catch((error) => {
        console.error(error);
        // setAlertMessage(true);
        setshowerror(true);
        setFormData({
          // Reset form data to initial values
          FHARate: 0,
          yieldDate: "",
          tenYearRate: 0,
          swap: 0,
          SOFRTen: 0,
          permAtOneZeroFour: 0,
          permAtOneZeroTow: 0,
          permAtPar: "",
          description: "",
          descriptionNew: "",
        });
      });

    setIsFormOpen(false);
  };

  const handleDatePickerChange = (date) => {
    setFormData({ ...formData, yieldDate: date });
    setIsDatePickerOpen(false);
  };

  const handleInputChange = (fieldName) => (event) => {
    if (fieldName === "yieldDate") {
      // Convert the input date format (mm/dd/yyyy) to (yyyy-mm-dd)
      const formattedDate = event.target.value.split("/").reverse().join("-");
      setFormData({
        ...formData,
        [fieldName]: formattedDate,
      });
    } else {
      setFormData({
        ...formData,
        [fieldName]: event.target.value,
      });
    }
  };

  const handleEditClick = async (index) => {
    setIsEditFormOpen(true);
    console.log("-----", index);
    setEditRowIndex(index);
    const selectedRow = rowsState[index - 1];
    setEditFormData({
      FHARate: selectedRow.FHARate,
      SOFRTen: selectedRow.SOFRTen,
      permAtOneZeroFour: selectedRow.permAtOneZeroFour,
      permAtOneZeroTow: selectedRow.permAtOneZeroTow,
      swap: selectedRow.swap,
      tenYearRate: selectedRow.tenYearRate,
      yieldDate: selectedRow.yieldDate,
      ginnieYieldTableId: selectedRow.ginnieYieldTableId,
      permAtPar: selectedRow.permAtPar,
      description: selectedRow.description,
      descriptionNew: selectedRow.descriptionNew,
    });
  };

  const handleDeleteClick = (ginnieYieldTableId) => {
    setIsDeleteDialogOpen(true);
    console.log("===============", ginnieYieldTableId);
    // Axios.post("https://mudzie.com/admin/public/api/deleteGraphData", {
    //   ginnieYieldTableId: ginnieYieldTableId,
    // })
    //   .then((response) => {
    //     console.log("Item deleted successfully:", response.data);
    //     // Update the state to reflect the deletion
    //   })
    //   .catch((error) => {
    //     console.error("Error deleting item:", error);
    //   })
    setDeleteRowIndex(ginnieYieldTableId);
    setEditRowIndex(null);
  };

  const handleEditFormSubmit = (e) => {
    e.preventDefault();
    const editedData = {
      FHARate: editFormData.FHARate,
      SOFRTen: editFormData.SOFRTen,
      permAtOneZeroFour: editFormData.permAtOneZeroFour,
      permAtOneZeroTow: editFormData.permAtOneZeroTow,
      swap: editFormData.swap,
      tenYearRate: editFormData.tenYearRate,
      yieldDate: editFormData.yieldDate,
      ginnieYieldTableId: editFormData.ginnieYieldTableId,
      permAtPar: editFormData.permAtPar,
      description: editFormData.description,
      descriptionNew: editFormData.descriptionNew,
    };
    console.log(editedData);

    Axios.post(
      "https://mudzie.com/admin/public/api/updateGraphData",
      editedData
    )
      .then((response) => {
        console.log("Data updated successfully:", response.data);
        setshowUpdate(true);

        setIsEditFormOpen(false);
      })
      .catch((error) => {
        console.error("Error updating data:", error);
        setshowupdateerror(true);
      });
    setIsEditFormOpen(false); // Close the edit form after submission
  };

  const handleEditFieldChange = (value, fieldName) => {
    setEditFormData((prevData) => ({
      ...prevData,
      [fieldName]: value,
    }));
  };

  const handleDeleteConfirmation = () => {
    console.log("+++++++++++++++++++++", deleteRowIndex);
    Axios.post("https://mudzie.com/admin/public/api/deleteGraphData", {
      ginnieYieldTableId: deleteRowIndex,
    })
      .then((response) => {
        console.log("Item deleted successfully:", response.data);
        setshowDeleteConfirm(true);
      })
      .catch((error) => {
        console.error("Error deleting item:", error);
      });

    setIsDeleteDialogOpen(false);
  };

  console.log("=====", rowsState);
  return (
    <div className="bgcolor">
      <Navbar />
      <Box height={70} />
      <Box sx={{ display: "flex" }}>
        <Sidenav menuHeighlightPath = "/dashboard/charttable" />
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Card>
                <CardHeader
                  title="Chart Table Data"
                  action={
                    <>
                      {" "}
                      <TextField
                        value={filterValue}
                        onChange={(e) => setFilterValue(e.target.value)}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon />
                            </InputAdornment>
                          ),
                        }}
                        variant="standard"
                      />
                      <Button
                        onClick={() => setIsFormOpen(true)}
                        color="primary"
                        variant="contained"
                        sx={{ mr: 4, ml: 4 }}
                      >
                        Add Entry
                      </Button>
                    </>
                  }
                />
                <CardContent>
                  <Paper sx={{ width: "100%", overflow: "hidden" }}>
                    <TableContainer
                      sx={{ maxHeight: 700, maxWidth: "max-content" }}
                    >
                      <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                          <TableRow>
                            {columns.map((column, index) => (
                              <TableCell
                                key={index}
                                align={column.align}
                                style={{ minWidth: column.minWidth }}
                              >
                                {column.label}
                              </TableCell>
                            ))}
                            <TableCell sx={{ pl: "30px" }}>Edit</TableCell>
                            <TableCell>Delete</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {loading ? (
                            <></>
                          ) : rowsState.length ? (
                            rowsState
                              .filter((row) => {
                                return filterValue.toLowerCase() === ""
                                  ? row
                                  : row.swap.toString().includes(filterValue) ||
                                      row.SOFRTen.toString()
                                        .toLowerCase()
                                        .includes(filterValue) ||
                                      row.FHARate.toString()
                                        .toLowerCase()
                                        .includes(filterValue) ||
                                      row.permAtOneZeroFour
                                        .toString()
                                        .includes(filterValue) ||
                                      row.permAtOneZeroTow
                                        .toString()
                                        .includes(filterValue) ||
                                      row.yieldDate
                                        .toString()
                                        .includes(filterValue) ||
                                      row.tenYearRate
                                        .toString()
                                        .includes(filterValue) ||
                                      (row.description &&
                                        row.description.includes(filterValue));
                              })
                              .slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                              )
                              .map((row, index) => {
                                return (
                                  <TableRow
                                    hover
                                    role="checkbox"
                                    tabIndex={-1}
                                    key={index}
                                  >
                                    {columns.map((column, dindex) => {
                                      const value = row[column.id];
                                      return (
                                        <TableCell
                                          align={column.align}
                                          key={dindex}
                                        >
                                          {column.id === "swap" && value === 0
                                            ? "" // Display blank if 'swap' is 0
                                            : column.format &&
                                              typeof value === "number"
                                            ? column.format(value)
                                            : value}
                                        </TableCell>
                                      );
                                    })}
                                    <TableCell>
                                      <Button
                                        onClick={() =>
                                          handleEditClick(row.logId)
                                        }
                                      >
                                        <EditIcon />
                                      </Button>
                                    </TableCell>
                                    <TableCell>
                                      <Button
                                        onClick={() =>
                                          handleDeleteClick(
                                            row.ginnieYieldTableId
                                          )
                                        }
                                      >
                                        <DeleteIcon />
                                      </Button>
                                    </TableCell>
                                  </TableRow>
                                );
                              })
                          ) : (
                            <></>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    {loading ? (
                      <></>
                    ) : (
                      <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={rowsState.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    )}
                  </Paper>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Dialog
        open={isFormOpen}
        onClose={() => setIsFormOpen(false)}
        fullWidth
        maxWidth="md" // Set the maximum width of the dialog for larger screens
      >
        <form onSubmit={handleFormSubmit}>
          <DialogTitle>Add New Entry</DialogTitle>

          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Yield Date"
                  type="date"
                  variant="outlined"
                  required
                  fullWidth
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={formData.yieldDate}
                  onChange={handleInputChange("yieldDate")}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="10-Year Rate"
                  variant="outlined"
                  required
                  fullWidth
                  margin="normal"
                  value={formData.tenYearRate}
                  onChange={handleInputChange("tenYearRate")}
                  error={isNaN(formData.tenYearRate)}
                  helperText={
                    isNaN(formData.tenYearRate) ? "Please enter number" : ""
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Swap"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={formData.swap}
                  onChange={handleInputChange("swap")}
                  error={isNaN(formData.swap)}
                  helperText={isNaN(formData.swap) ? "Please enter number" : ""}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="SOFR-10"
                  variant="outlined"
                  required
                  fullWidth
                  margin="normal"
                  value={formData.SOFRTen}
                  onChange={handleInputChange("SOFRTen")}
                  error={isNaN(formData.SOFRTen)}
                  helperText={
                    isNaN(formData.SOFRTen) ? "Please enter number" : ""
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Perm At-104"
                  variant="outlined"
                  required
                  fullWidth
                  margin="normal"
                  value={formData.permAtOneZeroFour}
                  onChange={handleInputChange("permAtOneZeroFour")}
                  error={isNaN(formData.permAtOneZeroFour)}
                  helperText={
                    isNaN(formData.permAtOneZeroFour)
                      ? "Please enter number"
                      : ""
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="FHARate"
                  variant="outlined"
                  required
                  fullWidth
                  margin="normal"
                  value={formData.FHARate}
                  onChange={handleInputChange("FHARate")}
                  error={isNaN(formData.FHARate)}
                  helperText={
                    isNaN(formData.FHARate) ? "Please enter number" : ""
                  }
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  label="Perm At-102"
                  variant="outlined"
                  required
                  fullWidth
                  margin="normal"
                  value={formData.permAtOneZeroTow}
                  onChange={handleInputChange("permAtOneZeroTow")}
                  error={isNaN(formData.permAtOneZeroTow)}
                  helperText={
                    isNaN(formData.permAtOneZeroTow)
                      ? "Please enter number"
                      : ""
                  }
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  label="PermAtPar"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={formData.permAtPar}
                  onChange={handleInputChange("permAtPar")}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Description-1"
                  variant="outlined"
                  required
                  fullWidth
                  margin="normal"
                  value={formData.description}
                  onChange={handleInputChange("description")}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Description-2"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={formData.descriptionNew}
                  onChange={handleInputChange("descriptionNew")}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setIsFormOpen(false)} color="primary">
              Cancel
            </Button>
            <Button type="submit" color="primary" variant="contained">
              Save
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      {/* edit and delete dialog */}
      <Dialog
        open={isEditFormOpen}
        onClose={() => setIsEditFormOpen(false)}
        fullWidth
      >
        <form onSubmit={handleEditFormSubmit}>
          <DialogTitle>Edit Entry</DialogTitle>
          <DialogContent>
            {editRowIndex !== null && (
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Yield Date"
                    type="date"
                    required
                    variant="outlined"
                    fullWidth
                    value={editFormData.yieldDate}
                    onChange={(e) =>
                      handleEditFieldChange(e.target.value, "yieldDate")
                    }
                    sx={{ mt: 2 }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="10-YearRate"
                    variant="outlined"
                    required
                    fullWidth
                    value={editFormData.tenYearRate}
                    onChange={(e) =>
                      handleEditFieldChange(e.target.value, "tenYearRate")
                    }
                    error={isNaN(editFormData.tenYearRate)}
                    helperText={
                      isNaN(editFormData.tenYearRate)
                        ? "Please enter a number"
                        : ""
                    }
                    sx={{ mt: 2 }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Swap"
                    variant="outlined"
                    fullWidth
                    value={editFormData.swap}
                    onChange={(e) =>
                      handleEditFieldChange(e.target.value, "swap")
                    }
                    error={isNaN(editFormData.swap)}
                    helperText={
                      isNaN(editFormData.swap) ? "Please enter a number" : ""
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="SOFR-10"
                    variant="outlined"
                    required
                    fullWidth
                    sx={{ mt: { xl: 0, lg: 0, md: 0, xs: 0, sm: 0 } }}
                    value={editFormData.SOFRTen}
                    onChange={(e) =>
                      handleEditFieldChange(e.target.value, "SOFRTen")
                    }
                    error={isNaN(editFormData.SOFRTen)}
                    helperText={
                      isNaN(editFormData.SOFRTen) ? "Please enter a number" : ""
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Perm At-104"
                    variant="outlined"
                    required
                    fullWidth
                    value={editFormData.permAtOneZeroFour}
                    onChange={(e) =>
                      handleEditFieldChange(e.target.value, "permAtOneZeroFour")
                    }
                    error={isNaN(editFormData.permAtOneZeroFour)}
                    helperText={
                      isNaN(editFormData.permAtOneZeroFour)
                        ? "Please enter a number"
                        : ""
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="FHARate"
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    value={editFormData.FHARate}
                    onChange={(e) =>
                      handleEditFieldChange(e.target.value, "FHARate")
                    }
                    error={isNaN(editFormData.FHARate)}
                    helperText={
                      isNaN(editFormData.FHARate) ? "Please enter a number" : ""
                    }
                    sx={{ mt: 0 }}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Perm At-102"
                    variant="outlined"
                    required
                    fullWidth
                    value={editFormData.permAtOneZeroTow}
                    onChange={(e) =>
                      handleEditFieldChange(e.target.value, "permAtOneZeroTow")
                    }
                    error={isNaN(editFormData.permAtOneZeroTow)}
                    helperText={
                      isNaN(editFormData.permAtOneZeroTow)
                        ? "Please enter a number"
                        : ""
                    }
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    label="PermAtPar"
                    variant="outlined"
                    fullWidth
                    value={editFormData.permAtPar}
                    onChange={(e) =>
                      handleEditFieldChange(e.target.value, "permAtPar")
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Description-1"
                    variant="outlined"
                    required
                    fullWidth
                    value={editFormData.description}
                    onChange={(e) =>
                      handleEditFieldChange(e.target.value, "description")
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Description-2"
                    variant="outlined"
                    required
                    fullWidth
                    value={editFormData.descriptionNew}
                    onChange={(e) =>
                      handleEditFieldChange(e.target.value, "descriptionNew")
                    }
                  />
                </Grid>
              </Grid>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setIsEditFormOpen(false)}>Cancel</Button>
            {/* <Button onClick={handleEditFormSubmit} color="primary">
      Save
    </Button> */}
            <Button type="submit" color="primary" variant="contained">
              Update
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      <Dialog
        open={isDeleteDialogOpen}
        onClose={() => setIsDeleteDialogOpen(false)}
      >
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this item?
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsDeleteDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleDeleteConfirmation} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* data store in backend dialog */}

      <Dialog
        open={showConfirmation}
        onClose={() => setShowConfirmation(false)}
      >
        <div>
          {showConfirmation && (
            <Alert
              severity="success"
              onClose={() => setShowConfirmation(false)}
            >
              <AlertTitle>success</AlertTitle>
              Data saved Successfully
            </Alert>
          )}
        </div>
      </Dialog>

      {/* data delete dialog */}
      <Dialog
        open={showDeleteConfirm}
        onClose={() => setshowDeleteConfirm(false)}
      >
        <div>
          {showDeleteConfirm && (
            <Alert
              severity="success"
              onClose={() => setshowDeleteConfirm(false)}
            >
              <AlertTitle>success</AlertTitle>
              Data Deleted Successfully
            </Alert>
          )}
        </div>
      </Dialog>

      {/* data update dialog  */}
      <Dialog open={showUpdate} onClose={() => setshowUpdate(false)}>
        <div>
          {showUpdate && (
            <Alert severity="success" onClose={() => setshowUpdate(false)}>
              <AlertTitle>success</AlertTitle>
              Data Updated Successfully
            </Alert>
          )}
        </div>
      </Dialog>

      <Dialog open={showerror} onClose={() => setshowerror(false)}>
        <div>
          {showerror && (
            <Alert severity="error" onClose={() => setshowerror(false)}>
              <AlertTitle>Error</AlertTitle>
              Data not saved Successfully
            </Alert>
          )}
        </div>
      </Dialog>

      <Dialog open={showupdateerror} onClose={() => setshowupdateerror(false)}>
        <div>
          {showupdateerror && (
            <Alert severity="error" onClose={() => setshowupdateerror(false)}>
              <AlertTitle>Error</AlertTitle>
              Data not Updated Successfully
            </Alert>
          )}
        </div>
      </Dialog>
    </div>
  );
};

export default Charttable;
