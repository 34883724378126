import React, { useState, useRef, useEffect } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import {
  Box, Button, Typography, Container, Grid, Toolbar, IconButton, Collapse, Dialog,
  DialogContent, styled,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import mudzie from "../images/Color logo - no background.png";
import twitter from "../images/twitter 1.png";
import facebook from "../images/facebook-app-symbol 1.png";
import linkdin from "../images/linkedin (2) 1.png";
import { Link, useNavigate } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Axios from "axios";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import { InfoOutlined as InfoIcon } from "@mui/icons-material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ReactJsAlert from "reactjs-alert";
import { loadStripe } from "@stripe/stripe-js";
//import { makeStyles } from "@mui/styles";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1740,
    },
  },
});

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'black',
    color: 'white',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(20),
    border: '1px solid #dadde9',
  },
}));

const ComingSoonMessage = styled("div")(({ theme }) => ({
  display: "none",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "rgba(255, 255, 255, 0.9)",
  padding: theme.spacing(1, 2),
  borderRadius: theme.spacing(0.5),
  fontWeight: "bold",
  color: "red",
  zIndex: 1,
}));

// const useStyles = makeStyles((theme) => ({
//   planCard: {
//     position: "relative",
//     maxWidth: 300,
//     padding: "1rem",
//     "&:hover .comingSoonMessage": {
//       opacity: 1,
//     },
//   },
//   comingSoonMessage: {
//     position: "absolute",
//     top: 0,
//     left: 0,
//     width: "100%",
//     height: "100%",
//     background: "rgba(0, 0, 0, 0.5)",
//     display: "flex",
//     justifyContent: "center",
//     alignItems: "center",
//     fontSize: "24px",
//     color: "white",
//     opacity: 0,
//     transition: "opacity 0.3s ease-in-out",
//   },
// }));

let stripePromise;

const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);
  }

  return stripePromise;
};

const Payments = () => {
  const CallRequestSwitch = localStorage.getItem("call_request_switch")
  console.log("Dddddddddddddddddddd : ", CallRequestSwitch);
  const [selectedOption, setSelectedOption] = useState("B");
  const [subscribe, setsubscribe] = useState(true);
  const navigate = useNavigate();
  const faqRef = useRef(null);
  const [token, settoken] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // const classes = useStyles();

  useEffect(() => {
    // Scroll to the top when the component mounts (page loads)
    window.scrollTo(0, 0);
  }, []);

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const userid = localStorage.getItem("userId");

  const scrollToFAQ = () => {
    faqRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
  };

  const [stripeError, setStripeError] = useState(null);
  const [isLoading, setLoading] = useState(false);

  const item = {
    price: "price_1NkP70SIKCzzZGNJC8PeTqfp",
    quantity: 1,
  };
  function generateRandomString(length) {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()_-+=[]{}|<>,.?/~";
    let randomString = "";
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      randomString += characters.charAt(randomIndex);
    }
    return randomString;
  }

  // const checkoutOptions = {
  //   lineItems: [item],
  //   mode: "payment",
  //   successUrl: `${window.location.origin}/success`,
  //   cancelUrl: `${window.location.origin}/cancel`,
  // };

  const encodeString = (str) => {
    // You can implement your own custom encoding logic here
    // This is just a simple example, you can make it more robust
    return btoa(str).substring(0, 12); // Base64 encoding with substring
  };

  const redirectToCheckout = async (key, plan, period, priceTier) => {
    // const uniqueId = generateRandomString(12);
    const encodedPlan = encodeString(plan);
    const encodedPeriod = encodeString(period);
    const encodePrice = encodeString(priceTier);
    console.log("11111111111111111", plan, encodePrice);
    const item = {
      price: key,
      quantity: 1,
    };
    const checkoutOptions = {
      lineItems: [item],
      mode: "payment",
      successUrl: `${window.location.origin}/success?plan=${encodedPlan}&period=${encodedPeriod}&price=${encodePrice}`,
      cancelUrl: `${window.location.origin}/cancel`,
    };
    setLoading(true);
    console.log("redirectToCheckout", plan, period, priceTier);

    const stripe = await getStripe();
    const { error } = await stripe.redirectToCheckout(checkoutOptions);
    console.log("Stripe checkout error", error);

    if (error) setStripeError(error.message);
    setLoading(false);
  };

  if (stripeError) alert(stripeError);

  useEffect(() => {
    const user = localStorage.getItem("user");
    const userde = JSON.parse(user);
    settoken(userde?.token);
    const today = new Date().toLocaleDateString();
    if (userde?.is_approved_for_subscribe === 1) {
      setsubscribe(false);
    } else {
      setsubscribe(true);
    }
  }, []);

  const faqData = [
    {
      question:
        " Basic Level: Best for lenders without an in-house trading desk",
      answer:
        "The Basic level is available to all users at a fixed subscription fee of $250 per month. Users at the Basic level have access to fundamental functionalities within Mudzie, allowing them to efficiently request GNMA pricing information for both permanent and construction loan quotes. However, it's important to note that the Basic level operates with predetermined defaults for various loan parameters.",
    },
    {
      question: "Basic Level Permissions",
      answer:
        "Request Pricing Information: Users at the Basic level can submit requests for pricing information for permanent and construction loan quotes for Ginnie Mae execution. ",
    },
    {
      question: "Loan Settings at Basic Level",
      answer:
        "Delivery: All loans are assumed to have a 60-day GNMA delivery timeline. Term: Permanent loans are assumed to have a 35-year term, while construction loans are assumed to have a 40-year term, and the construction period is assumed to be 18 months or less.Loan Amount: Loan amounts are assumed to be under $25 million. Call Protection: Call protection is set at 0/10-1%.",
    },
    {
      question:
        "Pro Level: Best for lenders with internal trading desks, who want to calculate GNMA and DUS indications",
      answer:
        "We anticipate that Pro users have a basic understanding of a similar pricing model offered by Bloomberg, however training will be provided to users. We cannot be held liable for a subscriber's improper use of the model. If a user would like to double check their math, they are welcome to use their consulting hours to check with our team. The only way to be certain of the pricing for a specific structure is to ask a market investor for a specific price or rate indication. This will be able to be done in the KimmyMae portal very soon.",
    },
    {
      question: "Pro Level Permissions",
      answer:
        " All Basic Level Permissions: Users at the Pro level inherit all permissions available to the Basic level users.Customizable Loan Parameters: Users have access to the full Mudzie calculator, but are responsible for sourcing various loan spreads from investors. Benefit: An economical way for lenders to provide loan pricing tools to more staff, especially underwriters",
    },
  ];

  const dataCardsMonth = [
    {
      priceTier: "250",
      priceTierTeam: "1000",
      subscribe: true,
      one: "Real-time HUD and RHS indications for permanent and construction loans",
      two: "Standard call protection, max 40 year term, 60 day delivery, loans under $25 million",
      three: "Additional seats in the same company receive a 20% discount",
      four: "Automatically-upgraded basic features for the term of the contract",
      five: "One hour of onboarding plus one hour of consulting after contract signing",
      six: "",
      price: "Basic: for RHS and MAP lenders",
      plan: "Basic",
      month: "month",
      link: "https://calendly.com/kim-860",
      // key: "price_1NkP70SIKCzzZGNJC8PeTqfp",  // original revert to this aniket
      // keyTeam: "price_1NkP70SIKCzzZGNJC8PeTqfp",
      key: "price_1PHQYgSIjKRCT2EMCU1inxXP",  // original revert to this aniket
      keyTeam: "price_1PHPwqSIjKRCT2EMBaMe0SGd",
      period: "monthly",
      isDisabled: false,
    },
    {
      priceTier: "350",
      priceTierTeam: "1400",
      subscribe: true,
      one: "Everything in Mudzie Basic",
      two: "Full control of input fields. Users can calculate all types of bonds",
      three: "Same configuration options as Bloomberg Terminal",
      four: "10% discount for additional seats in same company",
      five: "One hour of onboarding per team plus 5 hours of consulting after contract signing. Additional consulting at $250/hr",
      six: "",
      price: "Pro: For HUD, RHS, and DUS lenders",
      month: "month",
      plan: "Pro",
      link: "https://calendly.com/kim-860",
      key: "price_1NkP93SIKCzzZGNJbw8NUPEN",
      keyTeam: "price_1NkP93SIKCzzZGNJbw8NUPEN",
      period: "monthly",
      isDisabled: true,
    },
    {
      priceTier: "450",
      priceTierTeam: "1800",
      subscribe: true,
      one: "Vetted Lenders or Investors only (application process)",
      two: "Full bond configuration access, with daily spread guidelines and business intelligence downloads",
      three:
        "Market intelligence derived from trades made in the KimmyMae platform",
      four: "12 month contracts only",
      five: "One hour of onboarding and 10 hours of consulting after contract signing. Additional consulting hours are charged at $250/hr.",
      six: "",
      price: "Ultra Pro: for existing trading desks currently using Bloomberg",
      month: "month",
      plan: "Ultra Pro",
      link: "https://calendly.com/kim-860",
      key: "price_1NkPAKSIKCzzZGNJTnCCk61i",
      keyTeam: "price_1NkPAKSIKCzzZGNJTnCCk61i",
      period: "monthly",
      isDisabled: true,
    },
  ];

  const dataCardsYear = [
    {
      value: "2,500",
      priceTier: "2500",
      valueTeam: "10,000",
      priceTierTeam: "10000",
      // value: "10,000",
      // priceTier: "10000",
      subscribe: true,
      one: "Real-time HUD and RHS indications for permanent and construction loans",
      two: "Standard call protection, max 40 year term, 60 day delivery, loans under $25 million",
      three: "Additional seats in the same company receive a 20% discount",
      four: "Automatically-upgraded basic features for the term of the contract",
      five: "One hour of onboarding plus one hour of consulting after contract signing",
      six: "",
      price: "Basic: for RHS and MAP lenders",
      month: "year",
      plan: "Basic",
      link: "https://calendly.com/kim-860",
      // key: "price_1NuFq9SIKCzzZGNJJuxe4DBg",
      // keyTeam: "price_1NuFq9SIKCzzZGNJJuxe4DBg",
      key: "price_1PHPuMSIjKRCT2EMz4qBMhho",
      keyTeam: "price_1PHPyCSIjKRCT2EMg6Bmrtrz",
      period: "yearly",
      isDisabled: false,
    },
    {
      value: "3,500",
      priceTier: "3500",
      valueTeam: "14,000",
      priceTierTeam: "14000",
      // value: "15,000",
      // priceTier: "15000",
      subscribe: true,
      one: "Everything in Mudzie Basic",
      two: "Full control of input fields. Users can calculate all types of bonds",
      three: "Same configuration options as Bloomberg Terminal",
      four: "10% discount for additional seats in same company",
      five: "One hour of onboarding per team plus 5 hours of consulting after contract signing. Additional consulting at $250/hr",
      six: "",
      price: "Pro: For HUD, RHS, and DUS lenders",
      month: "year",
      plan: "Pro",
      link: "https://calendly.com/kim-860",
      key: "price_1NuFrWSIKCzzZGNJYr6cUO4a",
      keyTeam: "price_1NuFrWSIKCzzZGNJYr6cUO4a",
      period: "yearly",
      isDisabled: true,
    },
    {
      // value: "25,000",
      // priceTier: "25000",
      valueTeam: "18,000",
      priceTierTeam: "18000",
      value: "4,500",
      priceTier: "4500",
      subscribe: true,
      one: "Vetted Lenders or Investors only (application process)",
      two: "Full bond configuration access, with daily spread guidelines and business intelligence downloads",
      three:
        "Market intelligence derived from trades made in the KimmyMae platform",
      four: "12 month contracts only",
      five: "One hour of onboarding and 10 hours of consulting after contract signing. Additional consulting hours are charged at $250/hr",
      six: "",
      price: "Ultra Pro: for existing trading desks currently using Bloomberg",
      month: "year",
      plan: "Ultra Pro",
      link: "https://calendly.com/kim-860",
      key: "price_1NuFsnSIKCzzZGNJ7OKDR18z",
      keyTeam: "price_1NuFsnSIKCzzZGNJ7OKDR18z",
      period: "yearly",
      isDisabled: true,
    },
  ];

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showerror, setshowerror] = useState(false);
  const [userdata, setuserdata] = useState(false);
  const [request, setrequest] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [alertStatus, setAlertStatus] = React.useState(false);
  const [type, setType] = React.useState("success");
  const [title, setTitle] = React.useState("");

  const onCall = async (plan) => {
    console.log("###########", plan);
    if (user?.is_reject_for_subsicribe === 1) {
      console.log("you cannot send request");
      setrequest(true);
    } else {
      await Axios.get(
        `${process.env.REACT_APP_BASE_URL}api/call-request/${plan}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((res) => {
          console.log(res.data);
          setAlertStatus(true);
          // setReject(true);
          setType("success");
          setTitle("Your call request has been sent to the Mudzie Team.");
          // setShowConfirmation(true);
          setuserdata(true);
          //window.location.href = "https://calendly.com/kim-860";
          window.open("https://calendly.com/kim-860", "_blank");
        })
        .catch((error) => {
          console.log(error);
          setAlertStatus(true);
          //  setReject(true);
          setType("error");
          setTitle("Your Request not Sent Successfully");
          // setshowerror(true);
          setuserdata(true);
        });
    }
  };
  const [user, setUser] = useState(null);
  const [plan, setplan] = useState(null);
  const [period, setPeriod] = useState(null);
  const [priceSubbed, setPriceSubbed] = useState(null)

  useEffect(() => {
    const userid = localStorage.getItem("userId");
    const user = localStorage.getItem("user");
    const userde = JSON.parse(user);
    Axios.get(`${process.env.REACT_APP_BASE_URL}api/get-call-request-details`, {
      headers: {
        Authorization: `Bearer ${userde.token}`,
      },
    })
      .then((res) => {
        console.log("@@@@@@@@@@@@", res.data.data);
        setUser(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [userdata]);

  useEffect(() => {
    const user = localStorage.getItem("user");
    const userde = JSON.parse(user);
    const logId = localStorage.getItem("userId");
    const today = new Date().toLocaleDateString();
    Axios.get(
      `${process.env.REACT_APP_BASE_URL}api/admin/get-user-details/${logId}`,
      {
        headers: {
          Authorization: `Bearer ${userde.token}`,
        },
      }
    )
      .then((res) => {
        setplan(res.data.user[0]);
        setSelectedPlan(res.data.user[0].mudziePlan);
        setPeriod(res.data.user[0].period);
        setPriceSubbed(res.data.user[0].amount);

        console.log(res.data)
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (showConfirmation) {
      const timer = setTimeout(() => {
        setShowConfirmation(false);
      }, 1000); // 2 seconds (2000 milliseconds)

      return () => {
        clearTimeout(timer); // Clear the timeout if the component unmounts or showConfirmation becomes false
      };
    }
    if (showerror) {
      const timer = setTimeout(() => {
        setshowerror(false);
      }, 1000); // 2 seconds (2000 milliseconds)

      return () => {
        clearTimeout(timer); // Clear the timeout if the component unmounts or showConfirmation becomes false
      };
    }
    if (request) {
      const timer = setTimeout(() => {
        setrequest(false);
      }, 2000); // 2 seconds (2000 milliseconds)

      return () => {
        clearTimeout(timer); // Clear the timeout if the component unmounts or showConfirmation becomes false
      };
    }
  }, [showConfirmation, showerror, request]);

  const handleOut = () => {
    localStorage.removeItem("userId");
    localStorage.removeItem("user");
    navigate("/");
  };
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleDialogOpen = (isDisabled) => {
    if (isDisabled) {
      setDialogOpen(true);
    }

    setTimeout(() => {
      setDialogOpen(false);
    }, 2000);
  };

  // const handleDialogClose = () => {
  //   setDialogOpen(false);
  // };


  // ///////// team - indi toogle added by aniket

  const [toggleStates, setToggleStates] = useState(Array(3).fill("Individual")); // Initialize toggle states

  const handleToggle = (index, value) => {
    const newToggleStates = [...toggleStates];
    newToggleStates[index] = value;
    setToggleStates(newToggleStates);
  };


  // ///////// team - indi toogle added by aniket ended

  return (
    <ThemeProvider theme={theme}>
      <div style={{ backgroundColor: "white" }}>
        <Box sx={{ bgcolor: "black" }}>
          <Container maxWidth="lg">
            <Toolbar>
              <Typography
                variant="body1"
                sx={{ flexGrow: 1, color: "white", textAlign: "center" }}
              >
                Mudzie has a few openings for Founding Investor/Member.{" "}
                <Link to="/investors" style={{ color: "white" }}>
                  Click Here
                </Link>
              </Typography>
            </Toolbar>
          </Container>
        </Box>
        <Box
          sx={{
            flexDirection: "row",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            background: "linear-gradient(to right bottom, #1976D2, #175FA2)",
            height: "100px",
          }}
        >
          <Box sx={{ ml: { xl: 15, lg: 10, sm: 10, xs: 0 } }}>
            <img
              src={mudzie}
              alt="logo"
              width={200}
              height={60}
            // style={{ marginLeft: "80px", marginRight: "-30px" }}
            />
          </Box>
          <IconButton
            aria-label="profile"
            color="white"
            onClick={handleMenuOpen}
          >
            <AccountCircleIcon
              fontSize="large"
              sx={{ color: "white", mr: 5 }}
            />
          </IconButton>
        </Box>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={() => navigate("/calculator")}>
            Mudzie Calculator
          </MenuItem>
          <MenuItem onClick={() => navigate("/accountpage")}>
            My Account
          </MenuItem>
          <MenuItem onClick={handleOut}>Logout</MenuItem>
        </Menu>

        <h1
          style={{
            color: "#1869b6",
            textAlign: "center",
            fontFamily: "Ubuntu",
          }}
        >
          Pricing
        </h1>
        <p
          style={{
            textAlign: "center",
            fontWeight: "bold",
            fontSize: "30px",
            marginTop: "10px",
            fontFamily: "Ubuntu",
          }}
        >
          Mudzie is available to approved lenders only.
        </p>
        <div style={{ width: "100%", display: "flex", justifyContent: "center" }} >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              gap: "20px",
              marginTop: "20px",
              backgroundColor: "#1869b6",
              py: 1,
              px: 1,
              width: "max-content",
              borderRadius: "25px",
              "& .option": {
                border: "1px solid #ccc",
                borderRadius: "16px",
                padding: "8px 16px",
                fontSize: "16px",
                cursor: "pointer",
                transition: "background-color 0.3s ease, color 0.3s ease",
                backgroundColor: "white",
                color: "#333",
              },
              "& .option.selected": {
                backgroundColor: "Purple",
                color: "white",
              },
            }}
          >
            <button
              className={`option ${selectedOption === "A" ? "selected" : ""}`}
              onClick={() => handleOptionClick("A")}
            >
              Yearly{" "}
              <span
                style={{
                  fontSize: "10px",
                  background: "#72e483",
                  borderRadius: "20px",
                  paddingLeft: "5px",
                  paddingRight: "5px",
                  paddingTop: "2px",
                  paddingBottom: "2px",
                }}
              >
                2 Months Off
              </span>
            </button>
            <button
              className={`option ${selectedOption === "B" ? "selected" : ""}`}
              onClick={() => handleOptionClick("B")}
            >
              Monthly
            </button>
          </Box>
        </div>

        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: { xl: "row", lg: "row", sm: "column", xs: "column" },
            alignItems: {
              sm: "center",

              xs: "center",
              xl: "normal",
              lg: "normal",
            },
            justifyContent: "center",
            gap: "20px",
            marginTop: "20px",
          }}
        >
          {selectedOption === "B"
            ? dataCardsMonth.map((x, index) => (
              <HtmlTooltip key={index} disableHoverListener={!x.isDisabled}
                title={
                  <React.Fragment>
                    <Typography color="inherit">Coming Soon !!!</Typography>
                  </React.Fragment>
                }
              >
                <Card
                  key={index}
                  sx={{
                    maxWidth: 300,
                    padding: "1rem",
                    position: "relative",
                    ...(x.isDisabled && {
                      opacity: 0.4,
                      // "&:hover": {
                      //   cursor: "not-allowed",
                      //   "& $comingSoon": {
                      //     display: "block",
                      //   },
                      // },
                    }),
                  }}
                  onMouseEnter={() => handleDialogOpen(x.isDisabled)}
                // className={classes.planCard}
                >
                  <CardContent>
                    <Typography
                      sx={{
                        textAlign: "center",
                        fontSize: "18px",
                        fontFamily: "Ubuntu",
                        fontWeight: "bold",
                        color: "#1869b6",
                        paddingBottom:
                          index === 0 ? "35px" : index === 1 ? "35px" : "10px",
                      }}
                    >
                      Mudzie {x.price}
                    </Typography>
                    {x.priceTier === "Free" ? (
                      <Typography
                        variant="h6"
                        textAlign="center"
                        fontSize="60px"
                      >
                        {x.priceTier}
                      </Typography>
                    ) : (

                      <>
                        {/* team toogle */}
                        <div style={{ width: "100%", display: "flex", justifyContent: "center" }} >
                          <Box
                            sx={{
                              display: "flex", justifyContent: "center", gap: "20px", marginTop: "20px", backgroundColor: "#1869b6", py: 1, px: 1, width: "max-content", borderRadius: "25px",
                              "& .option": {
                                border: "1px solid #ccc", borderRadius: "16px", padding: "8px 16px", fontSize: "16px", cursor: "pointer", transition: "background-color 0.3s ease, color 0.3s ease", backgroundColor: "white", color: "#333",
                              },
                              "& .option.selected": {
                                backgroundColor: "Purple",
                                color: "white",
                              },
                            }}
                          >
                            <button
                              className={`option ${toggleStates[index] === "Team" ? "selected" : ""}`}
                              onClick={() => handleToggle(index, "Team")}
                            >
                              Team
                            </button>
                            <button
                              className={`option ${toggleStates[index] === "Individual" ? "selected" : ""}`}
                              onClick={() => handleToggle(index, "Individual")}
                            >
                              Individual
                            </button>
                          </Box>
                        </div>
                        {/* team toogle end */}

                        {/* toogle price */}

                        {
                          toggleStates[index] === 'Individual' ?
                            <Typography
                              variant="h6"
                              textAlign="center"
                              fontSize="50px"
                            >
                              ${x.priceTier}{" "}
                              <span style={{ fontSize: "20px", marginLeft: "-10px" }}>
                                /per month
                              </span>
                            </Typography> : ''
                        }
                        {
                          toggleStates[index] === 'Team' ?
                            <Typography
                              variant="h6"
                              textAlign="center"
                              fontSize="50px"
                            >
                              ${x.priceTierTeam}{" "}
                              <span style={{ fontSize: "20px", marginLeft: "-10px" }}>
                                /per month
                              </span>

                              <p style={{ fontSize: "20px", marginLeft: "-10px", marginTop: '0px' }}>
                                for 5 seats
                              </p>
                            </Typography> : ''
                        }


                        {/* toogle price end*/}


                      </>
                    )}

                    {x.isDisabled ? (
                      <></>
                    ) : user?.is_call_requested === 0 && user?.is_call_requested === null && CallRequestSwitch === "ON" ? (
                      <Button
                        onClick={() => onCall(x.plan)}
                        fullWidth
                        variant="contained"
                        sx={{
                          mt: 2,
                          borderRadius: "20px",
                          background: "#1869b6",
                        }}
                      >
                        Setup Discovery Call
                      </Button>
                    ) : user?.is_call_requested === 1 && user?.is_approved_for_subscribe === 0 &&
                      user?.is_reject_for_subsicribe === 0 && CallRequestSwitch === "ON" ? (
                      // Content for the second condition (is_call_requested === 1)
                      <Box sx={{ textAlign: "center" }}>
                        <Typography
                          variant="button"
                          fullWidth
                          color="success"
                          sx={{
                            mt: 3,
                            color: "green",
                            textAlign: "center",
                            fontWeight: "bold",
                          }}
                        >
                          Call Request Sent
                        </Typography>
                      </Box>
                    ) : user?.is_approved_for_subscribe === 1 || plan?.is_active_plan === 1
                      || CallRequestSwitch === "OFF" ? (
                      // Content for the third condition (is_approved_for_subscribe === 1)
                      <>
                        {
                          toggleStates[index] === 'Individual' ?
                            <Button
                              fullWidth
                              variant="contained"
                              disabled={
                                selectedPlan === x.plan &&
                                plan?.is_active_plan === 1 &&
                                priceSubbed == 250 &&
                                // toggleStates[index] === 'Individual' &&
                                period === x.period
                              }
                              sx={{
                                mt: 2,
                                borderRadius: "20px",
                                background: "#1869b6",
                              }}

                              onClick={() => {
                                redirectToCheckout(
                                  x.key,
                                  x.plan,
                                  x.period,
                                  x.priceTier
                                ); console.log(x.priceTier)
                              }}
                            >
                              {
                                selectedPlan === x.plan &&
                                  plan?.is_active_plan === 1 &&
                                  priceSubbed == 250 &&
                                  // toggleStates[index] === 'Individual' &&
                                  period === x.period
                                  ? "active plan!"
                                  : "subscribe"}
                            </Button> : ''
                        }
                        {
                          toggleStates[index] === 'Team' ?
                            <Button
                              fullWidth
                              variant="contained"
                              disabled={
                                selectedPlan === x.plan &&
                                plan?.is_active_plan === 1 &&
                                priceSubbed == 1000 &&
                                // toggleStates[index] === 'Team' 
                                period === x.period
                              }
                              sx={{
                                mt: 2,
                                borderRadius: "20px",
                                background: "#1869b6",
                              }}

                              onClick={() => {
                                redirectToCheckout(
                                  x.keyTeam,
                                  x.plan,
                                  x.period,
                                  x.priceTierTeam
                                );
                              }}
                            >
                              {selectedPlan === x.plan &&
                                plan?.is_active_plan === 1 &&
                                priceSubbed == 1000 &&
                                // toggleStates[index] === 'Team' &&
                                period === x.period
                                ? "active plan!"
                                : "subscribe"}

                            </Button> : ''
                        }

                      </>
                    ) : (
                      <Button
                        onClick={() => onCall(x.plan)}
                        fullWidth
                        variant="contained"
                        sx={{
                          mt: 2,
                          borderRadius: "20px",
                          background: "#1869b6",
                        }}
                      >
                        Setup Discovery Call
                      </Button>
                    )}

                    <Box sx={{ mt: 1 }}>
                      <Typography
                        variant="body1"
                        color="textSecondary"
                        gutterBottom
                        sx={{ display: "flex", py: 2 }}
                      >
                        <CheckCircleIcon
                          color="primary"
                          sx={{ marginRight: 1, color: "#1869b6" }}
                        />
                        {x.one}
                      </Typography>

                      <Typography
                        variant="body1"
                        color="textSecondary"
                        gutterBottom
                        sx={{ display: "flex", py: 2 }}
                      >
                        <CheckCircleIcon
                          color="primary"
                          sx={{ marginRight: 1, color: "#1869b6" }}
                        />
                        {x.two}
                      </Typography>
                      <Typography
                        variant="body1"
                        color="textSecondary"
                        gutterBottom
                        sx={{ display: "flex", py: 2 }}
                      >
                        <CheckCircleIcon
                          color="primary"
                          sx={{ marginRight: 1, color: "#1869b6" }}
                        />
                        {x.three}
                      </Typography>
                      <Typography
                        variant="body1"
                        color="textSecondary"
                        gutterBottom
                        sx={{ display: "flex", py: 2 }}
                      >
                        <CheckCircleIcon
                          color="primary"
                          sx={{ marginRight: 1, color: "#1869b6" }}
                        />
                        {x.four}
                      </Typography>
                      {x.five && (
                        <Typography
                          variant="body1"
                          color="textSecondary"
                          gutterBottom
                          sx={{ py: 2, display: "flex" }}
                        >
                          <CheckCircleIcon
                            color="primary"
                            sx={{ marginRight: 1, color: "#1869b6" }}
                          />
                          {x.five}
                        </Typography>
                      )}
                      {x.six && (
                        <Typography
                          variant="body1"
                          color="textSecondary"
                          gutterBottom
                          sx={{ py: 2 }}
                        >
                          <CheckCircleIcon
                            color="primary"
                            sx={{ marginRight: 1, color: "#1869b6" }}
                          />
                          {x.six}
                        </Typography>
                      )}
                    </Box>
                  </CardContent>
                </Card>
              </HtmlTooltip>
            ))
            : dataCardsYear.map((x, index) => (
              <HtmlTooltip key={index} disableHoverListener={!x.isDisabled}
                title={
                  <React.Fragment>
                    <Typography color="inherit">Coming Soon !!!</Typography>
                  </React.Fragment>
                }
              >
                <Card
                  key={index}
                  sx={{
                    maxWidth: 300,
                    padding: "1rem",
                    position: "relative",
                    ...(x.isDisabled && {
                      opacity: 0.4,
                      // "&:hover": {
                      //   cursor: "not-allowed",
                      //   "& $comingSoon": {
                      //     display: "block",
                      //   },
                      // },
                    }),
                  }}
                  onMouseEnter={() => handleDialogOpen(x.isDisabled)}
                >
                  <CardContent>
                    <Typography
                      sx={{
                        textAlign: "center",
                        fontSize: "18px",
                        fontFamily: "Ubuntu",
                        fontWeight: "bold",
                        color: "#1869b6",
                        paddingBottom:
                          index === 0 ? "35px" : index === 1 ? "35px" : "10px",
                      }}
                    >
                      Mudzie {x.price}
                    </Typography>
                    {x.priceTier === "Free" ? (
                      <Typography
                        variant="h6"
                        textAlign="center"
                        fontSize="60px"
                      >
                        {x.value}
                      </Typography>
                    ) : (
                      <>
                        {/* team toogle */}
                        <div style={{ width: "100%", display: "flex", justifyContent: "center" }} >
                          <Box
                            sx={{
                              display: "flex", justifyContent: "center", gap: "20px", marginTop: "20px", backgroundColor: "#1869b6", py: 1, px: 1, width: "max-content", borderRadius: "25px",
                              "& .option": {
                                border: "1px solid #ccc", borderRadius: "16px", padding: "8px 16px", fontSize: "16px", cursor: "pointer", transition: "background-color 0.3s ease, color 0.3s ease", backgroundColor: "white", color: "#333",
                              },
                              "& .option.selected": {
                                backgroundColor: "Purple",
                                color: "white",
                              },
                            }}
                          >
                            <button
                              className={`option ${toggleStates[index] === "Team" ? "selected" : ""}`}
                              onClick={() => handleToggle(index, "Team")}
                            >
                              Team
                            </button>
                            <button
                              className={`option ${toggleStates[index] === "Individual" ? "selected" : ""}`}
                              onClick={() => handleToggle(index, "Individual")}
                            >
                              Individual
                            </button>
                          </Box>
                        </div>
                        {/* team toogle end */}

                        {/* toogle price */}

                        {
                          toggleStates[index] === 'Individual' ?
                            <Typography
                              variant="h6"
                              textAlign="center"
                              fontSize="45px"
                            >
                              ${x.value}{" "}
                              <span style={{ fontSize: "20px", marginLeft: "-10px" }}>
                                /per year
                              </span>
                            </Typography> : ''
                        }
                        {
                          toggleStates[index] === 'Team' ?
                            <Typography
                              variant="h6"
                              textAlign="center"
                              fontSize="45px"
                            >
                              ${x.valueTeam}{" "}
                              <span style={{ fontSize: "20px", marginLeft: "-10px" }}>
                                /per year
                              </span>
                              <br />
                              <p style={{ fontSize: "20px", marginLeft: "-10px", marginTop: '0px' }}>
                                for 5 seats
                              </p>
                            </Typography> : ''
                        }


                        {/* toogle price end*/}

                      </>
                    )}
                    {/* Other details */}
                    {x.isDisabled ? (
                      <></>
                    ) : user?.is_call_requested === 0 && user?.is_call_requested === null
                      && CallRequestSwitch === "ON" ? (
                      <Button
                        onClick={() => onCall(x.plan)}
                        fullWidth
                        variant="contained"
                        sx={{
                          mt: 2,
                          borderRadius: "20px",
                          background: "#1869b6",
                        }}
                      >
                        Setup Discovery Call
                      </Button>
                    ) : user?.is_call_requested === 1 && user?.is_approved_for_subscribe === 0 &&
                      user?.is_reject_for_subsicribe === 0 && CallRequestSwitch === "ON" ? (
                      // Content for the second condition (is_call_requested === 1)
                      <Box sx={{ textAlign: "center" }}>
                        <Typography
                          variant="button"
                          fullWidth
                          color="success"
                          sx={{
                            mt: 3,
                            color: "green",
                            textAlign: "center",
                            fontWeight: "bold",
                          }}
                        >
                          Call Request Sent
                        </Typography>
                      </Box>
                    ) : user?.is_approved_for_subscribe === 1 || plan?.is_active_plan === 1
                      || CallRequestSwitch === "ON" ? (
                      // Content for the third condition (is_approved_for_subscribe === 1)
                      <>
                        {
                          toggleStates[index] === 'Individual' ?
                            <Button
                              fullWidth
                              variant="contained"
                              disabled={
                                selectedPlan === x.plan &&
                                plan?.is_active_plan === 1 &&
                                priceSubbed == 2500 &&
                                // toggleStates[index] === 'Individual' &&
                                period === x.period
                              }
                              sx={{
                                mt: 2,
                                borderRadius: "20px",
                                background: "#1869b6",
                              }}
                              onClick={() =>
                                redirectToCheckout(
                                  x.key,
                                  x.plan,
                                  x.period,
                                  x.priceTier
                                )
                              }
                            >
                              {selectedPlan === x.plan && plan?.is_active_plan === 1 && period === x.period && priceSubbed == 2500
                                ? "active plan!"
                                : "subscribe"}
                            </Button> : ''
                        }
                        {
                          toggleStates[index] === 'Team' ?
                            <Button
                              fullWidth
                              variant="contained"
                              disabled={
                                selectedPlan === x.plan &&
                                plan?.is_active_plan === 1 &&
                                priceSubbed == 10000 &&
                                // toggleStates[index] === 'Team' &&
                                period === x.period
                              }
                              sx={{
                                mt: 2,
                                borderRadius: "20px",
                                background: "#1869b6",
                              }}
                              onClick={() =>
                                redirectToCheckout(
                                  x.keyTeam,
                                  x.plan,
                                  x.period,
                                  x.priceTierTeam
                                )
                              }
                            >
                              {selectedPlan === x.plan && plan?.is_active_plan === 1 && period === x.period && priceSubbed == 10000
                                ? "active plan!"
                                : "subscribe"}
                            </Button> : ''
                        }

                      </>
                    ) : (
                      <Button
                        onClick={() => onCall(x.plan)}
                        fullWidth
                        variant="contained"
                        sx={{
                          mt: 2,
                          borderRadius: "20px",
                          background: "#1869b6",
                        }}
                      >
                        Setup Discovery Call
                      </Button>
                    )}

                    <Box sx={{ mt: 1 }}>
                      <Typography
                        variant="body1"
                        color="textSecondary"
                        gutterBottom
                        sx={{ display: "flex", py: 2 }}
                      >
                        <CheckCircleIcon
                          color="primary"
                          sx={{ marginRight: 1, color: "#1869b6" }}
                        />
                        {x.one}
                      </Typography>

                      <Typography
                        variant="body1"
                        color="textSecondary"
                        gutterBottom
                        sx={{ display: "flex", py: 2 }}
                      >
                        <CheckCircleIcon
                          color="primary"
                          sx={{ marginRight: 1, color: "#1869b6" }}
                        />
                        {x.two}
                      </Typography>
                      <Typography
                        variant="body1"
                        color="textSecondary"
                        gutterBottom
                        sx={{ display: "flex", py: 2 }}
                      >
                        <CheckCircleIcon
                          color="primary"
                          sx={{ marginRight: 1, color: "#1869b6" }}
                        />
                        {x.three}
                      </Typography>
                      <Typography
                        variant="body1"
                        color="textSecondary"
                        gutterBottom
                        sx={{ display: "flex", py: 2 }}
                      >
                        <CheckCircleIcon
                          color="primary"
                          sx={{ marginRight: 1, color: "#1869b6" }}
                        />
                        {x.four}
                      </Typography>
                      {x.five && (
                        <Typography
                          variant="body1"
                          color="textSecondary"
                          gutterBottom
                          sx={{ py: 2, display: "flex" }}
                        >
                          <CheckCircleIcon
                            color="primary"
                            sx={{ marginRight: 1, color: "#1869b6" }}
                          />
                          {x.five}
                        </Typography>
                      )}
                      {x.six && (
                        <Typography
                          variant="body1"
                          color="textSecondary"
                          gutterBottom
                          sx={{ py: 2 }}
                        >
                          <CheckCircleIcon
                            color="primary"
                            sx={{ marginRight: 1, color: "#1869b6" }}
                          />
                          {x.six}
                        </Typography>
                      )}
                    </Box>
                  </CardContent>
                </Card>
              </HtmlTooltip>
            ))}
          {/* <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
            <DialogContent
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <InfoIcon sx={{ fontSize: 48, color: "orange" }} />
              <Typography variant="h6" sx={{ mt: 1 }}>
                Coming Soon
              </Typography>
            </DialogContent>
          </Dialog> */}
        </Box>

        <div style={{ textAlign: "center", paddingTop: "20px" }}>
          <IconButton
            onClick={scrollToFAQ}
            sx={{ background: "#1869b6", "&:hover": { background: "blue" } }}
          >
            <KeyboardArrowDownIcon
              fontSize="large"
              sx={{ color: "white", "&:hover": { color: "white" } }}
            />
          </IconButton>
        </div>

        <Box paddingTop="50px" marginTop="100px" ref={faqRef}>
          <Typography
            variant="h4"
            fontWeight="bold"
            textAlign="center"
            fontFamily="Ubuntu"
            color="black"
            borderBottom="2px solid grey"
            marginBottom="5px"
            paddingBottom="5px"
            marginRight={{ xl: "12%" }}
          >
            Pricing FAQ
          </Typography>
          <Container
            maxWidth="lg"
            width="100%"
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Grid container spacing={4}>
              <Grid
                item
                lg={12}
                md={12}
                xs={12}
                sx={{ marginRight: { md: "4px" } }}
              >
                {faqData.map((faq, index) => (
                  <FaqItem
                    key={index}
                    question={faq.question}
                    answer={faq.answer}
                  />
                ))}
              </Grid>
            </Grid>
          </Container>
        </Box>

        <Box
          width="100%"
          height="80px"
          sx={{
            background: "linear-gradient(to right bottom, #1976D2, #175FA2)",
            mt: 5,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box height="100%" sx={{ display: "flex", alignItems: "center" }}>
            <Typography
              sx={{
                fontFamily: "Ubuntu",
                fontSize: "10px",
                color: "white",
                pl: 5,
              }}
            >
              Copyright © 2023 MUDZIE - All Rights Reserved | Powered by
              KimmyMae™
            </Typography>
          </Box>
          <Box>
            <Typography
              sx={{
                fontFamily: "Ubuntu",
                fontSize: "10px",
                color: "white",
                pr: 8,
                mt: 2,
              }}
            >
              Privacy policy | Terms and conditions
            </Typography>
            <hr style={{ width: "90%", marginLeft: "-5px" }} />
            <Box sx={{ display: "flex", gap: "10px", marginLeft: "100px" }}>
              <img src={twitter} alt="" width={20} height={20} />
              <img src={facebook} alt="" width={20} height={20} />
              <img src={linkdin} alt="" width={20} height={20} />
            </Box>
          </Box>
        </Box>
        <Dialog
          open={showConfirmation}
          onClose={() => setShowConfirmation(false)}
        >
          <div>
            {showConfirmation && (
              <Alert
                severity="success"
                onClose={() => setShowConfirmation(false)}
              >
                <AlertTitle>success</AlertTitle>
                Your Request submit Successfully
              </Alert>
            )}
          </div>
        </Dialog>
        <Dialog open={showerror} onClose={() => setshowerror(false)}>
          <div>
            {showerror && (
              <Alert severity="error" onClose={() => setshowerror(false)}>
                <AlertTitle>Error</AlertTitle>
                Your Request Not Submit
              </Alert>
            )}
          </div>
        </Dialog>
        <Dialog open={request} onClose={() => setrequest(false)}>
          <div>
            {request && (
              <Alert severity="error" onClose={() => setrequest(false)}>
                <AlertTitle>Error</AlertTitle>
                You canont send request because you are reject for subscribe
              </Alert>
            )}
          </div>
        </Dialog>
      </div>
      <ReactJsAlert
        status={alertStatus} // true or false
        type={type} // success, warning, error, info
        title={title}
        autoCloseIn="30000"
        Close={() => {
          setAlertStatus(false);
          //   navigate("/", { replace: true });
        }}
      />
    </ThemeProvider>
  );
};

function FaqItem({ question, answer }) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Box width="100%" borderBottom="1px solid black">
      <Box
        display="flex"
        justifyContent="space-between"
        cursor="pointer"
        width="100%"
        onClick={toggleOpen}
        padding="20px"
      >
        <Typography variant="h6" fontWeight="bold" fontFamily="Ubuntu">
          {question}
        </Typography>
        <IconButton size="small">
          {isOpen ? <RemoveIcon /> : <AddIcon />}
        </IconButton>
      </Box>
      <Collapse in={isOpen}>
        <Typography fontFamily="Ubuntu" padding="20px">
          {answer}
        </Typography>
      </Collapse>
    </Box>
  );
}

export default Payments;
