import Axios from "axios";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
// import Logo from "../images/Color logo with background.png";
import mudzie from "../images/Color logo - no background.png";
import { Box } from "@mui/material";
import twitter from "../images/twitter 1.png";
import facebook from "../images/facebook-app-symbol 1.png";
import linkdin from "../images/linkedin (2) 1.png";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const Success = () => {
  const [plan, setPlan] = useState("");
  const [period, setPeriod] = useState("");
  const [price, setprice] = useState("");
  const navigate = useNavigate();

  const decodeString = (encodedStr) => {
    // You can implement your own custom decoding logic here
    // This is just a simple example, you can make it more robust
    return atob(encodedStr); // Base64 decoding
  };

  useEffect(() => {
    const user = localStorage.getItem("user");
    const userde = JSON.parse(user);
    // Get the plan value from the query parameters
    const queryParams = new URLSearchParams(window.location.search);
    const encodedPlanValue = queryParams.get("plan");
    const encodedPeriodValue = queryParams.get("period");
    const encodePrice = queryParams.get("price");
    const decodedPlan = decodeString(encodedPlanValue);
    const decodedPeriod = decodeString(encodedPeriodValue);
    const decodePrice = decodeString(encodePrice);
    const obj = {
      amount: decodePrice,
      plan: decodedPlan,
      period: decodedPeriod,
    };
    console.log("obj : ", obj);
    Axios.post(`${process.env.REACT_APP_BASE_URL}api/payment-success`, obj, {
      headers: {
        Authorization: `Bearer ${userde?.token}`,
      },
    })
      .then((res) => {
        console.log("################", res.data);
      })
      .catch((error) => {
        console.log("4444444444", error);
      });

    // Update the state with the plan value
    setPlan(decodedPlan);
    setPeriod(decodedPeriod);
    setprice(decodePrice);
  }, []);

  const blinkStyle = {
    animation: 'blinker 3s linear infinite'
  };

  const blinkKeyframes = `
    @keyframes blinker {
      50% { opacity: 0; }
    }
  `;
  return (
    <div>
      <Box
        sx={{
          flexDirection: "row",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          background: "linear-gradient(to right bottom, #1976D2, #175FA2)",
          height: "100px",
        }}
      >
        <Box sx={{ ml: { xl: 15, lg: 10, sm: 10, xs: 0 } }}>
          <img
            src={mudzie}
            alt="logo"
            width={200}
            height={60}
          // style={{ marginLeft: "80px", marginRight: "-30px" }}
          />
        </Box>

        {/* <Typography
                            sx={{
								color: "white",
								marginLeft: "180px",
								fontFamily: "Ubuntu",
								fontSize: "10px",
								ml: { xl: 4, sm: 16, xs: 0 },
								mr: { xl: -16 },
							  }}
                        >
                            Hello,Prshant.hanamg...
                        </Typography> */}
      </Box>
      <Card elevation={3} style={{ width: "400px", margin: "50px auto" }}>
        <CardContent>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              borderRadius="50%"
              width="200px"
              height="200px"
              bgcolor="#F8FAF5"
              boxShadow="0 2px 3px #C8D0D8"
              sx={{ marginBottom: "20px" }}
            >
              <CheckCircleIcon sx={{ fontSize: "100px", color: "green" }} />
            </Box>
          </Box>

          <Typography
            variant="h1"
            sx={{
              color: "#1976d2",
              fontWeight: 900,
              fontSize: "40px",
              marginBottom: "10px",
              fontFamily: "Nunito Sans, Helvetica Neue, sans-serif",
              textAlign: "center",
            }}
          >
            Success
          </Typography>

          <Paper
            elevation={0}
            style={{ backgroundColor: "#f0f0f0", padding: "16px" }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} style={{ textAlign: "center", color: "#1976d2" }}>
                <Typography variant="h6" component="div">
                  Thank you for your purchase!{price}
                </Typography>

              </Grid>
              {
                price === "1000" || price === "10000" ?
                  <Grid item xs={12} style={{ textAlign: "center", color: "#1976d2" }}>
                    <div>
                      <style>
                        {blinkKeyframes}
                      </style>
                      <Typography variant="h6" component="div" style={blinkStyle}>
                        Please go to your account and add yourself and team members to start using Mudzie.
                      </Typography>
                    </div>
                  </Grid>
                  :
                  ""
              }
              <Grid item xs={4}>
                <Typography variant="body1" component="div">
                  Plan:
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="body1" component="div">
                  Mudzie {plan}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body1" component="div">
                  Period:
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="body1" component="div">
                  {period === "monthly"
                    ? "Monthly"
                    : period === "yearly"
                      ? "Yearly"
                      : ""}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body1" component="div">
                  Price:
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="body1" component="div">
                  ${price}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  onClick={() => navigate("/accountpage")}
                  fullWidth
                >
                  Go to My Account
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </CardContent>
      </Card>
      <Box
        width="100%"
        height="80px"
        bottom="0"
        sx={{
          background: "linear-gradient(to right bottom, #1976D2, #175FA2)",
          mt: 9,
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box height="100%" sx={{ display: "flex", alignItems: "center" }}>
          <Typography
            sx={{
              fontFamily: "Ubuntu",
              fontSize: "10px",
              color: "white",
              pl: 5,
            }}
          >
            Copyright © 2023 MUDZIE - All Rights Reserved | Powered by KimmyMae™
          </Typography>
        </Box>
        <Box>
          <Typography
            sx={{
              fontFamily: "Ubuntu",
              fontSize: "10px",
              color: "white",
              pr: 8,
              mt: 2,
            }}
          >
            Privacy policy | Terms and conditions
          </Typography>
          <hr style={{ width: "90%", marginLeft: "-5px" }} />
          <Box sx={{ display: "flex", gap: "10px", marginLeft: "100px" }}>
            <img src={twitter} alt="" width={20} height={20} />
            <img src={facebook} alt="" width={20} height={20} />
            <img src={linkdin} alt="" width={20} height={20} />
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default Success;
