import React, { useState, useEffect } from "react";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import SignIn from "./SingIn";
import Calculator from "./Calculator";
import DusCalculator from "./DusCalculator";
import SignUp from "./SignUp";
import ForgotPassword from "./ForgotPassword";
// import Admin from "./admin/Admin"
import Dashboard from "./pages/Dashboard";
import About from "./pages/About";
import Settings from "./pages/Settings";
import Logs from "./pages/Logs";
import Payments from "./components/Payments";
import Charttable from "./pages/Charttable";
import Investors from "./components/Investors";
import Adduser from "./pages/Adduser";
import AccountPage from "./components/AccountPage";
import Showuser from "./pages/Showuser";
import { UserDataProvider } from "./UserDataContext";
import ViewDetails from "./pages/ViewDetails";
import CallRequest from "./pages/CallRequest";
import DUSPXSpread from "./pages/DUSPXSpread";
import "@stripe/stripe-js";
import Success from "./components/Success";
import Cancel from "./components/Cancel";
import { RateToPricePX } from "./pages/RateToPricePX";

function App() {
  const navigate = useNavigate();
  const [firstTimeLogin, setFirstTimeLogin] = useState(true);

  function isAuthenticate() {
    // Add your authentication logic here
    const userId = localStorage.getItem("userId");
    if (userId === "admin") {
      return !!userId;
    }
  }

  function isAuthenticated() {
    // Add your authentication logic here
    const userId = localStorage.getItem("userId");
    console.log("------------------", userId);

    return !!userId;
  }
  useEffect(() => {
    const userId = localStorage.getItem("userId");
    if (userId === "admin") {
      setFirstTimeLogin(false);
      //navigate("/dashboard/settings");
      <Navigate to="/dashboard/settings" />;
    } else if (userId) {
      setFirstTimeLogin(false);
      // navigate("/calculator");
      <Navigate to="/calculator" />;
    } else {
      setFirstTimeLogin(true);
    }
  }, [Navigate, navigate]);

  return (
    <UserDataProvider>
      <Routes>
        <Route path="/" element={firstTimeLogin ? <SignIn /> : <Navigate to="/user" />} />
        <Route exact path="/signup" element={<SignUp />} />
        <Route exact path="/forgotpassword" element={<ForgotPassword />} />
        <Route path="/user"
          element={
            firstTimeLogin ? (
              <Navigate to="/" />
            ) : (
              <>
                {localStorage.getItem("userId") === "admin" ? (
                  <Navigate to="/dashboard/settings" />
                ) : (
                  <Navigate to="/calculator" />
                )}
              </>
            )
          }
        />
        <Route exact path="/calculator" element={isAuthenticated() ? <Calculator /> : <Navigate to="/" />} />
        <Route exact path="/duscalculator" element={isAuthenticated() ? <DusCalculator /> : <Navigate to="/" />} />
        <Route exact path="/dashboard" element={isAuthenticate() ? <Dashboard /> : <Navigate to="/" />} />
        <Route exact path="/dashboard/about" element={isAuthenticate() ? <About /> : <Navigate to="/" />} />
        <Route exact path="/dashboard/settings" element={isAuthenticated() ? <Settings /> : <Navigate to="/" />} />
        <Route exact path="/dashboard/ratetopricepx" element={isAuthenticated() ? <RateToPricePX /> : <Navigate to="/" />} />
        <Route exact path="/dashboard/logs" element={isAuthenticate() ? <Logs /> : <Navigate to="/" />} />
        <Route exact path="/payments" element={<Payments />} />
        <Route exact path="/dashboard/charttable" element={isAuthenticate() ? <Charttable /> : <Navigate to="/" />} />
        <Route exact path="/investors" element={<Investors />} />
        <Route path="/dashboard/adduser" element={isAuthenticate() ? <Adduser /> : <Navigate to="/" />} />
        <Route path="/accountpage" element={isAuthenticated() ? <AccountPage /> : <Navigate to="/" />} />
        <Route path="/dashboard/showuser" element={isAuthenticate() ? <Showuser /> : <Navigate to="/" />} />
        <Route path="/viewdetails" element={isAuthenticate() ? <ViewDetails /> : <Navigate to="/" />} />
        <Route path="/dashboard/callrequest" element={isAuthenticate() ? <CallRequest /> : <Navigate to="/" />} />
        <Route path="/success" element={isAuthenticated() ? <Success /> : <Navigate to="/" />} />
        <Route path="cancel" element={<Cancel />} />
        <Route path="/dashboard/duspxspread" element={isAuthenticate() ? <DUSPXSpread /> : <Navigate to="/" />} />
      </Routes>
    </UserDataProvider>
  );
}

export default App;
