import React, { useState, useEffect } from 'react';
import Sidenav from '../components/Sidenav';
import Navbar from '../components/Navbar';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import "../dash.css";
import Button from "@mui/material/Button";
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { useForm } from "react-hook-form";
import Axios from "axios";
import { ClipLoader } from 'react-spinners';

export const DUSPXSpread = () => {
    const user = localStorage.getItem("user");
    const userde = JSON.parse(user);
    const [submitSuccess, setSubmitSuccess] = useState(false)
    const [loading, setLoading] = useState(true)

    const { reset, register, handleSubmit, formState: { errors }, } = useForm();

    useEffect(() => {
        let defaultValues = {};
        Axios.get(
            `${process.env.REACT_APP_BASE_URL}api/admin/dpp-structure-values/`,
            {
                headers: {
                    Authorization: `Bearer ${userde?.token}`,
                },
            }
        )
            .then((res) => {
                defaultValues.dpp = res.data.data.dpp;
                defaultValues.fiveByFour = res.data.data.fiveByFour;
                defaultValues.fiveByThree = res.data.data.fiveByThree;
                defaultValues.sevenBySix = res.data.data.sevenBySix;
                defaultValues.sevenByFive = res.data.data.sevenByFive;
                defaultValues.tenByNine = res.data.data.tenByNine;
                defaultValues.tenByEight = res.data.data.tenByEight;
                defaultValues.tenBySeven = res.data.data.tenBySeven;
                defaultValues.twelByEleven = res.data.data.twelByEleven;
                defaultValues.twelByTen = res.data.data.twelByTen;
                defaultValues.fifteenByFourteen = res.data.data.fifteenByFourteen;
                defaultValues.fifteenByTen = res.data.data.fifteenByTen;
                defaultValues.twentyByFifteen = res.data.data.twentyByFifteen;
                defaultValues.thirtyByFifteen = res.data.data.thirtyByFifteen;
                reset({ ...defaultValues });
                setLoading(false)
            })
            .catch((err) => {
                console.log(err);
            });
    }, [reset]);

    const onSubmit = (data) => {
        setLoading(true)
        Axios.post(
            `${process.env.REACT_APP_BASE_URL}api/admin/dpp-structure-values/`, data,
            {
                headers: {
                    Authorization: `Bearer ${userde?.token}`,
                },
            }
        )
            .then((res) => {
                console.log(res);
                setLoading(false)
                setSubmitSuccess(true)
            })
            .catch((err) => {
                console.log(err);
            });
    };

    return (
        <div>
            <Navbar />
            <Box height={10} />
            <Box sx={{ display: 'flex' }}>
                <Sidenav menuHeighlightPath="/dashboard/duspxspread" />
                <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>

                            <Card sx={{ boxShadow: 12, borderRadius: '20px', mt: 10 }}>
                                {
                                    submitSuccess ?
                                        <Alert severity="success" onClose={() => setSubmitSuccess(false)}>
                                            <AlertTitle>Success</AlertTitle>
                                            All values saved successfully!
                                        </Alert>
                                        :
                                        <></>
                                }
                                {
                                    !loading ?
                                        <form onSubmit={handleSubmit(onSubmit)}>

                                            <CardHeader
                                                // title="Settings"
                                                title="Edit DUS - DPP"
                                                titleTypographyProps={{ fontFamily: 'Ubuntu', fontWeight: 'bold', mt: 3, pl: 4 }}
                                                action={<Button variant="contained" type="submit">Save</Button>}
                                            />
                                            <hr width='95%' />
                                            <Stack spacing={8} direction={"column"}>

                                                <Box sx={{ display: 'flex', gap: '100px', paddingLeft: '30px' }}>
                                                    <Grid item xs={1.2}>
                                                        <TextField
                                                            name='dpp'
                                                            InputLabelProps={{ style: { color: '#8D8D8D', fontFamily: 'Ubuntu', fontSize: '15px' } }}
                                                            margin="normal"
                                                            label="DPP"
                                                            type="number"
                                                            required
                                                            size="small"
                                                            variant="standard"
                                                            {...register("dpp", { required: "Required" })}
                                                            error={Boolean(errors.dpp)}
                                                            helperText={errors.dpp?.message}
                                                            sx={{
                                                                '& .MuiInput-underline': {
                                                                    borderBottomColor: '#8D8D8D',
                                                                    borderWidth: '2px',
                                                                    borderBottomStyle: 'solid' // Replace with your desired color
                                                                },
                                                                '& .MuiInput-underline:after': {
                                                                    borderBottomColor: '#8D8D8D',
                                                                    borderWidth: '2px',
                                                                    borderBottomStyle: 'solid' // Replace with your desired color
                                                                },
                                                            }}
                                                        />
                                                    </Grid>
                                                </Box>
                                            </Stack>
                                            <CardHeader
                                                // title="Settings"
                                                title="Edit DUS - Structure"
                                                titleTypographyProps={{ fontFamily: 'Ubuntu', fontWeight: 'bold', mt: 3, pl: 4 }}
                                            // action={<Button variant="contained" type="submit">Save</Button>}
                                            />
                                            <hr width='95%' />
                                            <CardContent>
                                                <Stack spacing={8} direction={"column"}>
                                                    <Box sx={{ display: 'flex', gap: '100px', paddingLeft: '30px' }}>
                                                        <Grid item xs={1.2}>
                                                            <TextField
                                                                name='fiveByFour'
                                                                InputLabelProps={{ style: { color: '#8D8D8D', fontFamily: 'Ubuntu', fontSize: '15px' } }}
                                                                margin="normal"
                                                                label="5/4.5"
                                                                type="number"
                                                                required
                                                                // value={fiveByFour}
                                                                // onChange={(e) => { setFiveByFour(e.target.value) }}
                                                                size="small"
                                                                variant="standard"
                                                                {...register("fiveByFour", { required: "Required" })}
                                                                error={Boolean(errors.fiveByFour)}
                                                                helperText={errors.fiveByFour?.message}
                                                                sx={{
                                                                    '& .MuiInput-underline': {
                                                                        borderBottomColor: '#8D8D8D',
                                                                        borderWidth: '2px',
                                                                        borderBottomStyle: 'solid' // Replace with your desired color
                                                                    },
                                                                    '& .MuiInput-underline:after': {
                                                                        borderBottomColor: '#8D8D8D',
                                                                        borderWidth: '2px',
                                                                        borderBottomStyle: 'solid' // Replace with your desired color
                                                                    },
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={1.2}>
                                                            <TextField
                                                                name='fiveByThree'
                                                                InputLabelProps={{ style: { color: '#8D8D8D', fontFamily: 'Ubuntu', fontSize: '15px' } }}
                                                                margin="normal"
                                                                label="5/3"
                                                                type="number"
                                                                required
                                                                // value={fiveByThree}
                                                                // onChange={(e) => { setFiveByThree(e.target.value) }}
                                                                size="small"
                                                                variant="standard"
                                                                {...register("fiveByThree", { required: "Required" })}
                                                                error={Boolean(errors.fiveByThree)}
                                                                helperText={errors.fiveByThree?.message}
                                                                sx={{
                                                                    '& .MuiInput-underline': {
                                                                        borderBottomColor: '#8D8D8D',
                                                                        borderWidth: '2px',
                                                                        borderBottomStyle: 'solid' // Replace with your desired color
                                                                    },
                                                                    '& .MuiInput-underline:after': {
                                                                        borderBottomColor: '#8D8D8D',
                                                                        borderWidth: '2px',
                                                                        borderBottomStyle: 'solid' // Replace with your desired color
                                                                    },
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={1.2}>
                                                            <TextField
                                                                name='sevenBySix'
                                                                InputLabelProps={{ style: { color: '#8D8D8D', fontFamily: 'Ubuntu', fontSize: '15px' } }}
                                                                margin="normal"
                                                                label="7/6.5"
                                                                type="number"
                                                                required
                                                                // value={sevenBySix}
                                                                // onChange={(e) => { setSevenBySix(e.target.value) }}
                                                                size="small"
                                                                variant="standard"
                                                                {...register("sevenBySix", { required: "Required" })}
                                                                error={Boolean(errors.sevenBySix)}
                                                                helperText={errors.sevenBySix?.message}
                                                                sx={{
                                                                    '& .MuiInput-underline': {
                                                                        borderBottomColor: '#8D8D8D',
                                                                        borderWidth: '2px',
                                                                        borderBottomStyle: 'solid' // Replace with your desired color
                                                                    },
                                                                    '& .MuiInput-underline:after': {
                                                                        borderBottomColor: '#8D8D8D',
                                                                        borderWidth: '2px',
                                                                        borderBottomStyle: 'solid' // Replace with your desired color
                                                                    },
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={1.2}>
                                                            <TextField
                                                                name='sevenByFive'
                                                                InputLabelProps={{ style: { color: '#8D8D8D', fontFamily: 'Ubuntu', fontSize: '15px' } }}
                                                                margin="normal"
                                                                label="7/5"
                                                                type="number"
                                                                required
                                                                // value={sevenByFive}
                                                                // onChange={(e) => { setSevenByFive(e.target.value) }}
                                                                size="small"
                                                                variant="standard"
                                                                {...register("sevenByFive", { required: "Required" })}
                                                                error={Boolean(errors.sevenByFive)}
                                                                helperText={errors.sevenByFive?.message}
                                                                sx={{
                                                                    '& .MuiInput-underline': {
                                                                        borderBottomColor: '#8D8D8D',
                                                                        borderWidth: '2px',
                                                                        borderBottomStyle: 'solid' // Replace with your desired color
                                                                    },
                                                                    '& .MuiInput-underline:after': {
                                                                        borderBottomColor: '#8D8D8D',
                                                                        borderWidth: '2px',
                                                                        borderBottomStyle: 'solid' // Replace with your desired color
                                                                    },
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={1.2}>
                                                            <TextField
                                                                name='tenByNine'
                                                                InputLabelProps={{ style: { color: '#8D8D8D', fontFamily: 'Ubuntu', fontSize: '15px' } }}
                                                                margin="normal"
                                                                label="10/9.5"
                                                                type="number"
                                                                required
                                                                // value={tenByNine}
                                                                // onChange={(e) => { setTenByNine(e.target.value) }}
                                                                size="small"
                                                                variant="standard"
                                                                {...register("tenByNine", { required: "Required" })}
                                                                error={Boolean(errors.tenByNine)}
                                                                helperText={errors.tenByNine?.message}
                                                                sx={{
                                                                    '& .MuiInput-underline': {
                                                                        borderBottomColor: '#8D8D8D',
                                                                        borderWidth: '2px',
                                                                        borderBottomStyle: 'solid' // Replace with your desired color
                                                                    },
                                                                    '& .MuiInput-underline:after': {
                                                                        borderBottomColor: '#8D8D8D',
                                                                        borderWidth: '2px',
                                                                        borderBottomStyle: 'solid' // Replace with your desired color
                                                                    },
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={1.2}>
                                                            <TextField
                                                                name='tenByEight'
                                                                InputLabelProps={{ style: { color: '#8D8D8D', fontFamily: 'Ubuntu', fontSize: '15px' } }}
                                                                margin="normal"
                                                                label="10/8"
                                                                type="number"
                                                                required
                                                                // value={tenByEight}
                                                                // onChange={(e) => { setTenByEight(e.target.value) }}
                                                                size="small"
                                                                variant="standard"
                                                                {...register("tenByEight", { required: "Required" })}
                                                                error={Boolean(errors.tenByEight)}
                                                                helperText={errors.tenByEight?.message}
                                                                sx={{
                                                                    '& .MuiInput-underline': {
                                                                        borderBottomColor: '#8D8D8D',
                                                                        borderWidth: '2px',
                                                                        borderBottomStyle: 'solid' // Replace with your desired color
                                                                    },
                                                                    '& .MuiInput-underline:after': {
                                                                        borderBottomColor: '#8D8D8D',
                                                                        borderWidth: '2px',
                                                                        borderBottomStyle: 'solid' // Replace with your desired color
                                                                    },
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Box>
                                                    <Box sx={{ display: 'flex', gap: '100px', paddingLeft: '30px' }}>
                                                        <Grid item xs={1.2}>
                                                            <TextField
                                                                name='tenBySeven'
                                                                InputLabelProps={{ style: { color: '#8D8D8D', fontFamily: 'Ubuntu', fontSize: '15px' } }}
                                                                margin="normal"
                                                                label="10/7"
                                                                type="number"
                                                                required
                                                                // value={tenBySeven}
                                                                // onChange={(e) => { setTenBySeven(e.target.value) }}
                                                                size="small"
                                                                variant="standard"
                                                                {...register("tenBySeven", { required: "Required" })}
                                                                error={Boolean(errors.tenBySeven)}
                                                                helperText={errors.tenBySeven?.message}
                                                                sx={{
                                                                    '& .MuiInput-underline': {
                                                                        borderBottomColor: '#8D8D8D',
                                                                        borderWidth: '2px',
                                                                        borderBottomStyle: 'solid' // Replace with your desired color
                                                                    },
                                                                    '& .MuiInput-underline:after': {
                                                                        borderBottomColor: '#8D8D8D',
                                                                        borderWidth: '2px',
                                                                        borderBottomStyle: 'solid' // Replace with your desired color
                                                                    },
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={1.2}>
                                                            <TextField
                                                                name='twelByEleven'
                                                                InputLabelProps={{ style: { color: '#8D8D8D', fontFamily: 'Ubuntu', fontSize: '15px' } }}
                                                                margin="normal"
                                                                label="12/11.5"
                                                                type="number"
                                                                required
                                                                // value={twelByEleven}
                                                                // onChange={(e) => { setTwelByEleven(e.target.value) }}
                                                                size="small"
                                                                variant="standard"
                                                                {...register("twelByEleven", { required: "Required" })}
                                                                error={Boolean(errors.twelByEleven)}
                                                                helperText={errors.twelByEleven?.message}
                                                                sx={{
                                                                    '& .MuiInput-underline': {
                                                                        borderBottomColor: '#8D8D8D',
                                                                        borderWidth: '2px',
                                                                        borderBottomStyle: 'solid' // Replace with your desired color
                                                                    },
                                                                    '& .MuiInput-underline:after': {
                                                                        borderBottomColor: '#8D8D8D',
                                                                        borderWidth: '2px',
                                                                        borderBottomStyle: 'solid' // Replace with your desired color
                                                                    },
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={1.2}>
                                                            <TextField
                                                                name='twelByTen'
                                                                InputLabelProps={{ style: { color: '#8D8D8D', fontFamily: 'Ubuntu', fontSize: '15px' } }}
                                                                margin="normal"
                                                                label="12/10"
                                                                type="number"
                                                                required
                                                                // value={twelByTen}
                                                                // onChange={(e) => { setTwelByTen(e.target.value) }}
                                                                size="small"
                                                                variant="standard"
                                                                {...register("twelByTen", { required: "Required" })}
                                                                error={Boolean(errors.twelByTen)}
                                                                helperText={errors.twelByTen?.message}
                                                                sx={{
                                                                    '& .MuiInput-underline': {
                                                                        borderBottomColor: '#8D8D8D',
                                                                        borderWidth: '2px',
                                                                        borderBottomStyle: 'solid' // Replace with your desired color
                                                                    },
                                                                    '& .MuiInput-underline:after': {
                                                                        borderBottomColor: '#8D8D8D',
                                                                        borderWidth: '2px',
                                                                        borderBottomStyle: 'solid' // Replace with your desired color
                                                                    },
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={1.2}>
                                                            <TextField
                                                                name='fifteenByFourteen'
                                                                InputLabelProps={{ style: { color: '#8D8D8D', fontFamily: 'Ubuntu', fontSize: '15px' } }}
                                                                margin="normal"
                                                                label="15/14.5"
                                                                type="number"
                                                                required
                                                                // value={fifteenByFourteen}
                                                                // onChange={(e) => { setFifteenByFourteen(e.target.value) }}
                                                                size="small"
                                                                variant="standard"
                                                                {...register("fifteenByFourteen", { required: "Required" })}
                                                                error={Boolean(errors.fifteenByFourteen)}
                                                                helperText={errors.fifteenByFourteen?.message}
                                                                sx={{
                                                                    '& .MuiInput-underline': {
                                                                        borderBottomColor: '#8D8D8D',
                                                                        borderWidth: '2px',
                                                                        borderBottomStyle: 'solid' // Replace with your desired color
                                                                    },
                                                                    '& .MuiInput-underline:after': {
                                                                        borderBottomColor: '#8D8D8D',
                                                                        borderWidth: '2px',
                                                                        borderBottomStyle: 'solid' // Replace with your desired color
                                                                    },
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={1.2}>
                                                            <TextField
                                                                name='fifteenByTen'
                                                                InputLabelProps={{ style: { color: '#8D8D8D', fontFamily: 'Ubuntu', fontSize: '15px' } }}
                                                                margin="normal"
                                                                label="15/10"
                                                                type="number"
                                                                required
                                                                // value={fifteenByTen}
                                                                // onChange={(e) => { setFifteenByTen(e.target.value) }}
                                                                size="small"
                                                                variant="standard"
                                                                {...register("fifteenByTen", { required: "Required" })}
                                                                error={Boolean(errors.fifteenByTen)}
                                                                helperText={errors.fifteenByTen?.message}
                                                                sx={{
                                                                    '& .MuiInput-underline': {
                                                                        borderBottomColor: '#8D8D8D',
                                                                        borderWidth: '2px',
                                                                        borderBottomStyle: 'solid' // Replace with your desired color
                                                                    },
                                                                    '& .MuiInput-underline:after': {
                                                                        borderBottomColor: '#8D8D8D',
                                                                        borderWidth: '2px',
                                                                        borderBottomStyle: 'solid' // Replace with your desired color
                                                                    },
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={1.2}>
                                                            <TextField
                                                                name='twentyByFifteen'
                                                                InputLabelProps={{ style: { color: '#8D8D8D', fontFamily: 'Ubuntu', fontSize: '15px' } }}
                                                                margin="normal"
                                                                label="20/15"
                                                                type="number"
                                                                required
                                                                // value={twentyByFifteen}
                                                                // onChange={(e) => { setTwentyByFifteen(e.target.value) }}
                                                                size="small"
                                                                variant="standard"
                                                                {...register("twentyByFifteen", { required: "Required" })}
                                                                error={Boolean(errors.twentyByFifteen)}
                                                                helperText={errors.twentyByFifteen?.message}
                                                                sx={{
                                                                    '& .MuiInput-underline': {
                                                                        borderBottomColor: '#8D8D8D',
                                                                        borderWidth: '2px',
                                                                        borderBottomStyle: 'solid' // Replace with your desired color
                                                                    },
                                                                    '& .MuiInput-underline:after': {
                                                                        borderBottomColor: '#8D8D8D',
                                                                        borderWidth: '2px',
                                                                        borderBottomStyle: 'solid' // Replace with your desired color
                                                                    },
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Box>
                                                    <Box sx={{ display: 'flex', gap: '100px', paddingLeft: '30px' }}>
                                                        <Grid item xs={1.2}>
                                                            <TextField
                                                                name='thirtyByFifteen'
                                                                InputLabelProps={{ style: { color: '#8D8D8D', fontFamily: 'Ubuntu', fontSize: '15px' } }}
                                                                margin="normal"
                                                                label="30/15"
                                                                type="number"
                                                                required
                                                                // value={thirtyByFifteen}
                                                                // onChange={(e) => { setThirtyByFifteen(e.target.value) }}
                                                                size="small"
                                                                variant="standard"
                                                                {...register("thirtyByFifteen", { required: "Required" })}
                                                                error={Boolean(errors.thirtyByFifteen)}
                                                                helperText={errors.thirtyByFifteen?.message}
                                                                sx={{
                                                                    '& .MuiInput-underline': {
                                                                        borderBottomColor: '#8D8D8D',
                                                                        borderWidth: '2px',
                                                                        borderBottomStyle: 'solid' // Replace with your desired color
                                                                    },
                                                                    '& .MuiInput-underline:after': {
                                                                        borderBottomColor: '#8D8D8D',
                                                                        borderWidth: '2px',
                                                                        borderBottomStyle: 'solid' // Replace with your desired color
                                                                    },
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Box>
                                                </Stack>
                                            </CardContent>
                                        </form>
                                        :
                                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                            <ClipLoader
                                                color="#013a80"
                                                loading={loading}
                                                size={50}
                                                aria-label="Loading Spinner"
                                                data-testid="loader"
                                            />
                                        </Box>
                                }
                            </Card>


                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </div>
    )
}
export default DUSPXSpread      