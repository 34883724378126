import React, { useState, useEffect } from 'react';
import Sidenav from '../components/Sidenav';
import Navbar from '../components/Navbar';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import SearchIcon from '@mui/icons-material/Search';
import "../dash.css";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableHead from '@mui/material/TableHead';
// import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Axios from 'axios';
import { InputAdornment } from '@mui/material';

export default function Logs() {
    const columns = [
        { id: 'logId', label: 'Log Id' },
        { id: 'user', label: 'User' },
        { id: 'emailId', label: 'Email Id' },
        { id: 'loanAmount', label: 'Loan Amount' },
        { id: 'programId', label: 'Program ID' },
        { id: 'rate', label: 'Rate' },
        { id: 'intTsy', label: 'Int TSY' },
        { id: 'intSOFR', label: 'Int SOFR' },
        { id: 'price', label: 'Price' },
        { id: 'type', label: 'Type' },
        { id: 'frontEndFees', label: 'Front End Fees' },
        { id: 'pxSpread', label: 'PX Spread' },
        { id: 'loanType', label: 'Loan Type' },
        { id: 'logTime', label: 'Log Time' },
    ];
    // const [logs, setLogs] = useState();
    function createData(logId, user, emailId, loanAmount, programId, rate, intTsy, intSOFR, price, type, frontEndFees, pxSpread, loanType, logTime) {
        return { logId, user, emailId, loanAmount, programId, rate, intTsy, intSOFR, price, type, frontEndFees, pxSpread, loanType, logTime };
    }
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    let rows = [];
    const [loading, setLoading] = useState(true);
    const [rowsState, setRowsState] = useState();
    useEffect(() => {
        Axios.get(`${process.env.REACT_APP_BASE_URL}api/getLogs`,)
            .then((res) => {
                if (res.data.length < 1)
                    console.log("Records not found");
                else {
                    res.data.map((row, index) => (
                        rows.push(createData(index + 1, row.name, row.emailId, row.loanAmount, row.programId, row.rate, Number(row.intTsy).toFixed(2), row.intSOFR, row.price, row.type, row.frontEndFees, row.pxSpread, row.loanType, row.logTime))
                    ))
                }
                setRowsState(rows);
                setLoading(false);
            })
    }, []);

    const [filterValue, setFilterValue] = useState('');

    console.log(filterValue)
    console.log(rowsState)
//     const handleFilterChange = (event) => {
//         event.preventDefault()
//     const { value } = event.target;
//     setFilterValue(value);

//     if (value.trim() === '') {
//       setFilteredRows(rowsState);
//     } else {
//       const filtered = rowsState.filter((row) =>
//         columns.some((column) =>
//           row[column.id].toString().toLowerCase().includes(value.toLowerCase())
//         )
//       );
//       setFilteredRows(filtered);
//     }
//   };
    return (
        <div className='bgcolor'>
            <Navbar />
            <Box height={70} />
            <Box sx={{ display: 'flex' }}>
                <Sidenav menuHeighlightPath = "/dashboard/logs" />
                <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                        
                            <Card>
                                <CardHeader
                                    title="Logs"
                                    action={<> <TextField
                                     label="Filter"
                                     value={filterValue}
                                     onChange={(e)=>setFilterValue(e.target.value)}
                                     InputProps={{
                                                startAdornment: (
                                                 <InputAdornment position='start'>
                                                    <SearchIcon/>
                                                 </InputAdornment>
                                                            ),
                                               }}
                                    variant="standard"
                                   /></>}
                                />
                                <CardContent>
                                    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                                        <TableContainer sx={{ maxHeight: 700, maxWidth: 1200 }}>
                                            <Table stickyHeader aria-label="sticky table">
                                                <TableHead>
                                                    <TableRow>
                                                        {columns.map((column, index) => (
                                                            <TableCell
                                                                key={index}
                                                                align={column.align}
                                                                style={{ minWidth: column.minWidth }}
                                                            >
                                                                {column.label}
                                                            </TableCell>
                                                        ))}
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {
                                                        loading ? <></> :
                                                            rowsState.length ?
                                                                rowsState.filter((row)=>{return filterValue.toLowerCase === ''? row:row.emailId.toLowerCase().includes(filterValue)|| row.user.toLowerCase().includes(filterValue) || row.price.toLowerCase().includes(filterValue)})
                                                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                                    .map((row, index) => {
                                                                        return (
                                                                            <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                                                                                {columns.map((column, dindex) => {
                                                                                    const value = row[column.id];
                                                                                    return (
                                                                                        <TableCell align={column.align} key={dindex}>
                                                                                            {column.format && typeof value === 'number'
                                                                                                ? column.format(value)
                                                                                                : value}
                                                                                        </TableCell>
                                                                                    );
                                                                                })}
                                                                            </TableRow>
                                                                        );
                                                                    }) :
                                                                <></>
                                                    }
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                        {loading ? <></> :
                                            <TablePagination
                                                rowsPerPageOptions={[10, 25, 100]}
                                                component="div"
                                                count={rowsState.length}
                                                rowsPerPage={rowsPerPage}
                                                page={page}
                                                onPageChange={handleChangePage}
                                                onRowsPerPageChange={handleChangeRowsPerPage}
                                            />
                                        }
                                    </Paper>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </div>
    )
}
