import React, { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import Navbar from "../components/Navbar";
import Axios from "axios";
import Sidenav from "../components/Sidenav";
import { Typography } from "@mui/material";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import {
  Box,
  Card,
  CardHeader,
  CardContent,
  Grid,
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  TablePagination,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import ReactJsAlert from "reactjs-alert";

const ViewDetails = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const userObject = Object.fromEntries(queryParams.entries());
  console.log(")))))))))))))))))", userObject);
  const [token, settoken] = useState(null);
  const [user, setUser] = useState(null);
  const [alertStatus, setAlertStatus] = React.useState(false);
  const [type, setType] = React.useState("success");
  const [title, setTitle] = React.useState("");

  useEffect(() => {
    const user = localStorage.getItem("user");
    const userde = JSON.parse(user);
    settoken(userde.token);
    const queryParams = new URLSearchParams(location.search);
    const userObject = Object.fromEntries(queryParams.entries());
    const logId = location?.state?.logId;
    console.log("view details", logId);
    Axios.get(
      `${process.env.REACT_APP_BASE_URL}api/admin/get-user-details/${logId}`,
      {
        headers: {
          Authorization: `Bearer ${userde.token}`,
        },
      }
    )
      .then((res) => {
        console.log("``````````````````````````````````", res.data);
        setUser(res.data.user[0]);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  console.log("$$$$$$$$$$$", user);

  const [isResetDialogOpen, setIsResetDialogOpen] = useState(false);
  const [password, setPassword] = useState("");
  const [userId, setUserId] = useState("");

  const handleEditPassword = (userId) => {
    console.log("useridddddddddddddd", userId);
    setUserId(userId);
    setIsResetDialogOpen(true);
  };

  const handleSave = () => {
    const user = localStorage.getItem("user");
    const userde = JSON.parse(user);
    // Create an object with userId and password
    const formData = {
      user_id: userId.toString(),
      password: password,
    };
    setIsResetDialogOpen(false);
    Axios.post(
      `${process.env.REACT_APP_BASE_URL}api/admin/update-password`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${userde.token}`,
        },
      }
    )
      .then((res) => {
        console.log(res.data);
        setAlertStatus(true);
        setType("success");
        setTitle("User password hase been updated");
      })
      .catch((error) => {
        console.log("error", error);
        setAlertStatus(true);
        setType("error");
        setTitle("User password not updated");
      });

    // Log the form submission as one object
    console.log("Form Submission:", formData);

    // Close the dialog
  };

  return (
    <div>
      <Navbar />
      <Box height={70} />
      <Box sx={{ display: "flex" }}>
        <Sidenav />
        <Box component="main" p={4} flexGrow={1}>
          <Paper
            elevation={3}
            sx={{
              boxShadow: 12,
              borderRadius: "20px",
              mt: 10,
              width: "100%",
              padding: 3,
            }}
          >
            <Typography variant="h4" gutterBottom sx={{ fontFamily: "Ubuntu" }}>
              User Details
            </Typography>
            <hr width="100%" />
            <Grid container spacing={2} sx={{ mt: 3 }}>
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1">
                  <strong>Name:</strong> {user?.name}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1">
                  <strong>Contact Number:</strong> {user?.contactNumber}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1">
                  <strong>Email:</strong> {user?.emailId}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1">
                  <strong>Company Name:</strong>{" "}
                  {user?.companyName === null ? "--" : user?.companyName}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1">
                  <strong>Mudzie Plan:</strong>{" "}
                  {user?.mudziePlan === null
                    ? "Not Subscribe"
                    : user?.mudziePlan}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                {user?.expiryDate === null ? (
                  <></>
                ) : (
                  <Typography variant="subtitle1">
                    <strong>Expiry Date:</strong> {user?.expiryDate}
                  </Typography>
                )}
                {/* <Typography variant="subtitle1">
                  <strong>Expiry Date:</strong>{" "}
                  {user?.expiryDate === null ? "" : user?.expiryDate}
                </Typography> */}
              </Grid>
              <Grid item xs={12} sm={6}>
                {user?.added_by == "admin" ? null : (
                  <Typography variant="subtitle1">
                    <strong>Call Request:</strong>{" "}
                    {user?.is_call_requested === 1
                      ? "Requested"
                      : "Not Requested"}
                  </Typography>
                )}
                {/* <Typography variant="subtitle1">
                  <strong>Call Request:</strong>{" "}
                  {userObject.is_call_requested || "N/A"}
                </Typography> */}
              </Grid>
            </Grid>
            {/* {userObject.is_call_requested === "0" ? (
              <></>
            ) : (
              <Box mt={3} textAlign="center">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleAllowClick}
                >
                  Allow
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  //onClick={handleCancelClick}
                  style={{ marginLeft: "16px" }}
                >
                  Cancel
                </Button>
              </Box>
            )} */}
            <Box mt={3} textAlign="center">
              <Button
                variant="contained"
                color="secondary"
                style={{ marginRight: "14px" }}
                onClick={() => window.history.back()} // Go back to the previous page
              >
                Go Back
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleEditPassword(user?.id)}
              >
                Edit Password
              </Button>
            </Box>
          </Paper>
        </Box>
      </Box>
      <Dialog
        open={isResetDialogOpen}
        onClose={() => setIsResetDialogOpen(false)}
      >
        <DialogTitle>Reset Password</DialogTitle>
        <DialogContent>
          <TextField
            label="New Password"
            fullWidth
            variant="outlined"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            style={{ marginTop: "16px" }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsResetDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSave} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <ReactJsAlert
        status={alertStatus} // true or false
        type={type} // success, warning, error, info
        title={title}
        autoCloseIn="30000"
        Close={() => {
          setAlertStatus(false);
          //   navigate("/", { replace: true });
        }}
      />
      {/* <Dialog
        open={showConfirmation}
        onClose={() => setShowConfirmation(false)}
      >
        <div>
          {showConfirmation && (
            <Alert
              severity="success"
              onClose={() => setShowConfirmation(false)}
            >
              <AlertTitle>success</AlertTitle>
              User Allow to Subscribe
            </Alert>
          )}
        </div>
      </Dialog>
      <Dialog open={showerror} onClose={() => setshowerror(false)}>
        <div>
          {showerror && (
            <Alert severity="error" onClose={() => setshowerror(false)}>
              <AlertTitle>Error</AlertTitle>
              Your Request Not Submit
            </Alert>
          )}
        </div>
      </Dialog> */}
    </div>
  );
};

export default ViewDetails;
