import React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
// import FormControlLabel from '@mui/material/FormControlLabel';
// import Checkbox from '@mui/material/Checkbox';
import { NavLink } from "react-router-dom";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
// import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import logo from "./images/favicon.jpg";
import Axios from "axios";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useUserData } from "./UserDataContext";
import ReactJsAlert from "reactjs-alert";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" target="_blank" href="https://kimmymae.com/">
        KimmyMae™
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const theme = createTheme();

export default function SignIn() {
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const { setUserData } = useUserData();
  const [alertStatus, setAlertStatus] = React.useState(false);
  const [type, setType] = React.useState("success");
  const [title, setTitle] = React.useState("");
  // const [login, setLogin] = useState(false);
  const onSubmit = ({ emailId, password }) => {
    const jsonobj = {
      emailId: emailId,
      password: password,
    };
    const url = `${process.env.REACT_APP_BASE_URL}`;
    console.log("((((((((((((((((", url);
    Axios.post(`${process.env.REACT_APP_BASE_URL}api/checkUser`, jsonobj, {
      "Content-Type": "application/json",
      // "Access-Control-Allow-Origin": "*",
      // "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
    })
      .then((res) => {
        console.log("***************", res.data);
        if (res.data.response === 0) {
          if (res.data.isVerified === "N") {
            setAlertStatus(true);
            setType("error");
            setTitle("Please verify your account");
          } else {
            setError("password", {
              type: "server",
              message: "Please enter correct email id and password.",
            });
          }
        } else {
          console.log("++++++++++++", res.data);

          if (res.data.is_admin === 1) {
            const admin = "admin";
            localStorage.setItem("userId", admin);
            const user = JSON.stringify(res.data);
            localStorage.setItem("user", user);
            navigate("/dashboard/settings", { replace: true });
          } else {
            localStorage.setItem("userId", res.data.userId);
            const user = JSON.stringify(res.data);
            localStorage.setItem("user", user);
            setUserData(res.data);
            navigate("/calculator", { replace: true });
          }

          // setLogin(true);
        }
      })
      .catch((error) => {
        // Handle errors here
        if (error.response) {
          // The request was made and the server responded with a status code
          console.error("Response Error:", error.response.data);
          console.error("Status Code:", error.response.status);
        } else if (error.request) {
          // The request was made but no response was received
          console.error("Request Error:", error.request);
        } else {
          // Something else went wrong
          console.error("Error:", error.message);
        }
      });
  };

  // useEffect(() => {
  //     if (login === true) {
  //         navigate('/calculator');
  //     }
  // }, [login]);

  return (
    <ThemeProvider theme={theme}>
      <Container component="main">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar src={logo} alt="KM" />
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Typography
            component="h1"
            variant="h5"
            color="primary"
            sx={{ fontWeight: "bold" }}
          >
            MUDZIE
          </Typography>
          <Typography>By KimmyMae™</Typography>
          <Typography sx={{ mt: 2 }}>
            Login for live market information and interest rates. Currently
            serving select partners in the HUD, RHS, DUS, and SBA markets.
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="emailId"
              label="Email Address"
              name="emailId"
              autoFocus
              {...register("emailId", {
                required: "Please enter email id.",
                pattern: {
                  value:
                    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                  message: "Please enter valid email id.",
                },
              })}
              error={Boolean(errors.emailId)}
              helperText={errors.emailId?.message}
              inputProps={{ maxLength: 250 }}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              {...register("password", { required: "Please enter password." })}
              error={Boolean(errors.password)}
              helperText={errors.password?.message}
              inputProps={{ maxLength: 100 }}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 2, mb: 2 }}
            >
              Login
            </Button>

            <Grid container>
              <Grid item xs>
                <Link component={NavLink} to="/forgotpassword" variant="body2">
                  Forgot password? Click here.
                </Link>
              </Grid>
              <Grid item>
                <Link component={NavLink} to={"/signup"} variant="body2">
                  {"Not a subscriber? Click here to join."}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Typography variant="body2" align="center" sx={{ mt: 2 }}>
          By clicking “Login”, you attest you have read and agree to be bound by
          KimmyMae™’s Terms and Conditions, Privacy Policy and disclaimers
          specific to the use of this tool. NOTE: Access to this tool is by
          express authorization only. The use of this tool by any persons other
          than the named subscriber is: i. strictly prohibited; ii. may cause
          the named subscriber to be banned from further access indefinitely,
          and; iii. is grounds for legal action against any perpetrators.
        </Typography>
        <Copyright sx={{ mt: 4, mb: 4 }} />
      </Container>
      <ReactJsAlert
        status={alertStatus} // true or false
        type={type} // success, warning, error, info
        title={title}
        autoCloseIn="30000"
        Close={() => {
          setAlertStatus(false);
          // navigate("/", { replace: true });
        }}
      />
    </ThemeProvider>
  );
}
